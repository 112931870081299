import * as React from 'react';
import { View } from '../../../components/Themed';
import { FlatList, Image, StyleSheet } from 'react-native';
import { Heading, UIText } from '../../../components/StyledText';
import i18n from '../../../translations';
import { Colors } from '../../../constants/Colors';
import Fonts from '../../../constants/Fonts';
import { Activity, ActivityStats, Dictionary, Review } from '../../../constants/Interfaces';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import EventCardFragment from './EventCardFragment';
import { EventStatsFragment } from './EventStatsFragment';
import Logger from '../../../constants/Logger';
import { AntDesign } from '@expo/vector-icons';
import { useRef, useState } from 'react';
import ReviewCardFragment from './ReviewCardFragment';
import { ActionModal } from '../../../components/ActionModal';
import ReviewFragment from './ReviewFragment';
import Layout from '../../../constants/Layout';
import { ActivityStatsFragment } from './ActivityStatsFragment';
import { convertToNZ, validatePastActivity } from '../../../constants/Utils';
import { ActivityTile } from '../../../components/ActivityTile';
import PrimaryButton from '../../../components/buttons/PrimaryButton';

export const OrganiserDashboardFragment = ({ navigation, managedActivities }) => {
  const pastActivities: Activity[] = useSelector((state: any) =>
    _.orderBy(state.activity.pastActivities, ['startDate'])
  );
  const managedCurrentActivities: Activity[] = useSelector(() =>
    _.filter(managedActivities || [], (activity) => !validatePastActivity(activity.endDate))
  );
  const activityStats: Dictionary<ActivityStats> = useSelector((state: any) => state.activity.stats);
  const reviews: Review[] = useSelector((state: any) =>
    _.orderBy(state.review.reviews, ['lastUpdated'], 'desc').slice(0, 5)
  );
  const [reviewModal, setReviewModal] = useState(false);
  const [activityStatsModal, setActivityStatsModal] = useState<boolean>(false);
  const [currentReview, setCurrentReview] = useState<Review>();
  const [currentActivityStats, setCurrentActivityStats] = useState<ActivityStats>();

  const carouselRef = useRef<FlatList>(null);

  const viewabilityConfig = useRef({
    itemVisiblePercentThreshold: 10,
  });

  const handleOpenWhanauList = (activity: Activity) => {
    Logger.debug(`Opening whanau list for activity ${activity.activityId}`);
    const currentStats: ActivityStats = activityStats[activity.activityId];
    if (currentStats) {
      setCurrentActivityStats(currentStats);
      setActivityStatsModal(true);
    }
  };

  const handleOpenReview = (review: Review) => {
    setCurrentReview(review);
    setReviewModal(true);
  };

  const handleCloseModal = () => {
    setReviewModal(false);
    setActivityStatsModal(false);
  };
  const handleOpenActivity = (activityId: string) => {
    Logger.debug(`Screen -> ActivitiesScreen -> handleOpenActivity: Navigating to activity ${activityId}`);
    navigation.navigate('DashboardStack', {
      screen: 'ActivityDetails',
      params: { activityId },
    });
  };

  return (
    <View transparent style={styles.root}>
      <View style={styles.mainContainer}>
        <Heading style={styles.heading}>{i18n.t('components.OrganiserDashboardFragment.dashboard')}</Heading>
        <Heading style={[styles.heading, styles.subHeading]}>
          {i18n.t('components.OrganiserDashboardFragment.eventorganiser')}
        </Heading>
        <View transparent style={styles.nextContainer}>
          <UIText style={styles.sectionHeader}>{i18n.t('components.OrganiserDashboardFragment.nextactivity')}</UIText>
          <FlatList
            decelerationRate="fast"
            ref={carouselRef}
            data={managedCurrentActivities}
            keyExtractor={(item) => item.activityId}
            ItemSeparatorComponent={() => <View style={{ width: 10 }} transparent />}
            viewabilityConfig={viewabilityConfig.current}
            renderItem={({ item }) => (
              <>
                <EventCardFragment
                  title={item.activityName!}
                  subTitle={convertToNZ({ date: item.startDate!, format: 'EEEE dd MMMM,  h:mma' })}
                  imageUrl={item.imageUrl}
                  activityId={item.activityId}
                  onPress={() => handleOpenActivity(item?.activityId!)}
                  splitButton={
                    <PrimaryButton
                      title={i18n.t('components.OrganiserDashboardFragment.whanaulist')}
                      onPress={() => handleOpenWhanauList(item)}
                      buttonStyle={{
                        height: 45.6,
                        borderWidth: 2,
                        borderRadius: 37,
                        alignItems: 'center',
                        borderColor: Colors.yellow,
                      }}
                      containerStyle={{ marginRight: 'auto', paddingVertical: 0 }}
                      textStyle={{ color: Colors.yellow, fontFamily: Fonts.family.bold }}
                    />
                  }
                />
                <View style={styles.statsContainer}>
                  <UIText style={styles.scansLabel}>{i18n.t('components.OrganiserDashboardFragment.scans')}</UIText>
                  <UIText style={styles.scansValue}>
                    {activityStats[item.activityId]?.engagementTypeCheckedIn.personTotal}
                  </UIText>
                </View>
              </>
            )}
            horizontal
            showsHorizontalScrollIndicator={false}
          />
        </View>
      </View>
      <View>
        <View style={styles.cornersContainer}>
          <View style={{ backgroundColor: 'white' }}>
            <View style={[styles.corner, { borderBottomStartRadius: 35 }]} />
          </View>
          <View style={{ backgroundColor: 'white' }}>
            <View style={[styles.corner, { borderBottomEndRadius: 20 }]} />
          </View>
        </View>
        <View transparent style={styles.activitiesContainer}>
          <UIText style={styles.sectionHeader}>
            {i18n.t('components.OrganiserDashboardFragment.upcomingActivities')}
          </UIText>
          {managedCurrentActivities && managedCurrentActivities.length > 1 ? (
            <FlatList
              horizontal
              scrollEnabled
              overScrollMode="never"
              showsHorizontalScrollIndicator={false}
              data={managedCurrentActivities.slice(1)}
              renderItem={({ item }) => (
                <ActivityTile
                  activity={item}
                  onPress={() => handleOpenActivity(item.activityId!)}
                  background="white"
                  whiteWidth={Layout.isSmallDevice ? 300 : 200}
                />
              )}
              getItemLayout={(data, index) => ({ length: 200, offset: 200 * index, index })}
              ItemSeparatorComponent={() => <View style={{ width: 10 }} transparent />}
              keyExtractor={(item) => item.activityId}
            />
          ) : null}
          <View transparent>
            <View style={styles.pastAcitiviesLabelContainer}>
              <UIText style={styles.sectionHeader}>
                {i18n.t('components.OrganiserDashboardFragment.pastactivity')}
              </UIText>
              <AntDesign name="arrowright" size={24} color={Colors.darkBurgundy} />
            </View>
            {pastActivities && pastActivities.length > 0 ? (
              <FlatList
                horizontal
                scrollEnabled
                overScrollMode="never"
                showsHorizontalScrollIndicator={false}
                data={pastActivities}
                renderItem={({ item }) => (
                  <EventStatsFragment activity={item} onPress={() => handleOpenActivity(item.activityId!)} />
                )}
                getItemLayout={(data, index) => ({ length: 300, offset: 300 * index, index })}
                keyExtractor={(item) => item.activityId}
              />
            ) : null}
          </View>
          <View transparent style={styles.reviewsContainer}>
            <View transparent style={styles.labelContainer}>
              <UIText style={styles.sectionHeader}>
                {i18n.t('components.OrganiserDashboardFragment.recentreviews')}
              </UIText>
              <Image source={require('../../../../assets/images/matariki-stars.png')} style={styles.reviewStar} />
            </View>
            <FlatList
              data={reviews}
              keyExtractor={(item, index) => index.toString()}
              renderItem={({ item }) => (
                <ReviewCardFragment review={item} onPress={handleOpenReview} labelColor="black" />
              )}
            />
          </View>
          <ActionModal visible={reviewModal} onModalClose={handleCloseModal} height={400}>
            {currentReview ? <ReviewFragment content={currentReview} /> : null}
          </ActionModal>
          <ActionModal
            visible={activityStatsModal}
            onModalClose={handleCloseModal}
            height={Layout.isSmallDevice ? Layout.window.height - 50 : 700}
          >
            {currentActivityStats ? <ActivityStatsFragment activityStats={currentActivityStats} /> : null}
          </ActionModal>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    minHeight: Layout.window.height,
    backgroundColor: Colors.white,
    marginBottom: 200,
  },
  mainContainer: {
    paddingHorizontal: Layout.backgroundMarginNarrow.left,
    backgroundColor: Colors.darkBurgundy,
  },
  heading: {
    marginBottom: 8,
    color: Colors.white,
    fontSize: Fonts.sizes.smallHeading,
  },
  subHeading: {
    fontFamily: Fonts.family.regular,
  },
  sectionHeader: {
    color: Colors.darkBurgundy,
    fontFamily: Fonts.family.bold,
    fontSize: Fonts.sizes.medium,
    marginVertical: 8,
  },
  nextContainer: {},
  buttonIcon: {
    paddingTop: 2,
    paddingRight: 8,
  },
  statsContainer: {
    position: 'absolute',
    right: 2,
    top: 4,
    backgroundColor: Colors.backgroundYellow,
    width: 50,
    height: 50,
    borderRadius: 50,
    alignItems: 'center',
    paddingTop: 10,
  },
  scansLabel: {
    fontSize: 9,
  },
  scansValue: {
    fontFamily: Fonts.family.bold,
  },
  activitiesContainer: {
    paddingHorizontal: 15,
  },
  reviewsContainer: {
    marginTop: 12,
  },
  labelContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 8,
  },
  reviewStar: {
    height: 34,
    width: 34,
    resizeMode: 'contain',
    marginLeft: 6,
  },
  cornersContainer: {
    backgroundColor: Colors.darkBurgundy,
    flexDirection: 'row',
    height: 15,
    width: '100%',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    borderRadius: 35,
    top: -15,
  },
  corner: {
    backgroundColor: Colors.darkBurgundy,
    height: 15,
    width: Layout.backgroundMarginNarrow.left,
    left: 0,
    borderWidth: 1,
    borderColor: Colors.darkBurgundy,
  },
  pastAcitiviesLabelContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});
