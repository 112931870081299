import React, { useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { Colors } from '../../../../constants/Colors';
import PrimaryButton from '../../../../components/buttons/PrimaryButton';
import { User } from '../../../../constants/Interfaces';
import Layout from '../../../../constants/Layout';
import MyWhanauListFragment from './MyWhanauListFragment';
import WhanauDetailsFragment from './WhanauDetailsFragment';
import i18n from '../../../../translations';

interface MyWhanauFragmentProps {
  activityId?: string;
  hideFooter: Function;
}

const MyWhanauFragment = ({ activityId = undefined, hideFooter }: MyWhanauFragmentProps) => {
  const [showNewWhanau, setShowNewWhanau] = useState<boolean>(!!activityId || false);
  const [whanauDetails, setWhanauDetails] = useState<boolean>(false);
  const [whanauSelected, setWhanauSelected] = useState<User>({});

  const handleWhanauSelected = (user: User) => {
    setWhanauSelected(user);
  };

  return (
    <View style={styles.root}>
      {!showNewWhanau && !whanauDetails && (
        <>
          <PrimaryButton
            themeColor="yellow"
            title={i18n.t('components.MyWhanauFragment.addWhanau')}
            onPress={() => setShowNewWhanau(true)}
            textStyle={{ color: Colors.white }}
            buttonStyle={styles.button}
          />
          <View style={styles.listContainer}>
            <MyWhanauListFragment openDetails={setWhanauDetails} onSelect={handleWhanauSelected} />
          </View>
        </>
      )}
      {showNewWhanau && (
        <WhanauDetailsFragment
          openDetails={setShowNewWhanau}
          activityId={activityId}
          hideFooter={hideFooter}
          isAddWhanau
        />
      )}

      {whanauDetails && (
        <WhanauDetailsFragment openDetails={setWhanauDetails} user={whanauSelected} hideFooter={hideFooter} />
      )}
    </View>
  );
};
export default MyWhanauFragment;

const styles = StyleSheet.create({
  root: {
    alignItems: 'center',
    width: Layout.window.width,
  },
  button: {
    height: 45,
    width: 200,
    borderRadius: 28,
  },
  listContainer: {
    height: Layout.window.height - 346,
    width: '100%',
    paddingHorizontal: '6%',
  },
});
