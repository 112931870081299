import React, { useRef, useState } from 'react';
import { Image } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import Toast from 'react-native-toast-message';
import { View } from '../../components/Themed';
import { StackActions } from '@react-navigation/native';
import { Heading, Link } from '../../components/StyledText';
import i18n from '../../translations';
import TextInput from '../../components/inputs/TextInput';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import Logger from '../../constants/Logger';
import { AppError, AuthState, User } from '../../constants/Interfaces';
import { PickerInput } from '../../components/inputs/PickerInput';
import CalendarInput from '../../components/inputs/CalendarInput';
import { logOut, parseJwt } from '../../constants/Utils';
import { styles } from './RegistrationCommonStyles';
import { patchUser, skipUserDetails } from '../../reducers/user.slice';
import { DateTime } from 'luxon';
import { listOfEthnicities, listOfGenders } from '../../constants/References';
import Layout from '../../constants/Layout';
import { purge } from '../../reducers/commonActions';

export default function UserDetailsScreen({ navigation }) {
  const dispatch = useDispatch();
  const userLoading: boolean = useSelector((state: any) => state.user.loading);
  const user: User = useSelector((state: any) => state.user.user);
  const userDetailsSkipped: boolean = useSelector((state: any) => state.user.userDetailsSkipped);
  const userBackgroundLoading: boolean = useSelector((state: any) => state.user.backgroundLoading);
  const userError: AppError = useSelector((state: any) => state.user.error);
  const authState: AuthState | null = useSelector((state: any) => state.authentication.authState);

  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [gender, setGender] = useState<string>();
  const [ethnicity, setEthnicity] = useState<string>();
  const [doB, setDoB] = useState<string>();
  const [phone, setPhone] = useState<string>();
  const [email, setEmail] = useState<string>();

  const refStub = {
    hide: () => {},
  };

  const genderRef = useRef(refStub);
  const ethnicityRef = useRef(refStub);

  let shouldCollectEmail: boolean = false;

  const submitForm = async () => {
    Logger.debug('Submitting the form now');
    const user: User = {
      firstName,
      lastName,
      phone,
      gender,
      ethnicity,
      doB: doB ? DateTime.fromISO(doB).toISODate() : undefined,
    };
    if (shouldCollectEmail && email) {
      user.email = email;
    }
    try {
      dispatch(await patchUser(user));
    } catch (e: any) {
      Logger.debug(`Error occurred: ${e.message}`);
    }
  };

  const handleSkip = async () => {
    dispatch(skipUserDetails());
  };

  /**
   * Handle the global clicking events and hide all dropdowns
   */
  const handleBlur = () => {
    genderRef.current.hide();
    ethnicityRef.current.hide();
  };

  const handleCancel = () => {
    logOut();
  };

  /**
   * Get the exact Y coordinate to display the dropdown.
   * @param position
   */
  const getDropdownY = (position: number) => 20 + 60 * position + 9 * (position - 1);

  const isFormValid = () => firstName && lastName && gender && ethnicity && doB;

  if (authState) {
    const { accessToken } = authState;
    const token = parseJwt(accessToken);
    shouldCollectEmail = !token || !token.emails || token.emails.length === 0;
  }

  React.useEffect(() => {
    if (user && user.firstName) {
      navigateToMarae();
    }
  }, [user]);

  React.useEffect(() => {
    if (userError) {
      Toast.show({
        type: 'error',
        position: 'bottom',
        autoHide: false,
        text1: userError.message,
      });
    }
  }, [userError]);

  React.useEffect(() => {
    if (userDetailsSkipped) {
      navigateToMarae();
    }
  }, [userDetailsSkipped]);

  const navigateToMarae = () => {
    navigation.dispatch(StackActions.replace('AddMarae'));
  };

  return (
    <View style={styles.container}>
      <KeyboardAwareScrollView
        extraScrollHeight={30}
        keyboardOpeningTime={500}
        viewIsInsideTabBar
        enableAutomaticScroll
        contentContainerStyle={[styles.scrollContainer, { flexGrow: 0 }]}
        enableResetScrollToCoords={false}
        enableOnAndroid
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        removeClippedSubviews={false}
        keyboardShouldPersistTaps="handled"
        nestedScrollEnabled={false}
        bounces={false}
      >
        <Image
          source={require('../../../assets/images/splash_top.png')}
          style={styles.topImage}
          resizeMethod="resize"
        />
        <Image
          source={require('../../../assets/images/mf_logo_dark_no_text.png')}
          style={styles.topLogo}
          resizeMethod="scale"
        />
        <View transparent style={styles.skipCancelContainer}>
          <Link onPress={handleCancel} title={i18n.t('pages.UserDetailsScreen.cancel')} />
          <View transparent style={styles.filler} />
          <Link onPress={handleSkip} title={i18n.t('pages.UserDetailsScreen.skip')} />
        </View>
        <View transparent style={styles.headerContainer}>
          <Heading style={styles.heading}>{i18n.t('pages.UserDetailsScreen.title')}</Heading>
        </View>
        <View
          transparent
          style={[styles.formContainer, { width: Layout.window.width, marginTop: Layout.backgroundMarginNormal.top }]}
        >
          {shouldCollectEmail ? (
            <TextInput
              value={email}
              onFocus={handleBlur}
              onChangeText={(email) => setEmail(email)}
              label={i18n.t('pages.UserDetailsScreen.email')}
            />
          ) : null}
          <TextInput
            value={firstName}
            onFocus={handleBlur}
            onChangeText={(firstName) => setFirstName(firstName)}
            label={i18n.t('pages.UserDetailsScreen.firstName')}
          />
          <TextInput
            value={lastName}
            onFocus={handleBlur}
            onChangeText={(lastName) => setLastName(lastName)}
            label={i18n.t('pages.UserDetailsScreen.surname')}
          />
          <PickerInput
            ref={genderRef}
            maxItems={6}
            placeholder={i18n.t('pages.UserDetailsScreen.gender')}
            data={listOfGenders}
            value={gender}
            onSelect={(gender: string) => {
              setGender(gender);
            }}
            onActive={handleBlur}
            dropdownStyle={{
              top: getDropdownY(3),
              marginHorizontal: Layout.backgroundMarginNormal.left,
            }}
          />
          <PickerInput
            ref={ethnicityRef}
            placeholder={i18n.t('pages.UserDetailsScreen.ethnicity')}
            maxItems={6}
            data={listOfEthnicities}
            value={ethnicity}
            onSelect={(ethnicity: string) => {
              setEthnicity(ethnicity);
            }}
            onActive={handleBlur}
            dropdownStyle={{
              top: getDropdownY(4),
              marginHorizontal: Layout.backgroundMarginNormal.left,
            }}
          />
          <CalendarInput
            onChange={(date) => setDoB(date)}
            value={doB}
            placeholder={i18n.t('pages.UserDetailsScreen.dob')}
            onActive={handleBlur}
          />
          <TextInput
            value={phone}
            onChangeText={(phone) => setPhone(phone)}
            label={`${i18n.t('pages.UserDetailsScreen.mobile')}`}
            keyboardType="numeric"
          />
          <PrimaryButton
            loading={userLoading || userBackgroundLoading}
            disabled={!isFormValid() || userLoading}
            title={i18n.t('pages.UserDetailsScreen.next')}
            onPress={submitForm}
            containerStyle={styles.submitButton}
          />
        </View>
      </KeyboardAwareScrollView>
      <Image
        source={require('../../../assets/images/splash_bottom.png')}
        style={[styles.bottomImage, { height: 150 }]}
        resizeMethod="resize"
      />
    </View>
  );
}
