import React from 'react';
import { StyleSheet, View } from 'react-native';
import CarouselAds from '../../CarouselAds';
import { Colors } from '../../../constants/Colors';

const CarouselKaupapa = ({ listData, isLayoutAbsolute = false, whiteText = false, isBlackDot = false }) => (
  <>
    <View style={[styles.body, isLayoutAbsolute ? styles.absoluteLayOut : {}, whiteText ? { bottom: '15%' } : {}]}>
      <View style={styles.carouselContainer}>
        {listData && (
          <CarouselAds data={listData} isWide showStoreButtons={false} whiteText={whiteText} isBlackDot={isBlackDot} />
        )}
      </View>
    </View>
  </>
);

export default CarouselKaupapa;

const styles = StyleSheet.create({
  body: {
    backgroundColor: Colors.transparent,
    width: '100%',
    height: 250,
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  absoluteLayOut: {
    position: 'absolute',
    bottom: 0,
  },
  carouselContainer: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
  },
  icons: {
    height: 60,
    resizeMode: 'contain',
  },
  image: {
    width: '40%',
    height: 240,
    resizeMode: 'contain',
  },
});
