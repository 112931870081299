import React, { useState } from 'react';
import { View, StyleSheet, Image } from 'react-native';
import { SplitButton } from '../../../../components/buttons/SplitButton';
import { UIText } from '../../../../components/StyledText';
import { Colors } from '../../../../constants/Colors';
import Fonts from '../../../../constants/Fonts';
import i18n from '../../../../translations';
import * as Updates from 'expo-updates';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Logger from '../../../../constants/Logger';
import ModalUI from '../../../../components/ModalUI';

const LanguageFragment = () => {
  const language = i18n.locale;
  const [active, setActive] = useState(false);

  const saveLanguage = async (language: string) => {
    try {
      await AsyncStorage.setItem('language', language);
    } catch (e) {
      Logger.error(`Error changing the language`, e);
      throw e;
    }
  };

  const handleEnglish = async () => {
    if (language !== 'en') {
      await saveLanguage('en');
      setActive(true);
    }
  };

  const handleMaori = async () => {
    if (language !== 'mi') {
      await saveLanguage('mi');
      setActive(true);
    }
  };

  return (
    <>
      <View style={styles.root}>
        <View style={styles.note}>
          <View style={styles.noteIconContainer}>
            <Image source={require('../../../../../assets/images/megaphoneIcon.png')} style={styles.noteIcon} />
          </View>
          <View style={styles.noteLabel}>
            <UIText style={[styles.noteText, { color: Colors.black }]}>
              {i18n.t('components.LanguageFragment.language')}
            </UIText>
          </View>
        </View>
        <SplitButton
          iconLeft={{
            left: (
              <Image
                source={require('../../../../../assets/images/englishLanguage.png')}
                style={styles.buttonLeftIcon}
              />
            ),
          }}
          iconRight={{
            left: (
              <View style={styles.iconContainer}>
                <Image
                  source={require('../../../../../assets/images/maoriLanguage.png')}
                  style={styles.buttonRightIcon}
                />
              </View>
            ),
          }}
          backgroundColorLeft={Colors.darkBurgundy}
          backgroundColorRight={Colors.yellow}
          labelLeft={i18n.t('components.LanguageFragment.english')}
          labelRight={i18n.t('components.LanguageFragment.maori')}
          labelColorRight={Colors.darkBurgundy}
          labelColorLeft={Colors.yellow}
          borderColor={Colors.yellow}
          onPressLeft={handleEnglish}
          onPressRight={handleMaori}
          leftRightRatio={0.85}
          leftContainerStyles={{ paddingLeft: 10 }}
          rigthContainerStyles={{ justifyContent: 'flex-start', paddingLeft: 10 }}
        />
      </View>
      <ModalUI toggle={active} closeModal={() => setActive(false)}>
        <UIText style={styles.modalMessage}>{i18n.t('components.LanguageFragment.confirmReset')}</UIText>
        <SplitButton
          labelLeft="Cancel"
          labelRight="Confirm"
          backgroundColorLeft={Colors.white}
          backgroundColorRight={Colors.darkBurgundy}
          onPressLeft={() => setActive(false)}
          onPressRight={() => Updates.reloadAsync()}
        />
      </ModalUI>
    </>
  );
};

export default LanguageFragment;

const styles = StyleSheet.create({
  root: {
    marginHorizontal: '6%',
  },
  note: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.white,
    borderRadius: 5,
    width: '100%',
    paddingHorizontal: 5,
    paddingVertical: 15,
    marginTop: 20,
    marginBottom: 25,
  },
  noteIconContainer: {
    backgroundColor: Colors.white,
    borderRadius: 20,
    width: 30,
    height: 30,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 10,
    borderWidth: 2,
    borderColor: Colors.black,
  },
  noteIcon: {
    height: 20,
    width: 20,
  },
  noteLabel: {
    paddingHorizontal: 10,
    width: '90%',
  },
  noteText: {
    color: Colors.white,
    fontSize: Fonts.sizes.medium,
  },
  buttonLeftIcon: {
    width: 30,
    height: 30,
    marginRight: 5,
  },
  iconContainer: {
    width: 25,
    height: 25,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 2,
    borderColor: Colors.black,
    borderRadius: 25,
    paddingBottom: 5,
    marginRight: 5,
  },
  buttonRightIcon: {
    width: 30,
    height: 30,
    resizeMode: 'contain',
  },
  modalMessage: {
    marginBottom: 15,
    alignSelf: 'center',
  },
});
