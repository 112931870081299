import React, { useState } from 'react';
import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import { View, StyleSheet, Image, Pressable, TextInput } from 'react-native';
import { SplitButton } from '../../../components/buttons/SplitButton';
import { Heading, Paragraph, UIText } from '../../../components/StyledText';
import { Colors } from '../../../constants/Colors';
import Fonts from '../../../constants/Fonts';
import Layout from '../../../constants/Layout';
import i18n from '../../../translations';
import { DateTime } from 'luxon';
import { postUserActivityReview } from '../../../reducers/review.slice';
import { Activity, Review, User } from '../../../constants/Interfaces';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

interface ReviewFragmentProps {
  handleGoback: () => void;
  handleDone: () => void;
  activityId: string;
}

const ReviewFragment = ({ handleGoback, handleDone, activityId }: ReviewFragmentProps) => {
  const user: User = useSelector((state: any) => state.user.user);
  const activity: Activity = useSelector((state: any) =>
    _.find(state.activity.activities.concat(state.activity.activity), ['activityId', activityId])
  );
  const dispatch = useDispatch();
  const [score, setScore] = useState(0);
  const handleRate = (score: number) => {
    setScore(score + 1);
  };
  const [review, setReview] = React.useState('');
  const date = DateTime.now().toFormat('MMM dd, yyyy');

  const handlesubmit = () => {
    if (review === '') {
      handleDone();
      return;
    }
    const payload: Review = {
      activityId,
      userId: user.userId,
      score,
      review,
      firstName: user.firstName,
      lastName: user.lastName,
      lastUpdated: DateTime.now().toISO(),
      activityName: activity.activityName,
    };
    dispatch(postUserActivityReview(payload));
    handleDone();
  };

  return (
    <View style={styles.root}>
      <Heading>{i18n.t('components.ReviewFragment.review')}</Heading>
      <Paragraph style={styles.paragraphModal}>{i18n.t('components.ReviewFragment.rateActivity')}</Paragraph>
      <View style={styles.rateContainer}>
        {[...new Array(9)].map((item, index: number) => (
          <StarCard score={index} setScore={handleRate} onSelect={score > index} key={`rating-${index}`} />
        ))}
      </View>
      <View style={styles.reviewContainer}>
        <TextInput
          style={styles.inputReview}
          onChangeText={(text) => setReview(text)}
          placeholder="Write review"
          multiline
        />
        <View style={{ flexShrink: 0 }}>
          <UIText>_</UIText>
          <UIText style={styles.dateLabel}>{date}</UIText>
        </View>
      </View>
      <View style={styles.buttonContainer}>
        <SplitButton
          iconLeft={{
            left: <MaterialIcons name="arrow-back-ios" size={16} color={Colors.darkBurgundy} style={[styles.icon]} />,
          }}
          iconRight={{
            left: <MaterialCommunityIcons name="check-all" size={16} color={Colors.white} style={[styles.icon]} />,
          }}
          labelLeft={i18n.t('pages.ActivityDetailsScreen.goBack')}
          labelRight={i18n.t('pages.ActivityDetailsScreen.done')}
          onPressLeft={handleGoback}
          onPressRight={handlesubmit}
          leftRightRatio={0.85}
        />
      </View>
    </View>
  );
};
const StarCard = ({ score, setScore, onSelect }) => (
  <Pressable style={[styles.starContainer, { opacity: onSelect ? 1 : 0.3 }]} onPress={() => setScore(score)}>
    <Image style={styles.starRate} source={require('../../../../assets/images/redStar.png')} />
  </Pressable>
);

export default ReviewFragment;

const styles = StyleSheet.create({
  root: {
    margin: 0,
    zIndex: 1000,
    backgroundColor: Colors.yellow,
    borderTopStartRadius: 45,
    borderTopEndRadius: 45,
    paddingHorizontal: Layout.backgroundMarginNormal.left,
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  paragraphModal: {
    marginVertical: 15,
    textAlign: 'center',
    fontSize: Fonts.sizes.medium,
    width: '90%',
  },
  subTitle: {
    fontFamily: Fonts.family.bold,
    fontSize: Fonts.sizes.default,
    marginTop: 20,
  },
  rateContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  starContainer: {
    backgroundColor: Colors.whiteOpacity,
    justifyContent: 'center',
    alignItems: 'center',
    height: 45,
    width: 35,
    marginHorizontal: 1,
    borderRadius: 10,
  },
  starRate: {
    height: 30,
    width: 25,
  },
  reviewContainer: {
    width: '100%',
    height: '50%',
    borderColor: Colors.darkBurgundy,
    marginVertical: 10,
    padding: 20,
    borderWidth: 2,
    justifyContent: 'space-between',
  },
  inputReview: {
    fontFamily: Fonts.family.regular,
    width: '100%',
    color: Colors.black,
    flex: 1,
    textAlignVertical: 'top',
  },
  dateLabel: {},
  buttonContainer: {
    width: '100%',
    alignItems: 'center',
    marginTop: 30,
  },
  icon: {
    height: 20,
    width: 20,
    marginRight: 4,
    paddingTop: 2,
  },
});
