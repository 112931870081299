import * as React from 'react';
import { View } from '../../../../components/Themed';
import Logger from '../../../../constants/Logger';
import { User } from '../../../../constants/Interfaces';
import { DateTime } from 'luxon';
import { getUser, patchUser } from '../../../../reducers/user.slice';
import { useDispatch, useSelector } from 'react-redux';
import TextInput from '../../../../components/inputs/TextInput';
import i18n from '../../../../translations';
import { PickerInput } from '../../../../components/inputs/PickerInput';
import { listOfEthnicities, listOfGenders } from '../../../../constants/References';
import CalendarInput from '../../../../components/inputs/CalendarInput';
import { StyleSheet } from 'react-native';
import PrimaryButton from '../../../../components/buttons/PrimaryButton';
import { Colors } from '../../../../constants/Colors';
import ModalUI from '../../../../components/ModalUI';
import { UIText } from '../../../../components/StyledText';
import { useFocusEffect } from '@react-navigation/native';
import BottomBanner from '../../../../components/BottomBanner';

export const DetailsFragment = ({ hideFooter }: { hideFooter: Function }) => {
  const dispatch = useDispatch();

  const user: User = useSelector((state: any) => state.user.user);
  const userLoading: boolean = useSelector((state: any) => state.user.loading);
  const userBackgroundLoading: boolean = useSelector((state: any) => state.user.backgroundLoading);

  const [firstName, setFirstName] = React.useState<string>(user?.firstName ? user.firstName : '');
  const [lastName, setLastName] = React.useState<string>(user?.lastName ? user.lastName : '');
  const [gender, setGender] = React.useState<string>(user?.gender ? user.gender : '');
  const [ethnicity, setEthnicity] = React.useState<string>(user?.ethnicity ? user.ethnicity : '');
  const [doB, setDoB] = React.useState<string>(user?.doB ? user.doB : '');
  const [phone, setPhone] = React.useState<string>(user?.phone ? user.phone : '');
  const [email, setEmail] = React.useState<string>(user?.email ? user.email : '');
  const [isDefaultDoB, setIsDefaultDoB] = React.useState<boolean>(false);

  const refStub = {
    hide: () => {},
  };
  const genderRef = React.useRef(refStub);
  const ethnicityRef = React.useRef(refStub);

  const getDropdownY = (position: number) => 4 + 60 * position + 10 * (position - 1);

  useFocusEffect(
    React.useCallback(() => {
      hideFooter(true);
      return () => {
        hideFooter(false);
      };
    }, [])
  );
  /**
   * Handle the global clicking events and hide all dropdowns
   */
  const handleBlur = () => {
    genderRef.current.hide();
    ethnicityRef.current.hide();
  };

  const isFormValid = () => firstName && lastName && gender && ethnicity && doB;

  const submitForm = async () => {
    Logger.debug('Submitting the form now');
    const user: User = {
      firstName,
      lastName,
      phone,
      gender,
      ethnicity,
      doB: doB ? DateTime.fromISO(doB).toFormat('yyyy-MM-dd') : undefined,
    };
    if (email && email.length > 0) {
      user.email = email;
    }
    if (doB && doB === '0001-01-01T00:00:00') {
      setIsDefaultDoB(true);
    } else {
      try {
        await dispatch(patchUser(user));
        dispatch(getUser());
      } catch (e) {
        Logger.debug(`Error occurred: ${e.message}`);
      }
    }
  };

  return (
    <View style={styles.scrollContainer}>
      <View transparent style={styles.container}>
        <TextInput
          value={firstName}
          onFocus={handleBlur}
          onChangeText={(firstName) => setFirstName(firstName)}
          label={i18n.t('components.DetailsFragment.firstName')}
        />
        <TextInput
          value={lastName}
          onFocus={handleBlur}
          onChangeText={(lastName) => setLastName(lastName)}
          label={i18n.t('components.DetailsFragment.surname')}
        />
        <PickerInput
          ref={genderRef}
          maxItems={6}
          placeholder={i18n.t('components.DetailsFragment.gender')}
          data={listOfGenders}
          value={gender}
          onSelect={(gender: string) => {
            setGender(gender);
          }}
          onActive={handleBlur}
          dropdownStyle={{
            left: 32,
            top: getDropdownY(3),
          }}
        />
        <PickerInput
          ref={ethnicityRef}
          placeholder={i18n.t('components.DetailsFragment.ethnicity')}
          maxItems={6}
          data={listOfEthnicities}
          value={ethnicity}
          onSelect={(ethnicity: string) => {
            setEthnicity(ethnicity);
          }}
          onActive={handleBlur}
          dropdownStyle={{
            left: 32,
            top: getDropdownY(4),
          }}
        />
        <CalendarInput
          onChange={(date) => setDoB(date)}
          value={doB}
          placeholder={i18n.t('components.DetailsFragment.dob')}
          onActive={handleBlur}
        />

        <TextInput
          value={email}
          disabled={!!user?.email}
          onFocus={handleBlur}
          onChangeText={(email) => setEmail(email)}
          label={i18n.t('components.DetailsFragment.email')}
        />
        <TextInput
          value={phone}
          onChangeText={(phone) => setPhone(phone)}
          label={`${i18n.t('components.DetailsFragment.mobile')} (${i18n.t('components.DetailsFragment.optional')})`}
          keyboardType="numeric"
        />
        <PrimaryButton
          themeColor="yellow"
          loading={userLoading || userBackgroundLoading}
          disabled={!isFormValid() || userLoading}
          title={i18n.t('components.DetailsFragment.save')}
          onPress={() => submitForm()}
          containerStyle={styles.submitButton}
        />
        <View style={{ height: 95, backgroundColor: Colors.darkBurgundy }} />
      </View>
      <View style={styles.footerContainer}>
        <BottomBanner themeColor="white" />
      </View>
      <ModalUI toggle={isDefaultDoB} closeModal={() => setIsDefaultDoB(false)}>
        <UIText>{i18n.t('components.DetailsFragment.invalidDoB')}</UIText>
      </ModalUI>
    </View>
  );
};

const styles = StyleSheet.create({
  scrollContainer: {
    width: '100%',
    paddingHorizontal: 15,
    backgroundColor: Colors.darkBurgundy,
    height: 880,
  },
  container: {
    marginTop: 24,
    paddingHorizontal: 32,
  },
  submitButton: {
    alignItems: 'center',
    marginTop: 4,
    zIndex: 3,
  },
  footerContainer: {
    left: -15,
    marginTop: 15,
  },
});
