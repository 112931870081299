import React from 'react';
import { View, Pressable } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons';
import { Colors } from '../../../constants/Colors';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';

const VideoPlayer = ({ videoUrl, close }) => {
  const styles = useStyles();

  return (
    <View style={styles.root}>
      <View style={styles.videoContainer}>
        <iframe
          width="100%"
          height="100%"
          src={videoUrl}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          style={{ position: 'relative', zIndex: 999 }}
        />
      </View>
      <Pressable onPress={close} style={styles.closeModal}>
        <MaterialIcons name="close" size={30} color={Colors.yellow} />
      </Pressable>
    </View>
  );
};

export default VideoPlayer;

const useStyles = CreateResponsiveStyle(
  {
    root: {
      width: '100%',
      height: '80%',
      alignSelf: 'center',
      borderWidth: 0,
      backgroundColor: Colors.blackOpacity70,
      padding: '5%',
      alignItems: 'center',
      justifyContent: 'center',
      top: '9%',
      position: 'absolute',
      zIndex: 3,
    },
    videoContainer: {
      width: '60%',
      height: '90%',
      alignSelf: 'center',
      justifyContent: 'space-evenly',
      position: 'relative',
      zIndex: 3,
    },
    closeModal: {
      position: 'absolute',
      top: 50,
      right: 50,
      alignSelf: 'flex-end',
    },
  },
  {
    [DEVICE_SIZES.MD]: {
      videoContainer: {
        width: '80%',
        height: '90%',
      },
    },
    [DEVICE_SIZES.SM]: {
      root: {
        height: '65%',
        padding: '3%',
      },
      videoContainer: {
        width: '100%',
        height: '60%',
      },
    },
    [DEVICE_SIZES.XS]: {
      root: {
        height: '75%',
        padding: '0%',
      },
      videoContainer: {
        width: '100%',
        height: '60%',
      },
    },
  }
);
