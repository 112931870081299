import * as React from 'react';
import { View } from './Themed';
import Svg, { Circle } from 'react-native-svg';
import { StyleSheet } from 'react-native';
import { Colors } from '../constants/Colors';
import { Heading } from './StyledText';
import Fonts from '../constants/Fonts';

interface DonutChartProps {
  percentage: number;
  size: number;
}

export const DonutChart = ({ percentage, size }: DonutChartProps) => {
  const radius = size / 2 - 10;
  const perimeter = 2 * Math.PI * radius;
  const strokeDashOffset = perimeter - (perimeter * percentage) / 100;
  return (
    <View transparent style={[styles.container, { width: size, height: size }]}>
      <Svg viewBox={`0 0 ${size} ${size}`} style={styles.donutContainer}>
        <Circle cx={size / 2} cy={size / 2} r={radius} fill={Colors.transparent} />
        <Circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          fill={Colors.transparent}
          stroke={Colors.defaultGrey}
          strokeWidth={20}
        />
        <Circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          fill={Colors.transparent}
          stroke={Colors.yellow}
          strokeWidth={20}
          strokeDasharray={perimeter}
          strokeDashoffset={strokeDashOffset}
        />
      </Svg>
      <Heading style={styles.label}>{Math.round(percentage)}%</Heading>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  donutContainer: {
    position: 'absolute',
    left: 0,
    top: 0,
    transform: [{ rotateZ: '-90deg' }],
  },
  label: {
    color: Colors.darkBurgundy,
    fontSize: Fonts.sizes.button,
  },
});
