import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import Footer from '../Footer';
import Header from '../Header';
import { Colors } from '../../constants/Colors';
import axios from 'axios';
import {
  RichTextResponseHeroImageVideo,
  RichTextResponseKaupapa,
  RichTextResponseYellow,
  RichTextResponseHeroImageWithButton,
  RichTextResponseQuote,
} from '../Utils/RichTextResponse';
import resolveResponse from 'contentful-resolve-response';
import { contentfulUrlGenerator } from '../Utils/contentfulUrlGenerator';
import { LinearGradient } from 'expo-linear-gradient';
import RectanglesPattern from './Fragments/RectanglesPattern';
import CarouselKaupapa from './Fragments/CarouselKaupapa';
import ActiveMarae from './Fragments/ActiveMarae';
import { CreateResponsiveStyle, DEVICE_SIZES, useDeviceSize, BreakpointsProvider } from 'rn-responsive-styles';
import { languageListener } from '../Utils/languageListener';
import Clouds from '../Clouds';

const About = () => {
  const styles = useStyles();
  const deviceSize = useDeviceSize();
  const [data, setData] = useState<any>();
  const language = languageListener();

  const URL = contentfulUrlGenerator('aboutUsSections', language);

  useEffect(() => {
    const getData = async () => {
      const result = await axios(URL);
      setData(resolveResponse(result?.data));
    };
    getData();
  }, [language]);

  return (
    <BreakpointsProvider breakpoints={[1920, 1366, 768, 540]}>
      <View style={styles.root}>
        <Header menuBarTheme="light" isLayoutAbsolute isHightLighted />
        <View style={styles.itemsWrapper}>
          {data &&
            data.map((item, index) => {
              if (item?.fields?.title === 'hero-image-video') {
                return (
                  <View style={styles.bodyRed} key={index}>
                    {RichTextResponseHeroImageVideo({ richTextResponse: item.fields.content, deviceSize })}
                    <LinearGradient
                      // Background Linear Gradient
                      colors={['transparent', 'rgba(0,0,0,0.2)', '#545454']}
                      style={styles.bottomToTopLinerGradient}
                      pointerEvents="none"
                    />
                    <RectanglesPattern />
                  </View>
                );
              }

              if (item?.fields?.title === 'kaupapa-section') {
                return (
                  <View style={styles.body} key={index}>
                    <View style={styles.bodyWithPadding}>
                      {RichTextResponseKaupapa({ richTextResponse: item.fields.content, deviceSize })}
                      <CarouselKaupapa listData={item?.fields?.carouselList} isBlackDot />
                    </View>
                  </View>
                );
              }

              if (item?.fields?.title === 'hero-image-with-button') {
                return (
                  <View style={[styles.body, { justifyContent: 'center' }]} key={index}>
                    {RichTextResponseHeroImageWithButton({ richTextResponse: item.fields.content, deviceSize })}
                    <LinearGradient
                      colors={['transparent', 'rgba(28,16,16, 0.8)']}
                      start={{ x: 1, y: 1 }}
                      end={{ x: 0, y: 1 }}
                      style={styles.LeftToRightLinerGradient}
                      pointerEvents="none"
                    />
                    <CarouselKaupapa listData={item?.fields?.carouselList} isLayoutAbsolute whiteText />
                  </View>
                );
              }

              if (item?.fields?.title === 'active-marae') return <ActiveMarae key={index} />;

              if (item?.fields?.title === 'quote-section') {
                return (
                  <View style={styles.quoteSectionContainer} key={index}>
                    {RichTextResponseQuote({ richTextResponse: item.fields.content, deviceSize })}
                  </View>
                );
              }

              return (
                <View style={styles.body} key={index}>
                  {RichTextResponseYellow({ richTextResponse: item.fields.content })}
                </View>
              );
            })}
        </View>

        <Clouds />
        <Footer showReasons />
      </View>
    </BreakpointsProvider>
  );
};

export default About;

const useStyles = CreateResponsiveStyle(
  {
    root: {
      backgroundColor: Colors.yellow,
      width: '100%',
      height: '100%',
      alignContent: 'center',
      overflow: 'hidden',
    },
    itemsWrapper: {
      zIndex: -1,
    },
    body: {
      width: '100%',
    },
    bodyRed: {
      backgroundColor: Colors.transparent,
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    alignItemsStart: {
      alignItems: 'flex-start',
    },
    bottomToTopLinerGradient: {
      width: '100%',
      height: '40%',
      position: 'absolute',
      bottom: 0,
      zIndex: -1,
    },
    LeftToRightLinerGradient: {
      width: '28.6%',
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
    },
    bodyWithPadding: {
      paddingHorizontal: 150,
      paddingVertical: 60,
    },
    quoteSectionContainer: {
      paddingHorizontal: 130,
      paddingBottom: 215,
      paddingTop: 50,
      maxWidth: 1920,
      alignSelf: 'center',
    },
  },
  {
    [DEVICE_SIZES.SM]: {
      quoteSectionContainer: {
        paddingHorizontal: 100,
      },
    },
    [DEVICE_SIZES.XS]: {
      quoteSectionContainer: {
        paddingHorizontal: 42,
      },
      bodyWithPadding: {
        paddingVertical: 0,
      },
    },
    [DEVICE_SIZES.MD]: {
      quoteSectionContainer: {
        paddingHorizontal: 80,
      },
    },
  }
);
