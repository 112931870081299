import { createStackNavigator } from '@react-navigation/stack';
import { SignUpParamList } from '../constants/Interfaces';
import * as React from 'react';
import UserDetailsScreen from '../screens/authentication/UserDetailsScreen';
import AddMaraeScreen from '../screens/authentication/AddMaraeScreen';
import ParticipationConfirmation from '../screens/home/ParticipationConfirmation';
import { SelectRegionScreen } from '../screens/profile/SelectRegionScreen';
import { SelectMaraeScreen } from '../screens/profile/SelectMaraeScreen';
import { TipScreen } from '../screens/profile/TipScreen';

const SignUpStack = createStackNavigator<SignUpParamList>();

export default function SignUpNavigator() {
  return (
    <SignUpStack.Navigator
      initialRouteName="ParticipationConfirmation"
      id="SignUpNavigator"
      screenOptions={{ headerShown: false }}
    >
      <SignUpStack.Screen name="ParticipationConfirmation" component={ParticipationConfirmation} />
      <SignUpStack.Screen name="UserDetails" component={UserDetailsScreen} />
      <SignUpStack.Screen name="AddMarae" component={AddMaraeScreen} />
      <SignUpStack.Screen name="SelectRegion" component={SelectRegionScreen} />
      <SignUpStack.Screen name="SelectMarae" component={SelectMaraeScreen} />
      <SignUpStack.Screen name="TipScreen" component={TipScreen} />
    </SignUpStack.Navigator>
  );
}
