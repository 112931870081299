import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Image, View } from 'react-native';
import CarouselAds from './CarouselAds';
import { contentfulUrlGenerator } from './Utils/contentfulUrlGenerator';
import { Colors } from '../constants/Colors';
import { languageListener } from './Utils/languageListener';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import { LinearGradient } from 'expo-linear-gradient';

const CarouselDownloadApp = () => {
  const styles = useStyles();
  const [data, setData] = useState<any>();
  const language = languageListener();

  const URL = contentfulUrlGenerator('downloadReasons', language);

  useEffect(() => {
    const getData = async () => {
      const result = await axios(URL);
      setData(result.data);
    };
    getData();
  }, [language]);

  const fields = data ? data?.items[0].fields : null;

  return (
    <View style={styles.body}>
      <View style={styles.carouselContainer}>
        {data && (
          <CarouselAds
            title={fields?.title}
            subtitle={fields?.subtitle}
            data={fields?.carouselText}
            showIcons
            whiteText
            isFooter
            carouselTimeout={3000}
          />
        )}
      </View>
      <View style={styles.phoneContainer}>
        <Image source={require('../../assets/images/footer-phone.png')} style={styles.phone} />
      </View>
      <LinearGradient
        // Background Linear Gradient
        colors={[Colors.darkBurgundy, Colors.lightBurgundy]}
        style={styles.bottomToTopLinerGradient}
        pointerEvents="none"
      />
    </View>
  );
};

export default CarouselDownloadApp;

const useStyles = CreateResponsiveStyle(
  {
    body: {
      backgroundColor: Colors.darkBurgundy,
      width: '100%',
      height: 729,
      maxHeight: 729,
      minHeight: 400,
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    phoneContainer: {
      flexBasis: '50%',
      transform: [{ translateX: 60 }, { translateY: 80 }],
    },
    phone: {
      width: '100%',
      height: '100%',
      resizeMode: 'contain',
    },
    carouselContainer: {
      flexBasis: '50%',
      maxWidth: 400,
      height: '80%',
      alignSelf: 'center',
      marginLeft: '10%',
      zIndex: 20,
    },
    icons: {
      height: 60,
      resizeMode: 'contain',
    },
    bottomToTopLinerGradient: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      bottom: 0,
      zIndex: -1,
    },
  },
  {
    [DEVICE_SIZES.MD]: {
      body: { height: 678 },
      phoneContainer: {
        transform: [{ translateX: 30 }, { translateY: 70 }],
      },
    },
    [DEVICE_SIZES.SM]: {
      body: { height: 678 },
      phoneContainer: {
        flexBasis: '60%',
        transform: [{ translateX: 60 }, { translateY: 80 }],
      },
      carouselContainer: {
        flexBasis: '40%',
        marginLeft: '5%',
      },
    },
    [DEVICE_SIZES.XS]: {
      body: { height: 620, flexDirection: 'column', justifyContent: 'center', alignItems: 'center' },
      phoneContainer: {
        display: 'none',
      },
      carouselContainer: {
        flexBasis: '100%',
        marginLeft: '3%',
      },
    },
  }
);
