import React, { useEffect, useRef } from 'react';
import { View, Animated, useWindowDimensions } from 'react-native';
import { Colors } from '../constants/Colors';
import { CreateResponsiveStyle, DEVICE_SIZES, useDeviceSize } from 'rn-responsive-styles';

interface LayoutProps {
  menuBarTheme?: 'dark' | 'light';
}

const Clouds = ({ menuBarTheme = 'dark' }: LayoutProps) => {
  const styles = useStyles();
  const deviceSize = useDeviceSize();
  const ITEM_WIDTH = useWindowDimensions().width;
  const frontCloudX1 = useRef(new Animated.Value(0)).current;
  const frontCloudX2 = useRef(new Animated.Value(0)).current;
  const middleCloudX1 = useRef(new Animated.Value(0)).current;
  const middleCloudX2 = useRef(new Animated.Value(0)).current;
  const backCloudX1 = useRef(new Animated.Value(0)).current;
  const backCloudX2 = useRef(new Animated.Value(0)).current;

  const cloudWidth = (deviceSize, cloudPosition) => {
    let cloudWidth: number;
    if (cloudPosition === 'front') {
      switch (deviceSize) {
        case 'extrasmall':
          cloudWidth = 180;
          break;
        case 'small':
          cloudWidth = 260;
          break;
        case 'medium':
          cloudWidth = 310;
          break;
        default:
          cloudWidth = 390;
      }
    } else if (cloudPosition === 'middle') {
      switch (deviceSize) {
        case 'extrasmall':
          cloudWidth = 150;
          break;
        case 'small':
          cloudWidth = 190;
          break;
        case 'medium':
          cloudWidth = 240;
          break;
        default:
          cloudWidth = 290;
      }
    } else {
      switch (deviceSize) {
        case 'extrasmall':
          cloudWidth = 100;
          break;
        case 'small':
          cloudWidth = 120;
          break;
        case 'medium':
          cloudWidth = 125;
          break;
        default:
          cloudWidth = 140;
      }
    }
    return cloudWidth;
  };

  useEffect(() => {
    // First cloud used to show immediately and only lasts for one iteration
    // and once finished triggers the next cloud to loop
    Animated.stagger(30000, [
      Animated.timing(frontCloudX1, {
        toValue: 1,
        duration: 30000,
        useNativeDriver: false,
      }),
      Animated.loop(
        Animated.timing(frontCloudX2, {
          toValue: 1,
          duration: 60000,
          useNativeDriver: false,
        })
      ),
    ]).start();
  }, []);

  useEffect(() => {
    Animated.stagger(63000, [
      Animated.timing(middleCloudX1, {
        toValue: 1,
        duration: 63000,
        useNativeDriver: false,
      }),
      Animated.loop(
        Animated.timing(middleCloudX2, {
          toValue: 1,
          duration: 70000,
          useNativeDriver: false,
        })
      ),
    ]).start();
  }, []);

  useEffect(() => {
    Animated.stagger(16000, [
      Animated.timing(backCloudX1, {
        toValue: 1,
        duration: 16000,
        useNativeDriver: false,
      }),
      Animated.loop(
        Animated.timing(backCloudX2, {
          toValue: 1,
          duration: 80000,
          useNativeDriver: false,
        })
      ),
    ]).start();
  }, []);

  return (
    <View style={[styles.root, menuBarTheme === 'light' ? styles.bgLight : null]}>
      <Animated.Image
        source={require('../../assets/images/web_cloud.png')}
        style={[
          styles.frontCloud,
          {
            transform: [
              {
                translateX: frontCloudX1.interpolate({
                  inputRange: [0, 1],
                  outputRange: [ITEM_WIDTH / 2, ITEM_WIDTH + 20],
                }),
              },
            ],
          },
        ]}
        resizeMethod="resize"
      />
      <Animated.Image
        source={require('../../assets/images/web_cloud.png')}
        style={[
          styles.frontCloud,
          {
            transform: [
              {
                translateX: frontCloudX2.interpolate({
                  inputRange: [0, 1],
                  outputRange: [-cloudWidth(deviceSize, 'front'), ITEM_WIDTH],
                }),
              },
            ],
          },
        ]}
        resizeMethod="resize"
      />
      <Animated.Image
        source={require('../../assets/images/web_cloud.png')}
        style={[
          styles.middleCloud,
          {
            transform: [
              {
                translateX: middleCloudX1.interpolate({
                  inputRange: [0, 1],
                  outputRange: [ITEM_WIDTH * 0.1, ITEM_WIDTH + 20],
                }),
              },
            ],
          },
        ]}
        resizeMethod="resize"
      />
      <Animated.Image
        source={require('../../assets/images/web_cloud.png')}
        style={[
          styles.middleCloud,
          {
            transform: [
              {
                translateX: middleCloudX2.interpolate({
                  inputRange: [0, 1],
                  outputRange: [-cloudWidth(deviceSize, 'middle'), ITEM_WIDTH],
                }),
              },
            ],
          },
        ]}
        resizeMethod="resize"
      />
      <Animated.Image
        source={require('../../assets/images/web_cloud.png')}
        style={[
          styles.backCloud,
          {
            transform: [
              {
                translateX: backCloudX1.interpolate({
                  inputRange: [0, 1],
                  outputRange: [ITEM_WIDTH * 0.8, ITEM_WIDTH + 20],
                }),
              },
            ],
          },
        ]}
        resizeMethod="resize"
      />
      <Animated.Image
        source={require('../../assets/images/web_cloud.png')}
        style={[
          styles.backCloud,
          {
            transform: [
              {
                translateX: backCloudX2.interpolate({
                  inputRange: [0, 1],
                  outputRange: [-cloudWidth(deviceSize, 'back'), ITEM_WIDTH],
                }),
              },
            ],
          },
        ]}
        resizeMethod="resize"
      />
    </View>
  );
};

export default Clouds;

const useStyles = CreateResponsiveStyle(
  {
    root: { backgroundColor: Colors.yellow, zIndex: -5 },
    bgLight: {
      backgroundColor: Colors.transparent,
    },
    frontCloud: {
      position: 'absolute',
      top: -95,
      left: 0,
      width: 390,
      height: 70,
      resizeMode: 'contain',
      zIndex: -1,
      opacity: 0.7,
    },
    middleCloud: {
      position: 'absolute',
      top: -145,
      left: 0,
      width: 290,
      height: 55,
      resizeMode: 'contain',
      zIndex: -2,
      opacity: 0.7,
    },
    backCloud: {
      position: 'absolute',
      top: -25,
      left: 0,
      width: 140,
      height: 35,
      resizeMode: 'contain',
      zIndex: -3,
      opacity: 0.5,
    },
  },
  {
    [DEVICE_SIZES.MD]: {
      frontCloud: {
        top: -75,
        width: 310,
        height: 55,
      },
      middleCloud: {
        top: -115,
        width: 240,
        height: 42,
      },
      backCloud: {
        top: -15,
        width: 125,
        height: 22,
      },
    },
    [DEVICE_SIZES.SM]: {
      frontCloud: {
        top: -65,
        width: 260,
        height: 45,
      },
      middleCloud: {
        top: -100,
        width: 190,
        height: 45,
      },
      backCloud: {
        top: -15,
        width: 120,
        height: 21,
      },
    },
    [DEVICE_SIZES.XS]: {
      frontCloud: {
        top: -75,
        width: 180,
        height: 31,
      },
      middleCloud: {
        top: -100,
        width: 150,
        height: 31,
      },
      backCloud: {
        top: -45,
        width: 100,
        height: 30,
      },
    },
  }
);
