import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Logger from '../constants/Logger';
import { purge } from './commonActions';

interface RegionState {
  selectedRegion: string;
  selectedRegionLeaderboard: string;
  regions: string[];
}
const initialState: RegionState = {
  selectedRegion: '',
  selectedRegionLeaderboard: '',
  regions: [],
};

const regionSlice = createSlice({
  name: 'region',
  initialState,
  reducers: {
    selectRegion(state, action: PayloadAction<string>) {
      Logger.debug(`Reducer -> region -> selectRegion: Setting preferred region to ${action.payload}`);
      state.selectedRegion = action.payload;
    },
    selectRegionLeaderboard(state, action: PayloadAction<string>) {
      Logger.debug(`Reducer -> region -> selectRegionLeaderboard: Setting preferred region to ${action.payload}`);
      state.selectedRegionLeaderboard = action.payload;
    },
    setDefaultRegionLeaderboard(state, action: PayloadAction<string>) {
      Logger.debug(`Reducer -> region -> setDefaultRegion: Setting default region to ${action.payload}`);
      if (state.selectedRegionLeaderboard.length === 0) {
        state.selectedRegionLeaderboard = action.payload;
      }
    },
    setDefaultRegion(state, action: PayloadAction<string>) {
      Logger.debug(`Reducer -> region -> setDefaultRegion: Setting default region to ${action.payload}`);
      if (state.selectedRegion.length === 0) {
        state.selectedRegion = action.payload;
      }
    },
    setRegions(state, action: PayloadAction<string[]>) {
      Logger.debug(`Reducer -> region -> setRegions: Setting regions to ${JSON.stringify(action.payload)}`);
      state.regions = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(purge, (state) => {
      Logger.debug(`Reducer -> history: Purge request received.`);
      state.selectedRegion = '';
      state.selectedRegionLeaderboard = '';
      state.regions = [];
    });
  },
});

export const { selectRegion, selectRegionLeaderboard, setDefaultRegion, setRegions } = regionSlice.actions;
export default regionSlice.reducer;
