import * as React from 'react';
import { Image, Pressable, StyleProp, StyleSheet, ViewStyle } from 'react-native';
import { View } from './Themed';

export const TipButton = ({
  navigation,
  message,
  style,
}: {
  navigation;
  message: string;
  style?: StyleProp<ViewStyle>;
}) => {
  const handleOpenTip = () => {
    navigation.navigate('TipScreen', { message });
  };

  return (
    <View transparent style={[styles.tipOpenerContainer, style]}>
      <Image style={styles.tipArrow} source={require('../../assets/images/tip-arrow.png')} />
      <Pressable onPress={handleOpenTip}>
        <Image style={styles.tipImage} source={require('../../assets/images/tip-icon.png')} />
      </Pressable>
    </View>
  );
};

const styles = StyleSheet.create({
  tipOpenerContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    flexDirection: 'row',
  },
  tipArrow: {
    marginTop: 30,
    height: 30,
    width: 30,
    resizeMode: 'contain',
  },
  tipImage: {
    width: 60,
    height: 60,
    resizeMode: 'contain',
  },
});
