import { StatusBar } from 'expo-status-bar';
import * as React from 'react';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import * as WebBrowser from 'expo-web-browser';
import configureStore from './src/store/configureStore';
import API from './src/constants/API';

import useCachedResources from './src/hooks/useCachedResources';
import useColorScheme from './src/hooks/useColorScheme';
import Navigation from './src/navigation';
import SplashScreen from './src/screens/SplashScreen';
import 'intl';
import { Platform } from 'react-native';
import 'intl/locale-data/jsonp/en';
import { BreakpointsProvider } from 'rn-responsive-styles';

// Fix: Can't find variable: Intl
if (Platform.OS === 'android') {
  // See https://github.com/expo/expo/issues/6536 for this issue.
  if (typeof (Intl as any).__disableRegExpRestore === 'function') {
    (Intl as any).__disableRegExpRestore();
  }
}

WebBrowser.maybeCompleteAuthSession();

const { persistor, store } = configureStore();

export default function App() {
  const isLoadingComplete = useCachedResources();
  const colorScheme = useColorScheme();
  const onBeforeLift = async () => {};

  if (!isLoadingComplete) {
    return null;
  }
  API.getInstance(store);

  return (
    <Provider store={store}>
      {Platform.OS !== 'web' && <SplashScreen />}
      <PersistGate loading={null} onBeforeLift={onBeforeLift} persistor={persistor}>
        <SafeAreaProvider>
          <BreakpointsProvider breakpoints={[1920, 1366, 768, 540]}>
            <Navigation colorScheme={colorScheme} />
          </BreakpointsProvider>
          <StatusBar />
        </SafeAreaProvider>
      </PersistGate>
    </Provider>
  );
}
