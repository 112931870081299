import React, { useState } from 'react';
import { View, StyleSheet, Pressable, Image, TextInput, FlatList } from 'react-native';
import { useSelector } from 'react-redux';
import { Colors } from '../constants/Colors';
import Fonts from '../constants/Fonts';
import { Activity } from '../constants/Interfaces';
import { UIText } from './StyledText';
import Layout from '../constants/Layout';
import { useNavigation } from '@react-navigation/native';
import Logger from '../constants/Logger';
import { TouchableWithoutFeedback } from 'react-native-gesture-handler';
import i18n from '../translations';
import { convertToNZ } from '../constants/Utils';

const SearchBar = ({ close }) => {
  const [search, setSearch] = useState<string>('');
  const activities: Activity[] = useSelector((state: any) => state.activity.activities);
  const filterResults =
    search !== '' ? activities.filter((item) => item.activityName?.toLowerCase().includes(search.toLowerCase())) : [];
  const navigation = useNavigation();

  const handleOpenActivity = (activityId: string) => {
    close();
    Logger.debug(`Screen -> ActivitiesScreen -> handleOpenActivity: Navigating to activity ${activityId}`);
    navigation.navigate('ActivityDetails', {
      activityId,
    });
  };

  const renderItem = (item: Activity) => (
    <TouchableWithoutFeedback style={styles.results} onPress={() => handleOpenActivity(item.activityId)}>
      <UIText style={styles.titleLabel}>{item.activityName}</UIText>
      <UIText>{`${convertToNZ({ date: item.startDate!, format: 'MMMM dd' })}, ${item.location?.regionName}`}</UIText>
    </TouchableWithoutFeedback>
  );

  return (
    <View style={styles.root}>
      <View style={styles.barContainer}>
        <Image source={require('../../assets/images/searchIcon.png')} style={styles.icon} />
        <TextInput style={styles.textInput} placeholder="Search ..." onChangeText={(text) => setSearch(text)} />
      </View>
      {search !== '' && (
        <View style={styles.resultsContainer}>
          {filterResults.length > 0 ? (
            <FlatList
              data={filterResults}
              keyExtractor={(item) => item.activityId}
              renderItem={({ item }) => renderItem(item)}
            />
          ) : (
            <UIText> {i18n.t('components.SearchBar.nofound', { search })} </UIText>
          )}
        </View>
      )}
      <Pressable style={{ flex: 1 }} onPress={close} />
    </View>
  );
};
export default SearchBar;

const styles = StyleSheet.create({
  root: {
    width: '100%',
    top: 20,
    height: Layout.window.height,
    position: 'absolute',
  },
  barContainer: {
    marginHorizontal: '5%',
    backgroundColor: Colors.white,
    borderRadius: 20,
    height: 30,
    paddingLeft: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    width: 20,
    height: 20,
    resizeMode: 'contain',
    marginRight: 5,
  },
  textInput: {
    flex: 1,
    height: '100%',
  },
  resultsContainer: {
    marginTop: 5,
    backgroundColor: Colors.white,
    marginHorizontal: '5%',
    maxHeight: 200,
    minHeight: 100,
    borderRadius: 10,
    paddingHorizontal: 35,
    justifyContent: 'center',
  },
  results: {
    borderBottomColor: Colors.defaultGrey,
    borderBottomWidth: 1,
    paddingVertical: 10,
  },
  titleLabel: {
    fontFamily: Fonts.family.bold,
  },
});
