import React from 'react';
import { Image, Linking, Pressable, View } from 'react-native';
import { UIText } from '../components/StyledText';
import Fonts from '../constants/Fonts';
import Constants from 'expo-constants';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';

interface DownloadContainerProps {
  title?: string;
  showIcons?: boolean;
  isFooter?: boolean;
}
const { iosStoreId, androidStoreId } = Constants?.expoConfig?.extra?.store;

const DownloadStoreContainer = ({ title, showIcons = false, isFooter = false }: DownloadContainerProps) => {
  const styles = useStyles();

  const handleStoreDownloadIOS = () => {
    Linking.openURL(iosStoreId);
  };

  const handleStoreDownloadAndroid = () => {
    Linking.openURL(androidStoreId);
  };

  return (
    <View style={[styles.appAdsContainer]}>
      {title && !isFooter && <UIText style={styles.smallLabel}>{title}</UIText>}
      {!showIcons && (
        <View style={[styles.storeContainer, isFooter ? styles.leftAligned : null]}>
          <Pressable onPress={handleStoreDownloadIOS} style={[styles.storeButtons]}>
            <Image source={require('../../assets/images/appStore.png')} style={styles.store} />
          </Pressable>
          <Pressable onPress={handleStoreDownloadAndroid} style={[styles.storeButtons]}>
            <Image source={require('../../assets/images/googlePlay.png')} style={styles.store} />
          </Pressable>
        </View>
      )}
      {showIcons && (
        <View style={[styles.storeContainer, isFooter ? styles.leftAligned : null]}>
          <Pressable onPress={handleStoreDownloadIOS} style={[styles.iconButtons]}>
            <Image source={require('../../assets/images/appleStoreIcon.png')} style={styles.iconsCircle} />
          </Pressable>
          <Pressable onPress={handleStoreDownloadAndroid} style={[styles.iconButtons]}>
            <Image source={require('../../assets/images/googleStoreIcon.png')} style={styles.iconsCircle} />
          </Pressable>
        </View>
      )}
    </View>
  );
};

export default DownloadStoreContainer;

const useStyles = CreateResponsiveStyle(
  {
    appAdsContainer: {
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 30,
      width: '100%',
    },
    smallLabel: {
      fontSize: Fonts.sizes.mini,
      marginVertical: '3%',
    },
    storeContainer: {
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'center',
      alignContent: 'center',
    },
    storeButtons: {
      width: 112 * 1.3,
      height: 34 * 1.3,
      marginRight: 3,
    },
    store: {
      width: '100%',
      height: '100%',
      resizeMode: 'contain',
    },
    iconButtons: {
      width: 122,
      marginRight: 6,
      marginBottom: 10,
    },
    iconsCircle: {
      width: '100%',
      height: 122,
      resizeMode: 'contain',
    },
    leftAligned: {
      justifyContent: 'flex-start',
    },
  },
  {
    [DEVICE_SIZES.MD]: {
      iconButtons: {
        width: 80,
      },
      iconsCircle: {
        height: 80,
      },
    },
    [DEVICE_SIZES.SM]: {
      iconButtons: {
        width: 80,
      },
      iconsCircle: {
        height: 80,
      },
    },
    [DEVICE_SIZES.XS]: {
      iconButtons: {
        width: 80,
      },
      iconsCircle: {
        height: 80,
      },
    },
  }
);
