import React, { useEffect, useState } from 'react';
import { View, StyleSheet, FlatList, Image, ActivityIndicator } from 'react-native';
import { useSelector } from 'react-redux';
import Layout from '../constants/Layout';
import API from '../constants/API';
import { Colors } from '../constants/Colors';
import Fonts from '../constants/Fonts';
import { User, UserActivity } from '../constants/Interfaces';
import Logger from '../constants/Logger';
import { Paragraph, UIText } from './StyledText';
import { convertToNZ } from '../constants/Utils';
import i18n from '../translations';

const UserActivities = ({ user }) => {
  const [activities, setActivities] = useState<UserActivity[]>([]);
  const mainUser: User = useSelector((state: any) => state.user.user);
  const isWhanau = user && user.userId !== mainUser?.userId;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getActivities = async (userId?: string, isWhanau?: boolean) => {
      Logger.info(`Reducer -> activity -> getUserWhanauActivitiesCompleted : Getting user activities`);
      const api: API = API.getInstance();

      try {
        setLoading(true);
        const activities: UserActivity[] = await api.get(
          isWhanau ? `/user/whanau/${userId}/activity/completed` : 'user/activity/completed'
        );
        Logger.debug(
          `Reducer -> activity -> getUserWhanauActivitiesCompleted : Activities information received: ${JSON.stringify(
            activities
          )}`
        );
        setActivities(activities);
      } catch (e) {
        Logger.warn(`Reducer -> activity -> getUserWhanauActivitiesCompleted : ${e}`);
        throw e;
      } finally {
        setLoading(false);
      }
    };
    getActivities(user.userId, isWhanau);
  }, []);

  return (
    <View style={styles.root}>
      <View style={[styles.noteIconContainer]}>
        <Image source={require('../../assets/images/megaphoneIcon.png')} style={styles.noteIcon} />
      </View>
      <View style={styles.note}>
        <Paragraph style={styles.noteText}>{`${user?.firstName} ${i18n.t(
          'components.UserActivities.attended'
        )}`}</Paragraph>
      </View>
      {loading && <ActivityIndicator />}
      <View style={styles.listContainer}>
        <FlatList
          data={activities}
          renderItem={({ item }) => (
            <View>
              <UIText style={styles.activityLabel}>{item.activityName}</UIText>
              <UIText style={styles.dateLabel}>
                {convertToNZ({ date: item.startDate!, format: 'MMMM dd, yyyy, h:mma' })}
              </UIText>
            </View>
          )}
          ListFooterComponent={() => <View />}
          ListFooterComponentStyle={{ height: 80 }}
          keyExtractor={(item, index) => `${index}-${item.endDate}`}
          ItemSeparatorComponent={() => (
            <>
              <View
                style={{
                  marginVertical: 10,
                  borderWidth: 1,
                  borderColor: Colors.yellow,
                  borderStyle: 'dashed',
                  height: 0.1,
                  borderRadius: 1,
                }}
              />
              <View />
            </>
          )}
        />
      </View>
    </View>
  );
};

export default UserActivities;
const styles = StyleSheet.create({
  root: {
    marginHorizontal: '4%',
  },
  note: {
    backgroundColor: Colors.white,
    paddingHorizontal: 8,
    paddingVertical: 15,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 2,
    borderColor: Colors.white,
    borderRadius: 5,
    marginBottom: 15,
  },
  noteText: {
    textAlign: 'center',
    fontSize: Fonts.sizes.medium,
  },
  listContainer: {
    height: Layout.window.height * 0.7 - 172,
  },
  activityLabel: {
    color: Colors.white,
    fontFamily: Fonts.family.bold,
  },
  dateLabel: {
    color: Colors.white,
  },
  noteIcon: {
    height: 30,
    width: 30,
  },
  noteIconContainer: {
    backgroundColor: Colors.white,
    borderRadius: 45,
    width: 45,
    height: 45,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 10,
    alignSelf: 'center',
    bottom: -15,
    zIndex: 10,
    borderWidth: 2,
    borderColor: Colors.black,
  },
});
