import * as React from 'react';
import { StyleSheet, Image, ScrollView } from 'react-native';
import { BarCodeScanner, BarCodeScannerResult } from 'expo-barcode-scanner';
import { Heading, UIText } from '../../../components/StyledText';
import { View } from '../../../components/Themed';
import { Colors } from '../../../constants/Colors';
import Logger from '../../../constants/Logger';
import i18n from '../../../translations';
import { useSelector } from 'react-redux';
import { PaperQrCode, TicketActivity, User } from '../../../constants/Interfaces';
import { getActivityStats, postUserActivityParticipation } from '../../../reducers/activity.slice';
import { useFocusEffect } from '@react-navigation/native';
import Fonts from '../../../constants/Fonts';
import Layout from '../../../constants/Layout';
import { openSettings } from 'expo-linking';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import _ from 'lodash';
import { SelectionItem } from '../../../components/SelectionItem';
import { SplitButton } from '../../../components/buttons/SplitButton';
import { useAppDispatch } from '../../../store/configureStore';

export const ActivityScannerFragment = ({
  activityId,
  title = i18n.t('components.CodeScannerFragment.header'),
  onScanComplete,
}) => {
  const user: User = useSelector((state: any) => state.user.user);
  const [hasPermission, setHasPermission] = React.useState<boolean | null>(null);
  const [scanned, setScanned] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string | null>();
  const checkInProgress: boolean = useSelector((state: any) => state.activity.checkInProgress);
  const dispatch = useAppDispatch();
  const [ticked, setTicked] = React.useState<boolean>(false);
  const [dataQRCode, setDataQRCode] = React.useState<PaperQrCode | null>(null);
  const whanaus: User[] = useSelector((state: any) => state.user.whanau) || [];

  const usersGoing: string[] = useSelector((state: any) => state.activity.myActivities)
    ?.filter((item) => item.activityId === activityId && item.going && !item.checkedIn)
    ?.map((item) => item.userId);
  const participantsGoing: TicketActivity[] = [
    { userId: user?.userId, name: `${user?.firstName} ${user?.lastName}`, activityId },
    ...whanaus
      .filter((item) => item.userId && usersGoing?.includes(item.userId))
      .map((item) => ({
        userId: item.userId,
        name: `${item.firstName} ${item.lastName}`,
        activityId,
      })),
  ];
  const [selectedParticipants, setSelectedParticipants] = React.useState([...participantsGoing]);

  const handleSelection = (participant: TicketActivity) => {
    setSelectedParticipants((whanau) => {
      const itemIndex = _.findIndex(whanau, ['userId', participant.userId]);
      if (itemIndex > -1) {
        whanau.splice(itemIndex, 1);
        return [...whanau];
      }
      return [...whanau, participant];
    });
  };

  const handleCheckIn = async () => {
    if (selectedParticipants.length > 0) {
      try {
        await Promise.all(
          selectedParticipants.map((whanau) =>
            dispatch(
              postUserActivityParticipation({
                userId: whanau.userId!,
                activityId: whanau.activityId,
                password: dataQRCode?.password,
              })
            ).unwrap()
          )
        );

        setTicked(true); // Show Ticked
        setScanned(false); // enable next scan
        setDataQRCode(null);
        setTimeout(() => {
          setTicked(false);
          if (onScanComplete) {
            onScanComplete();
          }
        }, 3000);
      } catch (e) {
        setErrorMessage(i18n.t('components.CodeScannerFragment.checkinerrorWhanau'));
        setTimeout(() => {
          setErrorMessage(null);
          setDataQRCode(null);
          setScanned(false); // enable next scan
        }, 4000);
      }
    }
  };

  React.useEffect(() => {
    (async () => {
      const { status } = await BarCodeScanner.requestPermissionsAsync();
      setHasPermission(status === 'granted');
    })();
  }, []);

  useFocusEffect(React.useCallback(() => () => dispatch(getActivityStats(activityId)), []));

  const handleBarCodeScanned = async ({ type, data }: BarCodeScannerResult) => {
    setScanned(true);
    Logger.info(`Bar code with type ${type} and data ${data} has been scanned!`);
    try {
      const paperQRCode: PaperQrCode = JSON.parse(data);
      if (!paperQRCode.activityId || !paperQRCode.password) {
        throw new Error('Invalid QR Code');
      }
      if (paperQRCode.activityId !== activityId) {
        setErrorMessage(i18n.t('components.CodeScannerFragment.checkinerrorA'));
        setDataQRCode(null);
        setTimeout(() => {
          setErrorMessage(null);
          setScanned(false); // enable next scan
        }, 4000);
        return;
      }
      setDataQRCode(paperQRCode);
    } catch (e) {
      Logger.warn(`Components -> CodeScannerFragment -> handleBarCodeScanned: Unable to parse correct QR code`);
      setErrorMessage(i18n.t('components.CodeScannerFragment.scanerror'));
      setDataQRCode(null);
      setTimeout(() => {
        setErrorMessage(null);
        setScanned(false); // enable next scan
      }, 4000);
    }
  };

  if (!hasPermission) {
    return (
      <View style={styles.accessCameraContainer}>
        <UIText style={styles.cameraMEssage}>{i18n.t('components.CodeScannerFragment.nopermission')}</UIText>
        <Image
          source={require('../../../../assets/images/mf_logo_dark_no_text.png')}
          style={styles.logo}
          resizeMethod="scale"
        />
        <PrimaryButton onPress={() => openSettings()} title="Change Settings" />
      </View>
    );
  }

  return (
    <View style={styles.container}>
      {ticked && (
        <View style={styles.scanner}>
          <Heading style={styles.ticked}>{i18n.t('components.CodeScannerFragment.scannedPaper')}</Heading>
          <Image source={require('../../../../assets/images/check-mark-verified.gif')} style={styles.tickedIcon} />
        </View>
      )}
      {!ticked && !dataQRCode && (
        <>
          <Heading>{title}</Heading>
          <BarCodeScanner onBarCodeScanned={scanned ? undefined : handleBarCodeScanned} style={styles.scanner} />
          {scanned && !checkInProgress && <Heading>{i18n.t('components.CodeScannerFragment.checkinprogress')}</Heading>}
          {!errorMessage && !scanned && !checkInProgress && (
            <Heading>{i18n.t('components.CodeScannerFragment.scanning')}</Heading>
          )}
          {checkInProgress && <Heading>{i18n.t('components.CodeScannerFragment.checkinprogress')}</Heading>}
          {errorMessage && errorMessage.length > 0 && <Heading style={styles.errorMessage}>{errorMessage}</Heading>}
        </>
      )}
      {dataQRCode && (
        <>
          <Heading>{i18n.t('components.CodeScannerFragment.selectWhanau')}</Heading>
          {!errorMessage && (
            <ScrollView style={styles.listContainer}>
              {participantsGoing.map((item) => (
                <SelectionItem
                  key={item.userId}
                  item={item}
                  isSelected={_.findIndex(selectedParticipants, ['userId', item.userId]) > -1}
                  setList={handleSelection}
                />
              ))}
              <SplitButton
                labelLeft="Back"
                labelRight="Done"
                onPressLeft={() => {
                  setDataQRCode(null);
                  setScanned(false);
                }}
                onPressRight={handleCheckIn}
                backgroundColorLeft={Colors.white}
                containerStyles={styles.button}
              />
            </ScrollView>
          )}
          {errorMessage && errorMessage.length > 0 && <Heading style={styles.errorMessage}>{errorMessage}</Heading>}
        </>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.white,
    alignItems: 'center',
    justifyContent: Layout.isSmallDevice ? 'center' : 'flex-start',
    height: '100%',
    paddingVertical: 10,
  },
  scanner: {
    marginTop: 50,
    marginBottom: 30,
    width: 300,
    height: 300,
  },
  ticked: {
    alignSelf: 'center',
    fontSize: Fonts.sizes.bigHeading,
  },
  tickedIcon: {
    height: 60,
    width: 60,
    marginTop: 15,
    alignSelf: 'center',
  },
  errorMessage: {
    color: Colors.yellow,
    alignSelf: 'center',
    fontFamily: Fonts.family.bold,
    width: '80%',
    textAlign: 'center',
  },
  cameraMEssage: {
    color: Colors.black,
    alignSelf: 'center',
    fontFamily: Fonts.family.bold,
  },
  logo: {
    width: 150,
    height: 150,
    resizeMode: 'contain',
    marginTop: 50,
    marginLeft: 20,
  },
  accessCameraContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 70,
  },
  settingsButton: {
    backgroundColor: Colors.yellow,
    padding: 5,
  },
  listContainer: {
    marginTop: Layout.backgroundMarginNarrow.top,
    paddingHorizontal: Layout.backgroundMarginNarrow.top,
    width: '100%',
  },
  button: {
    marginTop: Layout.backgroundMarginNarrow.top,
  },
});
