import * as React from 'react';
import { StyleSheet, Pressable, Image, ScrollView } from 'react-native';
import { View } from '../../components/Themed';
import Layout from '../../constants/Layout';
import { Colors } from '../../constants/Colors';
import { Heading, Paragraph } from '../../components/StyledText';
import { AntDesign } from '@expo/vector-icons';
import Fonts from '../../constants/Fonts';
import i18n from '../../translations';

interface TipScreenProps {
  navigation: any;
  route: {
    params: {
      message: string;
    };
  };
}

export const TipScreen = ({ navigation, route }: TipScreenProps) => {
  const message = route?.params?.message;

  const styles = StyleSheet.create({
    root: {
      flex: 1,
      alignItems: 'center',
      backgroundColor: Colors.yellow,
      width: Layout.window.width,
      height: Layout.window.height,
    },
    header: {
      width: '100%',
      height: 150,
      backgroundColor: Colors.yellow,
      padding: Layout.backgroundMarginNormal.left,
      marginTop: Layout.backgroundMarginNormal.top,
      borderBottomColor: Colors.grey,
      // borderBottomWidth: 1,
      flexDirection: 'row',
    },

    body: {
      paddingHorizontal: Layout.backgroundMarginNormal.left,
    },

    close: {
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      top: 20,
      right: 20,
      width: 50,
      height: 50,
      backgroundColor: Colors.whiteOpacitiy40,
      borderRadius: 45,
    },
    heading: {
      fontSize: Fonts.sizes.bigHeading,
      marginTop: 40,
      marginBottom: 5,
      textAlign: 'center',
    },
    headingSmall: {
      fontSize: Fonts.sizes.smallHeading,
      marginTop: 5,
      marginBottom: 5,
      textAlign: 'center',
    },
    headingSmallDoubleMarginBottom: {
      fontSize: Fonts.sizes.smallHeading,
      marginTop: 5,
      marginBottom: 10,
      textAlign: 'center',
    },
    tipImageContainer: {
      alignItems: 'center',
      width: '100%',
    },
    tipImage: {
      width: 150,
      height: 150,
      resizeMode: 'contain',
    },
    smallTitle: {
      fontSize: Fonts.sizes.smallHeading,
      marginTop: 20,
      marginBottom: 5,
    },
    smallTitleWithNoMarginTopAndDoubleMarginBottom: {
      fontSize: Fonts.sizes.smallHeading,
      marginBottom: 10,
    },

    bold: {
      fontFamily: Fonts.family.bold,
    },
    regular: {
      fontFamily: Fonts.family.regular,
    },
  });

  function close() {
    navigation.goBack();
  }

  return (
    <View style={styles.root}>
      <View style={styles.header}>
        <View transparent style={styles.tipImageContainer}>
          <Image style={styles.tipImage} source={require('../../../assets/images/tip-icon.png')} />
        </View>
        <Pressable style={styles.close} onPress={() => close()}>
          <AntDesign name="close" size={32} color={Colors.black} />
        </Pressable>
      </View>
      <ScrollView style={styles.body}>
        {message === 'region' && (
          <>
            <Heading style={styles.heading}>{i18n.t('components.MaraeRegions.tipTitle')}</Heading>
            <Heading style={styles.headingSmallDoubleMarginBottom}>
              {i18n.t('components.MaraeRegions.tipSubtitle')}
            </Heading>
            <Paragraph>{i18n.t('components.MaraeRegions.tipBody')}</Paragraph>
            <Heading style={styles.smallTitle}>{i18n.t('components.MaraeRegions.featureAppTitle')}</Heading>
            <Paragraph style={styles.smallTitleWithNoMarginTopAndDoubleMarginBottom}>
              {i18n.t('components.MaraeRegions.featureAppSubtitle')}
            </Paragraph>
            <Paragraph>{i18n.t('components.MaraeRegions.featureAppBody')}</Paragraph>
          </>
        )}
        {message === 'marae' && (
          <>
            <Heading style={styles.heading}>{i18n.t('components.Marae.tipTitle')}</Heading>
            <Heading style={styles.headingSmallDoubleMarginBottom}>{i18n.t('components.Marae.tipSubtitle')}</Heading>
            <Paragraph>{i18n.t('components.Marae.tipBody')}</Paragraph>
            <Heading style={styles.smallTitle}>{i18n.t('components.Marae.featureAppTitle')}</Heading>
            <Paragraph style={styles.smallTitleWithNoMarginTopAndDoubleMarginBottom}>
              {i18n.t('components.Marae.featureAppSubtitle')}
            </Paragraph>
            <Paragraph>{i18n.t('components.Marae.featureAppBody')}</Paragraph>
          </>
        )}
        {message === 'addMarae' && (
          <>
            <Heading style={styles.heading}>{i18n.t('components.AddNewMarae.tipTitle')}</Heading>
            <Paragraph>{i18n.t('components.AddNewMarae.tipBody')}</Paragraph>
            <Heading style={styles.smallTitle}>{i18n.t('components.AddNewMarae.featureAppTitle')}</Heading>
            <Paragraph>{i18n.t('components.AddNewMarae.featureAppBody')}</Paragraph>
            <Heading style={styles.smallTitle}>{i18n.t('components.AddNewMarae.maxMaraeTitle')}</Heading>
            <Paragraph>{i18n.t('components.AddNewMarae.maxMaraeBody')}</Paragraph>
          </>
        )}
        {message === 'myWhanau' && (
          <>
            <Heading style={styles.heading}>{i18n.t('components.MyWhanauFragment.tipTitle')}</Heading>
            <Paragraph>{i18n.t('components.MyWhanauFragment.tipBody')}</Paragraph>
            <Heading style={styles.smallTitle}>{i18n.t('components.MyWhanauFragment.mostActiveTitle')}</Heading>
            <Paragraph>{i18n.t('components.MyWhanauFragment.mostActiveBody')}</Paragraph>
          </>
        )}
        {message === 'language' && (
          <>
            <Heading style={styles.heading}>{i18n.t('components.LanguageFragment.tipTitle')}</Heading>
            <Paragraph>{i18n.t('components.LanguageFragment.tipBody')}</Paragraph>
          </>
        )}
        {message === 'activities' && (
          <>
            <Heading style={styles.heading}>{i18n.t('components.MaraeParty.tipActivitiesTitle')}</Heading>
            <Paragraph>{i18n.t('components.MaraeParty.tipActivitiesBody')}</Paragraph>
          </>
        )}
        {message === 'koha' && (
          <>
            <Heading style={styles.heading}>{i18n.t('components.MaraeParty.tipKohaTitle')}</Heading>
            <Paragraph>{i18n.t('components.MaraeParty.tipKohaBody')}</Paragraph>
          </>
        )}
        {message === 'koha' || message === 'myWhanau' ? (
          <>
            <Heading style={styles.smallTitle}>{i18n.t('components.TipModal.tipKohaSystemTitle')}</Heading>
            <Paragraph>{i18n.t('components.TipModal.tipKohaSystemBody')}</Paragraph>
            <Heading style={styles.smallTitle}>{i18n.t('components.TipModal.tipKohaPointsTitle')}</Heading>
            <Paragraph>{i18n.t('components.TipModal.tipKohaPointsBody')}</Paragraph>
          </>
        ) : null}
        <View style={{ paddingTop: Layout.backgroundMarginNormal.top, backgroundColor: 'transparent' }} />
      </ScrollView>
    </View>
  );
};
