import React from 'react';
import { View, Image, StyleSheet } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import BottomBanner from '../../components/BottomBanner';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import HeaderBar from '../../components/HeaderBar';
import { Heading, UIText } from '../../components/StyledText';
import { Colors } from '../../constants/Colors';
import Fonts from '../../constants/Fonts';
import { User } from '../../constants/Interfaces';
import Layout from '../../constants/Layout';
import { confirmUser } from '../../reducers/user.slice';
import i18n from '../../translations';

const UserConfirmationScreen = ({ navigation }) => {
  const user: User = useSelector((state: any) => state.user.user);
  const dispatch = useDispatch();
  return (
    <View style={[styles.root]}>
      <Image source={require('../../../assets/images/splash_top.png')} style={styles.topImage} resizeMethod="resize" />
      <View style={styles.icons}>
        <HeaderBar navigation={navigation} />
      </View>
      <Image
        source={require('../../../assets/images/mf_logo_dark_no_text.png')}
        style={styles.logo}
        resizeMethod="scale"
      />
      <View style={styles.body}>
        <View style={styles.headerContainer}>
          <Heading>{`${i18n.t('pages.UserConfirmationScreen.welcome')} ${
            user && user.firstName ? user.firstName : ''
          }`}</Heading>
          <UIText style={styles.subtitle}>{i18n.t('pages.UserConfirmationScreen.ready')}</UIText>
        </View>
      </View>
      <PrimaryButton
        title={i18n.t('pages.UserConfirmationScreen.viewActivities')}
        onPress={() => dispatch(confirmUser())}
      />

      <Image
        source={require('../../../assets/images/splash_bottom.png')}
        style={styles.bottomImage}
        resizeMethod="resize"
      />
      <BottomBanner themeColor="red" />
    </View>
  );
};
export default UserConfirmationScreen;

const styles = StyleSheet.create({
  root: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.backgroundYellow,
    width: Layout.window.width,
    height: Layout.window.height,
  },
  topImage: {
    position: 'absolute',
    top: 0,
    height: 150,
    resizeMode: 'contain',
  },
  logo: {
    marginTop: -40,
    width: 150,
    height: 150,
    resizeMode: 'contain',
    opacity: 1,
    zIndex: 2,
  },
  body: {
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: '5%',
    paddingHorizontal: 10,
  },
  headerContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },

  paragraph: {
    marginTop: 27,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    marginBottom: 20,
  },
  headerDividers: {
    width: '100%',
    height: 10,
    marginBottom: 20,
  },
  buttonsContainer: {
    width: '80%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    flexDirection: 'row',
    height: 45,
    backgroundColor: 'white',
    borderRadius: 37,
    paddingVertical: 13,
    paddingHorizontal: 16,
    fontWeight: 'bold',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    marginVertical: 10,
  },
  buttonImg: {
    height: 25,
    width: 25,
    marginRight: 14.7,
    resizeMode: 'contain',
  },
  buttonText: {
    fontFamily: Fonts.family.bold,
  },
  bottomImage: {
    position: 'absolute',
    bottom: 70,
    width: Layout.window.width,
    height: 150,
    resizeMode: 'cover',
    zIndex: -1,
  },
  icons: {
    position: 'absolute',
    width: '100%',
    top: 12,
    left: 0,
    zIndex: 100,
  },
  subtitle: {
    marginVertical: 15,
  },
});
