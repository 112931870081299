import { DropdownItem, Ethnicity, Gender } from './Interfaces';
import i18n from '../translations';

export const listOfGenders: DropdownItem[] = [
  {
    label: i18n.t('references.gender.male'),
    value: Gender.MALE,
  },
  {
    label: i18n.t('references.gender.female'),
    value: Gender.FEMALE,
  },
  {
    label: i18n.t('references.gender.non-binary'),
    value: Gender.NON_BINARY,
  },
  {
    label: i18n.t('references.gender.transgender'),
    value: Gender.TRANSGENDER,
  },
  {
    label: i18n.t('references.gender.intersex'),
    value: Gender.INTERSEX,
  },
  {
    label: i18n.t('references.gender.not-to-say'),
    value: Gender.NOT_TO_SAY,
  },
];

export const listOfEthnicities: DropdownItem[] = [
  {
    label: i18n.t('references.ethnicity.maori'),
    value: Ethnicity.MAORI,
  },
  {
    label: i18n.t('references.ethnicity.european'),
    value: Ethnicity.EUROPEAN,
  },
  {
    label: i18n.t('references.ethnicity.pacific'),
    value: Ethnicity.PACIFIC,
  },
  {
    label: i18n.t('references.ethnicity.asian'),
    value: Ethnicity.ASIAN,
  },
  {
    label: i18n.t('references.ethnicity.melaa'),
    value: Ethnicity.MELAA,
  },
  {
    label: i18n.t('references.ethnicity.other'),
    value: Ethnicity.OTHER,
  },
];
