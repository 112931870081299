import * as React from 'react';
import { FlatList, StyleSheet, TouchableOpacity, ActivityIndicator } from 'react-native';
import { View } from '../../components/Themed';
import i18n from '../../translations';
import Layout from '../../constants/Layout';
import { useDispatch, useSelector } from 'react-redux';
import { Iwi, Marae, Region, Scope, User, WhanauMarae } from '../../constants/Interfaces';
import { Colors } from '../../constants/Colors';
import { Heading, Paragraph } from '../../components/StyledText';
import { AntDesign } from '@expo/vector-icons';
import API from '../../constants/API';
import { useState } from 'react';
import Logger from '../../constants/Logger';
import { getUser, postUserMarae } from '../../reducers/user.slice';
import Fonts from '../../constants/Fonts';
import { TipButton } from '../../components/TipButton';
import EllipseBorderImage from '../../components/EllipseBorderImage';

interface SelectMaraeScreenProps {
  navigation: any;
  route: {
    params: {
      region: Region;
    };
  };
}

export const SelectMaraeScreen = ({ navigation, route }: SelectMaraeScreenProps) => {
  const { region } = route.params;
  const user: User = useSelector((state: any) => state?.user?.user);
  const [listOfMaraes, setListOfMaraes] = useState<Marae[]>([]);
  const [selectedMarae, setSelectedMarae] = useState<Marae>();
  const [listOfIwis, setListOfIwis] = useState<Iwi[]>([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const styles = StyleSheet.create({
    root: {
      flex: 1,
      alignItems: 'center',
      backgroundColor: Colors.white,
      width: Layout.window.width,
      height: Layout.window.height,
    },
    header: {
      width: '100%',
      height: 100,
      backgroundColor: Colors.white,
      padding: Layout.backgroundMarginNormal.left,
      marginTop: Layout.backgroundMarginNormal.top,
      borderBottomColor: Colors.grey,
      borderBottomWidth: 1,
      flexDirection: 'row',
    },

    closeContainer: { height: '100%', marginLeft: 'auto' },
    close: {
      justifyContent: 'center',
      alignItems: 'center',
      width: 50,
      height: 50,
      backgroundColor: Colors.whiteOpacitiy40,
      borderRadius: 45,
      zIndex: 999,
    },

    bold: {
      fontFamily: Fonts.family.bold,
    },
    regular: {
      fontFamily: Fonts.family.regular,
    },
    selected: {
      fontFamily: Fonts.family.bold,
      color: Colors.burgundy,
    },

    loadingContainer: {
      position: 'absolute',
      marginVertical: Layout.window.height / 2,
    },

    maraeImage: {
      width: 30,
      height: 30,
    },
  });

  function close() {
    navigation.goBack();
  }

  React.useEffect(() => {
    setLoading(true);
    const api: API = API.getInstance();
    api
      .get(`/region/${region.regionId}/marae`, undefined, undefined, Scope.ANONYMOUS)
      .then((maraes: Marae[]) => {
        setListOfMaraes(maraes);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const handleMaraeSelected = (marae: Marae) => {
    if (selectedMarae === marae) {
      setSelectedMarae(undefined);
      return;
    }
    if (loading) {
      return;
    }

    setListOfIwis([]);
    setSelectedMarae(marae);
    setLoading(true);

    const api: API = API.getInstance();
    api
      .get(`/marae/${marae.maraeId}/iwi`, undefined, undefined, Scope.ANONYMOUS)
      .then((iwis: Iwi[]) => {
        // If there is only one iwi, directly set its id
        if (iwis.length === 1) {
          submitMarae(marae, iwis[0]);
          return;
        }
        // If there are 0 Iwi, then select the marae
        if (iwis.length === 0) {
          console.error(`This marae has no Iwis, it can't be used`);
          return;
        }

        setLoading(false);
        setListOfIwis(iwis);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleIwiSelected = (iwi: Iwi) => {
    if (selectedMarae) {
      submitMarae(selectedMarae, iwi);
    }
  };

  const submitMarae = async (marae: Marae, iwi: Iwi) => {
    if (loading) {
      return;
    }
    Logger.debug('SelectMaraeScreen -> submitMarae -> Submitting the user marae now');
    setLoading(true);
    const userMarae: WhanauMarae = {
      maraeId: marae.maraeId,
      iwiId: iwi?.iwiId,
    };
    try {
      await dispatch(postUserMarae(userMarae));
      await dispatch(getUser());
      setLoading(false);
      // if in the users profile, go to confirmation screen
      if (navigation.getParent('ProfileNavigator')) {
        navigation.navigate('ConfirmationScreen', {
          title: i18n.t('components.AddMaraeForm.confirmTitle', { userName: user?.firstName || '' }),
          message: i18n.t('components.AddMaraeForm.confirmMessage', { maraeName: marae.maraeName }),
          buttonText: i18n.t('components.AddMaraeForm.confirmButtonText'),
          nextScreenName: 'Profile',
        });
      } else {
        // if in the sign up flow, go to the root navigator
        navigation.navigate('Root');
      }
    } catch (e) {
      Logger.error(`SelectMaraeScreen -> submitMarae -> Error occurred: ${e}`);
    }
  };

  const IwiItem = ({ item }: { item: Iwi }) => (
    <TouchableOpacity onPress={() => handleIwiSelected(item)}>
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <View style={{ width: 40, height: 40, marginRight: 20 }} />
        <Paragraph style={{ flex: 1, marginVertical: 7 }}>{item.iwiName}</Paragraph>
      </View>
    </TouchableOpacity>
  );

  const MaraeItem = ({ item }: { item: Marae }) => {
    const isSelected = item.maraeId === selectedMarae?.maraeId;
    return (
      <View transparent>
        <TouchableOpacity onPress={() => handleMaraeSelected(item)}>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <EllipseBorderImage
              item={item}
              imageStyle={styles.maraeImage}
              borderStyle={{ marginRight: 20 }}
              color="yellow"
              img={require('../../../assets/images/marae_placeholder.png')}
            />
            <Paragraph style={[isSelected ? styles.selected : null, { flex: 1 }]}>{item.maraeName}</Paragraph>
          </View>
        </TouchableOpacity>
        {isSelected && listOfIwis.length > 0 && (
          <FlatList
            data={listOfIwis}
            renderItem={IwiItem}
            keyExtractor={(item) => `iwi-${item.iwiId}`}
            style={{ width: '100%' }}
            contentContainerStyle={{ marginTop: Layout.backgroundMarginNormal.top }}
          />
        )}
      </View>
    );
  };

  return (
    <View style={styles.root}>
      <View style={styles.header}>
        <View>
          <Heading>Select your marae</Heading>
          <Paragraph style={styles.bold}>{`${region.regionName} region`}</Paragraph>
        </View>
        <View style={styles.closeContainer}>
          <TouchableOpacity style={styles.close} onPress={() => close()}>
            <AntDesign name="close" size={32} color={Colors.black} />
          </TouchableOpacity>
        </View>
      </View>
      <FlatList
        data={listOfMaraes}
        renderItem={MaraeItem}
        keyExtractor={(item) => `mar-${item.maraeId}`}
        ItemSeparatorComponent={() => (
          <View
            style={{
              width: '100%',
              borderBottomColor: Colors.grey,
              borderBottomWidth: 1,
              marginVertical: Layout.backgroundMarginNarrow.top,
            }}
          />
        )}
        style={{ width: '100%', padding: Layout.backgroundMarginNormal.left }}
        contentContainerStyle={{ paddingBottom: 40 }}
      />
      <TipButton navigation={navigation} message="marae" style={{ right: 20, top: 100 }} />
      {loading && (
        <View style={[styles.loadingContainer]} transparent>
          <ActivityIndicator size="large" color={Colors.black} />
        </View>
      )}
    </View>
  );
};
