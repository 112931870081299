import axios, { AxiosRequestConfig } from 'axios';
import Logger from './Logger';
import _ from 'lodash';
import { Platform } from 'react-native';
import * as WebBrowser from 'expo-web-browser';
import ApiConfig from '../config/ApiConfig';
import { AuthState, Scope } from './Interfaces';
import { DateTime } from 'luxon';
import { logOut, shouldTerminateSession } from './Utils';
import { getAuthenticatedUser } from '../reducers/authentication.slice';
import { TokenResponse } from 'expo-auth-session';

/**
 * Use function to get common config in case it gets overwritten downstream
 */
export function getCommonConfig(): AxiosRequestConfig {
  return {
    baseURL: ApiConfig.apiEndpoint,
    timeout: ApiConfig.defaultTimeout * 1000,
    responseType: 'json',
    headers: {},
  };
}

/**
 * Singleton class containing common methods for calling MaraeFit API.
 */

export default class API {
  private static instance: API;

  readonly store;

  readonly accessTokens: { [key: string]: AuthState | null };

  private readonly urls: { [key: string]: string };

  private readonly tokenPromises: { [key: string]: Promise<any> | null };

  private constructor(store?: any) {
    Logger.info(`API -> constructor: initializing now...`);
    this.accessTokens = {
      api: null,
    };
    this.store = store;
    this.urls = {};
    this.tokenPromises = {
      api: null,
    };
  }

  static getInstance(store?: any): API {
    if (!API.instance) {
      API.instance = new API(store);
    }
    return API.instance;
  }

  /**
   * Set access token for a particular scope
   * @param scope the name of the scope.
   * @param token the token response.
   */
  setToken(scope: Scope, token: AuthState) {
    if (!token.expiresIn) {
      return;
    }
    Logger.debug(
      `API -> setToken: Setting the access token for scope ${scope} now. Expiry date: ${DateTime.fromSeconds(
        token.issuedAt + token.expiresIn
      ).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}`
    );
    // Below logging is for debugging API related issues ONLY. DO NOT ENABLE ON PRODUCTION!!
    // Logger.debug(`API -> setToken: access token for scope ${scope} is: \n${token}`);
    // Above logging is for debugging API related issues ONLY. DO NOT ENABLE ON PRODUCTION!!
    this.accessTokens[scope] = token;
  }

  /**
   * Clear token for a specific scope.
   * @param scope
   */
  clearToken(scope: Scope = Scope.API) {
    Logger.info(`API -> clearToken: Clearing token for scope ${scope}.`);
    this.accessTokens[scope] = null;
  }

  /**
   * Private: Get access token for a scope.
   * @param scope the scope for the access token.
   */
  async getToken(scope: Scope): Promise<AuthState | null> {
    const token: AuthState | null = this.accessTokens[scope];
    if (token && TokenResponse.isTokenFresh(token)) {
      return token;
    }

    // if token is expiring soon, renew it first.
    if (scope === Scope.API) {
      try {
        if (!this.tokenPromises[scope]) {
          this.tokenPromises[scope] = this.store.dispatch(getAuthenticatedUser());
        }
        await this.tokenPromises[scope];
      } catch (e) {
        if (shouldTerminateSession(e)) {
          Logger.info(`API -> getToken: Session terminated because the invalid_grant was received.`);
          logOut();
        } else {
          Logger.error(`API -> getToken: Error occurred when getting a new access token.`, e);
        }
      } finally {
        this.tokenPromises[scope] = null;
      }
    }

    const newToken: AuthState | null = this.accessTokens[scope];
    if (newToken && newToken.expiresIn && newToken.issuedAt && TokenResponse.isTokenFresh(newToken)) {
      Logger.debug(
        `API -> getToken: New token expiry is: ${DateTime.fromSeconds(
          newToken.issuedAt + newToken.expiresIn
        ).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}`
      );
      return newToken;
    }

    // if there is still no access token, return null
    return null;
  }

  /**
   * Private: Append access token to the request. This method MUTATES the config object passed in.
   * @param config the Axios request config
   * @param scope the scope of the request
   */
  async appendToken(config: AxiosRequestConfig, scope: Scope = Scope.API): Promise<void> {
    if (scope !== Scope.ANONYMOUS) {
      // if a scope is given, we need to append the access token in the request header.
      const token = await this.getToken(scope);

      if (!token) {
        throw new Error(`API -> appendToken: NOT_AUTHORIZED No access token can be obtained for the scope: ${scope}`);
      }
      config.headers.Authorization = `Bearer ${token.accessToken}`;
    }
  }

  /**
   * Wrapper for API GET method for preventing caching in the donor API.
   * @param path the path of the API.
   * @param params the key-value pair parameters.
   * @param opts additional options for the donor API.
   * @param scope optional scope for the api. If null is passed in, the call won't attach any access token.
   */
  async get(path: string, params?: object, opts?: AxiosRequestConfig, scope?: Scope) {
    Logger.info(`API -> get: Getting data from ${path}`);
    const config: AxiosRequestConfig = {
      ...getCommonConfig(),
      ...opts,
      params,
    };

    try {
      await this.appendToken(config, scope);
      const response = await axios.get(path, config);
      return response.data;
    } catch (e) {
      Logger.error(`API -> get: Error in getting the data for ${path}`, e);
      // Logger.error(e.response?.data);
      throw e;
    }
  }

  /**
   * Wrapper for  API PATCH method.
   * @param path the path of the API.
   * @param patch the key-value pair patch.
   * @param opts additional options for the donor API.
   * @param scope optional scope for the api. If null is passed in, the call won't attach any access token.
   */
  async patch(path: string, patch: object, opts?: AxiosRequestConfig, scope?: Scope) {
    Logger.info(`API -> patch: Patch data to ${path}`);
    const config: AxiosRequestConfig = {
      ...getCommonConfig(),
      ...opts,
    };

    try {
      await this.appendToken(config, scope);
      const response = await axios.patch(path, patch, config);
      return response.data;
    } catch (e) {
      Logger.error(`API -> patch: Error in patching the data`, e);
      throw e;
    }
  }

  /**
   * Wrapper for donor API POST method.
   * @param path the path of the API.
   * @param payload the payload of the post method.
   * @param opts additional options for the donor API.
   * @param scope optional scope for the api. If null is passed in, the call won't attach any access token.
   */
  async post(path: string, payload: object, opts?: AxiosRequestConfig, scope?: Scope) {
    Logger.info(`API -> post: Posting data to ${path}`);
    Logger.debug(`API -> post: Payload data ${JSON.stringify(payload)}`);
    const config: AxiosRequestConfig = {
      ...getCommonConfig(),
      ...opts,
    };

    try {
      await this.appendToken(config, scope);
      const response = await axios.post(path, payload, config);
      return response.data;
    } catch (e) {
      Logger.error(`API -> post: Error in posting the data`, e);
      // Logger.error(JSON.stringify(e.response?.data));
      throw e;
    }
  }

  /**
   * Wrapper for donor API DELETE method.
   * @param path the path of the API.
   * @param opts additional options for the donor API.
   * @param scope optional scope for the api. If null is passed in, the call won't attach any access token.
   */
  async delete(path: string, opts?: AxiosRequestConfig, scope?: Scope) {
    Logger.info(`API -> del: Deleting data from ${path}`);
    const config: AxiosRequestConfig = {
      ...getCommonConfig(),
      ...opts,
    };

    try {
      await this.appendToken(config, scope);
      const response = await axios.delete(path, config);
      return response.data;
    } catch (e) {
      Logger.error(`API -> del: Error in deleting the data`, e);
      throw e;
    }
  }

  /**
   * Set any global url in the API instance that can be persisted in multiple pages.
   * @param key the key of the url.
   * @param url the url of the web page.
   */
  setUrl(key: string, url: string) {
    Logger.debug(`API -> setUrl: Setting url for key ${key} to be ${url}`);
    this.urls[key] = url;
  }

  /**
   * Batch set urls from a dictionary
   * @param urls the key-value mapping of urls.
   */
  setUrls(urls: { [key: string]: string }) {
    _.map(urls, (url, key) => {
      this.setUrl(key, url);
    });
  }

  /**
   * Open a url or key in the Web browser
   * @param request the url or key to open. If a local key is defined, use the url mapped to the key instead.
   */
  async openUrl(request: string) {
    if (!request || request.length === 0) {
      Logger.error(`API -> openUrl: Cannot handle an empty request`);
      throw new Error('Request is empty');
    }
    let url: string;
    if (request.startsWith('http')) {
      // if the request starts with http, we directly treat it as url.
      url = request;
    } else {
      // tries to find the key in the url store.
      if (!this.urls[request] || this.urls[request].length === 0) {
        Logger.error(`API -> openUrl: Cannot find the url for the given key: ${request}`);
        throw new Error('No Url found for the key');
      }
      url = this.urls[request];
    }
    Logger.debug(`API -> openUrl: Opening the url ${url} in the in-app-browser`);
    if (Platform.OS === 'ios') {
      await WebBrowser.dismissBrowser();
    }
    try {
      await WebBrowser.openBrowserAsync(url);
    } catch (e) {
      Logger.error(`API -> openUrl: Error starting the browser`, e);
      throw e;
    }
  }
}
