import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { commonStyles } from './CommonStyles';
import { contentfulUrlGenerator } from './Utils/contentfulUrlGenerator';
import { RichTextResponseYellow } from './Utils/RichTextResponse';
import { languageListener } from './Utils/languageListener';
import { CreateResponsiveStyle } from 'rn-responsive-styles';
import LayoutWeb from './LayoutWeb';

const Policy = () => {
  const styles = useStyles();
  const [data, setData] = useState<any>();
  const language = languageListener();

  const URL = contentfulUrlGenerator('privacyPolicy', language);

  useEffect(() => {
    const getData = async () => {
      const result = await axios(URL);
      setData(result.data);
    };
    getData();
  }, [language]);

  return (
    <LayoutWeb>
      <View style={[commonStyles.body, styles.container]}>
        {RichTextResponseYellow({ richTextResponse: data?.items[0].fields.content })}
      </View>
    </LayoutWeb>
  );
};

export default Policy;

const useStyles = CreateResponsiveStyle({
  container: {
    maxWidth: '80%',
    alignSelf: 'center',
  },
});
