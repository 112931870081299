import React from 'react';
import { View, StyleSheet, ImageBackground, Pressable, Image, Platform } from 'react-native';
import { ActionModal } from '../../../components/ActionModal';
import { DetailItem } from '../../../components/DetailItem';
import { UIText } from '../../../components/StyledText';
import { Colors } from '../../../constants/Colors';
import Fonts from '../../../constants/Fonts';
import Layout from '../../../constants/Layout';
import Logger from '../../../constants/Logger';
import i18n from '../../../translations';
import SecondaryButton from '../../../components/buttons/SecondaryButton';
import { useSelector } from 'react-redux';
import { getUserActivities, postUserActivity } from '../../../reducers/activity.slice';
import { ActivityStats, User, UserActivity } from '../../../constants/Interfaces';
import _ from 'lodash';
import ReviewFragment from './ReviewFragment';
import { validatePastActivity } from '../../../constants/Utils';
import ModalUI from '../../../components/ModalUI';
import { useAppDispatch } from '../../../store/configureStore';
import { ActivityScannerFragment } from '../../home/fragments/ActivityScannerFragment';

interface RegiteredFragmentProps {
  activityId: string;
  activityStats: ActivityStats | undefined;
  myActivity: UserActivity;
}

const RegisteredFragment = ({ activityId, activityStats, myActivity }: RegiteredFragmentProps) => {
  const user: User = useSelector((state: any) => state.user.user);
  const isPastActivity = validatePastActivity(myActivity.endDate);
  const activitiesBackgroundLoading: boolean = useSelector((state: any) => state.activity.backgroundLoading);
  const myActivities: UserActivity[] = useSelector((state: any) => state.activity.myActivities);
  const whanaus: UserActivity[] = myActivities.filter((item) =>
    item.activityId === activityId && item.userId !== user.userId ? item.userId : ''
  );
  _.filter((item) => item !== '');

  const dispatch = useAppDispatch();
  const scanned =
    myActivities.find((item) => item.activityId === activityId && item.userId === user.userId)?.checkedIn ||
    myActivity?.checkedIn;
  const [activeScanner, setActiveScanner] = React.useState<boolean>(false);
  const [activeReview, setActiveReview] = React.useState<boolean>(false);
  const [activePastActivity, setActivePastActivity] = React.useState<boolean>(false);

  const handleScanQR = () => {
    if (isPastActivity) {
      setActivePastActivity(true);
    } else {
      setActiveScanner(true);
    }
  };

  const handleCantGo = async () => {
    Logger.debug(`I cannot Attend`);
    const promises: any[] = [];
    promises.push(
      dispatch(
        postUserActivity({
          activity: {
            activityId: activityId.trim(),
            favourite: myActivity ? myActivity.favourite : false,
            going: false,
            maraeId: myActivity?.maraeId,
          },
        })
      )
    );
    _.each(whanaus, (whanau) => {
      promises.push(
        dispatch(
          postUserActivity({
            userId: whanau.userId,
            activity: {
              activityId: activityId.trim(),
              favourite: false,
              going: false,
              maraeId: myActivity.maraeId,
            },
          })
        )
      );
    });
    await Promise.all(promises);
    dispatch(getUserActivities());
  };

  const handleDone = async () => {
    await dispatch(getUserActivities());
    setActiveReview(false);
  };
  const handleReview = () => {
    setActiveReview(true);
  };
  const handleReviewBlocked = () => {
    if (activitiesBackgroundLoading) {
      return;
    }
    dispatch(getUserActivities())
      .unwrap()
      .then((activities: UserActivity[]) => {
        const scanned = activities.find(
          (item) => item.activityId === activityId && item.userId === user.userId
        )?.checkedIn;
        if (scanned) {
          handleReview();
        }
      });
  };
  const handleScanDone = () => {
    setActiveScanner(false);
    dispatch(getUserActivities());
  };
  const renderScanQR = () => {
    const isDisabled = isPastActivity || scanned;
    return (
      <Pressable
        style={[styles.qrContainer, styles.ticketContainer, { opacity: isDisabled ? 0.7 : 1 }]}
        onPress={isDisabled ? null : handleScanQR}
      >
        <UIText style={styles.ticketText}>{i18n.t('components.RegisteredFragment.scanQR')}</UIText>
        <Image
          source={
            !isDisabled
              ? require('../../../../assets/images/qr_code.png')
              : require('../../../../assets/images/qr_code_disabled.png')
          }
          style={styles.qrImage}
        />
      </Pressable>
    );
  };

  return (
    <>
      <View style={styles.goingContainer}>
        <View style={styles.activitiyContainer}>
          {renderScanQR()}

          <Pressable
            onPress={scanned ? handleReview : handleReviewBlocked}
            style={[
              styles.ticketContainer,
              scanned ? { borderStyle: 'dashed', opacity: 1 } : { borderStyle: 'solid', opacity: 0.7 },
            ]}
          >
            <ImageBackground
              source={
                scanned
                  ? require('../../../../assets/images/review.png')
                  : require('../../../../assets/images/reviewDisable.png')
              }
              style={styles.reviewContainer}
            >
              <UIText style={styles.ticketText}>{i18n.t('components.RegisteredFragment.yourReview')}</UIText>
            </ImageBackground>
          </Pressable>
        </View>
        {!scanned && (
          <SecondaryButton
            icon={require('../../../../assets/images/settings-backup-restore.png')}
            bgColor="yellow"
            title={i18n.t('components.RegisteredFragment.noAttend')}
            onPress={handleCantGo}
            styleButton={styles.cantGoButton}
            styleText={styles.cantGoText}
            styleIcon={styles.cantGoIcon}
          />
        )}
      </View>
      {!scanned && (
        <DetailItem
          label={i18n.t('components.RegisteredFragment.eHaereAna')}
          description={i18n.t('pages.ActivityDetailsScreen.whanaugoing', {
            count: activityStats ? activityStats.engagementTypeGoing.personTotal : 0,
          })}
        />
      )}
      {scanned && (
        <DetailItem
          label={i18n.t('components.RegisteredFragment.eHaereAna')}
          description={i18n.t('components.RegisteredFragment.whanauWent', {
            count: activityStats ? activityStats.engagementTypeGoing.personTotal : 0,
          })}
        />
      )}

      <ActionModal
        visible={activeReview}
        onModalClose={() => setActiveReview(false)}
        height={Platform.OS === 'android' ? 530 : 650}
        bgColor="yellow"
      >
        <ReviewFragment handleGoback={() => setActiveReview(false)} handleDone={handleDone} activityId={activityId} />
      </ActionModal>

      <ModalUI toggle={activePastActivity} closeModal={() => setActivePastActivity(false)}>
        <UIText>{i18n.t('components.RegisteredFragment.activityClosed')}</UIText>
      </ModalUI>

      <ActionModal
        visible={activeScanner}
        onModalClose={() => setActiveScanner(false)}
        height={Layout.window.height - 100}
      >
        <ActivityScannerFragment
          activityId={myActivity?.activityId}
          title={i18n.t('components.CodeScannerFragment.headerWhanau')}
          onScanComplete={handleScanDone}
        />
      </ActionModal>
    </>
  );
};

export default RegisteredFragment;
const styles = StyleSheet.create({
  goingContainer: {
    width: Layout.window.width - Layout.backgroundMarginNormal.left * 2,
    marginHorizontal: Layout.backgroundMarginNormal.left,
  },
  cantGoButton: {
    borderWidth: 3,
    borderColor: Colors.darkBurgundy,
    marginVertical: 15,
    width: '100%',
  },
  cantGoText: {
    color: Colors.darkBurgundy,
  },
  cantGoIcon: {
    height: 20,
    width: 20,
  },
  activitiyContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 15,
    height: Layout.window.width / 2.4,
  },
  ticketContainer: {
    borderWidth: 2,
    borderRadius: 2,
    borderColor: Colors.darkBurgundy,
    width: '48%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  reviewContainer: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    paddingTop: 15,
  },
  qrContainer: {
    borderStyle: 'dashed',
  },
  ticketText: {
    color: Colors.darkBurgundy,
    fontFamily: Fonts.family.bold,
    fontSize: Fonts.sizes.medium,
  },
  qrImage: {
    width: '70%',
    height: '70%',
  },
});
