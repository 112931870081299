import * as React from 'react';
import { StyleSheet } from 'react-native';
import { View } from './Themed';
import { UIText } from './StyledText';
import Fonts from '../constants/Fonts';
import Layout from '../constants/Layout';

interface DetailItemProps {
  label: string;
  description?: string | any | null;
}

export const DetailItem = ({ label, description = '' }: DetailItemProps) => (
  <View transparent style={styles.container}>
    <View transparent style={styles.labelContainer}>
      <UIText style={styles.label}>{label}</UIText>
    </View>
    <View transparent style={styles.descriptionContainer}>
      {typeof description === 'string' ? <UIText>{description}</UIText> : description}
    </View>
  </View>
);

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Layout.backgroundMarginNormal.left,
    paddingBottom: 24,
  },
  labelContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    fontFamily: Fonts.family.bold,
  },
  iconContainer: {
    width: 28,
  },
  descriptionContainer: {
    paddingTop: 4,
  },
});
