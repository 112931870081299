import React from 'react';
import { Image, StyleSheet } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { View } from '../../components/Themed';
import { Heading, Link, Paragraph } from '../../components/StyledText';
import i18n from '../../translations';
import { styles } from './RegistrationCommonStyles';
import { skipMarae } from '../../reducers/user.slice';
import Layout from '../../constants/Layout';
import { purge } from '../../reducers/commonActions';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import { getUserActivities } from '../../reducers/activity.slice';
import { useAppDispatch } from '../../store/configureStore';
import { logOut } from '../../constants/Utils';

export default function AddMaraeScreen({ navigation }) {
  const dispatch = useAppDispatch();

  const handleSkip = async () => {
    await dispatch(skipMarae());
    navigateToRoot();
  };

  const handleCancel = () => {
    logOut();
  };

  const handleFind = () => {
    navigation.navigate('SelectRegion');
  };

  const navigateToRoot = async () => {
    await dispatch(getUserActivities());
    navigation.navigate('Root');
  };

  React.useEffect(
    () =>
      navigation.addListener('beforeRemove', (e) => {
        // Prevent default behavior of leaving the screen
        e.preventDefault();
      }),
    [navigation]
  );

  return (
    <View style={styles.container}>
      <KeyboardAwareScrollView
        extraScrollHeight={30}
        keyboardOpeningTime={500}
        viewIsInsideTabBar
        enableAutomaticScroll
        contentContainerStyle={styles.scrollContainer}
        enableResetScrollToCoords={false}
        enableOnAndroid
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        removeClippedSubviews={false}
        keyboardShouldPersistTaps="handled"
        nestedScrollEnabled={false}
        bounces={false}
      >
        <Image
          source={require('../../../assets/images/splash_top.png')}
          style={styles.topImage}
          resizeMethod="resize"
        />
        <Image
          source={require('../../../assets/images/mf_logo_dark_no_text.png')}
          style={styles.topLogo}
          resizeMethod="scale"
        />
        <View transparent style={styles.skipCancelContainer}>
          <Link onPress={handleCancel} title={i18n.t('pages.AddMaraeScreen.cancel')} />
          <View transparent style={styles.filler} />
          <Link onPress={handleSkip} title={i18n.t('pages.AddMaraeScreen.skip')} />
        </View>
        <View transparent style={{ paddingTop: 60 }} />
        <View transparent style={styles.headerContainer}>
          <Heading style={styles.heading}>{i18n.t('pages.AddMaraeScreen.title')}</Heading>
          <Heading style={styles.subtitle}>{i18n.t('pages.AddMaraeScreen.subtitle')}</Heading>
          <Paragraph style={styles.introduction}>{i18n.t('pages.AddMaraeScreen.introduction')}</Paragraph>
        </View>
        <View transparent style={{ width: Layout.window.width }}>
          <PrimaryButton
            title={i18n.t('pages.AddMaraeScreen.find')}
            onPress={handleFind}
            containerStyle={styles.submitButton}
          />
        </View>
      </KeyboardAwareScrollView>
      <Image
        source={require('../../../assets/images/splash_bottom.png')}
        style={localStyles.bottomImage}
        resizeMethod="resize"
      />
    </View>
  );
}

const localStyles = StyleSheet.create({
  bottomImage: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: Layout.window.width,
    height: 250,
    resizeMode: 'cover',
    zIndex: -1,
  },
});
