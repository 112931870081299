import React, { useState, useCallback } from 'react';
import { View, StyleSheet, Pressable } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import { useDispatch, useSelector } from 'react-redux';
import HeroLeaderboard from '../../components/HeroLeaderboard';
import ModalUI from '../../components/ModalUI';
import ProgressBar from '../../components/ProgressBar';
import ScreenHolder from '../../components/ScreenHolder';
import { Heading, UIText } from '../../components/StyledText';
import { Colors } from '../../constants/Colors';
import Fonts from '../../constants/Fonts';
import { UserMarae, MaraeLeaderboard } from '../../constants/Interfaces';
import Layout from '../../constants/Layout';
import useLogoutUser from '../../hooks/useLogoutUser';
import { getMaraeLeaderboard } from '../../reducers/marae.slice';
import i18n from '../../translations';
import ElevateContent from './ElevateContent';

export const LeaderboardScreen = ({ navigation }) => {
  const leaderboardList: MaraeLeaderboard[] = useSelector((state: any) => state.marae.leaderboard);
  const dispatch = useDispatch();

  useLogoutUser();

  useFocusEffect(
    useCallback(() => {
      dispatch(getMaraeLeaderboard());
    }, [])
  );

  const [activeModal, setActiveModal] = useState(false);
  const filterRegion: string = useSelector((state: any) => state.region.selectedRegionLeaderboard);
  const myMaraes: UserMarae[] = useSelector((state: any) => state.user.user?.marae) || [];
  const leaderboard = filterRegion
    ? leaderboardList.filter((item) => item.region === filterRegion).sort((a, b) => b.pointTotal - a.pointTotal)
    : [...leaderboardList].sort((a, b) => b.pointTotal - a.pointTotal);

  const top10 = leaderboard?.slice(0, 10);
  const highestScore = leaderboard[0]?.pointTotal;

  const noTop10 = () => {
    const noRankedMaraes = leaderboard.slice(10);
    const noRanked: any = [];
    for (let i = 0; i < myMaraes.length; i += 1) {
      for (let j = 0; j < noRankedMaraes.length; j += 1) {
        if (myMaraes[i].maraeId === noRankedMaraes[j].maraeId) {
          const obj = { position: j + 11, marae: noRankedMaraes[j] };
          noRanked.push(obj);
        }
      }
    }
    return noRanked.sort((a, b) => a.position - b.position);
  };
  return (
    <ScreenHolder
      navigation={navigation}
      heroImage={leaderboard ? <HeroLeaderboard data={top10.slice(0, 3)} navigation={navigation} /> : null}
    >
      <View style={styles.container}>
        <Heading style={styles.title}>{i18n.t('pages.LeaderboardScreen.mostActive')}</Heading>
        <View style={styles.board}>
          <UIText style={styles.pointsLabel}>{i18n.t('pages.LeaderboardScreen.points')}</UIText>
          {top10.length > 2
            ? top10
                .slice(3)
                .map((marae, index) => (
                  <ProgressBar marae={marae} position={index + 4} top={highestScore} key={index} />
                ))
            : top10
                .slice(1)
                .map((marae, index) => (
                  <ProgressBar marae={marae} position={index + 2} top={highestScore} key={index} />
                ))}
          {noTop10()
            ? noTop10().map((marae) => (
                <ProgressBar marae={marae.marae} position={marae.position} top={highestScore} key={marae.position} />
              ))
            : null}
        </View>
        <View style={styles.leyendContainer}>
          <UIText style={[styles.leyendLabel, { fontFamily: Fonts.family.bold }]}>
            {i18n.t('pages.LeaderboardScreen.key')}
          </UIText>
          <View style={[styles.leyendIndicator, { backgroundColor: Colors.pink }]} />
          <UIText style={styles.leyendLabel}>{i18n.t('pages.LeaderboardScreen.myMarae')}</UIText>
          <View style={[styles.leyendIndicator, { backgroundColor: Colors.yellow }]} />
          <UIText style={styles.leyendLabel}>{i18n.t('pages.LeaderboardScreen.10mostActive')}</UIText>
        </View>
        <View>
          <Pressable style={styles.elevateButton} onPress={() => setActiveModal(true)}>
            <UIText style={[styles.maraeBar]}>{i18n.t('pages.LeaderboardScreen.elevatePosition')}</UIText>
          </Pressable>
        </View>
        <ModalUI toggle={activeModal} closeModal={() => setActiveModal(false)}>
          <ElevateContent />
        </ModalUI>
      </View>
      <View style={{ height: Layout.isSmallDevice ? 150 : 100, backgroundColor: Colors.transparent, top: 50 }} />
    </ScreenHolder>
  );
};

const styles = StyleSheet.create({
  container: {
    minHeight: Layout.isSmallDevice ? Layout.window.height : Layout.window.height - 150,
  },
  title: {
    color: Colors.white,
    alignSelf: 'center',
  },
  board: {
    justifyContent: 'center',
    width: '95%',
  },
  pointsLabel: {
    color: Colors.white,
    fontFamily: Fonts.family.bold,
    fontSize: Fonts.sizes.small,
    alignSelf: 'flex-end',
  },
  maraeBar: {
    fontFamily: Fonts.family.bold,
  },
  leyendContainer: {
    justifyContent: 'space-around',
    alignItems: 'center',
    flexDirection: 'row',
    paddingHorizontal: '18%',
    marginTop: 20,
  },
  leyendIndicator: {
    height: '100%',
    width: 4,
  },
  leyendLabel: {
    fontFamily: Fonts.family.regular,
    fontSize: Fonts.sizes.medium,
    color: Colors.white,
  },
  elevateButton: {
    width: '60%',
    height: 50,
    backgroundColor: Colors.yellow,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 25,
    marginTop: 20,
    alignSelf: 'center',
  },
});
