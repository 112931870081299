import { useNavigation } from '@react-navigation/core';
import React from 'react';
import { View, Image, Pressable } from 'react-native';
import { Colors } from '../constants/Colors';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';

interface MenuBarProps {
  showMenu?: () => void;
  showLogo?: boolean;
  menuBarTheme?: 'dark' | 'light';
  isLayoutAbsolute?: boolean;
  isHighlighted?: boolean;
}
const MenuBar = ({
  showMenu,
  showLogo = true,
  menuBarTheme = 'dark',
  isLayoutAbsolute = false,
  isHighlighted = false,
}: MenuBarProps) => {
  const styles = useStyles();
  const navigation = useNavigation();

  const handleHome = () => {
    navigation.navigate('LandingWeb');
  };

  return (
    <View style={!isLayoutAbsolute ? styles.root : styles.absoluteRoot}>
      {showLogo && (
        <Pressable onPress={handleHome} style={styles.logoContainer}>
          {menuBarTheme === 'light' ? (
            <Image
              source={require('../../assets/images/mf_logo_light.png')}
              style={isHighlighted ? styles.logoHightlighted : styles.logo}
            />
          ) : (
            <Image
              source={require('../../assets/images/mf_logo_dark_no_text.png')}
              style={isHighlighted ? styles.logoHightlighted : styles.logo}
            />
          )}
        </Pressable>
      )}
      {menuBarTheme === 'light' ? (
        <Image
          source={require('../../assets/images/splash_top_white.png')}
          style={[styles.splashImage, styles.opacityLight]}
        />
      ) : (
        <Image source={require('../../assets/images/splash_top.png')} style={[styles.splashImage]} />
      )}
      <View style={[styles.navContainer]}>
        <Pressable
          style={[styles.pressableBg, menuBarTheme === 'light' ? styles.pressableBgLight : null]}
          onPress={showMenu}
        >
          {menuBarTheme === 'light' ? (
            <Image source={require('../../assets/images/hamburgerIconWhite.png')} style={styles.icon} />
          ) : (
            <Image source={require('../../assets/images/hamburgerIcon.png')} style={styles.icon} />
          )}
        </Pressable>
      </View>
    </View>
  );
};

export default MenuBar;

const useStyles = CreateResponsiveStyle(
  {
    root: {
      height: 120,
      width: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingHorizontal: '5%',
      marginTop: 20,
    },
    absoluteRoot: {
      height: 120,
      width: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingHorizontal: '5%',
      marginTop: 20,
      position: 'absolute',
      zIndex: 10,
    },
    logoContainer: {
      height: '100%',
      width: 140,
    },
    logo: {
      height: '100%',
      width: 140,
      resizeMode: 'contain',
    },
    logoHightlighted: {
      aspectRatio: 1,
      width: 160,
      resizeMode: 'contain',
    },
    navContainer: {
      width: 140,
      alignItems: 'center',
    },
    pressableBg: {
      width: 70,
      height: 70,
      backgroundColor: Colors.transparent,
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 90,
      borderWidth: 3,
      borderColor: Colors.black,
    },
    pressableBgLight: {
      borderColor: Colors.white,
    },
    icon: {
      width: 46,
      height: 46,
      resizeMode: 'contain',
    },
    splashImage: {
      resizeMode: 'contain',
      height: 144,
      width: 326,
      opacity: 0.7,
      marginTop: -10,
    },
    opacityLight: {
      opacity: 0.3,
    },
  },
  {
    [DEVICE_SIZES.MD]: {
      root: {
        height: 72,
        marginTop: 10,
      },
      absoluteRoot: {
        height: 72,
        marginTop: 10,
      },
      logoHightlighted: {
        width: 128,
      },
      splashImage: {
        height: 105,
        width: 209,
        marginTop: 20,
      },
      pressableBg: {
        width: 60,
        height: 60,
      },
      icon: {
        width: 40,
        height: 40,
        resizeMode: 'contain',
      },
    },
    [DEVICE_SIZES.SM]: {
      root: {
        height: 72,
        marginTop: 10,
      },
      absoluteRoot: {
        height: 72,
        marginTop: 10,
      },
      pressableBg: {
        width: 50,
        height: 50,
      },
      logoHightlighted: {
        width: 118,
      },
      icon: {
        width: 30,
        height: 30,
        resizeMode: 'contain',
      },
      splashImage: {
        height: 105,
        width: 209,
        marginTop: 20,
      },
    },
    [DEVICE_SIZES.XS]: {
      root: {
        height: 62,
        marginTop: 10,
      },
      absoluteRoot: {
        height: 62,
        marginTop: 10,
      },
      splashImage: {
        height: 105,
        width: 209,
        marginTop: 20,
      },
      logoContainer: {
        width: 70,
      },
      logoHightlighted: {
        width: 70,
      },
      logo: {
        width: 70,
      },
      navContainer: {
        width: 50,
      },
      pressableBg: {
        width: 50,
        height: 50,
      },
      icon: {
        width: 30,
        height: 30,
        resizeMode: 'contain',
      },
    },
  }
);
