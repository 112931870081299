import { ProfileScreen } from '../screens/profile/ProfileScreen';
import { SelectRegionScreen } from '../screens/profile/SelectRegionScreen';
import * as React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { useNavigation } from '@react-navigation/native';
import { SelectMaraeScreen } from '../screens/profile/SelectMaraeScreen';
import { TipScreen } from '../screens/profile/TipScreen';

const ProfileStack = createStackNavigator();

export const ProfileNavigator = () => {
  const navigation = useNavigation();

  React.useEffect(
    () =>
      navigation.addListener('beforeRemove', () => {
        navigation.navigate('DashboardStack');
      }),
    []
  );

  return (
    <ProfileStack.Navigator screenOptions={{ headerShown: false }} id="ProfileNavigator">
      <ProfileStack.Screen name="Profile" component={ProfileScreen} />
      <ProfileStack.Screen name="SelectRegion" component={SelectRegionScreen} />
      <ProfileStack.Screen name="SelectMarae" component={SelectMaraeScreen} />
      <ProfileStack.Screen name="TipScreen" component={TipScreen} />
    </ProfileStack.Navigator>
  );
};
