import React from 'react';
import { Image, StyleSheet, Pressable } from 'react-native';
import RatingStars from '../../../components/RatingStars';
import { UIText } from '../../../components/StyledText';
import { Colors } from '../../../constants/Colors';
import { Review } from '../../../constants/Interfaces';
import Fonts from '../../../constants/Fonts';
import { View } from '../../../components/Themed';
import { getGenderPlaceholder } from '../../../constants/Utils';

interface ReviewCardProps {
  review: Review;
  onPress: (review: Review) => void;
  labelColor?: 'black';
}

const ReviewCard = ({ review, onPress, labelColor }: ReviewCardProps) => {
  const reviewerName = `${review.firstName} ${review.lastName}`;
  const { activityName, imageUrl, score = 0, gender } = review;

  const handlePress = () => {
    onPress(review);
  };

  const fontColor = () => {
    if (!labelColor) {
      return Colors.white;
    }
    return Colors.black;
  };

  return (
    <Pressable style={styles.root} onPress={handlePress}>
      <Image source={imageUrl ? { uri: imageUrl } : getGenderPlaceholder(gender)} style={styles.image} />
      <View transparent>
        <View transparent style={styles.labelContainer}>
          <UIText style={[styles.label, { fontFamily: Fonts.family.bold, color: fontColor() }]}>
            {reviewerName},{' '}
          </UIText>
          <UIText style={[styles.label, { color: fontColor() }]}>{activityName}</UIText>
        </View>
        <RatingStars stars={score} />
      </View>
    </Pressable>
  );
};

export default ReviewCard;

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 5,
  },
  image: {
    height: 40,
    width: 40,
    resizeMode: 'contain',
    marginRight: 12,
    borderRadius: 3,
  },
  labelContainer: {
    flexDirection: 'row',
    marginBottom: 6,
  },
  label: {
    fontSize: Fonts.sizes.medium,
  },
});
