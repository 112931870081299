import React, { useState } from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import Fonts from '../../constants/Fonts';
import { Colors } from '../../constants/Colors';
import DateTimePickerModal from 'react-native-modal-datetime-picker';
import { DateTime } from 'luxon';
import Layout from '../../constants/Layout';
import { UIText } from '../StyledText';
import Logger from '../../constants/Logger';

interface CalendarProps {
  onChange: (value: string) => void;
  value?: string;
  placeholder?: string;
  onActive?: () => void;
}
const CalendarInput = (props: CalendarProps) => {
  const { onChange, value, placeholder, onActive } = props;
  const [active, setActive] = useState(false);
  const hideDatePicker = () => {
    setActive(false);
  };
  const handleConfirm = (date: Date) => {
    onChange(DateTime.fromJSDate(date).toISO());
    hideDatePicker();
  };
  const displayDate = (date: string) => {
    Logger.debug(`Date is: ${DateTime.fromISO(date).toString()}`);
    return DateTime.fromISO(date).toFormat('dd/MM/yyyy');
  };

  const handlePress = () => {
    if (!active && onActive) {
      onActive();
    }
    setActive(!active);
  };

  return (
    <>
      <View style={[styles.container]}>
        <Pressable onPress={handlePress}>
          <View style={[styles.inputContainer]}>
            <View style={[styles.inputInnerContainer]}>
              <UIText style={[styles.placeholder, { color: Colors.black }]}>
                {value ? displayDate(value) : placeholder}
              </UIText>
            </View>

            <MaterialCommunityIcons
              name="calendar"
              size={Fonts.sizes.heading}
              color={Colors.grey}
              style={styles.calendarIcon}
            />
          </View>
        </Pressable>
        <View style={styles.errorMessageContainer} />
      </View>
      {active && (
        <View style={[styles.calendarContainer]}>
          <DateTimePickerModal
            isVisible={active}
            maximumDate={new Date()}
            mode="date"
            onConfirm={handleConfirm}
            onCancel={hideDatePicker}
          />
        </View>
      )}
    </>
  );
};
export default CalendarInput;

const styles = StyleSheet.create({
  container: {
    zIndex: 1,
  },
  inputContainer: {
    backgroundColor: Colors.white,
    height: 60,
    borderRadius: 28,
    justifyContent: 'center',
  },
  inputInnerContainer: {
    padding: Layout.backgroundMarginNormal.left,
    paddingRight: 0,
    height: '100%',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  calendarContainer: {
    position: 'absolute',
    left: Layout.backgroundMarginNormal.left,
    backgroundColor: Colors.white,
    zIndex: 30,
    elevation: 5,
    shadowColor: Colors.grey,
    shadowOpacity: 0.5,
    shadowOffset: { width: 2, height: 2 },
    shadowRadius: 2,
    width: '100%',
  },
  calendarContainerWeb: {
    position: 'absolute',
    top: 60,
    left: 0,
    backgroundColor: Colors.white,
    zIndex: 30,
    shadowColor: Colors.grey,
    shadowOpacity: 0.5,
    shadowOffset: { width: 2, height: 2 },
    shadowRadius: 2,
    width: '100%',
  },
  placeholder: {
    paddingRight: 12,
  },
  calendarIcon: {
    position: 'absolute',
    right: 18,
    top: 18,
  },
  errorMessageContainer: {
    height: 12,
  },
});
