export const Colors = {
  yellow: '#FEBC2C',
  darkYellow: '#FDB813',
  backgroundYellow: '#FDBC00',
  orange: '#F7931D',
  darkOrange: '#F5821F',
  red: '#ED193A',
  darkRed: '#D1213E',
  burgundy: '#BE1D37',
  lightBurgundy: '#8e0b29',
  darkBurgundy: '#840A26',
  tileBurgundy: '#6B0C21',
  marron: '#620b1f',
  black: '#060606',
  white: '#FFFFFF',
  grey: '#5C5C5C',
  green: '#00e46b',
  lightGrey: '#DBDBDB',
  tintColorLight: '#2F95DC',
  handleBar: '#7D4F59',
  whiteOpacity: 'rgba(255,255,255, 0.37)',
  blackOpacity: 'rgba(0,0,0, 0.2)',
  transparent: 'transparent',
  pink: '#cc00cc',
  defaultGrey: '#cbd2dc',
  darkGrey: '#6f6f6f',
  palePink: '#e7d1d6',
  yellowOpacity: 'rgba(252, 187, 44, 0.16)',
  whiteOpacitiy70: 'rgba(252, 255,255, 0.77)',
  whiteOpacitiy40: 'rgba(252, 255,255, 0.40)',
  blackOpacity70: 'rgba(0,0,0, 0.7)',
};

export default {
  light: {
    text: Colors.black,
    background: Colors.white,
    tint: Colors.tintColorLight,
    tabIconDefault: Colors.black,
    tabIconSelected: Colors.tintColorLight,
  },
  dark: {
    text: Colors.black,
    background: Colors.white,
    tint: Colors.tintColorLight,
    tabIconDefault: Colors.black,
    tabIconSelected: Colors.tintColorLight,
  },
};
