import React from 'react';
import { View } from 'react-native';
import { Colors } from '../constants/Colors';
import Footer from './Footer';
import Header from './Header';
import { CreateResponsiveStyle, useDeviceSize } from 'rn-responsive-styles';
import Clouds from './Clouds';

interface LayoutProps {
  children: any;
  menuBarTheme?: 'dark' | 'light';
  isLayoutAbsolute?: boolean;
}

const LayoutWeb = ({ children, menuBarTheme = 'dark', isLayoutAbsolute = false }: LayoutProps) => {
  const styles = useStyles();
  const deviceSize = useDeviceSize();

  return (
    <View style={[styles.root, menuBarTheme === 'light' ? styles.bgLight : null]}>
      <Header menuBarTheme={menuBarTheme} />
      {children}
      <Clouds />
      <Footer showReasons={deviceSize === 'extrasmall'} isLayoutAbsolute={isLayoutAbsolute} />
    </View>
  );
};
export default LayoutWeb;

const useStyles = CreateResponsiveStyle({
  root: {
    backgroundColor: Colors.yellow,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    overflow: 'hidden',
  },
  bgLight: {
    backgroundColor: Colors.white,
  },
});
