import { AnyAction, createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit';
import {
  Activity,
  ActivityParticipation,
  ActivityStats,
  Dictionary,
  Scope,
  UserActivity,
} from '../constants/Interfaces';
import Logger from '../constants/Logger';
import API from '../constants/API';
import { RejectedAction } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import _ from 'lodash';
import { purge } from './commonActions';
import { saveCacheImage } from '../constants/Utils';

interface ActivityState {
  activities: Activity[];
  pastActivities: Activity[];
  myActivities: UserActivity[];
  stats: Dictionary<ActivityStats>; // key is the activity id, value is ActivityStats;
  loading: boolean;
  checkInProgress: boolean;
  backgroundLoading: boolean;
  error: SerializedError | null;
}

const initialState: ActivityState = {
  activities: [],
  pastActivities: [],
  myActivities: [],
  stats: {},
  loading: false,
  checkInProgress: false,
  backgroundLoading: false,
  error: null,
};

/**
 * Get the public activities
 */
export const getActivities = createAsyncThunk('activity/getActivities', async (authState: object) => {
  Logger.info(`Reducer -> activity -> getActivities: Getting public activities`);
  const api: API = API.getInstance();
  try {
    const activities: Activity[] = await api.get(
      '/activity',
      undefined,
      undefined,
      authState ? Scope.API : Scope.ANONYMOUS
    );
    Logger.debug(`Reducer -> activity -> getActivities: Activities information received`);
    if (activities && activities.length > 0) {
      activities.map((activity) => saveCacheImage({ activityId: activity.activityId, uri: activity.imageUrl }));
    }
    return activities;
  } catch (e) {
    Logger.warn(`Reducer -> activity -> getActivities: ${e.message}`);
    throw e;
  }
});

/**
 * Get the past activities the user manages (only for event organisers or admins)
 */
export const getPastActivities = createAsyncThunk('activity/getPastActivities', async () => {
  Logger.info(`Reducer -> activity -> getPastActivities: Getting past activities under the user`);
  const api: API = API.getInstance();
  try {
    const activities: Activity[] = await api.get('/activity/completed');
    Logger.debug(`Reducer -> activity -> getPastActivities: Activities information received`);
    return activities;
  } catch (e) {
    Logger.warn(`Reducer -> activity -> getPastActivities: ${e.message}`);
    throw e;
  }
});

/**
 * Get user activities
 */
export const getUserActivities = createAsyncThunk('activity/getUserActivities', async () => {
  Logger.info(`Reducer -> activity -> getUserActivities: Getting user activities`);
  const api: API = API.getInstance();
  try {
    const activities: UserActivity[] = await api.get('/user/activity');
    Logger.debug(`Reducer -> activity -> getUserActivities: Activities information received`);
    return activities;
  } catch (e) {
    Logger.warn(`Reducer -> activity -> getUserActivities: ${e.message}`);
    throw e;
  }
});

/**
 * Post user (or whanau) activity
 */
export const postUserActivity = createAsyncThunk(
  'activity/postUserActivity',
  async (payload: {
    userId?: string;
    activity: Pick<UserActivity, 'favourite' | 'going' | 'activityId' | 'maraeId' | 'whanauType'>;
  }) => {
    Logger.info(`Reducer -> activity -> postUserActivity: Posting user activity ${JSON.stringify(payload.activity)}`);
    const api: API = API.getInstance();
    const postPayload: UserActivity = {
      activityId: payload.activity.activityId,
      favourite: payload.activity.favourite,
      going: payload.activity.going,
      maraeId: payload.activity.maraeId,
      whanauType: payload.activity.whanauType,
    };

    try {
      await api.post(
        `/user${payload.userId ? `/whanau/${payload.userId}` : ''}/activity/${payload.activity.activityId}`,
        postPayload
      );
      Logger.debug(`Reducer -> activity -> postUserActivity: Activity posted`);
      return payload.activity;
    } catch (e) {
      Logger.warn(`Reducer -> activity -> postUserActivity: ${e.message}`);
      throw e;
    }
  }
);

/**
 * Post activity participation (checkin)
 */
export const postUserActivityParticipation = createAsyncThunk(
  'activity/postUserActivityParticipation',
  async (payload: ActivityParticipation) => {
    const { userId, activityId, password } = payload;
    Logger.info(
      `Reducer -> activity -> postUserActivityParticipation: Checkin user ${userId} for activity ${activityId}`
    );
    const api: API = API.getInstance();

    try {
      const url = password
        ? `/activity/${activityId}/user/${userId}/participation?password=${password}`
        : `/activity/${activityId}/user/${userId}/participation`;
      await api.post(url, {});
      Logger.debug(`Reducer -> activity -> postUserActivityParticipation: Activity posted`);
      return {};
    } catch (e) {
      Logger.warn(`Reducer -> activity -> postUserActivityParticipation: ${e.message}`);
      throw e;
    }
  }
);

/**
 * Get stats for a particular activity
 */
export const getActivityStats = createAsyncThunk(
  'activity/getActivityStats',
  async (activityId: string | undefined) => {
    if (!activityId) {
      return null;
    }
    Logger.info(`Reducer -> activity -> getActivityStats: Getting stats for activity ${activityId}`);
    const api: API = API.getInstance();
    try {
      const activityStats: ActivityStats = await api.get(
        `/activity/${activityId}/stats`,
        undefined,
        undefined,
        Scope.ANONYMOUS
      );
      Logger.debug(`Reducer -> activity -> getActivityStats: Activity stats received: ${activityId}`);
      return { activityId, activityStats };
    } catch (e) {
      Logger.warn(`Reducer -> activity -> getActivityStats: ${e.message}`);
      throw e;
    }
  }
);

const isRejectedAction = (action: AnyAction): action is RejectedAction<any, any> => action.type.endsWith('/rejected');

const activitySlice = createSlice({
  name: 'activity',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getActivities.fulfilled, (state, action) => {
        const activities: Activity[] = action.payload;
        if (activities && activities.length > 0) {
          state.activities = activities;
        }
        state.backgroundLoading = false;
        state.error = null;
      })
      .addCase(getActivities.pending, (state) => {
        state.backgroundLoading = true;
        state.error = null;
      })
      .addCase(getPastActivities.fulfilled, (state, action) => {
        const activities: Activity[] = action.payload;
        if (activities && activities.length > 0) {
          state.pastActivities = activities;
        }
        state.backgroundLoading = false;
        state.error = null;
      })
      .addCase(getPastActivities.pending, (state) => {
        state.backgroundLoading = true;
        state.error = null;
      })
      .addCase(getUserActivities.fulfilled, (state, action) => {
        const activities: UserActivity[] = action.payload;
        if (activities && activities.length > 0) {
          state.myActivities = activities;
        }
        state.backgroundLoading = false;
        state.error = null;
      })
      .addCase(getUserActivities.pending, (state) => {
        state.backgroundLoading = true;
        state.error = null;
      })
      .addCase(getActivityStats.fulfilled, (state, action) => {
        if (action.payload) {
          const { activityId, activityStats } = action.payload;
          if (activityStats) {
            state.stats[activityId] = activityStats;
          }
        }
        state.backgroundLoading = false;
        state.error = null;
      })
      .addCase(getActivityStats.pending, (state) => {
        state.backgroundLoading = true;
        state.error = null;
      })
      .addCase(postUserActivity.fulfilled, (state, action) => {
        const activity: UserActivity = action.payload;
        if (activity) {
          const index = _.findIndex(state.myActivities, ['activityId', activity.activityId]);
          if (index > -1) {
            state.myActivities[index] = { ...state.myActivities[index], ...activity };
          } else {
            state.myActivities.push(activity);
          }
        }
        state.backgroundLoading = false;
        state.error = null;
      })
      .addCase(postUserActivity.pending, (state) => {
        state.backgroundLoading = true;
        state.error = null;
      })
      .addCase(postUserActivityParticipation.fulfilled, (state) => {
        state.checkInProgress = false;
      })
      .addCase(postUserActivityParticipation.pending, (state) => {
        state.checkInProgress = true;
        state.error = null;
      })
      .addCase(purge, (state) => {
        Logger.debug(`Reducer -> activity: Purge request received.`);
        state.activities = [];
        state.pastActivities = [];
        state.myActivities = [];
        state.stats = {};
        state.loading = false;
        state.backgroundLoading = false;
        state.error = null;
      })
      .addMatcher(isRejectedAction, (state, action) => {
        state.loading = false;
        state.backgroundLoading = false;
        state.checkInProgress = false;
        state.error = action.error;
      });
  },
});

export default activitySlice.reducer;
