import { Dimensions, Platform } from 'react-native';

const { width } = Dimensions.get('window');
const { height } = Dimensions.get('window');

const DEFAULT_SCALE_CONSTANT = 1;
const SMALL_SCREEN_SCALE_CONSTANT = 0.85;
const SMALL_DEVICE_THRESHOLD = 375;

const scaleConstant = width < SMALL_DEVICE_THRESHOLD ? SMALL_SCREEN_SCALE_CONSTANT : DEFAULT_SCALE_CONSTANT;

export default {
  window: {
    width,
    height,
  },
  backgroundMarginNormal: {
    left: 20 * scaleConstant,
    right: 20 * scaleConstant,
    top: 26.67 * scaleConstant,
    bottom: 26.67 * scaleConstant,
  },
  backgroundMarginNarrow: {
    left: 13.33 * scaleConstant,
    right: 13.33 * scaleConstant,
    top: 13.33 * scaleConstant,
    bottom: 13.33 * scaleConstant,
  },
  isSmallDevice: width < 376 && height < 668,
  isLongDevice: height > 740,
  isWeb: Platform.OS === 'web',
  isPhone: height < 700 || width < 500,
  bigScreen: width > 1500,
};
