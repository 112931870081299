import React, { useState } from 'react';
import { View, StyleSheet, Animated, Pressable, Image } from 'react-native';
import { useSelector } from 'react-redux';
import { Colors } from '../constants/Colors';
import SearchBar from './SearchBar';
import { DrawerParamList, User } from '../constants/Interfaces';
import { getGenderPlaceholder } from '../constants/Utils';

export interface HeaderBarProps {
  headerBarHeight?: Animated.AnimatedInterpolation;
  imagePosition?: Animated.AnimatedInterpolation;
  backgroundColorHeader?: Animated.AnimatedInterpolation;
  ellipseHeight?: Animated.AnimatedInterpolation;
  navigation: DrawerParamList;
  showIcon?: Boolean;
  opacityProfile?: Animated.AnimatedInterpolation;
  isRefreshHolder?: boolean;
  smallProfile?: boolean;
}

const HeaderBar = (props: HeaderBarProps) => {
  const {
    headerBarHeight,
    imagePosition,
    backgroundColorHeader,
    ellipseHeight,
    navigation,
    showIcon = true,
    opacityProfile = 1,
    isRefreshHolder = false,
    smallProfile = false,
  } = props;
  const user: User = useSelector((state: any) => state.user.user);
  const [searchActive, setSearchActive] = useState(false);

  return (
    <Animated.View
      style={[
        styles.root,
        isRefreshHolder
          ? { height: headerBarHeight, backgroundColor: backgroundColorHeader || Colors.transparent }
          : null,
      ]}
    >
      <>
        <Animated.View
          style={[
            styles.container,
            {
              backgroundColor: backgroundColorHeader || Colors.transparent,
            },
          ]}
        >
          <Animated.View
            style={[
              styles.barContainer,
              {
                height: headerBarHeight || 80,
              },
            ]}
          >
            {user && showIcon && !searchActive ? (
              <Animated.View
                style={[
                  styles.imageEllipse,
                  {
                    borderColor: Colors.darkBurgundy,
                    opacity: opacityProfile || 1,
                  },
                  smallProfile
                    ? {
                        height: 40,
                        width: 40,
                        borderRadius: 40,
                        transform: [{ translateX: 30 }],
                      }
                    : {
                        height: ellipseHeight || 60,
                        width: ellipseHeight || 60,
                        borderRadius: ellipseHeight || 60,
                        transform: [{ translateX: imagePosition || 10 }],
                      },
                ]}
              >
                <Animated.Image
                  style={[
                    styles.profilePic,
                    {
                      height: '90%',
                      width: '90%',
                      borderRadius: 60 || 55,
                      opacity: opacityProfile || 1,
                    },
                  ]}
                  source={!user || user?.imageUrl ? { uri: user?.imageUrl } : getGenderPlaceholder(user?.gender)}
                />
              </Animated.View>
            ) : (
              <View style={styles.placeholder} />
            )}
            {!searchActive ? (
              <View style={styles.iconsContainer}>
                <Pressable style={styles.pressableBg} onPress={() => setSearchActive(!searchActive)}>
                  <Image source={require('../../assets/images/searchIcon.png')} style={styles.icon} />
                </Pressable>
                {navigation && navigation.openDrawer && (
                  <Pressable style={styles.pressableBg} onPress={() => navigation.openDrawer()}>
                    <Image source={require('../../assets/images/hamburgerIcon.png')} style={styles.icon} />
                  </Pressable>
                )}
              </View>
            ) : (
              <SearchBar close={() => setSearchActive(false)} />
            )}
          </Animated.View>
        </Animated.View>
      </>
    </Animated.View>
  );
};

export default HeaderBar;

const styles = StyleSheet.create({
  root: {
    width: '100%',
    elevation: 5,
    zIndex: 100,
  },
  container: {
    paddingTop: 20,
  },
  barContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  profilePic: {
    zIndex: 100,
    marginVertical: 20,
  },
  iconsContainer: {
    flexDirection: 'row',
    height: 50,
    width: 80,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginRight: 30,
    marginTop: 10,
  },
  pressableBg: {
    width: 30,
    height: 30,
    backgroundColor: Colors.whiteOpacity,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 15,
  },
  icon: {
    width: 20,
    height: 20,
    resizeMode: 'contain',
  },
  imageEllipse: {
    borderWidth: 2,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 15,
  },
  placeholder: {
    flexGrow: 1,
  },
  searchBarContainer: {
    backgroundColor: Colors.white,
  },
});
