import React from 'react';
import { Heading, UIText } from '../../components/StyledText';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import { StyleSheet, Image, Dimensions, View } from 'react-native';
import { Colors } from '../../constants/Colors';
import { commonStyles } from '../CommonStyles';
import Fonts from '../../constants/Fonts';
import VideoContainer from '../About/Fragments/VideoContainer';
import { responsiveFontGenerator } from './responsiveSytlingGenerators';

const renderOptionsRed = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <UIText style={[commonStyles.contentText, commonStyles.subtitleLabel, styles.whiteText]}>{children}</UIText>
    ),
    renderText: (text) => text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),

    [BLOCKS.EMBEDDED_ASSET]: (node) => (
      <VideoContainer
        imageUrl={`https://${node.data.target.fields.file.url}`}
        width={node.data.target.fields.file.details.image.width}
        height={node.data.target.fields.file.details.image.height}
        videoUrl={node.data.target.fields.title}
      />
    ),
  },
};

const renderOptionsYellow = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <UIText style={styles.policyText}>{children}</UIText>,
    [BLOCKS.HEADING_1]: (node, children) => <Heading style={styles.policyH1}>{children}</Heading>,
    [BLOCKS.HEADING_2]: (node, children) => <Heading style={styles.policyH2}>{children}</Heading>,
    [BLOCKS.HEADING_4]: (node, children) => <Heading style={styles.policyH4}>{children}</Heading>,
    [BLOCKS.HEADING_6]: (node, children) => <UIText style={styles.policyH6}>{children}</UIText>,

    [BLOCKS.EMBEDDED_ASSET]: (node) => (
      // render the EMBEDDED_ASSET as you need
      <Image
        source={{ uri: `https://${node.data.target.fields.file.url}` }}
        style={[
          styles.image,
          {
            width: node.data.target.fields.file.details.image.width,
            height: node.data.target.fields.file.details.image.height,
          },
        ]}
      />
    ),
  },
};

export function RichTextResponseRed({ richTextResponse }): any {
  return documentToReactComponents(richTextResponse, renderOptionsRed);
}

export function RichTextResponseYellow({ richTextResponse }): any {
  return documentToReactComponents(richTextResponse, renderOptionsYellow);
}

export function RichTextResponseHeroImageVideo({ richTextResponse, deviceSize }): any {
  const h1Style = responsiveFontGenerator({
    deviceSize,
    inheritedStyle: [styles.h1, styles.absoluteLayout, styles.whiteText],
    ownStyles: {
      XL: [{ fontSize: 100 }],
      L: [{ fontSize: 100 }],
      M: [{ fontSize: 60 }],
      S: [{ fontSize: 50 }],
      XS: [{ fontSize: 30 }],
    },
  });

  const h3Style = responsiveFontGenerator({
    deviceSize,
    inheritedStyle: [styles.h3, styles.absoluteLayout, styles.whiteText],
    ownStyles: {
      XL: [{ fontSize: 50, marginTop: 200 }],
      L: [{ fontSize: 50, marginTop: 200 }],
      M: [{ fontSize: 30, marginTop: 200 }],
      S: [{ fontSize: 25, marginTop: 150 }],
      XS: [{ fontSize: 20, marginTop: 100 }],
    },
  });

  const heroImageStyle = responsiveFontGenerator({
    deviceSize,
    inheritedStyle: [styles.heroImage],
    ownStyles: {
      XL: [{ height: '1080px' }],
      L: [{ height: '1080px' }],
      M: [{ height: '767px' }],
      S: [{ height: '1180px' }],
      XS: [{ height: '667.8px' }],
    },
  });

  const renderOptionsHeroImageVideo = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <UIText>{children}</UIText>,
      [BLOCKS.HEADING_1]: (node, children) => <Heading style={h1Style}>{children}</Heading>,
      [BLOCKS.HEADING_2]: (node, children) => (
        <Heading style={[styles.h2, styles.absoluteLayout, styles.whiteText]}>{children}</Heading>
      ),
      [BLOCKS.HEADING_3]: (node, children) => <Heading style={h3Style}>{children}</Heading>,
      [BLOCKS.HEADING_6]: (node, children) => <UIText style={styles.center}>{children}</UIText>,

      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const urlRegCheck =
          '(https?://(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|www.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|https?://(?:www.|(?!www))[a-zA-Z0-9]+.[^s]{2,}|www.[a-zA-Z0-9]+.[^s]{2,})';
        if (node.data.target.fields.title.match(urlRegCheck)) {
          return (
            <VideoContainer
              imageUrl={`https://${node.data.target.fields.file.url}`}
              width={node.data.target.fields.file.details.image.width}
              height={node.data.target.fields.file.details.image.height}
              videoUrl={node.data.target.fields.title}
            />
          );
        }

        if (node.data.target.fields.title === 'hero-image') {
          return <Image source={{ uri: `https://${node.data.target.fields.file.url}` }} style={heroImageStyle} />;
        }

        return (
          <Image
            source={{ uri: `https://${node.data.target.fields.file.url}` }}
            style={[
              styles.image,
              {
                width: node.data.target.fields.file.details.image.width,
                height: node.data.target.fields.file.details.image.height,
              },
            ]}
          />
        );
      },
    },
  };

  return documentToReactComponents(richTextResponse, renderOptionsHeroImageVideo);
}

export function RichTextResponseKaupapa({ richTextResponse, deviceSize }): any {
  const paragraphStyle = responsiveFontGenerator({
    deviceSize,
    inheritedStyle: [styles.center],
    ownStyles: {
      XS: [{ fontSize: 16, width: Dimensions.get('window').width * 0.8, alignSelf: 'center' }],
    },
  });
  const renderOptionsKaupapa = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <UIText style={paragraphStyle}>{children}</UIText>,
      [BLOCKS.HEADING_1]: (node, children) => <Heading style={styles.h1}>{children}</Heading>,
      [BLOCKS.HEADING_2]: (node, children) => <Heading style={styles.h2}>{children}</Heading>,
      [BLOCKS.HEADING_3]: (node, children) => <Heading style={styles.h3}>{children}</Heading>,
      [BLOCKS.HEADING_6]: (node, children) => <UIText style={styles.center}>{children}</UIText>,

      [BLOCKS.EMBEDDED_ASSET]: (node) => (
        <Image
          source={{ uri: `https://${node.data.target.fields.file.url}` }}
          style={[
            styles.image,
            {
              width: '368px',
              height: '275px',
            },
            !(deviceSize === 'extrasmall') ? { marginTop: 40 } : {},
            deviceSize === 'extrasmall' ? { transform: [{ scale: 0.5 }] } : {},
          ]}
        />
      ),
    },
  };
  return documentToReactComponents(richTextResponse, renderOptionsKaupapa);
}

export function RichTextResponseHeroImageWithButton({ richTextResponse, deviceSize }): any {
  const h1Style = responsiveFontGenerator({
    deviceSize,
    inheritedStyle: [
      styles.h1,
      styles.absoluteLayout,
      styles.whiteText,
      styles.alighLeftText,
      styles.moveToTop,
      styles.bodyPadding,
    ],
    ownStyles: {
      XL: [{ fontSize: 60, lineHeight: 74, width: '42.5%' }],
      L: [{ fontSize: 56, lineHeight: 70, width: '50%' }],
      M: [{ fontSize: 50, lineHeight: 60, width: '60%' }],
      S: [{ fontSize: 40, lineHeight: 50, width: '70%' }],
      XS: [{ fontSize: 26, lineHeight: 32, width: '80%', paddingLeft: '10%' }],
    },
  });

  const renderOptionsHeroImageWithButton = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <UIText>{children}</UIText>,
      [BLOCKS.HEADING_1]: (node, children) => (
        <Heading style={[h1Style, { paddingBottom: 0, marginBottom: 0 }]}>{children}</Heading>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <Heading style={[styles.h2, styles.absoluteLayout, styles.whiteText]}>{children}</Heading>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <Heading style={[styles.h3, styles.absoluteLayout, styles.whiteText]}>{children}</Heading>
      ),
      [BLOCKS.HEADING_6]: (node, children) => <UIText style={styles.center}>{children}</UIText>,

      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        if (node.data.target.fields.title === 'hero-image') {
          return (
            <Image
              source={{ uri: `https://${node.data.target.fields.file.url}` }}
              style={[styles.heroImage, { height: '1080px' }]}
            />
          );
        }

        return (
          <Image
            source={{ uri: `https://${node.data.target.fields.file.url}` }}
            style={[
              styles.image,
              {
                width: node.data.target.fields.file.details.image.width,
                height: node.data.target.fields.file.details.image.height,
              },
            ]}
          />
        );
      },
    },
  };
  return documentToReactComponents(richTextResponse, renderOptionsHeroImageWithButton);
}

export function RichTextResponseQuote({ richTextResponse, deviceSize }): any {
  const paragraphStyle = responsiveFontGenerator({
    deviceSize,
    inheritedStyle: [{ textAlign: 'left' }],
    ownStyles: {
      XL: [{ width: '47%', lineHeight: 34, fontSize: 20 }],
      L: [{ width: '47%', lineHeight: 34, fontSize: 20 }],
      M: [{ width: '47%', lineHeight: 34, fontSize: 20 }],
      S: [{ width: '100%', lineHeight: 28, fontSize: 16, textAlign: 'left', marginBottom: 55 }],
      XS: [{ width: '100%', lineHeight: 28, fontSize: 16, textAlign: 'left', marginBottom: 55 }],
    },
  });

  const quoteContainerStyle = responsiveFontGenerator({
    deviceSize,
    inheritedStyle: [{ borderWidth: 5, borderColor: Colors.black }],
    ownStyles: {
      XL: [{ width: '35%', position: 'absolute', paddingHorizontal: 70, paddingVertical: 35, right: 150, bottom: 205 }],
      L: [{ width: '38%', position: 'absolute', paddingHorizontal: 70, paddingVertical: 35, right: 100, bottom: 235 }],
      M: [{ width: '40%', position: 'absolute', paddingHorizontal: 70, paddingVertical: 35, right: 60, bottom: 255 }],
      S: [{ width: '100%', paddingHorizontal: 25, paddingLeft: 40, paddingVertical: 35 }],
      XS: [{ width: '100%', paddingHorizontal: 25, paddingLeft: 40, paddingVertical: 35 }],
    },
  });
  const renderOptionsQuote = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <UIText style={paragraphStyle}>{children}</UIText>,
      [BLOCKS.HEADING_1]: (node, children) => (
        <Heading style={[styles.h1, { fontSize: 36, marginBottom: 56 }]}>{children}</Heading>
      ),
      [BLOCKS.HEADING_2]: (node, children) => <Heading style={styles.h2}>{children}</Heading>,
      [BLOCKS.HEADING_4]: (node, children) => (
        <View style={quoteContainerStyle}>
          <Image
            source={require('../../../assets/images/icon-quote.png')}
            style={
              !(deviceSize === 'small' || deviceSize === 'extrasmall')
                ? {
                    width: 75,
                    height: 108,
                    position: 'absolute',
                    left: -35,
                  }
                : {
                    width: 75,
                    height: 108,
                    position: 'absolute',
                    left: -45,
                    top: 0,
                    transform: [{ scale: 0.5 }],
                  }
            }
          />
          <UIText
            style={{
              position: 'absolute',
              backgroundColor: Colors.black,
              color: Colors.white,
              bottom: -48,
              right: 4,
              paddingHorizontal: 20,
              paddingVertical: 14,
            }}
          >
            Ani Oriwia-Adds
          </UIText>
          <Heading
            style={
              !(deviceSize === 'small' || deviceSize === 'extrasmall')
                ? {
                    fontSize: 22,
                    fontStyle: 'italic',
                    lineHeight: 30,
                    textAlign: 'left',
                    fontFamily: Fonts.family.regular,
                    fontWeight: '700',
                  }
                : {
                    fontSize: 16,
                    fontStyle: 'italic',
                    lineHeight: 24,
                    textAlign: 'left',
                    fontFamily: Fonts.family.regular,
                    fontWeight: '500',
                  }
            }
          >
            {children}
          </Heading>
        </View>
      ),
      [BLOCKS.HEADING_6]: (node, children) => <UIText style={styles.center}>{children}</UIText>,

      [BLOCKS.EMBEDDED_ASSET]: (node) => (
        <Image
          source={{ uri: `https://${node.data.target.fields.file.url}` }}
          style={[
            styles.image,
            {
              width: node.data.target.fields.file.details.image.width,
              height: node.data.target.fields.file.details.image.height,
            },
          ]}
        />
      ),
    },
  };
  return documentToReactComponents(richTextResponse, renderOptionsQuote);
}

const styles = StyleSheet.create({
  image: {
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  heroImage: {
    width: '100%',
    height: '100%',
    position: 'relative',
    zIndex: -10,
    resizeMode: 'cover',
  },
  whiteText: {
    color: Colors.white,
  },
  blackText: {
    fontFamily: Fonts.family.regular,
    marginBottom: 15,
    alignSelf: 'center',
  },
  h1: {
    fontSize: Fonts.sizes.bigHeading,
    alignSelf: 'center',
    textAlign: 'center',
    marginVertical: 15,
  },
  h2: {
    alignSelf: 'center',
    fontFamily: Fonts.family.bold,
    fontSize: 20,
    textAlign: 'center',
    marginTop: 15,
    marginBottom: 25,
  },
  h3: {
    fontSize: 18,
    fontFamily: Fonts.family.regular,
  },
  center: {
    textAlign: 'center',
    fontFamily: Fonts.family.regular,
  },
  absoluteLayout: {
    position: 'absolute',
    zIndex: -1,
  },
  moveToTop: {
    zIndex: 999,
  },
  alighLeftText: {
    alignSelf: 'flex-start',
    textAlign: 'left',
  },
  bodyPadding: {
    paddingLeft: '14.5%',
    paddingVertical: 60,
  },
  policyH1: {
    color: Colors.black,
    fontFamily: Fonts.family.bold,
    fontSize: 50,
    lineHeight: 60,
    alignSelf: 'center',
    paddingVertical: 20,
  },
  policyH2: {
    fontFamily: Fonts.family.bold,
    fontSize: 22,
    lineHeight: 34,
    paddingVertical: 10,
  },
  policyH4: {
    fontFamily: Fonts.family.bold,
    fontSize: 22,
    lineHeight: 34,
    paddingVertical: 10,
  },
  policyH6: {
    fontSize: 22,
    lineHeight: 34,
    paddingVertical: 10,
  },
  policyText: {
    fontSize: 22,
    lineHeight: 34,
  },
});
