import { useFocusEffect } from '@react-navigation/native';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AuthState, User } from '../constants/Interfaces';
import { isAccessTokenValid } from '../reducers/authentication.slice';
import { getUser } from '../reducers/user.slice';

export default function useLogoutUser() {
  // token
  const authState: AuthState = useSelector((state: any) => state.authentication.authState);
  // if user exists, considered logged in
  const user: User = useSelector((state: any) => state.user.user);
  const dispatch = useDispatch();

  useFocusEffect(
    useCallback(() => {
      const isAuthenticated = isAccessTokenValid(authState);
      const isLoggedOut = !authState;
      if (user && !isAuthenticated && !isLoggedOut) {
        console.debug('useLogoutUser: User token has expired, trying token refresh.');
        // fire an api call. If the token is unusable this will trigger a logout within refreshToken
        dispatch(getUser());
      }
    }, [])
  );
}
