import React from 'react';
import { View, Image, StyleSheet, Pressable, ImageBackground } from 'react-native';
import { UIText } from '../../../components/StyledText';
import { Colors } from '../../../constants/Colors';
import Fonts from '../../../constants/Fonts';
import Logger from '../../../constants/Logger';
import { convertToNZ, getCacheImage } from '../../../constants/Utils';

const FavouriteActivityFragment = ({ activity, onPress }) => {
  const [activityImage, setActivityImage] = React.useState<string | undefined>(activity.imageUrl);

  const cacheImage = async () => {
    try {
      const uri = await getCacheImage({ activityId: activity.activityId, uri: activity.imageUrl });
      setActivityImage(uri);
    } catch (e) {
      Logger.debug(e);
    }
  };

  React.useEffect(() => {
    cacheImage();
  }, [activity]);
  return (
    <Pressable style={styles.favContainer} onPress={onPress}>
      {activity && (
        <ImageBackground style={styles.favImage} source={{ uri: activityImage }}>
          <View style={styles.favIconContainer}>
            <Image style={styles.favIcon} source={require('../../../../assets/images/favIcon_black.png')} />
          </View>
        </ImageBackground>
      )}

      <View style={styles.favDetailsContainer}>
        <UIText style={styles.favLabel}>
          {convertToNZ({ date: activity.startDate!, format: 'cccc dd MMMM, yyyy,h:mma' })}
        </UIText>
        <UIText style={[styles.favLabel, styles.title]}>{activity.activityName}</UIText>
      </View>
    </Pressable>
  );
};

export default FavouriteActivityFragment;

const styles = StyleSheet.create({
  favContainer: {
    flexDirection: 'row',
    marginVertical: 15,
  },
  favImage: {
    height: 80,
    width: 80,
    marginRight: 15,
  },
  favIconContainer: {
    width: 25,
    height: 25,
    borderRadius: 20,
    backgroundColor: Colors.yellow,
    justifyContent: 'center',
    alignItems: 'center',
    top: -10,
    left: 65,
  },
  favIcon: {
    height: '50%',
    width: '50%',
  },
  favDetailsContainer: {
    justifyContent: 'center',
    flex: 1,
  },
  favLabel: {
    color: Colors.white,
    fontSize: Fonts.sizes.medium,
  },
  title: {
    fontSize: Fonts.sizes.smallHeading,
    fontFamily: Fonts.family.bold,
  },
});
