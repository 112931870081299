import React, { useEffect, useRef, useState } from 'react';
import { View, StyleSheet, FlatList } from 'react-native';
import { ActionModal } from '../../../components/ActionModal';
import { Paragraph, UIText } from '../../../components/StyledText';
import { Colors } from '../../../constants/Colors';
import Fonts from '../../../constants/Fonts';
import i18n from '../../../translations';
import CallOrganiserFragment from './CallOrganiserFragment';
import EventCardFragment from './EventCardFragment';
import InsightsFragment from './InsightsFragment';
import ReviewFragment from './ReviewFragment';
import ReviewCardFragment from './ReviewCardFragment';
import { SplitButton } from '../../../components/buttons/SplitButton';
import { Activity, Review, User } from '../../../constants/Interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { getPastActivities } from '../../../reducers/activity.slice';
import Logger from '../../../constants/Logger';
import Layout from '../../../constants/Layout';

const AdminDashboardFragment = ({ navigation }) => {
  const [callModal, setCallModal] = useState(false);
  const [reviewModal, setReviewModal] = useState(false);
  const [insightsModal, setInsightsModal] = useState(false);
  const [currentReview, setCurrentReview] = useState<Review>();
  const [currentActivity, setCurrentActivity] = useState<Activity>();
  const reviews: Review[] = useSelector((state: any) => state.review.reviews.slice(0, 5));
  const user: User = useSelector((state: any) => state.user.user);
  const activities: Activity[] = useSelector((state: any) => state.activity.activities);
  const pastActivities: Activity[] = useSelector((state: any) => state.activity.pastActivities);
  const dispatch = useDispatch();

  const managedActivities: Activity[] = [...activities, ...pastActivities].filter(
    (item) => item.kaiwhakahaereId === user.userId
  );
  const carouselRef = useRef<FlatList>(null);

  const viewabilityConfig = useRef({
    itemVisiblePercentThreshold: 10,
  });
  const handleCloseModal = () => {
    setCallModal(false);
    setReviewModal(false);
    setInsightsModal(false);
  };
  const handleInsights = (activity: Activity) => {
    setCurrentActivity(activity);
    setInsightsModal(true);
  };
  const handleCall = (activity: Activity) => {
    setCurrentActivity(activity);
    setCallModal(true);
  };
  const handleOpenReview = (review: Review) => {
    setCurrentReview(review);
    setReviewModal(true);
  };
  const handleOpenActivity = (activity: Activity) => {
    setCurrentActivity(activity);
    Logger.debug(`Screen -> ActivitiesScreen -> handleOpenActivity: Navigating to activity ${activity.activityId}`);
    navigation.navigate('DashboardStack', {
      screen: 'ActivityDetails',
      params: { activityId: activity.activityId },
    });
  };

  useEffect(() => {
    dispatch(getPastActivities());
  }, []);

  return (
    <>
      <View style={styles.root}>
        <UIText style={styles.title}>{i18n.t('components.Kaiwhakahaere.MaraeFitDashboard')}</UIText>
        <UIText style={styles.subtitle}>{i18n.t('components.Kaiwhakahaere.kaiwhakahaere')}</UIText>
        <Paragraph style={styles.sectionTitle}>{i18n.t('components.Kaiwhakahaere.organisersBy')}</Paragraph>
        <FlatList
          decelerationRate="fast"
          ref={carouselRef}
          data={managedActivities}
          keyExtractor={(item) => item.activityId}
          ItemSeparatorComponent={() => <View style={{ width: 10 }} />}
          viewabilityConfig={viewabilityConfig.current}
          renderItem={({ item }) => (
            <EventCardFragment
              subTitle={item.activityName}
              title={`${item.organiser.firstName} ${item.organiser.lastName}`}
              imageUrl={item?.imageUrl}
              onPress={() => handleOpenActivity(item)}
              activityId={item?.activityId}
              isInList
              splitButton={
                <SplitButton
                  labelLeft={i18n.t('components.Kaiwhakahaere.viewInsights')}
                  labelRight={i18n.t('components.Kaiwhakahaere.call')}
                  onPressLeft={() => handleInsights(item)}
                  onPressRight={() => handleCall(item)}
                  backgroundColorLeft={Colors.darkBurgundy}
                  backgroundColorRight={Colors.yellow}
                  labelColorLeft={Colors.yellow}
                  labelColorRight={Colors.black}
                  borderColor={Colors.yellow}
                />
              }
            />
          )}
          horizontal
          showsHorizontalScrollIndicator={false}
        />

        <FlatList
          data={reviews}
          renderItem={({ item }) => <ReviewCardFragment review={item} onPress={handleOpenReview} />}
        />
      </View>
      <ActionModal visible={callModal} onModalClose={handleCloseModal} height={400}>
        {currentActivity ? <CallOrganiserFragment organiser={currentActivity.organiser!} /> : null}
      </ActionModal>
      <ActionModal visible={reviewModal} onModalClose={handleCloseModal} height={400}>
        {currentReview ? <ReviewFragment content={currentReview} /> : null}
      </ActionModal>
      <ActionModal visible={insightsModal} onModalClose={handleCloseModal} height={650}>
        {currentActivity ? <InsightsFragment activity={currentActivity} /> : null}
      </ActionModal>
    </>
  );
};

export default AdminDashboardFragment;

const styles = StyleSheet.create({
  root: {
    minHeight: Layout.window.height + 200,
    paddingHorizontal: Layout.backgroundMarginNarrow.left,
    paddingBottom: 150,
  },
  title: {
    color: Colors.white,
    fontSize: Fonts.sizes.bigHeading,
    fontFamily: Fonts.family.bold,
  },
  subtitle: {
    color: Colors.white,
    fontSize: Fonts.sizes.heading,
  },
  sectionTitle: {
    color: Colors.yellow,
    fontFamily: Fonts.family.bold,
    marginVertical: 10,
  },
});
