import * as React from 'react';
import { StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../store/configureStore';
import { View } from '../../components/Themed';
import { UIText } from '../../components/StyledText';
import { Activity, AuthState, Role, User, UserActivity } from '../../constants/Interfaces';
import UserConfirmationScreen from './UserConfirmationScreen';
import AdminDashboardFragment from './fragments/AdminDashboardFragment';
import { OrganiserDashboardFragment } from './fragments/OrganiserDashboardFragment';
import { UserDashboardFragment } from './fragments/UserDashboardFragment';
import { getReviews } from '../../reducers/review.slice';
import { ActivitiesScreen } from '../activities/ActivitiesScreen';
import { getActivities, getActivityStats, getPastActivities } from '../../reducers/activity.slice';
import { getUser, getUserActivitiesCompleted } from '../../reducers/user.slice';
import i18n from '../../translations';
import RefreshHolder from '../../components/RefreshHolder';
import _ from 'lodash';
import Layout from '../../constants/Layout';
import useLogoutUser from '../../hooks/useLogoutUser';

export default function HomeScreen({ navigation }) {
  const user: User = useSelector((state: any) => state.user.user);
  const authState: AuthState = useSelector((state: any) => state.authentication.authState);
  const needsConfirmation: boolean = useSelector((state: any) => state.user.needsConfirmation);
  const isConfirmed: boolean = useSelector((state: any) => state.user.isConfirmed);
  const myActivities: UserActivity[] = useSelector((state: any) => state.activity.myActivities).filter(
    (item: UserActivity) => item.userId === user?.userId
  );
  const [managedActivities, setManagedActivities] = React.useState<Activity[]>();
  const goingActivities = myActivities.filter((item) => item.going && !item.checkedIn);

  const dispatch = useAppDispatch();

  useLogoutUser();

  const populateManagedActivities = (activities: Activity[]) => {
    if (!user || user.role !== Role.ORGANISER) {
      return;
    }
    const newManagedActivities = _.filter(
      activities,
      (activity: Activity) => activity.organiser?.userId === user.userId
    );
    setManagedActivities(newManagedActivities);
    _.each(newManagedActivities, (activity: Activity) => {
      dispatch(getActivityStats(activity.activityId));
    });
  };

  React.useEffect(() => {
    // NOTE: This only triggers when the screen is loaded into the navigator, and not when the screen is navigated to.
    dispatch(getUserActivitiesCompleted())
      .unwrap()
      .then((activities: Activity[]) => {
        populateManagedActivities(activities);
      });
    dispatch(getReviews());
    if (user && user.role === Role.ORGANISER) dispatch(getPastActivities());
  }, []);

  const handleRefresh = async () => {
    await dispatch(getUser());
    await dispatch(getActivities(authState))
      .unwrap()
      .then((activities: Activity[]) => {
        populateManagedActivities(activities);
      });
    await dispatch(getReviews());
    if (user && user.role === Role.ORGANISER) dispatch(getPastActivities());
  };

  if (needsConfirmation && !isConfirmed) {
    return <UserConfirmationScreen navigation={navigation} />;
  }

  const renderFragment = () => {
    if (user && user.role === Role.USER_ADMIN) {
      return (
        <RefreshHolder navigation={navigation} refresh={handleRefresh}>
          <AdminDashboardFragment navigation={navigation} />
        </RefreshHolder>
      );
    }

    if (user && user.role === Role.ORGANISER) {
      return (
        <RefreshHolder
          navigation={navigation}
          refresh={handleRefresh}
          footerColor="red"
          backgroundColorContent="white"
          footerOff
        >
          <OrganiserDashboardFragment navigation={navigation} managedActivities={managedActivities} />
        </RefreshHolder>
      );
    }

    if (user && user.role === Role.USER) {
      return (
        <RefreshHolder navigation={navigation} refresh={handleRefresh}>
          <UserDashboardFragment navigation={navigation} />
        </RefreshHolder>
      );
    }

    return (
      <View transparent style={styles.container}>
        <UIText>{i18n.t('pages.HomeScreen.error')}</UIText>
      </View>
    );
  };

  return user && user.role === Role.USER && goingActivities.length < 1 ? (
    <ActivitiesScreen navigation={navigation} />
  ) : (
    <>{renderFragment()}</>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  loadingContainer: {
    position: 'absolute',
    marginVertical: Layout.window.height / 2,
  },
});
