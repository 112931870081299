import { AnyAction, createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit';
import { Review } from '../constants/Interfaces';
import Logger from '../constants/Logger';
import API from '../constants/API';
import { RejectedAction } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import { purge } from './commonActions';

interface ReviewState {
  reviews: Review[];
  loading: boolean;
  backgroundLoading: boolean;
  error: SerializedError | null;
}

const initialState: ReviewState = {
  reviews: [],
  loading: false,
  backgroundLoading: false,
  error: null,
};

export const getReviews = createAsyncThunk('review/getReviews', async () => {
  Logger.info(`Reducer -> Review -> getReviews: Getting reviews`);
  const api: API = API.getInstance();
  try {
    const reviews: Review[] = await api.get('/review');
    Logger.debug(`Reducer -> Review -> getReviews: Reviews information received: ${JSON.stringify(reviews)}`);
    return reviews;
  } catch (e) {
    Logger.warn(`Reducer -> Review -> getReviews: ${e.message}`);
    throw e;
  }
});

export const postUserActivityReview = createAsyncThunk('review/postUserActivityReview', async (payload: Review) => {
  const { activityId, review, score } = payload;
  Logger.info(`Reducer -> review -> postUserActivityReview: for activity ${activityId}`);
  const api: API = API.getInstance();
  try {
    await api.post(`/user/activity/${activityId}/review`, { review, score });
    Logger.debug(`Reducer -> review -> postUserActivityReview: Activity posted`);
    return payload;
  } catch (e) {
    Logger.warn(`Reducer -> review -> postUserActivityReview: ${e.message}`);
    throw e;
  }
});

const isRejectedAction = (action: AnyAction): action is RejectedAction<any, any> => action.type.endsWith('/rejected');

const reviewSlice = createSlice({
  name: 'review',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getReviews.fulfilled, (state, action) => {
        state.reviews = action.payload;
        state.backgroundLoading = false;
        state.error = null;
      })
      .addCase(getReviews.pending, (state) => {
        state.backgroundLoading = true;
        state.error = null;
      })
      .addCase(postUserActivityReview.fulfilled, (state, action) => {
        const review = action.payload;
        state.reviews.push(review);
        state.backgroundLoading = false;
        state.error = null;
      })
      .addCase(postUserActivityReview.pending, (state) => {
        state.backgroundLoading = true;
        state.error = null;
      })
      .addCase(purge, (state) => {
        Logger.debug(`Reducer -> Review: Purge request received.`);
        state.reviews = [];
        state.loading = false;
        state.backgroundLoading = false;
        state.error = null;
      })
      .addMatcher(isRejectedAction, (state, action) => {
        state.loading = false;
        state.backgroundLoading = false;
        state.error = action.error;
      });
  },
});

export default reviewSlice.reducer;
