import React from 'react';
import { View, StyleSheet, Pressable } from 'react-native';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import { Heading, UIText } from '../../../components/StyledText';
import { Location } from '../../../constants/Interfaces';
import i18n from '../../../translations';
import { createOpenLink } from 'react-native-open-maps';

interface MapsFragmentProps {
  location: Location | null | undefined;
  web?: Boolean;
  TextLabelWeb?;
}

const MapsFragment = ({ location, web, TextLabelWeb }: MapsFragmentProps) => {
  const addressLine1 = location?.addressLine1;
  const city = location?.city;
  const regionName = location?.regionName;
  const geoLocation = `${location?.geoLat!},${location?.geoLong!}`;
  const openLocation = createOpenLink({ provider: 'google', query: geoLocation });
  const isGeoValid = !(location?.geoLat === 0 && location?.geoLong === 0);
  return (
    <>
      {web ? (
        isGeoValid && (
          <Pressable onPress={openLocation}>
            <UIText style={[TextLabelWeb, styles.link]}>{i18n.t('components.MapsFragment.viewMap')}</UIText>
          </Pressable>
        )
      ) : (
        <View style={styles.addressModal}>
          <Heading>{i18n.t('components.MapsFragment.directions')}</Heading>
          <UIText>{`${addressLine1}, ${city}, ${regionName}`}</UIText>
          {isGeoValid && (
            <PrimaryButton
              title="Open in Maps"
              themeColor="red"
              onPress={openLocation}
              buttonStyle={styles.buttonStyle}
              containerStyle={styles.containerStyle}
              textStyle={styles.textStyle}
            />
          )}
        </View>
      )}
    </>
  );
};

export default MapsFragment;

const styles = StyleSheet.create({
  addressModal: {
    marginHorizontal: '10%',
    flex: 1,
    justifyContent: 'space-around',
  },
  buttonStyle: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerStyle: {
    paddingHorizontal: 0,
  },
  textStyle: {
    marginHorizontal: '5%',
    width: '90%',
  },
  link: {
    textDecorationLine: 'underline',
  },
});
