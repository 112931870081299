import { MaterialIcons } from '@expo/vector-icons';
import _ from 'lodash';
import React, { Dispatch, SetStateAction } from 'react';
import { View, StyleSheet, Pressable, FlatList } from 'react-native';
import { Colors } from '../../../../constants/Colors';
import { useSelector } from 'react-redux';
import EllipseBorderImage from '../../../../components/EllipseBorderImage';
import { UIText } from '../../../../components/StyledText';
import { User } from '../../../../constants/Interfaces';
import { getGenderPlaceholder } from '../../../../constants/Utils';

interface MyWhanauListProps {
  openDetails: Dispatch<SetStateAction<boolean>>;
  onSelect: (user: User) => void;
  selectedWhanaus?: User[];
  selection?: boolean;
}
const MyWhanauListFragment = ({ openDetails, onSelect, selectedWhanaus = [], selection }: MyWhanauListProps) => {
  const whanau: User[] = useSelector((state: any) => state.user.whanau);

  const renderItem = ({ item }: { item: User }) => {
    const selected = _.findIndex(selectedWhanaus, ['userId', item.userId]) > -1;
    return (
      <View style={styles.whanauList}>
        <Pressable
          onStartShouldSetResponderCapture={() => true}
          onMoveShouldSetResponderCapture={() => true}
          style={styles.whanauContainer}
          onPress={() => {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            item && onSelect ? onSelect!(item!) : null;
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            openDetails ? openDetails!(true) : null;
          }}
        >
          <EllipseBorderImage
            item={item}
            imageStyle={styles.whanauImage}
            openDetails={openDetails}
            onSelect={onSelect}
            color="yellow"
            img={item?.imageUrl ? { uri: item.imageUrl } : getGenderPlaceholder(item.gender)}
          />
          <UIText
            style={[styles.whanauName, { color: selection ? Colors.black : Colors.white }]}
          >{`${item.firstName} ${item.lastName}`}</UIText>
        </Pressable>
        {selected ? (
          <View style={styles.selectedContainer}>
            <MaterialIcons name="check" size={16} color={Colors.black} />
          </View>
        ) : null}
      </View>
    );
  };
  return <FlatList data={whanau} renderItem={renderItem} keyExtractor={(item, index) => index.toString()} />;
};

export default MyWhanauListFragment;
const styles = StyleSheet.create({
  whanauList: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    height: 60,
    borderBottomColor: Colors.blackOpacity,
    borderBottomWidth: 1,
  },
  whanauContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '90%',
    height: '100%',
  },
  whanauImage: {
    width: 30,
    height: 30,
    marginRight: 10,
  },
  whanauName: {
    marginLeft: 15,
  },
  selectedContainer: {
    backgroundColor: Colors.green,
    justifyContent: 'center',
    alignItems: 'center',
    width: 20,
    height: 20,
    borderRadius: 15,
  },
});
