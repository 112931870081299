import { Colors } from './Colors';

const DEFAULT_FONT = 'Barlow Regular';
const BOLD_FONT = 'Barlow Bold';
const SEMI_BOLD_FONT = 'Barlow SemiBold';

export default {
  family: {
    regular: DEFAULT_FONT,
    bold: BOLD_FONT,
    semi: SEMI_BOLD_FONT,
  },
  sizes: {
    default: 17,
    paragraph: 17,
    button: 18,
    heading: 24,
    smallHeading: 20,
    bigHeading: 28,
    small: 13,
    medium: 14.5,
    mini: 11,
  },
  shadow: {
    textShadowColor: Colors.black,
    textShadowOffset: {
      width: 0,
      height: 1,
    },
    textShadowRadius: 2,
  },
};
