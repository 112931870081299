import { StyleSheet } from 'react-native';
import { Colors } from '../constants/Colors';
import Fonts from '../constants/Fonts';
import Layout from '../constants/Layout';

const { isPhone } = Layout;
export const commonStyles = StyleSheet.create({
  yellowContainer: {
    padding: '5%',
    backgroundColor: Colors.yellow,
  },
  body: {
    zIndex: -1,
  },
  sectionContatiner: {
    maxWidth: 800,
    alignSelf: 'center',
  },
  titleLabel: {
    fontSize: isPhone ? Fonts.sizes.default : Fonts.sizes.bigHeading,
    fontFamily: Fonts.family.bold,
    textAlign: 'center',
  },
  subtitleLabel: {
    fontSize: isPhone ? Fonts.sizes.default : Fonts.sizes.smallHeading,
    fontFamily: Fonts.family.bold,
    textAlign: 'center',
  },
  marginMobile: {
    padding: '5%',
  },
  mainImageContainer: {
    marginVertical: 15,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mainImage: {
    height: 150,
    width: '100%',
    resizeMode: 'contain',
  },
  smallLabel: {
    fontSize: Fonts.sizes.mini,
    marginVertical: '3%',
  },
  descriptionLabel: {
    color: Colors.black,
    opacity: 0.7,
  },
  contentText: {
    textAlign: 'center',
    marginVertical: 15,
    fontSize: isPhone ? Fonts.sizes.medium : Fonts.sizes.default,
  },
  maxwidthContainer: {
    paddingHorizontal: Layout.window.height > 1444 ? '20%' : '5%',
  },
});
