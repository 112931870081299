import React, { useEffect, useRef } from 'react';
import { View, StyleSheet, Animated, ImageBackground, Image } from 'react-native';
import { Colors } from '../constants/Colors';
import HeaderBar from './HeaderBar';
import BottomBanner from './BottomBanner';
import Layout from '../constants/Layout';

interface ScreenHolderProps {
  children;
  navigation;
  heroImage?: JSX.Element | null;
  headerComponent?: JSX.Element | null;
  showFooter?: boolean;
}

const ScreenHolder = ({ children, navigation, heroImage, headerComponent, showFooter }: ScreenHolderProps) => {
  const { yellow, darkBurgundy } = Colors;
  const scrollPosition = useRef(new Animated.Value(0)).current;
  const minHeaderHeight = 60;
  // eslint-disable-next-line no-nested-ternary
  const maxHeaderHeight = heroImage ? 400 : headerComponent ? 320 : 200;
  const customHolder = !!heroImage || !!headerComponent;
  const opacityFooter = new Animated.Value(0);

  const headerHeight = scrollPosition.interpolate({
    // eslint-disable-next-line no-nested-ternary
    inputRange: [0, heroImage ? 500 : headerComponent ? 320 : 250],
    outputRange: [maxHeaderHeight, 5 * 1],
    extrapolate: 'clamp',
  });

  const headerBarHeight = scrollPosition.interpolate({
    inputRange: [0, 250],
    outputRange: [80, minHeaderHeight],
    extrapolate: 'clamp',
  });

  const ellipseHeight = scrollPosition.interpolate({
    inputRange: [0, customHolder ? 5 * 16 * 2 : 5 * 16],
    outputRange: [65, 6 * 6],
    extrapolateLeft: 'identity',
    extrapolateRight: 'clamp',
  });
  const imagePosition = scrollPosition.interpolate({
    inputRange: [0, customHolder ? 5 * 16 * 2 : 5 * 16],
    outputRange: [10, 5 * 6],
    extrapolateLeft: 'identity',
    extrapolateRight: 'clamp',
  });
  const backgroundColorHeader = scrollPosition.interpolate({
    inputRange: [0, customHolder ? 5 * 16 * 2 : 5 * 16],
    outputRange: [yellow, darkBurgundy],
    extrapolateLeft: 'identity',
    extrapolateRight: 'clamp',
  });

  const opacityImage = scrollPosition.interpolate({
    inputRange: [0, customHolder ? 5 * 16 * 2 : 5 * 16],
    outputRange: [1, 5 * 0],
    extrapolateRight: 'clamp',
  });
  const backgroundColorHeaderBar = scrollPosition.interpolate({
    inputRange: [0, customHolder ? 5 * 16 * 2 : 5 * 16],
    outputRange: [Colors.transparent, yellow],
    extrapolateLeft: 'identity',
    extrapolateRight: 'clamp',
  });
  const opacityProfileImage = scrollPosition.interpolate({
    inputRange: [0, customHolder ? 5 * 16 * 2 : 5 * 16],
    outputRange: [0, 1],
    extrapolateRight: 'clamp',
  });

  const footerLabel = opacityFooter.interpolate({
    inputRange: [0, 150],
    outputRange: [80, 0],
    extrapolateRight: 'clamp',
  });
  const footerImagebottom = opacityFooter.interpolate({
    inputRange: [0, 150],
    outputRange: [Layout.isSmallDevice ? -20 : -5, 65],
    extrapolateRight: 'clamp',
  });
  useEffect(() => {
    if (showFooter) {
      Animated &&
        Animated.timing(opacityFooter, {
          toValue: 150,
          duration: 800,
          useNativeDriver: false,
        }).start();
    } else {
      Animated &&
        Animated.timing(opacityFooter, {
          toValue: 0,
          duration: 800,
          useNativeDriver: false,
        }).start();
    }
  }, [opacityFooter]);

  return (
    <>
      <View style={headerComponent ? { backgroundColor: Colors.yellow } : null}>
        {!headerComponent && (
          <HeaderBar
            headerBarHeight={headerBarHeight}
            imagePosition={imagePosition}
            backgroundColorHeader={backgroundColorHeader}
            navigation={navigation}
            ellipseHeight={ellipseHeight}
          />
        )}
        {headerComponent && (
          <View style={[styles.headerBarContainer, { backgroundColor: Colors.transparent }]}>
            <HeaderBar
              navigation={navigation}
              backgroundColorHeader={backgroundColorHeaderBar}
              opacityProfile={opacityProfileImage}
              ellipseHeight={ellipseHeight}
              imagePosition={imagePosition}
              headerBarHeight={headerBarHeight}
            />
          </View>
        )}
        <Animated.ScrollView
          bounces={false}
          contentInsetAdjustmentBehavior="automatic"
          style={[styles.scrollView, { zIndex: -1 }, headerComponent ? { backgroundColor: Colors.transparent } : null]}
          scrollEventThrottle={16}
          onScroll={Animated.event(
            [
              {
                nativeEvent: {
                  contentOffset: { y: scrollPosition },
                },
              },
            ],
            {
              useNativeDriver: false,
            }
          )}
        >
          <View
            style={[
              styles.heroImage,
              { height: heroImage ? 400 : 150 },
              headerComponent ? { top: -50, height: 320, backgroundColor: Colors.yellow } : null,
            ]}
          >
            <Animated.View
              style={{
                height: headerHeight,
                opacity: opacityImage,
                backgroundColor: Colors.yellow,
              }}
            >
              {heroImage}
              {headerComponent || (
                <ImageBackground
                  source={
                    heroImage
                      ? require('../../assets/images/screenHolder_footer.png')
                      : require('../../assets/images/app_header.png')
                  }
                  style={[styles.imageBackground]}
                />
              )}
            </Animated.View>
          </View>
          <View style={[styles.scrollViewContainer, headerComponent ? { padding: 0, top: -50 } : null]}>
            {children}
          </View>
        </Animated.ScrollView>
        {headerComponent ? (
          <>
            <Animated.View style={{ bottom: footerImagebottom }}>
              <Image
                source={require('../../assets/images/bottom_activities_screen.png')}
                style={[
                  styles.bottomImage,
                  showFooter
                    ? { height: 78, bottom: Layout.isSmallDevice ? -3 : -4 }
                    : { height: 75, bottom: Layout.isSmallDevice ? 10 : 5 },
                ]}
                resizeMethod="resize"
              />
            </Animated.View>

            <Animated.View style={{ height: footerLabel }}>
              <BottomBanner themeColor="red" />
            </Animated.View>
          </>
        ) : null}
      </View>

      {!headerComponent && <BottomBanner themeColor="white" />}
    </>
  );
};

export default ScreenHolder;

const styles = StyleSheet.create({
  scrollView: {
    backgroundColor: `${Colors.darkBurgundy}`,
  },
  heroImage: {
    backgroundColor: `${Colors.darkBurgundy}`,
  },
  imageBackground: {
    flex: 1,
  },
  scrollViewContainer: {
    paddingHorizontal: 15,
  },
  headerBarContainer: {
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  bottomImage: {
    position: 'absolute',
    bottom: 0,
    width: Layout.window.width,
    resizeMode: 'contain',
    backgroundColor: Colors.yellow,
  },
});
