import * as React from 'react';
import { Pressable, StyleSheet } from 'react-native';
import { UIText } from '../StyledText';
import { Colors } from '../../constants/Colors';
import Fonts from '../../constants/Fonts';

interface SectionButtonProps {
  title: string;
  selected?: boolean;
  onPress: () => void;
}

export default function SectionButton(props: SectionButtonProps) {
  const { title, selected = false, onPress } = props;
  return (
    <Pressable style={[styles.container, { opacity: selected ? 1 : 0.4 }]} onPress={onPress}>
      <UIText style={styles.title}>{title}</UIText>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.white,
    borderRadius: 13,
    paddingHorizontal: 10,
    paddingVertical: 4,
    marginHorizontal: 4,
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    color: Colors.darkBurgundy,
    fontSize: Fonts.sizes.medium,
  },
});
