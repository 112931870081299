import React, { Dispatch, SetStateAction, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import MaraePointsFragment from './MaraePointsFragment';
import MaraeDeleteFragment from './MaraeDeleteFragment';
import MaraePartyFragment from './MaraePartyFragment';
import { Heading } from '../../../../components/StyledText';
import { Colors } from '../../../../constants/Colors';
import Backbutton from '../../../../components/Backbutton';
import { UserMarae } from '../../../../constants/Interfaces';
import SectionButton from '../../../../components/buttons/SectionButton';
import i18n from '../../../../translations';

interface MaraeDetailsProps {
  marae: UserMarae;
  openDetails: Dispatch<SetStateAction<boolean>>;
  setTip: Function;
}

const MaraeDetailsFragment = ({ openDetails, marae, setTip }: MaraeDetailsProps) => {
  const [active, setActive] = useState(1);

  const getTip = () => {
    if (active === 1) setTip('activities');
    if (active === 2) setTip('koha');
    if (active === 3 || active === 4) setTip(undefined);
  };
  React.useEffect(() => {
    getTip();
  }, [active]);

  return (
    <View style={styles.root}>
      <View style={styles.headerContainer}>
        <View style={styles.header}>
          <Backbutton onPress={() => openDetails(false)} />
          <View style={styles.titleContainer}>
            <Heading style={styles.headerTitle}>{`${marae.maraeName} Marae`}</Heading>
          </View>
        </View>
        <MaraeDetailsNav active={active} setActive={setActive} />
      </View>
      {active === 1 && <MaraePartyFragment marae={marae} section="activities" />}
      {active === 2 && <MaraePartyFragment marae={marae} section="koha" />}
      {active === 3 && <MaraePointsFragment marae={marae} />}
      {active === 4 && <MaraeDeleteFragment marae={marae} openDetails={openDetails} />}
    </View>
  );
};

const MaraeDetailsNav = ({ active, setActive }) => (
  <View style={styles.navbar}>
    <SectionButton
      title={i18n.t('components.MaraeDetails.activities')}
      selected={active === 1}
      onPress={() => setActive(1)}
    />
    <SectionButton
      title={i18n.t('components.MaraeDetails.koha')}
      selected={active === 2}
      onPress={() => setActive(2)}
    />
    {false && (
      <SectionButton
        title={i18n.t('components.MaraeDetails.points')}
        selected={active === 3}
        onPress={() => setActive(3)}
      />
    )}
    <SectionButton
      title={i18n.t('components.MaraeDetails.edit')}
      selected={active === 4}
      onPress={() => setActive(4)}
    />
  </View>
);

export default MaraeDetailsFragment;
const styles = StyleSheet.create({
  root: {
    width: '100%',
    alignContent: 'center',
    justifyContent: 'center',
  },
  headerContainer: {
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    alignContent: 'center',
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    paddingVertical: 10,
  },
  titleContainer: {
    width: '100%',
    alignItems: 'center',
  },
  headerTitle: {
    color: Colors.white,
  },
  navbar: {
    width: '100%',
    alignSelf: 'center',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  navItem: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.whiteOpacity,
    borderRadius: 20,
    padding: 2,
    paddingHorizontal: 10,
    minWidth: 65,
  },
  navItemText: {
    color: Colors.darkBurgundy,
  },
});
