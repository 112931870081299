import React, { useCallback, useRef, useState } from 'react';
import {
  StyleSheet,
  FlatList,
  StyleProp,
  ViewStyle,
  ListRenderItem,
  ViewToken,
  TouchableWithoutFeedback,
} from 'react-native';
import { View } from './Themed';
import { CarouselCard } from '../constants/Interfaces';
import { Heading, Paragraph } from './StyledText';
import Layout from '../constants/Layout';
import { Colors } from '../constants/Colors';

interface CarouselProps {
  data: CarouselCard[];
  onIndexChange?: (currentIndex: number | null) => void;
  carouselStyle?: StyleProp<ViewStyle>;
  contentContainerStyle?: StyleProp<ViewStyle>;
  itemContainerStyle?: StyleProp<ViewStyle>;
  renderItem?: ListRenderItem<CarouselCard>;
  onScroll?: (x: number, y: number) => void;
}

export default function Carousel(props: CarouselProps) {
  const { data, onIndexChange, renderItem, carouselStyle, contentContainerStyle, itemContainerStyle, onScroll } = props;

  const [currentIndex, setCurrentIndex] = useState<number | null>(0);
  const carouselRef = useRef<FlatList>(null);

  const viewabilityConfig = useRef({
    itemVisiblePercentThreshold: 50,
  });

  const handleViewableItemsChanged = useCallback(({ viewableItems }: { viewableItems: ViewToken[] }) => {
    if (viewableItems && viewableItems.length === 1) {
      setCurrentIndex(viewableItems[0].index);
      if (onIndexChange) {
        onIndexChange(viewableItems[0].index);
      }
    }
  }, []);

  const handleIndexSelect = (index: number) => () => {
    if (carouselRef.current && index !== currentIndex) {
      carouselRef.current.scrollToIndex({
        index,
      });
    }
  };

  const defaultRenderItem = ({ item }: { item: CarouselCard }) => (
    <View transparent style={[styles.itemContainer, itemContainerStyle]}>
      <Heading>{item.title}</Heading>
      <Paragraph style={styles.paragraph}>{item.paragraph}</Paragraph>
    </View>
  );

  const handleScroll = (event) => {
    if (onScroll) {
      onScroll(event.nativeEvent.contentOffset.x, event.nativeEvent.contentOffset.y);
    }
  };

  return (
    <View transparent style={[styles.container, carouselStyle]}>
      <FlatList
        ref={carouselRef}
        data={data}
        renderItem={renderItem || defaultRenderItem}
        scrollEnabled
        onScroll={handleScroll}
        scrollEventThrottle={16}
        horizontal
        viewabilityConfig={viewabilityConfig.current}
        onViewableItemsChanged={handleViewableItemsChanged}
        showsHorizontalScrollIndicator={false}
        overScrollMode="never"
        pagingEnabled
        getItemLayout={(data, index) => ({ length: Layout.window.width, offset: Layout.window.width * index, index })}
        keyExtractor={(item, index) => index.toString()}
        style={styles.carouselContainer}
        contentContainerStyle={[styles.contentContainer, contentContainerStyle]}
      />
      <View transparent style={styles.navContainer}>
        {data.map((item: CarouselCard, index) => (
          <TouchableWithoutFeedback key={index} onPress={handleIndexSelect(index)}>
            <View style={[styles.dot, { opacity: index === currentIndex ? 1 : 0.3 }]} />
          </TouchableWithoutFeedback>
        ))}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 0,
    marginTop: 10,
  },
  carouselContainer: {
    flexGrow: 0,
    height: 300,
  },
  contentContainer: {
    height: 300,
    backgroundColor: Colors.transparent,
  },
  itemContainer: {
    flex: 0,
    height: 300,
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: Layout.window.width,
    padding: Layout.backgroundMarginNormal.left,
  },
  navContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dot: {
    width: 15,
    height: 15,
    backgroundColor: Colors.black,
    borderRadius: 15,
    marginHorizontal: 6,
  },
  paragraph: {
    marginTop: Layout.backgroundMarginNormal.top,
    textAlign: 'center',
    lineHeight: 22,
  },
});
