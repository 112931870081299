import * as React from 'react';
import { StyleSheet } from 'react-native';
import { View } from '../../../components/Themed';
import { Heading, Paragraph, UIText } from '../../../components/StyledText';
import i18n from '../../../translations';
import Fonts from '../../../constants/Fonts';
import { MaterialIcons } from '@expo/vector-icons';
import { Colors } from '../../../constants/Colors';
import Layout from '../../../constants/Layout';
import { User } from '../../../constants/Interfaces';
import MyWhanauListFragment from '../../profile/fragments/MyWhanau/MyWhanauListFragment';
import { useNavigation } from '@react-navigation/native';
import SecondaryButton from '../../../components/buttons/SecondaryButton';
import PrimaryButton from '../../../components/buttons/PrimaryButton';

interface TakeWhanauFragmentProps {
  onNext: () => void;
  onBack: () => void;
  toggleWhanau: (user: User) => void;
  selectedWhanaus: User[];
  activityId?: string;
}

export const TakeWhanauFragment = ({
  onNext,
  onBack,
  toggleWhanau,
  selectedWhanaus = [],
  activityId,
}: TakeWhanauFragmentProps) => {
  const handleSelected = (user: User) => {
    toggleWhanau(user);
  };
  const navigation = useNavigation();

  return (
    <View transparent style={styles.container}>
      <UIText style={styles.step}>
        {i18n.t('components.TakeWhanauFragment.step', {
          currentStep: 1,
          totalSteps: 3,
        })}
      </UIText>
      <Heading style={styles.title}>{i18n.t('components.TakeWhanauFragment.title')}</Heading>
      <Paragraph style={styles.instruction}>{i18n.t('components.TakeWhanauFragment.instruction')}</Paragraph>

      <PrimaryButton
        onPress={() => {
          onBack();
          navigation.navigate('ProfileStack', {
            screen: 'Profile',
            params: { index: 2, activityId },
          });
        }}
        title={i18n.t('components.TakeWhanauFragment.addWhanau')}
      />
      <SecondaryButton
        onPress={onNext}
        bgColor="white"
        styleButton={{ borderColor: Colors.darkBurgundy, borderWidth: 2 }}
        styleText={{ color: Colors.darkBurgundy }}
        iconRight={
          <MaterialIcons
            name="arrow-forward-ios"
            size={16}
            color={Colors.darkBurgundy}
            style={[styles.buttonIcon, styles.buttonIconRight]}
          />
        }
        title={i18n.t('components.TakeWhanauFragment.next')}
      />

      <View style={styles.listContainer}>
        <MyWhanauListFragment
          openDetails={() => {}}
          onSelect={handleSelected}
          selectedWhanaus={selectedWhanaus}
          selection
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    paddingHorizontal: Layout.backgroundMarginNormal.left,
  },
  actionButtonContainer: {
    marginHorizontal: 20,
    marginBottom: 15,
  },
  step: {
    fontSize: Fonts.sizes.small,
  },
  title: {
    paddingVertical: 14,
    fontSize: Fonts.sizes.smallHeading,
  },
  instruction: {
    fontSize: Fonts.sizes.medium,
    textAlign: 'center',
    paddingBottom: 15,
  },
  buttonIcon: {
    paddingTop: 2,
  },
  buttonIconLeft: {
    paddingRight: 8,
  },
  buttonIconRight: {
    paddingLeft: 8,
  },
  listContainer: {
    width: '90%',
    height: 400,
  },
});
