import {
  AuthRequestConfig,
  DiscoveryDocument,
  makeRedirectUri,
  Prompt,
  ResponseType,
  TokenRequestConfig,
} from 'expo-auth-session';
import Constants from 'expo-constants';
import { DomainHintList } from '../constants/Interfaces';

export const redirectUri = makeRedirectUri({
  // For usage in bare and standalone
  native: `${Constants.expoConfig.scheme}://auth`,
  useProxy: false,
});

// @ts-ignore
const { clientId, b2cHost, b2cDirectory, signInSinUpFlow } = Constants.expoConfig.extra.azure;

const scopes: string[] = [`https://${b2cDirectory}/${clientId}/user_impersonation`, 'openid', 'offline_access'];

export const authRequestConfig: AuthRequestConfig = {
  redirectUri,
  clientId,
  scopes,
  responseType: ResponseType.Code,
  prompt: Prompt.Login,
};

export const authRequestConfigGoogle: AuthRequestConfig = {
  ...authRequestConfig,
  extraParams: {
    domain_hint: DomainHintList.google,
  },
};
export const authRequestConfigFacebook: AuthRequestConfig = {
  ...authRequestConfig,
  extraParams: {
    domain_hint: DomainHintList.facebook,
  },
};
export const authRequestConfigApple: AuthRequestConfig = {
  ...authRequestConfig,
  extraParams: {
    domain_hint: DomainHintList.apple,
  },
};

export const tokenRequestConfig: TokenRequestConfig = {
  clientId,
  scopes,
};

export const discovery: DiscoveryDocument = {
  authorizationEndpoint: `${b2cHost}/${b2cDirectory}/oauth2/v2.0/authorize?p=${signInSinUpFlow}&nonce=defaultNonce`,
  tokenEndpoint: `${b2cHost}/${b2cDirectory}/${signInSinUpFlow}/oauth2/v2.0/token`,
};
