import { DateTime } from 'luxon';

/**
 * Singleton class containing methods for calling firebase crashlytics/ general logging.
 */

export default class Logger {
  static debug(message: any) {
    // eslint-disable-next-line no-console
    console.debug(`${DateTime.local().toISO()} ${message}`);
  }

  static info(message: string) {
    // eslint-disable-next-line no-console
    console.info(`${DateTime.local().toISO()} ${message}`);
    // crashlytics().log(message);
  }

  static warn(message: string) {
    // eslint-disable-next-line no-console
    console.warn(`${DateTime.local().toISO()} ${message}`);
    // crashlytics().log(message);
  }

  static error(message: string, e?: any) {
    // eslint-disable-next-line no-console
    console.warn(`${DateTime.local().toISO()} ${message}`);
    // eslint-disable-next-line no-console
    console.warn(`${DateTime.local().toISO()} ${e}`);
    let messageTitle: string = Logger.getErrorTitle(message);
    let messageContent: string = message;

    if (messageTitle.length > 50) {
      messageTitle = `${messageTitle.substr(0, 50)}...`;
    }

    if (e && e.message) {
      messageContent = `${message} Detail: ${e.message}`;
    } else if (e && e.code) {
      messageContent = `${message} Code: ${e.code}`;
    } else if (e) {
      messageContent = `${message} Detail: ${JSON.stringify(e)}`;
    }

    const jsError = new Error(messageContent);
    jsError.name = messageTitle;
    // crashlytics().recordError(jsError);
  }

  static getErrorTitle(message: string) {
    let errorTitle = message;
    if (message.indexOf(':')) {
      errorTitle = message.split(':')[0];
    } else if (message.indexOf('-')) {
      errorTitle = message.split('-')[0];
    } else if (message.indexOf(' ')) {
      errorTitle = message.split(' ')[0];
    }
    return errorTitle;
  }
}
