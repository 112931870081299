import React, { useRef } from 'react';
import { StyleSheet, Image, Animated, ActivityIndicator } from 'react-native';
import { View } from '../../components/Themed';
import i18n from '../../translations';
import Layout from '../../constants/Layout';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import { AuthState, CarouselCard, User } from '../../constants/Interfaces';
import Carousel from '../../components/Carousel';
import { Colors } from '../../constants/Colors';
import BottomBanner from '../../components/BottomBanner';
import { useDispatch, useSelector } from 'react-redux';
import { isAccessTokenValid } from '../../reducers/authentication.slice';
import { purge } from '../../reducers/commonActions';

export default function LandingScreen({ navigation }) {
  const authLoading: boolean = useSelector((state: any) => state.authentication.loading);
  const userLoading: boolean = useSelector((state: any) => state.user.loading);
  const user: User = useSelector((state: any) => state.user.user);
  const authState: AuthState = useSelector((state: any) => state.authentication.authState);
  // TODO: this does not check for if the current token can be refreshed.
  const isAuthenticated = user && isAccessTokenValid(authState);
  const highCloudsX = useRef(new Animated.Value(0)).current;
  const lowCloudsX = useRef(new Animated.Value(0)).current;
  const dispatch = useDispatch();

  const content: CarouselCard[] = [
    {
      title: i18n.t('pages.LandingScreen.heading1'),
      paragraph: i18n.t('pages.LandingScreen.paragraph1'),
    },
    {
      title: i18n.t('pages.LandingScreen.heading2'),
      paragraph: i18n.t('pages.LandingScreen.paragraph2'),
    },
    {
      title: i18n.t('pages.LandingScreen.heading3'),
      paragraph: i18n.t('pages.LandingScreen.paragraph3'),
    },
    {
      title: i18n.t('pages.LandingScreen.heading4'),
      paragraph: i18n.t('pages.LandingScreen.paragraph4'),
    },
    {
      title: i18n.t('pages.LandingScreen.heading5'),
      paragraph: i18n.t('pages.LandingScreen.paragraph5'),
    },
    {
      title: i18n.t('pages.LandingScreen.heading6'),
      paragraph: i18n.t('pages.LandingScreen.paragraph6'),
    },
  ];

  const handleScroll = (x: number) => {
    Animated.parallel([
      Animated.timing(highCloudsX, {
        toValue: x,
        duration: 0,
        useNativeDriver: true,
      }),
      Animated.timing(lowCloudsX, {
        toValue: x,
        duration: 0,
        useNativeDriver: true,
      }),
    ]).start();
  };

  return (
    <View transparent style={styles.container}>
      {authLoading || userLoading ? (
        <View style={styles.authLoadingContainer}>
          <ActivityIndicator color={Colors.black} />
        </View>
      ) : null}
      <Animated.Image
        source={require('../../../assets/images/high_clouds.png')}
        style={[
          styles.highClouds,
          {
            transform: [
              {
                translateX: highCloudsX.interpolate({
                  inputRange: [0, 780],
                  outputRange: [0, -80],
                }),
              },
            ],
          },
        ]}
        resizeMethod="resize"
      />
      <Animated.Image
        source={require('../../../assets/images/low_clouds.png')}
        style={[
          styles.lowClouds,
          {
            transform: [
              {
                translateX: lowCloudsX.interpolate({
                  inputRange: [0, 780],
                  outputRange: [0, -210],
                }),
              },
            ],
          },
        ]}
        resizeMethod="resize"
      />
      <Image
        source={require('../../../assets/images/mf_logo_dark_no_text.png')}
        style={styles.logo}
        resizeMethod="scale"
      />
      <Carousel data={content} onScroll={handleScroll} carouselStyle={styles.carousel} />
      <View transparent style={styles.loginButtonContainer}>
        <PrimaryButton
          title={i18n.t('pages.LandingScreen.getStarted')}
          themeColor="yellow"
          onPress={() => {
            if (isAuthenticated) {
              navigation.navigate('Root');
            } else {
              dispatch(purge());
              navigation.navigate('Guest');
            }
          }}
        />
      </View>
      <Image
        source={require('../../../assets/images/landing_bottom.png')}
        style={styles.bottomImage}
        resizeMethod="resize"
      />
      <BottomBanner />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.backgroundYellow,
    width: Layout.window.width,
    height: Layout.window.height,
  },
  authLoadingContainer: {
    flex: 1,
    width: '100%',
    height: '100%',
    zIndex: 10,
    elevation: 10,
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: Colors.white,
    opacity: 0.7,
    alignItems: 'center',
    justifyContent: 'center',
  },
  centralContainer: {
    flexDirection: 'row',
    zIndex: 2,
  },
  centralRightContainer: {
    alignItems: 'center',
    zIndex: 2,
    maxWidth: 500,
  },
  topImage: {
    position: 'absolute',
    top: 0,
    height: 160,
    resizeMode: 'contain',
  },
  bottomImage: {
    position: 'absolute',
    bottom: 0,
    width: Layout.window.width,
    height: '55%',
    resizeMode: 'cover',
  },
  logo: {
    marginTop: 80,
    width: 120,
    height: 120,
    resizeMode: 'contain',
    opacity: 1,
    zIndex: 2,
  },
  carousel: {
    zIndex: 3,
  },
  loginButtonContainer: {
    flexGrow: 1,
    zIndex: 3,
    justifyContent: 'flex-end',
    paddingBottom: 100,
  },
  highClouds: {
    position: 'absolute',
    top: Layout.window.height / 5,
    left: Layout.window.width / 2 - 30,
    width: 900,
    height: 190,
    resizeMode: 'contain',
    zIndex: 1,
  },
  lowClouds: {
    position: 'absolute',
    bottom: Layout.window.height / 3 + Layout.backgroundMarginNormal.bottom,
    left: Layout.window.width / 6,
    width: 800,
    height: 60,
    resizeMode: 'contain',
    zIndex: 1,
  },
  paragraph: {
    paddingTop: 20,
  },
  badgeContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  androidBadge: {
    width: 150,
    height: 120,
    resizeMode: 'contain',
    opacity: 1,
    zIndex: 2,
  },
});
