import { StyleSheet } from 'react-native';
import { Colors } from '../../constants/Colors';
import Fonts from '../../constants/Fonts';
import Layout from '../../constants/Layout';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: Colors.backgroundYellow,
  },
  loadingContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.backgroundYellow,
  },
  loadingText: {
    color: Colors.black,
    marginTop: 8,
    fontSize: Fonts.sizes.default,
  },
  scrollContainer: {
    flexGrow: 1,
    paddingTop: 40,
    paddingBottom: 40,
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  headerContainer: {
    marginTop: 50,
    alignItems: 'center',
  },
  formContainer: {
    width: '100%',
    paddingHorizontal: Layout.backgroundMarginNormal.left,
    paddingTop: 15,
  },
  heading: {
    paddingTop: Layout.backgroundMarginNormal.top,
    color: Colors.black,
  },
  subtitle: {
    paddingTop: Layout.backgroundMarginNormal.top / 2,
    color: Colors.black,
    fontSize: Fonts.sizes.default,
  },
  introduction: {
    paddingTop: Layout.backgroundMarginNormal.top,
    color: Colors.black,
    paddingHorizontal: Layout.backgroundMarginNormal.left,
    textAlign: 'center',
    lineHeight: 22,
  },
  bottomImage: {
    width: Layout.window.width,
    bottom: 0,
    height: 250,
    resizeMode: 'cover',
    zIndex: -102,
    position: 'absolute',
  },
  topImage: {
    position: 'absolute',
    top: 0,
    height: 200,
    resizeMode: 'contain',
    opacity: 0.5,
  },
  topLogo: {
    position: 'absolute',
    top: 30,
    height: 50,
    resizeMode: 'contain',
    opacity: 1,
  },
  logo: {
    width: 60,
    height: 60,
    resizeMode: 'contain',
  },
  divider: {
    paddingTop: Layout.backgroundMarginNormal.top,
    width: Layout.window.width - 2 * Layout.backgroundMarginNormal.left,
    resizeMode: 'contain',
  },
  submitButton: {
    alignItems: 'center',
    marginTop: 4,
    zIndex: 3,
  },
  introText: {
    marginBottom: Layout.backgroundMarginNarrow.bottom,
    color: Colors.white,
  },
  formLeftContainer: {
    flex: 1,
    width: 400 - 1.5 * Layout.backgroundMarginNormal.left,
    paddingRight: Layout.backgroundMarginNormal.left,
  },
  formRightContainer: {
    flex: 1,
    width: 400 - 1.5 * Layout.backgroundMarginNormal.left,
  },
  skipCancelContainer: {
    flexDirection: 'row',
    width: '100%',
    paddingHorizontal: Layout.backgroundMarginNormal.left * 2,
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  filler: {
    flexGrow: 1,
  },
});
