import React from 'react';
import { View, Image, StyleSheet, Pressable, ActivityIndicator } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { Heading, Paragraph, UIText } from '../components/StyledText';
import { Colors } from '../constants/Colors';
import Fonts from '../constants/Fonts';
import Layout from '../constants/Layout';
import { loginUser } from '../reducers/authentication.slice';
import { AuthState, DomainHintList } from '../constants/Interfaces';
import i18n from '../translations';
import { useNavigation } from '@react-navigation/core';
import LayoutWeb from './LayoutWeb';

const SignIn = () => {
  const authState: AuthState = useSelector((state: any) => state.authentication.authState);
  const authLoading: boolean = useSelector((state: any) => state.authentication.loading);
  const dispatch = useDispatch();
  const navigation = useNavigation();

  React.useEffect(() => {
    if (authState) {
      navigation.navigate('AddWhanau');
    }
  }, []);

  const handleLogin = (domain) => dispatch(loginUser(domain));
  return (
    <LayoutWeb>
      {authLoading ? (
        <View style={styles.authLoadingContainer}>
          <ActivityIndicator color={Colors.black} />
        </View>
      ) : null}
      <View style={styles.body}>
        <View style={styles.headerContainer}>
          <Heading style={styles.titleHeader}>{i18n.t('pages.SocialMediaLogin.title')}</Heading>
          <Paragraph style={styles.paragraph}>{i18n.t('pages.SocialMediaLogin.intro')}</Paragraph>
          <Image style={styles.headerDividers} source={require('../../assets/images/divider.png')} />
        </View>
        <View style={styles.buttonsContainer}>
          <Pressable
            style={styles.button}
            onPress={() => {
              handleLogin(DomainHintList.google);
            }}
          >
            <Image style={styles.buttonImg} source={require('../../assets/images/googleIcon.png')} />
            <UIText style={styles.buttonText}>Continue with Google</UIText>
          </Pressable>
          <Pressable
            style={styles.button}
            onPress={() => {
              handleLogin(DomainHintList.facebook);
            }}
          >
            <Image style={styles.buttonImg} source={require('../../assets/images/facebookIcon.png')} />
            <UIText style={styles.buttonText}>Continue with Facebook</UIText>
          </Pressable>

          <Pressable
            style={styles.button}
            onPress={() => {
              handleLogin(null);
            }}
          >
            <Image style={styles.buttonImg} source={require('../../assets/images/emailIcon.png')} />
            <UIText style={styles.buttonText}>Continue with Email</UIText>
          </Pressable>
        </View>
      </View>
    </LayoutWeb>
  );
};
export default SignIn;

const styles = StyleSheet.create({
  authLoadingContainer: {
    width: '100%',
    height: '100%',
    zIndex: 10,
    elevation: 10,
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: Colors.white,
    opacity: 0.7,
    alignItems: 'center',
    justifyContent: 'center',
  },
  body: {
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: '5%',
    paddingHorizontal: 10,
    maxWidth: 800,
    alignSelf: 'center',
    marginVertical: 40,
    zIndex: 1,
  },
  headerContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  titleHeader: {
    fontFamily: Fonts.family.bold,
  },
  paragraph: {
    marginVertical: 20,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    fontSize: Layout.isSmallDevice ? Fonts.sizes.medium : Fonts.sizes.paragraph,
  },
  headerDividers: {
    width: '100%',
    height: 10,
    marginBottom: 20,
  },
  buttonsContainer: {
    width: Layout.window.width < 370 ? '85%' : '80%',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 15,
    maxWidth: 270,
  },
  button: {
    flexDirection: 'row',
    height: 45,
    backgroundColor: 'white',
    borderRadius: 37,
    paddingVertical: 13,
    paddingHorizontal: 16,
    fontWeight: 'bold',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    marginVertical: 5,
  },
  buttonImg: {
    height: 25,
    width: 25,
    marginRight: 14.7,
    resizeMode: 'contain',
  },
  buttonText: {
    fontFamily: Fonts.family.bold,
    fontSize: Layout.isSmallDevice ? 15 : Fonts.sizes.paragraph,
  },
  checkBoxCointainer: {
    justifyContent: 'center',
    flexDirection: 'row',
    marginVertical: 0,
    marginHorizontal: '18%',
  },
  termsLabel: {
    textAlign: 'left',
    fontSize: Fonts.sizes.medium,
  },
  checkBox: {
    marginTop: 4,
    width: 20,
    height: 20,
    backgroundColor: Colors.white,
    padding: 2,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    marginRight: 5,
    zIndex: 10,
  },
  checkBoxTick: {
    backgroundColor: Colors.darkBurgundy,
    width: 12,
    height: 12,
    borderRadius: 6,
  },
  link: {
    fontFamily: Fonts.family.bold,
    textDecorationLine: 'underline',
    marginTop: 5,
    fontSize: Fonts.sizes.medium,
  },
  conditionsText: {
    fontSize: Fonts.sizes.small,
    marginBottom: 10,
    alignSelf: 'center',
  },
});
