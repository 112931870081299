import { AnyAction, AsyncThunk, createAsyncThunk, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import * as Crypto from 'expo-crypto';
import { RootState } from '../store/configureStore';
import Logger from '../constants/Logger';
import {
  AccessTokenRequestConfig,
  AuthRequest,
  AuthRequestConfig,
  AuthSessionResult,
  exchangeCodeAsync,
  refreshAsync,
  RefreshTokenRequestConfig,
  TokenResponse,
} from 'expo-auth-session';
import {
  authRequestConfig,
  authRequestConfigApple,
  authRequestConfigFacebook,
  authRequestConfigGoogle,
  discovery,
  redirectUri,
  tokenRequestConfig,
} from '../config/AuthConfig';
import { cleanAuthCode, getAuthState } from '../constants/Utils';
import { AuthState, DomainHintList } from '../constants/Interfaces';
import { RejectedAction } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import { purge } from './commonActions';
import API from '../constants/API';
import { DateTime } from 'luxon';

interface AuthenticationState {
  authState: AuthState | null;
  lastLogin: Date | null;
  loading: boolean;
  error: SerializedError | null;
}

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;
type PendingAction = ReturnType<GenericAsyncThunk['pending']>;

const initialState: AuthenticationState = {
  authState: null,
  lastLogin: null,
  loading: false,
  error: null,
};

const CHARSET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

/**
 * Check if the current token is valid.
 * This does NOT check if the token can be renewed. So you may get false results on tokens that are still able to be used with the API class.
 * @param scope
 */
export const isAccessTokenValid = (authState: AuthState): boolean => {
  Logger.debug(`Reducer -> authentication -> isAccessTokenValid: Checking authentication state now`);
  if (!authState) {
    return false;
  }
  if (!authState.accessToken) {
    return false;
  }
  return TokenResponse.isTokenFresh(authState);
};

const getAuthConfig = (provider?: string): AuthRequestConfig => {
  if (provider === 'google.com') {
    return authRequestConfigGoogle;
  }
  if (provider === 'facebook.com') {
    return authRequestConfigFacebook;
  }
  if (provider === 'apple.com') {
    return authRequestConfigApple;
  }
  return authRequestConfig;
};

const convertBufferToString = (buffer: Uint8Array): string => {
  const state: string[] = [];
  for (let i = 0; i < buffer.byteLength; i += 1) {
    const index = buffer[i] % CHARSET.length;
    state.push(CHARSET[index]);
  }
  return state.join('');
};

/**
 * Same logic in PKCE patched for async calling method
 * @param input
 */
const getRandomValuesAsync = async (input: Uint8Array): Promise<Uint8Array> => {
  const output = input;
  // Get access to the underlying raw bytes
  if (input.byteLength !== input.length) input = new Uint8Array(input.buffer);

  const bytes = await Crypto.getRandomBytesAsync(input.length);

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < bytes.length; i++) {
    input[i] = bytes[i];
  }

  return output;
};

const getState = async () => {
  const buffer = new Uint8Array(10);
  await getRandomValuesAsync(buffer);
  return convertBufferToString(buffer);
};

/**
 * Reducer function for login the user.
 */
export const loginUser = createAsyncThunk('authentication/loginUser', async (provider?: DomainHintList) => {
  Logger.info(`Reducer -> authentication -> loginUser: Start a new login request`);

  const authRequestConfig = getAuthConfig(provider);
  authRequestConfig.state = await getState();

  try {
    const authRequest = new AuthRequest(authRequestConfig);

    // obtain auth code
    const authCodeResult: AuthSessionResult = await authRequest.promptAsync(discovery, {
      windowName: '_self',
    });

    /**
     * - If the user cancelled the authentication session by closing the browser, the result is { type: 'cancel' }.
     * - If the authentication is dismissed manually with AuthSession.dismiss(), the result is { type: 'dismiss' }.
     * - If the authentication flow is successful, the result is {type: 'success', params: Object, event: Object }
     * - If the authentication flow is returns an error, the result is {type: 'error', params: Object, errorCode: string, event: Object }
     * - If you call AuthSession.startAsync more than once before the first call has returned, the result is {type: 'locked'}, because only one AuthSession can be in progress at any time.
     */

    // throw error if the login process was interrupted or abandoned
    if (authCodeResult.type === 'cancel' || authCodeResult.type === 'dismiss' || authCodeResult.type === 'locked') {
      Logger.warn('Reducer -> authentication -> loginUser: Login request was cancelled, dismissed, or locked');
      return null;
    }

    // throw error if the login process encountered technical errors
    if (authCodeResult.type === 'error') {
      Logger.error('Reducer -> authentication -> loginUser: Error occurred during the authentication process');
      console.warn(authCodeResult);
      throw new Error('Error occurred during the authentication process');
    }

    // @ts-ignore
    const { code } = authCodeResult.params;

    const accessTokenRequestConfig: AccessTokenRequestConfig = {
      ...tokenRequestConfig,
      code: cleanAuthCode(code),
      redirectUri,
    };

    // obtain access token and refresh token
    const response: TokenResponse = await exchangeCodeAsync(accessTokenRequestConfig, discovery);
    const authState: AuthState = getAuthState(response);
    // Logger.debug(`Reducer -> authentication -> loginUser: new authState now: ${JSON.stringify(authState)}`);

    return authState;
  } catch (e) {
    Logger.error(`Reducer -> authentication -> loginUser: Error occurred in login`, e);
    throw e;
  }
});

/**
 * Will refresh the token if required
 */
export const getAuthenticatedUser = createAsyncThunk('authentication/getAuthenticatedUser', async (param, thunkAPI) => {
  Logger.info(`Reducer -> authentication -> getAuthenticatedUser: Getting cached user token`);

  const state: RootState = thunkAPI.getState();
  const { authState } = state.authentication;
  // If no auth state is present, just quit the function
  if (!authState) {
    Logger.debug(`Reducer -> authentication -> getAuthenticatedUser: No auth state is present. Exit function.`);
    return null;
  }

  // If current token is valid, just quit the function and no need to refresh
  if (TokenResponse.isTokenFresh(authState)) {
    /*
    Logger.debug(
      `Reducer -> authentication -> getAuthenticatedUser: Current access token is ${JSON.stringify(
        authState
      )}. Exit function.`
    );
    */
    Logger.debug(`Reducer -> authentication -> getAuthenticatedUser: Current access token is fresh.`);
    return null;
  }

  // Refresh the token
  Logger.debug(
    `Reducer -> authentication -> getAuthenticatedUser: Current access token is expiring soon. Trying to refresh the token now.`
  );
  const refreshTokenRequestConfig: RefreshTokenRequestConfig = {
    ...tokenRequestConfig,
    refreshToken: authState.refreshToken,
  };
  const response: TokenResponse = await refreshAsync(refreshTokenRequestConfig, discovery);
  const newAuthState: AuthState = getAuthState(response);
  // Logger.debug(`Reducer -> authentication -> getAuthenticatedUser: New authState is ${JSON.stringify(newAuthState)}`);
  if (!newAuthState.expiresIn) {
    Logger.debug(`Reducer -> authentication -> getAuthenticatedUser: refreshed authState, no expiresIn.`);
  } else {
    Logger.debug(
      `Reducer -> authentication -> getAuthenticatedUser: refreshed authState. New expiry date: ${DateTime.fromSeconds(
        newAuthState.issuedAt + newAuthState.expiresIn
      ).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}`
    );
  }

  return newAuthState;
});

const isPendingAction = (action: AnyAction): action is PendingAction =>
  action.type.startsWith('/authentication') && action.type.endsWith('/pending');
const isRejectedAction = (action: AnyAction): action is RejectedAction<any, any> =>
  action.type.startsWith('/authentication') && action.type.endsWith('/rejected');

const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    setAuthState(state, action: PayloadAction<AuthState>) {
      Logger.debug(`Reducer -> authentication -> setAuthState: Setting setAuthState ${action.payload}`);
      state.authState = action.payload;
    },
    clearAuthState(state) {
      Logger.debug(`Reducer -> authentication -> setAuthState: Clearing auth state`);
      const api = API.getInstance();
      api.clearToken();
      state.authState = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.fulfilled, (state, action) => {
        const authState = action.payload;
        if (authState) {
          state.authState = authState;
        }
        state.loading = false;
        state.error = null;
      })
      .addCase(getAuthenticatedUser.fulfilled, (state, action) => {
        const authState = action.payload;
        if (authState) {
          state.authState = authState;
        }
        state.loading = false;
        state.error = null;
      })
      .addCase(purge, (state) => {
        Logger.debug(`Reducer -> authentication: Purge request received.`);
        const api = API.getInstance();
        api.clearToken();
        state.authState = null;
        state.lastLogin = null;
        state.loading = false;
        state.error = null;
      })
      .addMatcher(isPendingAction, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addMatcher(isRejectedAction, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
  },
});
export const { setAuthState, clearAuthState } = authenticationSlice.actions;

export default authenticationSlice.reducer;
