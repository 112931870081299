import React from 'react';
import { View, StyleSheet, Pressable, ViewStyle, StyleProp } from 'react-native';
import { AntDesign } from '@expo/vector-icons';

import Modal from 'react-native-modal';
import { Colors } from '../constants/Colors';

interface ModalUIProps {
  toggle: boolean;
  closeModal: () => void;
  children: any;
  contentContainerStyle?: StyleProp<ViewStyle>;
}

const ModalUI = ({ toggle, closeModal, children, contentContainerStyle }: ModalUIProps) => (
  <Modal
    style={styles.modalContainer}
    isVisible={toggle}
    onBackdropPress={closeModal}
    onSwipeComplete={closeModal}
    swipeDirection="down"
  >
    <View style={[styles.mainModal, contentContainerStyle]}>
      <Pressable style={styles.close} onPress={closeModal}>
        <AntDesign name="close" size={24} color={Colors.black} />
      </Pressable>
      {children}
    </View>
  </Modal>
);
export default ModalUI;

const styles = StyleSheet.create({
  modalContainer: {},
  close: {
    alignSelf: 'flex-end',
    marginBottom: 15,
  },
  mainModal: {
    backgroundColor: Colors.white,
    borderRadius: 13,
    paddingVertical: 15,
    paddingHorizontal: 20,
  },
});
