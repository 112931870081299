import React from 'react';
import { View, Image, FlatList } from 'react-native';
import { UIText } from '../../../components/StyledText';
import { Colors } from '../../../constants/Colors';
import { commonStyles } from '../../CommonStyles';
import { CreateResponsiveStyle, DEVICE_SIZES, useDeviceSize } from 'rn-responsive-styles';
import { Hoverable } from 'react-native-web-hover';

const ActiveMarae = () => {
  const styles = useStyles();
  const deviceSize = useDeviceSize();
  const data = [
    {
      id: 1,
      image: require('../../../../assets/images/Erepeti-Marae.jpg'),
      name: 'Erepeti Marae',
      region: 'Ruakituri Valley',
      points: 13505,
      year: '2018 - 2019',
    },
    {
      id: 2,
      image: require('../../../../assets/images/Manutai-Marae.jpg'),
      name: 'Manutai Marae',
      region: 'Tahaenui Nuhaka',
      points: 8650,
      year: '2017 - 2018',
    },
    {
      id: 3,
      image: require('../../../../assets/images/Kohupatiki-Marae.png'),
      name: 'Kohupatiki Marae',
      region: 'Kohupātiki Road',
      points: 12210,
      year: '2016 - 2017',
    },
    {
      id: 4,
      image: require('../../../../assets/images/Pakowhai-Marae.jpg'),
      name: 'Pakowhai Marae',
      region: 'Waituhi',
      points: 1180,
      year: '2013 - 2014',
    },
  ];

  const selectedItemId = -1;

  const renderItem = ({ item }) => (
    <Hoverable>
      {({ hovered }) => (
        <View style={styles.renderContainer}>
          <View style={styles.imageContainer}>
            <Image source={item.image} style={styles.maraeImage} />
          </View>

          <View
            style={
              hovered
                ? [styles.cardContainer, { borderWidth: 6, borderColor: Colors.black, opacity: 1 }]
                : [styles.cardContainer]
            }
          >
            <UIText style={commonStyles.titleLabel}>{item.name}</UIText>
            <UIText style={[commonStyles.contentText, { marginBottom: 30 }]}>{item.region}</UIText>
            <View
              style={
                hovered
                  ? { backgroundColor: Colors.black, width: '92%', height: 1 }
                  : { backgroundColor: Colors.backgroundYellow, width: '92%', height: 1 }
              }
            >
              {}
            </View>
            <View style={!hovered ? styles.trophieContainer : [styles.trophieContainer, { bottom: 112 }]}>
              <Image source={require('../../../../assets/images/icon-ellipse.png')} style={styles.trophieIcon} />
            </View>
            <View
              style={{
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-around',
                position: 'absolute',
                bottom: 80,
              }}
            >
              <View>
                <UIText style={{ marginBottom: 5 }}>Year</UIText>
                <UIText style={commonStyles.subtitleLabel}>
                  <strong>{item.year}</strong>
                </UIText>
              </View>
              <View>
                <UIText style={{ marginBottom: 5 }}>Points</UIText>
                <UIText style={commonStyles.subtitleLabel}>
                  <strong>{item.points}</strong>
                </UIText>
              </View>
            </View>
          </View>
        </View>
      )}
    </Hoverable>
  );

  const flatListGenerator = (deviceSize) => {
    if (!(deviceSize === 'small' || deviceSize === 'extrasmall')) {
      return (
        <FlatList
          bounces={false}
          data={data}
          extraData={selectedItemId}
          initialScrollIndex={0}
          renderItem={renderItem}
          scrollEnabled
          scrollEventThrottle={16}
          horizontal
          showsHorizontalScrollIndicator={false}
          overScrollMode="never"
          pagingEnabled
          keyExtractor={(item, index) => index.toString()}
          style={styles.flatListStyle}
        />
      );
    }

    if (!(deviceSize === 'extrasmall')) {
      return (
        <FlatList
          bounces={false}
          data={data}
          extraData={selectedItemId}
          initialScrollIndex={0}
          renderItem={renderItem}
          scrollEnabled
          scrollEventThrottle={16}
          showsHorizontalScrollIndicator={false}
          overScrollMode="never"
          pagingEnabled
          keyExtractor={(item, index) => index.toString()}
          style={styles.flatListStyle}
          numColumns={Math.ceil(data.length / 2)}
          key={Math.ceil(data.length / 2)}
          contentContainerStyle={{ alignItems: 'center', justifyContent: 'center' }}
        />
      );
    }

    return (
      <FlatList
        bounces={false}
        data={data}
        extraData={selectedItemId}
        initialScrollIndex={0}
        renderItem={renderItem}
        scrollEnabled
        scrollEventThrottle={16}
        showsHorizontalScrollIndicator={false}
        overScrollMode="never"
        pagingEnabled
        keyExtractor={(item, index) => index.toString()}
        style={styles.flatListStyle}
        numColumns={Math.ceil(data.length / 4)}
        key={Math.ceil(data.length / 4)}
      />
    );
  };

  return (
    <View style={[commonStyles.yellowContainer, { alignItems: 'center' }]}>
      <Image source={require('../../../../assets/images/podiumIcon.png')} style={styles.sectionImage} />
      <UIText style={[commonStyles.titleLabel, styles.titleText]}>Track your points on the MaraeFit Leaderboard</UIText>
      <UIText style={[commonStyles.contentText, styles.paraGraphText]}>
        “Everyone who participates in a MaraeFit kaupapa can koha their points to a chosen marae. The marae with the
        most points at the end of the annual series is recognised and acknowledged. Check out past winners!”
      </UIText>

      {flatListGenerator(deviceSize)}
    </View>
  );
};

export default ActiveMarae;

const useStyles = CreateResponsiveStyle(
  {
    sectionImage: {
      width: '50%',
      height: 128,
      resizeMode: 'contain',
      alignSelf: 'center',
      marginBottom: 50,
    },
    imageContainer: {
      width: 129,
      height: 129,
      borderRadius: 129,
      backgroundColor: Colors.transparent,
      justifyContent: 'center',
      alignContent: 'center',
      padding: 3,
      top: 55,
      zIndex: 1,
    },
    renderContainer: {
      alignItems: 'center',
      justifyContent: 'center',
      alignSelf: 'center',
      marginHorizontal: 25,
    },
    titleText: {
      fontSize: 36,
      marginBottom: 50,
    },
    paraGraphText: {
      fontSize: 24,
      paddingHorizontal: '22%',
      lineHeight: 38,
    },
    maraeImage: {
      height: '100%',
      width: '100%',
      borderRadius: 70,
      alignSelf: 'center',
    },
    flatListStyle: {},
    cardContainer: {
      backgroundColor: Colors.white,
      opacity: 0.7,
      alignItems: 'center',
      justifyContent: 'center',
      height: 340,
      width: 300,
      paddingTop: 30,
      paddingHorizontal: 20,
      paddingBottom: 80,
      borderRadius: 5,
    },
    trophieContainer: {
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      bottom: 118,
    },
    trophieIcon: {
      height: 50,
      width: 50,
    },
  },
  {
    [DEVICE_SIZES.SM]: {
      titleText: {
        fontSize: 26,
        lineHeight: 43,
      },
      paraGraphText: {
        fontSize: 18,
        lineHeight: 26,
        paddingHorizontal: '5%',
      },
      renderContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        width: '50%',
        marginHorizontal: -20,
        transform: [{ scale: 0.8 }],
      },
      flatListStyle: {
        width: '100%',
      },
    },
    [DEVICE_SIZES.XS]: {
      sectionImage: { width: 70, height: 70, marginTop: 30 },
      titleText: {
        fontSize: 20,
        lineHeight: 26,
        marginBottom: 40,
      },
      paraGraphText: {
        fontSize: 16,
        lineHeight: 24,
        paddingHorizontal: '10%',
      },
      renderContainer: {
        transform: [{ scale: 1 }],
        alignItems: 'center',
      },
      cardContainer: {
        marginRight: 0,
        alignItems: 'center',
      },
      flatListStyle: {
        width: '100%',
      },
    },
  }
);
