import React, { forwardRef, useImperativeHandle, useState } from 'react';
import {
  StyleProp,
  StyleSheet,
  Keyboard,
  TextInput,
  TouchableHighlight,
  TouchableWithoutFeedback,
  ViewStyle,
} from 'react-native';
import { View } from '../Themed';
import Logger from '../../constants/Logger';
import { UIText } from '../StyledText';
import { Colors } from '../../constants/Colors';
import Layout from '../../constants/Layout';
import { MaterialIcons } from '@expo/vector-icons';
import Fonts from '../../constants/Fonts';
import { DropdownItem } from '../../constants/Interfaces';
import i18n from '../../translations';
import _ from 'lodash';
import { matchWild } from '../../constants/Utils';

export interface PickerInputProps {
  containerStyle?: StyleProp<ViewStyle>;
  inputContainerStyle?: StyleProp<ViewStyle>;
  inputStyle?: StyleProp<ViewStyle>;
  dropdownIconStyle?: StyleProp<ViewStyle>;
  dropdownStyle?: StyleProp<ViewStyle>;
  itemStyle?: StyleProp<ViewStyle>;
  placeholderStyle?: StyleProp<ViewStyle>;
  placeholder?: string;
  data: DropdownItem[];
  value?: string | number;
  maxItems?: number;
  sortEnabled?: boolean;
  searchEnabled?: boolean;
  onSelect: (value: any) => void;
  onActive?: () => void;
  disabled?: boolean;
}

export const PickerInput = forwardRef((props: PickerInputProps, ref) => {
  const {
    containerStyle,
    inputContainerStyle,
    inputStyle,
    dropdownIconStyle,
    dropdownStyle,
    itemStyle,
    placeholderStyle,
    placeholder,
    data,
    value,
    maxItems = 5,
    sortEnabled = false,
    searchEnabled = false,
    onSelect,
    onActive,
    disabled,
  } = props;
  const [active, setActive] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>();
  useImperativeHandle(ref, () => ({
    hide: () => {
      setActive(false);
    },
  }));

  const toggleDropdown = () => {
    if (!disabled) {
      if (active) {
        setSearchQuery(undefined);
      }
      if (!active && onActive) {
        onActive();
      }
      setActive(!active);
      Keyboard.dismiss();
    }
  };

  const renderItems = (items: DropdownItem[]) => (
    <View>
      {items.map((item: DropdownItem, index) => (
        <TouchableHighlight
          key={index.toString()}
          style={[styles.dropdownItem, itemStyle]}
          underlayColor={Colors.grey}
          onPress={() => {
            setActive(false);
            Logger.debug(`Setting value to: ${item.label}`);
            onSelect(item.value);
          }}
        >
          <UIText>{item.label}</UIText>
        </TouchableHighlight>
      ))}
    </View>
  );

  const getItems = (data: DropdownItem[], maxItems: number, searchQuery?: string, sortEnabled?: boolean) => {
    let results: DropdownItem[] = data;
    if (searchQuery) {
      results = _.filter(data, (item) => matchWild(item.label, searchQuery));
    }
    if (sortEnabled) {
      results = _.sortBy(results, ['label']);
    }
    results = _.slice(results, 0, maxItems);
    return results;
  };

  const getLabelForValue = (value: string | number) => {
    const dropdownItem: DropdownItem | undefined = _.find(data, ['value', value]);
    if (dropdownItem) {
      return dropdownItem.label;
    }
    return '';
  };

  return (
    <>
      <View transparent style={[styles.container, containerStyle]}>
        <TouchableWithoutFeedback
          onPress={() => {
            if (searchEnabled && active) {
              // Do not toggle if search is enabled and active
              return;
            }
            toggleDropdown();
          }}
        >
          <View style={[styles.inputContainer, inputContainerStyle]}>
            {active && searchEnabled && !disabled ? (
              <TextInput
                onChangeText={(text) => setSearchQuery(text)}
                placeholder={i18n.t('components.PickerInput.searchPlaceholder')}
                value={searchQuery}
                style={[
                  styles.inputInnerContainer,
                  styles.searchInput,
                  { color: !disabled ? Colors.black : Colors.grey },
                ]}
              />
            ) : (
              <View style={[styles.inputInnerContainer, inputStyle]}>
                <UIText
                  style={[styles.placeholder, placeholderStyle, { color: !disabled ? Colors.black : Colors.grey }]}
                >
                  {value ? getLabelForValue(value) : placeholder}
                </UIText>
              </View>
            )}
            {!disabled ? (
              <TouchableWithoutFeedback onPress={toggleDropdown}>
                <View style={[styles.dropdownIconContainer, dropdownIconStyle]}>
                  <MaterialIcons
                    name="arrow-drop-down"
                    size={Fonts.sizes.bigHeading}
                    color={Colors.black}
                    style={styles.dropdownIcon}
                  />
                </View>
              </TouchableWithoutFeedback>
            ) : null}
          </View>
        </TouchableWithoutFeedback>
        <View transparent style={styles.errorMessageContainer} />
      </View>
      {active ? (
        <View style={[styles.dropdownContainer, dropdownStyle]}>
          {renderItems(getItems(data, maxItems, searchQuery, sortEnabled))}
        </View>
      ) : null}
    </>
  );
});

const styles = StyleSheet.create({
  container: {
    zIndex: 1,
  },
  inputContainer: {
    backgroundColor: Colors.white,
    height: 60,
    borderRadius: 28,
    overflow: 'hidden',
    justifyContent: 'center',
  },
  inputInnerContainer: {
    padding: Layout.backgroundMarginNormal.left,
    paddingRight: 0,
    height: '100%',
    backgroundColor: Colors.transparent,
    justifyContent: 'center',
    overflow: 'hidden',
  },
  dropdownContainer: {
    position: 'absolute',
    // left: Layout.backgroundMarginNormal.left,
    backgroundColor: Colors.white,
    zIndex: 30,
    elevation: 5,
    shadowColor: Colors.grey,
    shadowOpacity: 0.5,
    shadowOffset: { width: 2, height: 2 },
    shadowRadius: 2,
    width: '100%',
  },
  dropdownItem: {
    height: 50,
    paddingHorizontal: Layout.backgroundMarginNormal.left,
    justifyContent: 'center',
  },
  placeholder: {
    paddingRight: 12,
  },
  searchInput: {
    fontFamily: Fonts.family.regular,
    fontSize: Fonts.sizes.default,
  },
  dropdownIconContainer: {
    position: 'absolute',
    right: 0,
    top: 0,
    width: 60,
    height: 60,
    backgroundColor: Colors.white,
    justifyContent: 'center',
    alignItems: 'center',
  },
  dropdownIcon: {},
  errorMessageContainer: {
    height: 12,
  },
});
