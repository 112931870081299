import React from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import { UIText } from './StyledText';
import { Colors } from '../constants/Colors';
import Fonts from '../constants/Fonts';

interface HeadingItemProps {
  title: string;
  count?: number;
  showCount?: boolean;
  selected: boolean;
  onSelect: () => void;
}

export const HeadingItem = (props: HeadingItemProps) => {
  const { title, count, showCount = false, selected = false, onSelect } = props;
  return (
    <Pressable onPress={onSelect} style={styles.container}>
      <UIText
        style={{
          color: selected ? Colors.yellow : Colors.white,
          fontFamily: selected ? Fonts.family.bold : Fonts.family.regular,
        }}
      >
        {title}
      </UIText>
      {showCount ? (
        <View
          style={[
            styles.countContainer,
            { backgroundColor: selected ? Colors.yellow : Colors.white },
            { opacity: selected ? 1 : 0.3 },
          ]}
        >
          <UIText style={styles.count}>{count}</UIText>
        </View>
      ) : null}
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.transparent,
    height: 36,
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingHorizontal: 8,
  },
  countContainer: {
    position: 'absolute',
    top: 0,
    right: 4,
    height: 14,
    width: 14,
    borderRadius: 12,
    backgroundColor: Colors.white,
    alignItems: 'center',
    justifyContent: 'center',
    margin: 0,
    padding: 0,
  },
  count: {
    fontSize: 10,
    fontFamily: Fonts.family.bold,
    color: Colors.burgundy,
  },
});
