import React from 'react';
import { View, StyleSheet } from 'react-native';
import Fonts from '../../../constants/Fonts';
import Layout from '../../../constants/Layout';
import RatingReview from '../../../components/RatingStars';
import { DetailItem } from '../../../components/DetailItem';
import i18n from '../../../translations';
import { Paragraph, UIText } from '../../../components/StyledText';
import { ActivityStats, Location, Review } from '../../../constants/Interfaces';

interface ShowReviewFragmentProps {
  review: Review;
  location?: Location;
  activityStats: ActivityStats | undefined;
}

const ShowReviewFragment = ({ review, location, activityStats }: ShowReviewFragmentProps) => (
  <View style={styles.root}>
    <View style={styles.reviewContainer}>
      <RatingReview stars={review.score!} starsColor="red" />
      <UIText style={styles.label}>{i18n.t('components.ShowReview.yourReview')}</UIText>
      <Paragraph style={styles.reviewMessage}>{review.review!}</Paragraph>
    </View>
    <DetailItem
      label={i18n.t('components.ShowReview.ihaere')}
      description={i18n.t('pages.ActivityDetailsScreen.whanauattended', {
        count: activityStats ? activityStats.engagementTypeCheckedIn.personTotal : 0,
      })}
    />
    <DetailItem
      label={i18n.t('components.ShowReview.address')}
      description={location ? `${location?.addressLine1}, ${location?.city}` : 'Location not especified'}
    />
  </View>
);

export default ShowReviewFragment;

const styles = StyleSheet.create({
  root: {
    width: Layout.window.width - Layout.backgroundMarginNormal.left * 2,
    marginTop: 20,
  },
  reviewContainer: {
    marginHorizontal: Layout.backgroundMarginNormal.left,
  },
  label: {
    marginTop: 10,
    fontFamily: Fonts.family.bold,
  },
  reviewMessage: {
    marginBottom: 15,
  },
});
