import * as React from 'react';
import { Image, ImageBackground, StyleSheet } from 'react-native';
import HeaderBar from '../../components/HeaderBar';
import { View } from '../../components/Themed';
import { HeadingItem } from '../../components/HeadingItem';
import i18n from '../../translations';
import Layout from '../../constants/Layout';
import MyMaraeFragment from './fragments/MyMarae/MyMaraeFragment';
import { useSelector } from 'react-redux';
import { User } from '../../constants/Interfaces';
import { Colors } from '../../constants/Colors';
import BottomBanner from '../../components/BottomBanner';
import { ProfileFragment } from './fragments/Profile/ProfileFragment';
import MyWhanauFragment from './fragments/MyWhanau/MyWhanauFragment';
import LanguageFragment from './fragments/Language/LanguageFragment';
import useLogoutUser from '../../hooks/useLogoutUser';

export const ProfileScreen = ({ navigation, route }) => {
  const [index, setIndex] = React.useState<number>(0);
  const user: User = useSelector((state: any) => state.user.user);
  const whanau: User[] = useSelector((state: any) => state.user.whanau);
  const [tip, setTip] = React.useState<string | undefined>(undefined);
  const [hideFooter, setHideFooter] = React.useState<boolean>(false);

  useLogoutUser();
  const styles = StyleSheet.create({
    root: {
      flex: 1,
      alignItems: 'center',
      backgroundColor: hideFooter ? Colors.white : Colors.darkBurgundy,
      width: Layout.window.width,
      height: Layout.window.height,
    },
    header: {
      width: '100%',
      height: 100,
      backgroundColor: Colors.yellow,
    },
    headingContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-around',
      paddingHorizontal: 25,
      paddingBottom: 8,
      width: Layout.window.width,
    },
    body: {
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: Colors.darkBurgundy,
    },
    content: {
      backgroundColor: Colors.darkBurgundy,
    },
    divider: {
      paddingTop: Layout.backgroundMarginNormal.top,
      width: Layout.window.width - 2 * Layout.backgroundMarginNormal.left,
      height: 12,
      resizeMode: 'contain',
      opacity: 0.2,
    },
    imageBackground: {
      top: 50,
      height: 50,
      width: '100%',
      resizeMode: 'cover',
    },
    headerBar: {
      width: '100%',
      position: 'absolute',
      maxHeight: Layout.window.height,
      minHeight: 80,
      zIndex: 10,
    },
    footerContainer: {
      backgroundColor: 'red',
      height: 150,
      bottom: Layout.isSmallDevice || !Layout.isLongDevice ? -10 : 150,
      position: 'absolute',
      zIndex: 100,
    },
  });

  React.useEffect(() => {
    if (route?.params?.index) setIndex(route?.params?.index);
  }, [route?.params?.index]);

  const getFragment = () => {
    switch (index) {
      case 0: {
        return <ProfileFragment hideFooter={setHideFooter} />;
      }
      case 1: {
        return <MyMaraeFragment navigation={navigation} setTip={setTip} />;
      }
      case 2: {
        return <MyWhanauFragment activityId={route?.params?.activityId} hideFooter={setHideFooter} />;
      }
      case 3: {
        return <LanguageFragment />;
      }

      default: {
        return null;
      }
    }
  };
  const getTip = () => {
    if (index === 0) setTip(undefined);
    if (index === 2) setTip('myWhanau');
    if (index === 3) setTip('language');
  };
  React.useEffect(() => {
    getTip();
  }, [index]);

  return (
    <View style={styles.root}>
      <View style={styles.header}>
        <ImageBackground
          source={require('../../../assets/images/profile_header.png')}
          style={[styles.imageBackground]}
        />
      </View>
      <View style={styles.headerBar} transparent>
        <HeaderBar navigation={navigation} smallProfile />
      </View>
      <View style={styles.body}>
        <View transparent style={styles.headingContainer}>
          <HeadingItem
            title={i18n.t('pages.ProfileScreen.profile')}
            selected={index === 0}
            onSelect={() => {
              setIndex(0);
            }}
          />
          <HeadingItem
            title={i18n.t('pages.ProfileScreen.mymarae')}
            selected={index === 1}
            showCount
            count={!user ? 0 : user?.marae?.length}
            onSelect={() => {
              setIndex(1);
            }}
          />
          <HeadingItem
            title={i18n.t('pages.ProfileScreen.mywhanau')}
            selected={index === 2}
            showCount
            count={whanau?.length}
            onSelect={() => {
              setIndex(2);
            }}
          />
          <HeadingItem
            title={i18n.t('pages.ProfileScreen.language')}
            selected={index === 3}
            onSelect={() => {
              setIndex(3);
            }}
          />
        </View>
        <Image source={require('../../../assets/images/divider.png')} style={styles.divider} />
      </View>
      <View style={styles.content}>{getFragment()}</View>
      {!hideFooter && <BottomBanner themeColor="white" tip={tip} />}
    </View>
  );
};
