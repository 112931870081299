import * as React from 'react';
import { Image, Pressable } from 'react-native';
import { UIText } from '../components/StyledText';
import { Colors } from '../constants/Colors';
import Fonts from '../constants/Fonts';
import { Activity } from '../constants/Interfaces';
import { convertToNZ } from '../constants/Utils';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';

interface ActivityTileProps {
  activity: Activity;
  onPress: (activityId: string) => void;
}

export const ActivityTile = ({ activity, onPress }: ActivityTileProps) => {
  const styles = useStyles();
  const handlePress = () => {
    onPress(activity.activityId);
  };

  const startDate = convertToNZ({ date: activity?.startDate!, format: 'dd MMMM yyyy' });
  const endDate = convertToNZ({ date: activity?.endDate!, format: 'dd MMMM yyyy' });

  return (
    <Pressable style={[styles.container]} onPress={handlePress} disabled={!activity?.activityName}>
      {activity && <Image source={{ uri: activity?.imageUrl }} style={[styles.coverImage]} />}
      <UIText style={[styles.activityName]}>{activity.activityName}</UIText>
      <UIText numberOfLines={1} style={[styles.addressLabel]}>
        {activity.location?.addressLine1}
      </UIText>
      {startDate !== 'Invalid DateTime' && (
        <UIText style={[styles.addressLabel]}>
          {startDate} - {endDate}
        </UIText>
      )}
    </Pressable>
  );
};

const useStyles = CreateResponsiveStyle(
  {
    container: {
      color: Colors.black,
      backgroundColor: Colors.white,
      flexBasis: '30%',
      maxWidth: 530,
      marginTop: 30,
      marginBottom: 30,
    },
    coverImage: {
      alignSelf: 'center',
      resizeMode: 'cover',
      width: '100%',
      aspectRatio: 1,
      marginBottom: 12,
    },
    addressLabel: {
      fontSize: 16,
      lineHeight: 22,
      color: Colors.grey,
    },
    activityName: {
      fontFamily: Fonts.family.bold,
      marginBottom: 8,
      marginTop: 18,
      overflow: 'hidden',
      fontSize: 20,
      lineHeight: 26,
    },
  },
  {
    [DEVICE_SIZES.LG]: {},
    [DEVICE_SIZES.MD]: {
      activityName: {
        fontSize: 18,
        lineHeight: 24,
      },
    },
    [DEVICE_SIZES.SM]: {
      activityName: {
        fontSize: 17,
        lineHeight: 24,
        marginTop: 10,
      },
    },
    [DEVICE_SIZES.XS]: {
      container: {
        flexBasis: '100%',
        marginTop: 5,
        backgroundColor: Colors.backgroundYellow,
      },
    },
  }
);
