import { AntDesign } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { Linking, View, StyleSheet, Pressable, Image, useWindowDimensions, Text } from 'react-native';
import { UIText } from '../components/StyledText';
import { Colors } from '../constants/Colors';
import Fonts from '../constants/Fonts';
import Constants from 'expo-constants';

const { iosStoreId, androidStoreId } = Constants?.expoConfig?.extra?.store;
const appName = Constants.manifest?.name;
const formattedName = appName?.replaceAll(' MaraeFit', '');

const HamburgerMenu = ({ handleClose }) => {
  const { styles } = useStyle();
  const navigation = useNavigation();
  const handleHome = () => {
    handleClose();
    navigation.navigate('LandingWeb');
  };
  const handleAbout = () => {
    handleClose();
    navigation.navigate('About');
  };
  const handleActivities = () => {
    handleClose();
    navigation.navigate('ActivitiesListing');
  };
  const handlePrivacy = () => {
    handleClose();
    navigation.navigate('Privacy');
  };
  const handleTerms = () => {
    handleClose();
    navigation.navigate('Terms');
  };
  const handleDelete = () => {
    handleClose();
    navigation.navigate('Delete');
  };

  const [language, setLanguage] = useState<string | null>(localStorage.getItem('MaraeFitLanguage') || 'en');
  const changeLanguage = () => {
    setLanguage(localStorage.getItem('MaraeFitLanguage'));
  };
  useEffect(() => {
    window.addEventListener('storage', changeLanguage);
    return () => {
      window.removeEventListener('storage', changeLanguage);
    };
  }, []);
  const handleLanguage = () => {
    if (language === 'en') {
      localStorage.setItem('MaraeFitLanguage', 'mi');
      window.dispatchEvent(new Event('storage'));
    } else {
      localStorage.setItem('MaraeFitLanguage', 'en');
      window.dispatchEvent(new Event('storage'));
    }
    handleClose();
  };

  const handleStoreDownloadIOS = () => {
    Linking.openURL(iosStoreId);
  };

  const handleStoreDownloadAndroid = () => {
    Linking.openURL(androidStoreId);
  };

  return (
    <View style={styles.root}>
      <Pressable style={styles.close} onPress={handleClose}>
        <AntDesign name="close" size={24} color={Colors.black} />
      </Pressable>

      <View style={styles.optionsContainer}>
        <Pressable style={styles.optionBox} onPress={handleHome}>
          <UIText style={styles.optionLabel}>Home</UIText>
        </Pressable>
        <Pressable style={styles.optionBox} onPress={handleAbout}>
          <UIText style={styles.optionLabel}>About</UIText>
        </Pressable>
        <Pressable style={styles.optionBox} onPress={handleActivities}>
          <UIText style={styles.optionLabel}>Kaupapa</UIText>
        </Pressable>
        <Pressable style={styles.optionBox} onPress={handlePrivacy}>
          <UIText style={styles.optionLabel}>Privacy</UIText>
        </Pressable>
        <Pressable style={styles.optionBox} onPress={handleTerms}>
          <UIText style={styles.optionLabel}>Terms and Conditions</UIText>
        </Pressable>
        <Pressable style={styles.optionBox} onPress={handleDelete}>
          <UIText style={styles.optionLabel}>Delete Your Account</UIText>
        </Pressable>
        <Pressable style={[styles.optionBox, { display: 'none' }]} onPress={handleLanguage}>
          <UIText style={styles.optionLabel}>{language === 'en' ? 'Maori' : 'English'}</UIText>
        </Pressable>
      </View>
      <View style={styles.storeContainer}>
        <Text style={styles.versionNumber}>{`${formattedName}${Constants.manifest?.version}`}</Text>
        <UIText style={styles.downloadLabel}>
          Maraefit is available for download on both the Google Play and App Store
        </UIText>
        <Pressable onPress={handleStoreDownloadAndroid}>
          <Image source={require('../../assets/images/googlePlay.png')} style={styles.store} />
        </Pressable>
        <Pressable onPress={handleStoreDownloadIOS}>
          <Image source={require('../../assets/images/appStore.png')} style={styles.store} />
        </Pressable>
      </View>
    </View>
  );
};

export default HamburgerMenu;

const useStyle = () => {
  const dimensions = useWindowDimensions();

  const styles = StyleSheet.create({
    root: {
      backgroundColor: Colors.white,
      height: dimensions.height,
      position: 'absolute',
      top: 0,
      zIndex: 100,
      right: 0,
      width: '90%',
      maxWidth: 540,
      padding: '5%',
      alignItems: 'center',
    },
    close: {
      position: 'absolute',
      top: 20,
      right: '5%',
    },
    optionsContainer: {
      marginTop: '10%',
      width: '100%',
    },
    optionBox: {
      marginBottom: 10,
      borderBottomWidth: 2,
      paddingBottom: 5,
    },
    optionLabel: {
      fontFamily: Fonts.family.bold,
      color: Colors.black,
    },
    downloadLabel: {
      fontSize: Fonts.sizes.small,
      flexWrap: 'wrap',
      marginVertical: 10,
    },
    storeContainer: {
      width: '90%',
      position: 'absolute',
      bottom: '3%',
    },
    store: {
      width: '45%',
      height: 40,
      resizeMode: 'contain',
      marginBottom: 5,
    },
    versionNumber: {
      position: 'absolute',
      bottom: 5,
      right: 5,
      color: Colors.defaultGrey,
      fontSize: Fonts.sizes.small,
    },
  });

  return { styles };
};
