import * as React from 'react';
import { NavigationContainer, DefaultTheme, DarkTheme } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { ColorSchemeName, Linking, Platform } from 'react-native';
import Toast from 'react-native-toast-message';
import { AuthState, Scope } from '../constants/Interfaces';
import AuthNavigator from './AuthNavigator';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthenticatedUser, isAccessTokenValid, setAuthState } from '../reducers/authentication.slice';
import { navigationRef } from './NavigationService';
import API from '../constants/API';
import SignUpNavigator from './SignUpNavigator';
import DrawerNavigation from './DrawerNavigation';
import Logger from '../constants/Logger';
import LandingWeb from '../web/LandingWeb';
import ActivityDetailsWeb from '../web/ActivityDetailsWeb';
import ActivitiesListing from '../web/ActivitiesListing';
import Constants from 'expo-constants';
import About from '../web/About/About';
import Policy from '../web/Policy';
import Terms from '../web/Terms';
import Delete from '../web/Delete';
import SignIn from '../web/SignIn';
import AddWhanau from '../web/AddWhanau';
import { AccessTokenRequestConfig, AuthSessionResult, exchangeCodeAsync } from 'expo-auth-session';
import { discovery, redirectUri, tokenRequestConfig } from '../config/AuthConfig';
import { cleanAuthCode, getAuthResultFromUrl } from '../constants/Utils';
import GuestDrawerNavigation from './GuestDrawerNavigation';

const isWeb = Platform.OS === 'web';

const apiEndpoint = Constants.expoConfig?.extra?.azure.apiEndpoint;
const URL = apiEndpoint.slice(0, -3);
const linking = {
  prefixes: [URL, '192.168.0.20:19006'],
  config: {
    screens: {
      LandingWeb: '',
      ActivityDetails: 'activity/:id',
      About: 'about',
      Privacy: 'privacy',
      SignIn: 'sign-in',
      AddWhanau: 'addwhanau',
      Terms: 'terms',
      ActivitiesListing: 'activities',
      Delete: 'delete-account',
    },
  },
};
export default function Navigation({ colorScheme }: { colorScheme: ColorSchemeName }) {
  const dispatch = useDispatch();
  Linking.getInitialURL().then((url) => {
    if (url) {
      const urlParts = url.split('?');
      if (urlParts && urlParts.length !== 2) {
        return;
      }
      const authCodeResult: AuthSessionResult = getAuthResultFromUrl(url);
      Logger.debug(`authCodeResult: ${JSON.stringify(authCodeResult)}`);
      const { code } = authCodeResult.params;

      const accessTokenRequestConfig: AccessTokenRequestConfig = {
        ...tokenRequestConfig,
        code: cleanAuthCode(code),
        redirectUri,
      };
      // obtain access token and refresh token
      exchangeCodeAsync(accessTokenRequestConfig, discovery).then((result: AuthState) => {
        Logger.debug(`Result: ${JSON.stringify(result)}`);
        dispatch(setAuthState(result));
      });
    }
  });
  return (
    <NavigationContainer
      ref={navigationRef}
      theme={colorScheme === 'dark' ? DarkTheme : DefaultTheme}
      linking={linking}
    >
      <RootNavigator />
      <Toast ref={(ref) => Toast.setRef(ref)} />
    </NavigationContainer>
  );
}

// A root stack navigator is often used for displaying modals on top of all other content
// Read more here: https://reactnavigation.org/docs/modal
const Stack = createStackNavigator<any>();

function RootNavigator() {
  const authState: AuthState = useSelector((state: any) => state.authentication.authState);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!isWeb) {
      dispatch(getAuthenticatedUser());
      const api = API.getInstance();
      if (!authState || !authState.accessToken) {
        api.clearToken();
      }
      if (isAccessTokenValid(authState)) {
        api.setToken(Scope.API, authState);
      }
    }
  }, [authState]);

  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      {isWeb && (
        <>
          <Stack.Screen name="LandingWeb" component={LandingWeb} options={{ title: 'MaraeFit' }} />
          <Stack.Screen
            name="ActivityDetails"
            component={ActivityDetailsWeb}
            options={{ title: 'MaraeFit Kaupapa Details' }}
          />
          <Stack.Screen name="About" component={About} options={{ title: 'About MaraeFit' }} />
          <Stack.Screen name="SignIn" component={SignIn} />
          <Stack.Screen name="AddWhanau" component={AddWhanau} />
          <Stack.Screen name="Privacy" component={Policy} options={{ title: 'MaraeFit' }} />
          <Stack.Screen name="Terms" component={Terms} options={{ title: 'MaraeFit' }} />
          <Stack.Screen name="Delete" component={Delete} options={{ title: 'MaraeFit' }} />
          <Stack.Screen
            name="ActivitiesListing"
            component={ActivitiesListing}
            options={{ title: 'MaraeFit Activities' }}
          />
        </>
      )}

      <Stack.Screen name="AuthStack" component={AuthNavigator} />
      <Stack.Screen name="SignUpStack" component={SignUpNavigator} />
      <Stack.Screen name="Root" component={DrawerNavigation} options={{ gestureEnabled: false }} />
      <Stack.Screen name="Guest" component={GuestDrawerNavigation} options={{ gestureEnabled: false }} />
    </Stack.Navigator>
  );
}
