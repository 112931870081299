import React, { useState } from 'react';
import { StyleProp, StyleSheet, TextInput as BaseInput } from 'react-native';
import { Colors } from '../../constants/Colors';
import Layout from '../../constants/Layout';
import Fonts from '../../constants/Fonts';
import { TextProps, View, ViewProps } from '../Themed';
import { UIText } from '../StyledText';

export interface TextInputProps {
  containerStyle?: StyleProp<ViewProps>;
  inputContainerStyle?: StyleProp<ViewProps>;
  labelContainerStyle?: StyleProp<ViewProps>;
  errorContainerStyle?: StyleProp<ViewProps>;
  placeholder?: string;
  value?: string | null;
  label?: string | null;
  errorMessage?: string;
  disabled?: boolean;
  onChangeText: (text: string) => void;
  validator?: (text: string) => string | null;
  inputStyle?: StyleProp<TextProps>;
  errorStyle?: StyleProp<TextProps>;
  labelStyle?: StyleProp<TextProps>;
  placeholderTextColor?: string;
  onFocus?: () => void;
  keyboardType?: 'numeric';
}

export default function TextInput(props: TextInputProps) {
  const {
    containerStyle,
    inputContainerStyle,
    labelContainerStyle,
    errorContainerStyle,
    placeholder,
    value,
    label,
    errorMessage = '',
    disabled = false,
    onChangeText,
    validator,
    inputStyle,
    errorStyle,
    labelStyle,
    placeholderTextColor,
    onFocus,
    keyboardType = 'default',
  } = props;

  const [validatorErrorMessage, setValidatorErrorMessage] = useState<string>();

  const handleChangeText = (text: string) => {
    if (validator) {
      const result = validator(text);
      if (result) {
        setValidatorErrorMessage(result);
      } else {
        setValidatorErrorMessage(undefined);
      }
    }
    onChangeText(text);
  };

  return (
    <View transparent style={[containerStyle]}>
      <View style={[styles.inputContainer, inputContainerStyle]}>
        {label && value && value.length > 0 ? (
          <View transparent style={labelContainerStyle}>
            <UIText style={[styles.label, labelStyle]}>{label}</UIText>
          </View>
        ) : null}
        <BaseInput
          style={[styles.input, inputStyle]}
          value={value!}
          onFocus={onFocus}
          editable={!disabled}
          autoCapitalize="none"
          onChangeText={handleChangeText}
          placeholder={placeholder || label!}
          placeholderTextColor={placeholderTextColor || Colors.black}
          keyboardType={keyboardType || 'default'}
        />
      </View>
      <View transparent style={[styles.errorContainer, errorContainerStyle]}>
        <UIText style={[styles.error, errorStyle]}>{validatorErrorMessage || errorMessage}</UIText>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  inputContainer: {
    backgroundColor: Colors.white,
    height: 60,
    paddingHorizontal: Layout.backgroundMarginNormal.left,
    paddingVertical: 8,
    borderRadius: 28,
  },
  label: {
    fontSize: Fonts.sizes.small,
    color: Colors.grey,
  },
  input: {
    flex: 1,
    fontFamily: Fonts.family.regular,
    fontSize: Fonts.sizes.default,
  },
  error: {
    color: Colors.red,
  },
  errorContainer: {
    height: 12,
  },
});
