import { useNavigation } from '@react-navigation/core';
import React, { useEffect, useState } from 'react';
import { Image, Pressable, View } from 'react-native';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import { UIText } from '../components/StyledText';
import { Colors } from '../constants/Colors';
import Fonts from '../constants/Fonts';
import CarouselDownloadApp from './CarouselDownloadApp';
import SocialMediaBar from './SocialMediaBar';

interface FooterProps {
  showReasons?: boolean;
  isLayoutAbsolute?: boolean;
}

const Footer = ({ showReasons = false, isLayoutAbsolute = false }: FooterProps) => {
  const styles = useStyles();
  const navigation = useNavigation();

  const [language, setLanguage] = useState<string | null>(localStorage.getItem('MaraeFitLanguage') || 'en');
  const changeLanguage = () => {
    setLanguage(localStorage.getItem('MaraeFitLanguage'));
  };
  useEffect(() => {
    window.addEventListener('storage', changeLanguage);
    return () => {
      window.removeEventListener('storage', changeLanguage);
    };
  }, []);
  const handleLanguage = () => {
    if (language === 'en') {
      localStorage.setItem('MaraeFitLanguage', 'mi');
      window.dispatchEvent(new Event('storage'));
    } else {
      localStorage.setItem('MaraeFitLanguage', 'en');
      window.dispatchEvent(new Event('storage'));
    }
  };
  const handleAbout = () => {
    navigation.navigate('About');
  };
  const handlePrivacy = () => {
    navigation.navigate('Privacy');
  };
  const handleHelp = () => {
    navigation.navigate('Terms');
  };
  return (
    <View style={[styles.footerOuter, isLayoutAbsolute ? styles.footerPosition : null]}>
      <Image source={require('../../assets/images/desktopFooter.png')} style={styles.footerImage} />
      {showReasons && (
        <View style={styles.footerReasons}>
          <CarouselDownloadApp />
        </View>
      )}
      <View style={styles.root}>
        <View style={[styles.topContainer, showReasons ? styles.topContainerHigh : null]}>
          <Image source={require('../../assets/images/mf_logo_light.png')} style={styles.logo} />
          <View style={styles.labelContainer}>
            <Pressable onPress={handleAbout}>
              <UIText style={styles.textLabel}>About</UIText>
            </Pressable>
            <Pressable onPress={handlePrivacy}>
              <UIText style={styles.textLabel}>Privacy</UIText>
            </Pressable>
            <Pressable onPress={handleHelp}>
              <UIText style={styles.textLabel}>Terms</UIText>
            </Pressable>
            <Pressable onPress={handleLanguage} style={{ display: 'none' }}>
              <UIText style={styles.textLabel}>{language === 'en' ? 'Maori' : 'English'}</UIText>
            </Pressable>
          </View>
          <Image source={require('../../assets/images/sportsnz_logo_light.png')} style={styles.logoNZ} />
        </View>
        <View style={[styles.bottomContainer, showReasons ? styles.bottomContainerHigh : null]}>
          <View style={styles.bottomLeftContainer}>
            <UIText style={styles.textCopyrightLabel}>Copyright © 2021 Sport New Zealand.</UIText>
            <Pressable onPress={handlePrivacy}>
              <UIText style={styles.textPrivacyLabel}>Privacy and copyright</UIText>
            </Pressable>
          </View>
          <SocialMediaBar />
          <Image source={require('../../assets/images/nzgovt-white.png')} style={styles.logoGov} />
        </View>
      </View>
    </View>
  );
};

export default Footer;

const useStyles = CreateResponsiveStyle(
  {
    footerOuter: {
      width: '100%',
      zIndex: 0,
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    footerPosition: {
      position: 'absolute',
    },
    footerImage: {
      height: 214,
      width: '100%',
      backgroundColor: Colors.transparent,
      zIndex: 4,
      resizeMode: 'cover',
      // Get rid of annoying line
      marginBottom: -2,
    },
    footerReasons: {
      zIndex: 3,
      // Get rid of annoying line
      marginBottom: -2,
    },
    root: {
      backgroundColor: Colors.darkBurgundy,
      zIndex: 0,
      paddingHorizontal: '15%',
      flexDirection: 'column',
    },
    topContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      borderColor: Colors.white,
      borderBottomWidth: 1,
      paddingVertical: 10,
      // marginTop: 10,
    },
    topContainerHigh: {
      marginTop: 40,
    },
    logo: {
      width: 93,
      height: 73,
      resizeMode: 'contain',
      alignSelf: 'center',
    },
    logoNZ: {
      width: 125,
      height: 57,
      resizeMode: 'contain',
      alignSelf: 'center',
    },
    labelContainer: {
      height: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    textLabel: {
      color: Colors.white,
      marginRight: 10,
      fontSize: 16,
      fontFamily: Fonts.family.bold,
      padding: 10,
    },
    bottomContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    bottomContainerHigh: {
      marginBottom: 20,
    },
    bottomLeftContainer: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    textCopyrightLabel: {
      color: Colors.white,
      marginRight: 10,
      fontSize: 14,
      fontFamily: Fonts.family.bold,
    },
    textPrivacyLabel: {
      color: Colors.white,
      fontSize: 14,
      fontFamily: Fonts.family.bold,
      textDecorationLine: 'underline',
    },
    logoGov: {
      height: 60,
      width: '18%',
      minWidth: 100,
      resizeMode: 'contain',
    },
  },
  {
    [DEVICE_SIZES.XS]: {
      footerImage: {
        height: 62,
      },
      root: {
        paddingHorizontal: '5%',
      },
      topContainer: {
        paddingVertical: 2,
        marginBottom: 10,
        borderBottomWidth: 0,
      },
      logo: {
        height: 40,
      },
      logoNZ: {
        height: 36,
      },
      labelContainer: {
        display: 'none',
      },
      bottomContainer: {
        display: 'none',
      },
    },
    [DEVICE_SIZES.SM]: {
      footerImage: {
        height: 85,
      },
      topContainer: {
        paddingVertical: 2,
        marginBottom: 10,
        borderBottomWidth: 0,
      },
      labelContainer: {
        display: 'none',
      },
      bottomContainer: {
        display: 'none',
      },
    },
    [DEVICE_SIZES.MD]: {
      footerImage: {
        height: 152,
      },
    },
  }
);
