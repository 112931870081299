import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import AsyncStorage from '@react-native-async-storage/async-storage';
import authentication from './authentication.slice';
import user from './user.slice';
import activity from './activity.slice';
import history from './history.slice';
import region from './region.slice';
import marae from './marae.slice';
import review from './review.slice';

const basePersistConfig = {
  storage: AsyncStorage,
  blacklist: ['error', 'loading', 'backgroundLoading'],
};

const userPersistConfig = { ...basePersistConfig, key: 'user' };
const authenticationPersistConfig = { ...basePersistConfig, key: 'authentication' };
const activityPersistConfig = { ...basePersistConfig, key: 'activity' };
const historyPersistConfig = { ...basePersistConfig, key: 'history' };
const regionPersistConfig = { ...basePersistConfig, key: 'region' };
const maraePersistConfig = { ...basePersistConfig, key: 'marae' };
const reviewPersistConfig = { ...basePersistConfig, key: 'review' };

export const rootReducer = combineReducers({
  user: persistReducer(userPersistConfig, user),
  authentication: persistReducer(authenticationPersistConfig, authentication),
  activity: persistReducer(activityPersistConfig, activity),
  history: persistReducer(historyPersistConfig, history),
  region: persistReducer(regionPersistConfig, region),
  marae: persistReducer(maraePersistConfig, marae),
  review: persistReducer(reviewPersistConfig, review),
});
