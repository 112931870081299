import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import { StyleSheet, View, Text, Linking } from 'react-native';
import { Colors } from '../constants/Colors';
import Fonts from '../constants/Fonts';
import API from '../constants/API';

const contentfulToReactnative = {
  renderMark: {
    [MARKS.UNDERLINE]: (text) => text,
    [MARKS.BOLD]: (text) => <Text>{text}</Text>,
    [MARKS.ITALIC]: (text) => <Text>{text}</Text>,
    [MARKS.CODE]: (text) => <Text>{text}</Text>,
  },
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => (
      <Text onPress={() => openLink(node?.data?.uri)} style={styles.linkText}>
        {children}
      </Text>
    ),

    [BLOCKS.EMBEDDED_ENTRY]: () => null,
    [BLOCKS.EMBEDDED_ASSET]: () => null,
    [BLOCKS.PARAGRAPH]: (_node, children) => <Text style={styles.blackText}>{children}</Text>,
    [BLOCKS.HEADING_1]: (_node, children) => <Text style={styles.h1}>{children}</Text>,
    [BLOCKS.HEADING_2]: (_node, children) => <Text style={styles.h2}>{children}</Text>,
    [BLOCKS.HEADING_3]: (_node, children) => <Text style={styles.h3}>{children}</Text>,
    [BLOCKS.HEADING_4]: (_node, children) => <Text style={styles.h3}>{children}</Text>,
    [BLOCKS.HEADING_5]: (_node, children) => <Text style={styles.h3}>{children}</Text>,
    [BLOCKS.HEADING_6]: (_node, children) => <Text style={styles.h3}>{children}</Text>,
    [BLOCKS.UL_LIST]: (_node, children) => <View>{children.map((child) => child)}</View>,
    [BLOCKS.OL_LIST]: (_node, children) => children.map((child) => child),
    [BLOCKS.LIST_ITEM]: (_node, child) => <View>{child}</View>,
    [BLOCKS.QUOTE]: (_node, child) => <Text>{child}</Text>,
    [BLOCKS.HR]: (_node, child) => <Text>{child}</Text>,
  },
};

function openLink(url: string) {
  if (url.includes('mailto:')) {
    Linking.openURL(url);
  } else {
    const api: API = API.getInstance();
    api.openUrl(url);
  }
}

export function RichTextResponseNative({ richTextResponse }) {
  return documentToReactComponents(richTextResponse, contentfulToReactnative);
}

const styles = StyleSheet.create({
  image: {
    alignSelf: 'center',
    marginVertical: 15,
    alignItems: 'center',
    justifyContent: 'center',
  },
  whiteText: {
    color: Colors.whiteOpacitiy70,
    fontFamily: Fonts.family.regular,
    lineHeight: 24,
    fontSize: Fonts.sizes.default,
    textAlign: 'left',
  },
  blackText: {
    fontFamily: Fonts.family.regular,
    marginBottom: 15,
    lineHeight: 24,
    fontSize: Fonts.sizes.default,
    textAlign: 'left',
  },
  linkText: {
    color: 'blue',
    textDecorationLine: 'underline',
    fontFamily: Fonts.family.bold,
    lineHeight: 24,
    fontSize: Fonts.sizes.default,
    textAlign: 'left',
  },
  h1: {
    fontFamily: Fonts.family.bold,
    fontSize: Fonts.sizes.bigHeading,
    textAlign: 'left',
    marginVertical: 15,
    lineHeight: 37,
  },
  h2: {
    fontFamily: Fonts.family.bold,
    fontSize: Fonts.sizes.heading,
    textAlign: 'left',
    marginTop: 15,
    marginBottom: 25,
    lineHeight: 37,
  },
  h3: {
    fontFamily: Fonts.family.bold,
    fontSize: Fonts.sizes.smallHeading,
    lineHeight: 37,
  },
  center: {
    textAlign: 'left',
    marginVertical: 20,
  },
});
