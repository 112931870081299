import AntDesign from '@expo/vector-icons/build/AntDesign';
import React, { Dispatch, SetStateAction } from 'react';
import { View, StyleSheet, Image, Pressable } from 'react-native';
import { Colors } from '../../../../constants/Colors';
import Fonts from '../../../../constants/Fonts';
import NoteInfo from '../../../../components/NoteInfo';
import { UIText } from '../../../../components/StyledText';
import i18n from '../../../../translations';
import Logger from '../../../../constants/Logger';
import { useDispatch, useSelector } from 'react-redux';
import { deleteUserMarae, getUser } from '../../../../reducers/user.slice';
import { UserMarae, UserStats } from '../../../../constants/Interfaces';
import { Ellipse, Svg } from 'react-native-svg';

interface MareaDeleteProps {
  marae: UserMarae;

  openDetails: Dispatch<SetStateAction<boolean>>;
}

const MaraeDeleteFragment = ({ marae, openDetails }: MareaDeleteProps) => {
  const userStats: UserStats = useSelector((state: any) => state.user.userStats);
  const { maraeId } = marae;

  const pointBreakDown = userStats?.pointBreakdown;
  const pointsMarae = pointBreakDown ? pointBreakDown.find((item) => item.maraeId === maraeId) : null;
  const hasPoints = pointsMarae && pointsMarae?.points ? pointsMarae!.points > 0 : false;

  const dispatch = useDispatch();

  const handleDelete = async () => {
    Logger.debug('Deleting marae now');
    try {
      await dispatch(deleteUserMarae(marae));
      dispatch(getUser());
      openDetails(false);
    } catch (e) {
      Logger.debug(`Error occurred: ${e}`);
    }
  };
  return (
    <View style={styles.root}>
      <View style={styles.maraeContainer}>
        <Image style={styles.maraeImage} source={require('../../../../../assets/images/marae_placeholder.png')} />
      </View>
      {!hasPoints && (
        <Pressable style={styles.removeContainer} onPress={handleDelete}>
          <View style={styles.closeContainer}>
            <AntDesign name="close" size={20} color={Colors.white} />
          </View>
          <UIText style={styles.buttonText}>{i18n.t('components.MaraeDelete.remove')}</UIText>
        </Pressable>
      )}
      {hasPoints && (
        <>
          <Svg height="100" width="100" style={styles.curveLine}>
            <Ellipse cx="0" cy="55" rx="50" ry="30" stroke={Colors.yellow} strokeWidth="1" strokeDasharray="5,5" />
          </Svg>
          <View style={styles.noteContainer}>
            <NoteInfo message="removeMarae" maraeName={marae.maraeName} />
          </View>
        </>
      )}
    </View>
  );
};

export default MaraeDeleteFragment;

const styles = StyleSheet.create({
  root: {
    alignSelf: 'center',
    alignItems: 'center',
    height: 500,
    width: 300,
    paddingTop: 35,
  },
  maraeContainer: {
    width: 120,
    height: 120,
    backgroundColor: Colors.darkBurgundy,
    borderColor: Colors.yellow,
    borderWidth: 2,
    borderRadius: 60,
    justifyContent: 'center',
    alignItems: 'center',
  },
  maraeImage: {
    height: 100,
    width: 100,
    borderRadius: 50,
    resizeMode: 'cover',
  },
  removeContainer: {
    width: '70%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: 10,
    borderWidth: 3,
    borderColor: Colors.yellow,
    height: 50,
    borderRadius: 25,
    marginTop: 30,
  },
  closeContainer: {
    backgroundColor: Colors.yellow,
    justifyContent: 'center',
    alignItems: 'center',
    height: 35,
    width: 35,
    borderRadius: 20,
    marginRight: 10,
  },
  buttonText: {
    color: Colors.white,
    fontSize: Fonts.sizes.button,
    fontFamily: Fonts.family.bold,
  },
  noteContainer: {
    marginTop: 30,
    width: 273,
    padding: 3,
    borderColor: Colors.yellow,
    borderWidth: 1,
    borderStyle: 'dashed',
    borderRadius: 1,
  },
  curveLine: {
    left: 200,
    bottom: 300,
    zIndex: -1,
    position: 'absolute',
  },
});
