import React from 'react';
import { Image, StyleSheet, View } from 'react-native';
import { Colors } from '../constants/Colors';
import Layout from '../constants/Layout';
import { TipModal } from './TipModal';

interface BottomBannerProps {
  themeColor?: 'red' | 'white' | string;
  tip?: string;
}

export const getBackgroundColor = (themeColor: string) => {
  if (themeColor === 'red') {
    return Colors.darkBurgundy;
  }

  if (themeColor === 'white') {
    return Colors.white;
  }

  return themeColor;
};

export default function BottomBanner(props: BottomBannerProps) {
  const { themeColor = 'red', tip } = props;

  return (
    <>
      <View style={[styles.container, { backgroundColor: getBackgroundColor(themeColor) }]}>
        <Image
          source={
            themeColor === 'red'
              ? require('../../assets/images/mf_logo_light.png')
              : require('../../assets/images/mf_logo_dark.png')
          }
          resizeMethod="resize"
          style={[styles.image, styles.leftImage]}
        />
        <Image
          source={
            themeColor === 'red'
              ? require('../../assets/images/sportsnz_logo_light.png')
              : require('../../assets/images/sportsnz_logo_dark.png')
          }
          resizeMethod="resize"
          style={[styles.image, styles.rightImage]}
        />
      </View>
      {themeColor !== 'red' && (
        <>
          {tip && <TipModal message={tip} />}
          <View style={styles.cornersContainer}>
            <View style={{ backgroundColor: 'white' }}>
              <View style={[styles.corner, { borderBottomStartRadius: 35 }]} />
            </View>
            <View style={{ backgroundColor: 'white' }}>
              <View style={[styles.corner, { borderBottomEndRadius: 20 }]} />
            </View>
          </View>
        </>
      )}
    </>
  );
}
const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    bottom: 0,
    height: 70,
    flexDirection: 'row',
    alignItems: 'center',
    width: Layout.window.width,
    justifyContent: 'space-between',
  },

  image: {
    width: 80,
    height: 40,
    resizeMode: 'contain',
  },
  leftImage: {
    marginLeft: 10,
  },
  rightImage: {
    marginRight: 26,
  },
  cornersContainer: {
    backgroundColor: Colors.transparent,
    flexDirection: 'row',
    height: 15,
    width: Layout.window.width,
    position: 'absolute',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    bottom: 70,
    borderRadius: 35,
    pointerEvents: 'none',
  },
  corner: {
    backgroundColor: Colors.darkBurgundy,
    height: 15,
    width: Layout.backgroundMarginNarrow.left,
    left: 0,
    borderWidth: 1,
    borderColor: Colors.darkBurgundy,
  },
});
