import { SearchTerm, VisitItem } from '../constants/Interfaces';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import Logger from '../constants/Logger';
import { DateTime } from 'luxon';
import { normalizeSearch } from '../constants/Utils';
import { purge } from './commonActions';

interface HistoryState {
  visits: VisitItem[]; // aggregated visits for activities. Update timestamp for the same activity for repeated visits.
  searches: SearchTerm[]; // aggregated searches for terms. Update count for the same term.
}

const initialState: HistoryState = {
  visits: [],
  searches: [],
};

const historySlice = createSlice({
  name: 'history',
  initialState,
  reducers: {
    addVisit(state, action: PayloadAction<VisitItem>) {
      Logger.debug(`Reducer -> history -> addVisit: Adding another visit ${JSON.stringify(action.payload)}`);
      const newVisit: VisitItem = action.payload;
      const currentIndex = _.findIndex(state.visits, ['id', newVisit.id]);
      if (currentIndex > -1) {
        state.visits[currentIndex].timestamp = DateTime.now().toISO();
      } else {
        state.visits.push({ ...newVisit, timestamp: DateTime.now().toISO() });
      }
    },
    addSearch(state, action: PayloadAction<string>) {
      Logger.debug(`Reducer -> history -> addSearch: Adding another search ${action.payload}`);
      const searchTerm: string = normalizeSearch(action.payload);
      const currentIndex = _.findIndex(state.searches, ['title', searchTerm]);
      if (currentIndex > -1) {
        state.searches[currentIndex].count += 1;
      } else {
        state.searches.push({
          title: searchTerm,
          count: 1,
        });
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(purge, (state) => {
      Logger.debug(`Reducer -> history: Purge request received.`);
      state.visits = [];
      state.searches = [];
    });
  },
});

export const { addVisit, addSearch } = historySlice.actions;
export default historySlice.reducer;
