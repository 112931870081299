import * as React from 'react';
import { BackHandler } from 'react-native';
import { createDrawerNavigator } from '@react-navigation/drawer';
import HamburgerMenu from '../components/HamburgerMenu';
import { DrawerParamList } from '../constants/Interfaces';
import { ActivitiesNavigator } from './ActivitiesNavigator';
import Layout from '../constants/Layout';
import { useNavigation } from '@react-navigation/native';

const Drawer = createDrawerNavigator<DrawerParamList>();

export default function GuestDrawerNavigation() {
  const navigation = useNavigation();
  React.useEffect(
    () =>
      navigation.addListener('beforeRemove', (e) => {
        e.preventDefault();
        BackHandler.exitApp();
      }),
    []
  );
  return (
    <Drawer.Navigator
      screenOptions={{
        drawerPosition: 'right',
        drawerType: 'front',
        headerShown: false,
        drawerStyle: {
          width: Layout.window.width * 0.88,
        },
      }}
      initialRouteName="ActivitiesStack"
      backBehavior="history"
      id="GuestDrawer"
      drawerContent={(props) => <HamburgerMenu {...props} />}
    >
      <Drawer.Screen name="ActivitiesStack" component={ActivitiesNavigator} />
    </Drawer.Navigator>
  );
}
