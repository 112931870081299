import * as React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { ActivitiesScreen } from '../screens/activities/ActivitiesScreen';
import { ActivityDetailsScreen } from '../screens/activities/ActivityDetailsScreen';
import { ActivitiesParamList } from '../constants/Interfaces';
import { useNavigation } from '@react-navigation/native';
import { SelectRegionScreen } from '../screens/profile/SelectRegionScreen';
import { TipScreen } from '../screens/profile/TipScreen';

const ActivitiesStack = createStackNavigator<ActivitiesParamList>();

export const ActivitiesNavigator = () => {
  const navigation = useNavigation();

  React.useEffect(() => navigation.addListener('beforeRemove', () => navigation.navigate('DashboardStack')), []);

  return (
    <ActivitiesStack.Navigator screenOptions={{ headerShown: false }}>
      <ActivitiesStack.Screen name="Activities" component={ActivitiesScreen} />
      <ActivitiesStack.Screen name="ActivityDetails" component={ActivityDetailsScreen} />
      <ActivitiesStack.Screen name="SelectRegion" component={SelectRegionScreen} />
      <ActivitiesStack.Screen name="TipScreen" component={TipScreen} />
    </ActivitiesStack.Navigator>
  );
};
