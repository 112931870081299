import * as React from 'react';
import { Pressable, StyleSheet } from 'react-native';
import { View } from './Themed';
import Modal from 'react-native-modal';
import { MaterialIcons } from '@expo/vector-icons';
import { Colors } from '../constants/Colors';

interface ActionModalProps {
  children: any;
  visible: boolean;
  onModalClose: () => void;
  height?: number;
  bgColor?: 'yellow' | 'white';
  closeIcon?: boolean;
  propagateSwipe?: boolean;
}

export const ActionModal = ({
  children,
  visible = false,
  onModalClose,
  height = 600,
  bgColor = 'white',
  closeIcon = true,
  propagateSwipe = false,
}: ActionModalProps) => {
  const handleCloseModal = () => {
    onModalClose();
  };

  return (
    <Modal
      isVisible={visible}
      style={[styles.modalContainer]}
      onBackdropPress={handleCloseModal}
      onSwipeComplete={handleCloseModal}
      swipeDirection="down"
      propagateSwipe={propagateSwipe}
    >
      <View
        style={[styles.modal, { height }, { backgroundColor: bgColor === 'yellow' ? Colors.yellow : Colors.white }]}
      >
        <View transparent style={styles.grabHandleContainer}>
          <View style={styles.grabHandle} />
        </View>
        {closeIcon && (
          <Pressable style={styles.close} onPress={handleCloseModal}>
            <MaterialIcons name="close" size={30} color={Colors.black} />
          </Pressable>
        )}
        <View transparent style={styles.modalContentContainer}>
          {children}
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modalContainer: {
    justifyContent: 'flex-end',
    margin: 0,
    zIndex: 1000,
  },
  modal: {
    width: '100%',
    borderTopStartRadius: 50,
    borderTopEndRadius: 50,
    paddingVertical: 15,
  },
  modalContentContainer: {
    marginTop: 20,
    flex: 1,
  },
  close: {
    position: 'absolute',
    top: 20,
    right: 20,
  },
  heading: {
    marginTop: 50,
    marginBottom: 20,
  },
  grabHandleContainer: {
    height: 12,
    alignItems: 'center',
    justifyContent: 'center',
  },
  grabHandle: {
    width: 80,
    height: 5,
    borderRadius: 2.5,
    backgroundColor: Colors.lightGrey,
  },
});
