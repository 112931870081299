import * as React from 'react';
import { View } from './Themed';
import { StyleSheet, Image, ImageSourcePropType, StyleProp, ViewStyle } from 'react-native';
import { Heading, UIText } from './StyledText';
import Fonts from '../constants/Fonts';

interface AgeStatsProps {
  containerStyle?: StyleProp<ViewStyle>;
  stats: number;
  label: string;
  source: ImageSourcePropType;
}

export const AgeStats = ({ stats, label, source, containerStyle }: AgeStatsProps) => (
  <View transparent style={[styles.container, containerStyle]}>
    <Image source={source} style={styles.icon} />
    <Heading style={styles.stats}>{stats}</Heading>
    <UIText style={styles.label}>{label}</UIText>
  </View>
);

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  icon: {
    width: 50,
    height: 50,
    resizeMode: 'cover',
    marginBottom: 2,
  },
  stats: {
    marginBottom: 2,
  },
  label: {
    fontSize: Fonts.sizes.mini,
  },
});
