import { Ionicons } from '@expo/vector-icons';
import React from 'react';
import { StyleSheet, Pressable } from 'react-native';
import { Colors } from '../constants/Colors';

const Backbutton = ({ onPress }) => (
  <Pressable style={styles.backButton} onPress={onPress}>
    <Ionicons name="ios-chevron-back" size={20} color={Colors.black} />
  </Pressable>
);
export default Backbutton;

const styles = StyleSheet.create({
  backButton: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.whiteOpacity,
    height: 22,
    borderRadius: 8,
  },
});
