import i18n from 'i18n-js';

import en from './en.json';
import mi from './mi.json';

const translations = { en, mi };

i18n.fallbacks = true;
i18n.translations = translations;

export default i18n;
