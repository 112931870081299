import * as React from 'react';
import Fonts from '../constants/Fonts';

import { Text, TextProps } from './Themed';
import { StyleProp, TouchableHighlight, ViewStyle } from 'react-native';
import { Colors } from '../constants/Colors';

interface LinkProps {
  title: string;
  onPress: () => void;
  containerStyle?: StyleProp<ViewStyle>;
}

export function MonoText(props: TextProps) {
  return <Text {...props} style={[{ fontFamily: 'space-mono' }, props.style]} />;
}

export function Paragraph(props: TextProps) {
  return (
    <Text
      {...props}
      style={[{ fontFamily: Fonts.family.regular, fontSize: Fonts.sizes.paragraph }, props.style]}
      maxFontSizeMultiplier={1.05}
    />
  );
}

export function Heading(props: TextProps) {
  return (
    <Text
      {...props}
      style={[{ fontFamily: Fonts.family.bold, fontSize: Fonts.sizes.heading }, props.style]}
      maxFontSizeMultiplier={1.05}
    />
  );
}

export function UIText(props: TextProps) {
  return (
    <Text
      {...props}
      style={[{ fontFamily: Fonts.family.regular, fontSize: Fonts.sizes.default }, props.style]}
      maxFontSizeMultiplier={1.05}
    />
  );
}

export function Link(props: LinkProps) {
  const { title, onPress, containerStyle } = props;
  return (
    <TouchableHighlight onPress={onPress} style={containerStyle} underlayColor={Colors.grey}>
      <Text style={[{ fontFamily: Fonts.family.regular, fontSize: Fonts.sizes.default }]} maxFontSizeMultiplier={1.05}>
        {title}
      </Text>
    </TouchableHighlight>
  );
}
