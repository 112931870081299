import * as React from 'react';
import { View } from '../../components/Themed';
import { Image, ImageBackground, Pressable, StyleSheet } from 'react-native';
import InView from 'react-native-component-inview';
import { Colors } from '../../constants/Colors';
import Layout from '../../constants/Layout';
import { UIText } from '../../components/StyledText';
import { Activity, ActivityStats, User, UserActivity, Review } from '../../constants/Interfaces';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import Fonts from '../../constants/Fonts';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { DetailItem } from '../../components/DetailItem';
import i18n from '../../translations';
import { SplitButton } from '../../components/buttons/SplitButton';
import Logger from '../../constants/Logger';
import { getActivityStats, getUserActivities, postUserActivity } from '../../reducers/activity.slice';
import { ActionModal } from '../../components/ActionModal';
import { ActivityRegistrationFragment } from './fragments/ActivityRegistrationFragment';
import ModalUI from '../../components/ModalUI';
import RegisteredFragment from './fragments/RegisteredFragment';
import ShowReviewFragment from './fragments/ShowReviewFragment';
import ScreenHolder from '../../components/ScreenHolder';
import { convertToNZ, getCacheImage, shareContent, validatePastActivity } from '../../constants/Utils';
import { LinearGradient } from 'expo-linear-gradient';

interface ActivityDetailsScreenProps {
  navigation: any;
  route: {
    params: {
      activityId: string;
    };
  };
}

export const ActivityDetailsScreen = ({ navigation, route }: ActivityDetailsScreenProps) => {
  const { activityId } = route.params;
  const activity: Activity = useSelector((state: any) =>
    _.find(state.activity.activities.concat(state.activity.pastActivities), ['activityId', activityId])
  );
  const activityStats: ActivityStats = useSelector((state: any) => state.activity.stats[activityId]);
  const activitiesCompleted: UserActivity[] = useSelector((state: any) => state.user.activitiesCompleted);
  const user: User = useSelector((state: any) => state.user.user);
  const myActivityCompleted: UserActivity | undefined = _.find(
    activitiesCompleted,
    (item) => item?.activityId === activityId
  );
  const reviews: Review[] = useSelector((state: any) => state.review.reviews);
  const review = reviews.find((item) => item.activityId!.trim() === activityId && item.userId === user.userId);
  const dispatch = useDispatch();
  const [modalVisible, setModalVisible] = React.useState<boolean>(false);
  const [alertVisible, setAlertVisible] = React.useState<boolean>(false);
  const isFinished: boolean = validatePastActivity(activity?.endDate);
  const [activityImage, setActivityImage] = React.useState<string | undefined>(activity?.imageUrl);
  const [showFooter, setShowFooter] = React.useState(false);
  const myActivity: UserActivity =
    isFinished && myActivityCompleted
      ? myActivityCompleted
      : useSelector((state: any) =>
          _.find(state.activity.myActivities, (item) =>
            item.activityId === activityId && item.userId === user?.userId ? user?.userId : ''
          )
        );
  const registered = myActivity?.going;
  const [favourite, setFavourite] = React.useState(myActivity?.favourite || false);
  const toggleFavourite = () => {
    if (!user) {
      navigation.navigate('SocialMediaLogin');
      return;
    }
    setFavourite(!favourite);
    postFavourite();
  };

  const isLoggedIn = navigation.getParent('Drawer');

  const postFavourite = async () => {
    Logger.debug(`Add to favourite`);
    let postPayload: UserActivity = { ...myActivity };
    // If there is no my activity, create a default one first
    if (!myActivity) {
      postPayload = {
        activityId: activity.activityId,
        favourite: false,
        going: false,
      };
    }
    // Toggle the boolean
    postPayload.favourite = !postPayload.favourite;
    await dispatch(
      postUserActivity({
        activity: postPayload,
      })
    );
    dispatch(getUserActivities());
  };

  const handleGoing = () => {
    if (!isLoggedIn) {
      navigation.navigate('SocialMediaLogin');
      return;
    }
    if (!user || !user.marae || user.marae.length === 0 || user.firstName === null) {
      setAlertVisible(true);
      return;
    }
    setModalVisible(true);
  };

  const handleGoingModalClose = () => {
    Logger.debug(`Modal closed`);
    setModalVisible(false);
  };
  const cacheImage = async () => {
    try {
      const uri = await getCacheImage({ activityId: activity.activityId, uri: activity.imageUrl });
      setActivityImage(uri);
    } catch (e) {
      Logger.debug(e);
    }
  };
  React.useEffect(() => {
    cacheImage();
  }, [activity]);

  React.useEffect(() => {
    dispatch(getActivityStats(activityId));
  }, []);

  const checkVisible = (isVisible: boolean) => {
    setShowFooter(isVisible);
  };
  const HeaderComponent = () => (
    <View style={styles.header}>
      <Image source={require('../../../assets/images/top_overlay.png')} style={styles.topImage} resizeMethod="resize" />
      <ImageBackground source={{ uri: activityImage }} style={[styles.imageBackground]}>
        <LinearGradient colors={[Colors.transparent, Colors.blackOpacity70]} style={styles.summaryContainer}>
          <View transparent style={styles.titleContainer}>
            <UIText style={styles.title}>{`${activity?.activityName}`}</UIText>
          </View>
          <UIText style={styles.date}>
            {`${
              activity?.startDate ? convertToNZ({ date: activity.startDate, format: 'MMMM dd, yyyy, h:mma' }) : null
            }`}
          </UIText>
        </LinearGradient>
        {!registered && !isFinished && (
          <View transparent style={styles.favouriteContainer}>
            <MaterialCommunityIcons
              name={favourite ? 'heart' : 'heart-outline'}
              size={18}
              color={favourite ? Colors.darkBurgundy : Colors.white}
            />
          </View>
        )}
      </ImageBackground>
    </View>
  );
  return (
    <ScreenHolder navigation={navigation} headerComponent={<HeaderComponent />} showFooter={showFooter}>
      <View style={styles.container}>
        {activity ? (
          <View style={styles.body}>
            {/* Reviewed  */}
            {review && (
              <ShowReviewFragment review={review} location={myActivity?.location} activityStats={activityStats} />
            )}
            {/* RegisteredFragment  */}
            {!review && registered && (
              <RegisteredFragment activityId={activityId} activityStats={activityStats} myActivity={myActivity} />
            )}
            <View transparent style={styles.detailsContainer}>
              {!registered && !isFinished && (
                <SplitButton
                  labelLeft={
                    myActivity && favourite
                      ? i18n.t('pages.ActivityDetailsScreen.favourited')
                      : i18n.t('pages.ActivityDetailsScreen.addtofavourites')
                  }
                  labelRight={i18n.t('pages.ActivityDetailsScreen.going')}
                  iconLeft={{
                    left:
                      myActivity && favourite ? (
                        <MaterialCommunityIcons
                          name="check-all"
                          size={16}
                          color={Colors.darkBurgundy}
                          style={[styles.buttonIcon, styles.buttonIconLeft]}
                        />
                      ) : (
                        <MaterialCommunityIcons
                          name="heart"
                          size={16}
                          color={Colors.darkBurgundy}
                          style={[styles.buttonIcon, styles.buttonIconLeft]}
                        />
                      ),
                  }}
                  iconRight={{
                    right: (
                      <MaterialCommunityIcons
                        name="arrow-right"
                        size={16}
                        color={Colors.white}
                        style={[styles.buttonIcon, styles.buttonIconRight]}
                      />
                    ),
                  }}
                  onPressLeft={toggleFavourite}
                  onPressRight={handleGoing}
                  containerStyles={styles.actionButton}
                />
              )}
              {isFinished && !registered && (
                <View style={styles.closedLabelContainer}>
                  <UIText style={styles.closedLabelText}>THIS KAUPAPA IS NOW CLOSED</UIText>
                </View>
              )}
              {!review && (
                <>
                  {!registered && (
                    <DetailItem
                      label={
                        registered
                          ? i18n.t('pages.ActivityDetailsScreen.eHaereAna')
                          : i18n.t('pages.ActivityDetailsScreen.areyougoing')
                      }
                      description={
                        registered
                          ? i18n.t('pages.ActivityDetailsScreen.whanauattended', {
                              count: activityStats ? activityStats.engagementTypeGoing.personTotal : 0,
                            })
                          : i18n.t('pages.ActivityDetailsScreen.whanaugoing', {
                              count: activityStats ? activityStats.engagementTypeGoing.personTotal : 0,
                            })
                      }
                    />
                  )}
                  <DetailItem
                    label={i18n.t('pages.ActivityDetailsScreen.address')}
                    description={`${activity?.location?.addressLine1}, ${activity.location?.city}`}
                  />
                  <DetailItem
                    label={i18n.t('pages.ActivityDetailsScreen.details')}
                    description={activity.description}
                  />
                  <DetailItem
                    label={i18n.t('pages.ActivityDetailsScreen.organiser')}
                    description={`${i18n.t('pages.ActivityDetailsScreen.organiserDetails', {
                      orgName: activity.organisation?.name,
                      name: `${activity.organiser?.firstName} ${activity.organiser?.lastName}`,
                      email: activity.organiser?.email,
                    })}${
                      activity.organiser?.phone
                        ? i18n.t('pages.ActivityDetailsScreen.phone', {
                            phone: activity.organiser?.phone,
                          })
                        : ''
                    }
                  `}
                  />
                  <DetailItem label={i18n.t('pages.ActivityDetailsScreen.spread')} />
                  <InView onChange={(isVisible) => checkVisible(isVisible)} collapsable={false}>
                    <Pressable style={styles.shareContainer} onPress={() => shareContent(activityId)}>
                      <Image source={require('../../../assets/images/shareFb.png')} style={styles.fbIcon} />
                    </Pressable>
                    <View style={{ height: Layout.isSmallDevice ? 100 : 95, top: 120 }} transparent />
                  </InView>
                </>
              )}
            </View>
          </View>
        ) : null}

        {activity && (
          <ActionModal visible={modalVisible} onModalClose={handleGoingModalClose} height={650} propagateSwipe>
            <ActivityRegistrationFragment
              activity={activity}
              myActivity={myActivity}
              onDismiss={handleGoingModalClose}
            />
          </ActionModal>
        )}
        <ModalUI closeModal={() => setAlertVisible(false)} toggle={alertVisible}>
          <View transparent>
            <UIText>
              You must complete your profile, including selecting the marae, before registering for an kaupapa.
            </UIText>
          </View>
        </ModalUI>
      </View>
    </ScreenHolder>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: Colors.transparent,
    width: '100%',
    minHeight: Layout.window.height - 200,
    marginVertical: Layout.backgroundMarginNormal.top,
  },
  header: {
    width: '100%',
    backgroundColor: Colors.yellow,
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  summaryContainer: {
    paddingHorizontal: 15,
    height: '50%',
    width: Layout.window.width,
    justifyContent: 'flex-end',
    paddingBottom: 20,
  },
  regionLabelContainer: {
    alignSelf: 'flex-start',
    marginBottom: 8,
    backgroundColor: Colors.backgroundYellow,
    borderRadius: 7,
    paddingHorizontal: 8,
    paddingVertical: 4,
    alignItems: 'center',
    justifyContent: 'center',
  },
  regionLabelContent: {
    fontFamily: Fonts.family.bold,
  },
  titleContainer: {
    height: 120,
    justifyContent: 'flex-end',
  },
  title: {
    ...Fonts.shadow,
    fontFamily: Fonts.family.bold,
    fontSize: Fonts.sizes.bigHeading,
    color: Colors.white,
    flexWrap: 'wrap',
  },
  date: {
    ...Fonts.shadow,
    fontSize: Fonts.sizes.medium,
    color: Colors.white,
  },
  headingContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: 8,
  },
  favouriteContainer: {
    position: 'absolute',
    bottom: 20,
    right: 30,
    width: 28,
    height: 28,
    borderRadius: 28,
    padding: 0,
    backgroundColor: Colors.whiteOpacity,
    alignItems: 'center',
    justifyContent: 'center',
  },
  body: {
    flex: 1,
    width: Layout.window.width,
    backgroundColor: Colors.transparent,
  },
  detailsContainer: {},
  imageBackground: {
    flex: 1,
    width: Layout.window.width,
    resizeMode: 'contain',
    justifyContent: 'flex-end',
  },
  topImage: {
    position: 'absolute',
    top: 0,
    height: 100,
    maxWidth: 150,
    resizeMode: 'contain',
    zIndex: 1,
  },
  bottomImage: {
    position: 'absolute',
    bottom: 0,
    width: Layout.window.width,
    height: 250,
    resizeMode: 'cover',
  },
  actionButton: {
    marginHorizontal: Layout.backgroundMarginNormal.left,
    marginBottom: Layout.backgroundMarginNormal.top,
  },
  buttonIcon: {
    paddingTop: 2,
  },
  buttonIconLeft: {
    paddingRight: 8,
  },
  buttonIconRight: {
    paddingLeft: 8,
  },
  shareContainer: {
    marginLeft: Layout.backgroundMarginNormal.left,
    top: -30,
    backgroundColor: Colors.transparent,
    width: 80,
    height: 30,
  },
  fbIcon: {
    width: '100%',
    height: 30,
    resizeMode: 'contain',
  },
  closedLabelContainer: {
    backgroundColor: Colors.darkBurgundy,
    height: 48,
    width: '80%',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    borderRadius: 28,
    marginBottom: Layout.backgroundMarginNormal.bottom,
  },
  closedLabelText: {
    color: Colors.white,
    fontFamily: Fonts.family.bold,
  },
});
