import * as React from 'react';
import { View } from '../../../../components/Themed';
import { StyleSheet, ScrollView } from 'react-native';
import SectionButton from '../../../../components/buttons/SectionButton';
import i18n from '../../../../translations';
import { DetailsFragment } from './DetailsFragment';
import EditPhoto from '../../../../components/EditPhoto';
import Layout from '../../../../constants/Layout';
import { useSelector } from 'react-redux';
import { ProfileSection, User } from '../../../../constants/Interfaces';
import UserActivities from '../../../../components/UserActivities';
import DeleteAccountFragment from './DeleteAccountFragment';

export const ProfileFragment = ({ hideFooter }: { hideFooter: Function }) => {
  const [index, setIndex] = React.useState<ProfileSection>(ProfileSection.details);
  const user: User = useSelector((state: any) => state.user.user);

  const getFragment = () => {
    switch (index) {
      case ProfileSection.details: {
        return <DetailsFragment hideFooter={hideFooter} />;
      }
      case ProfileSection.photo: {
        return <EditPhoto user={user} />;
      }
      case ProfileSection.activities: {
        return (
          <View style={styles.activitiesContainer} transparent>
            <UserActivities user={user} />
          </View>
        );
      }
      case ProfileSection.delete: {
        return <DeleteAccountFragment goBack={() => setIndex(ProfileSection.details)} />;
      }
      default: {
        return null;
      }
    }
  };

  return (
    <>
      <View style={styles.root} transparent>
        <View transparent style={styles.sectionHeaderContainer}>
          <SectionButton
            title={i18n.t('components.ProfileFragment.details')}
            selected={index === 0}
            onPress={() => setIndex(0)}
          />
          <SectionButton
            title={i18n.t('components.ProfileFragment.photo')}
            selected={index === 1}
            onPress={() => setIndex(1)}
          />
          <SectionButton
            title={i18n.t('components.ProfileFragment.activities')}
            selected={index === 2}
            onPress={() => setIndex(2)}
          />
          <SectionButton
            title={i18n.t('components.ProfileFragment.deleteAccount')}
            selected={index === 3}
            onPress={() => setIndex(3)}
          />
        </View>
      </View>
      <ScrollView bounces={false}>{getFragment()}</ScrollView>
    </>
  );
};

const styles = StyleSheet.create({
  root: {
    width: Layout.window.width,
  },
  sectionHeaderContainer: {
    paddingVertical: 8,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  activitiesContainer: {
    paddingHorizontal: '4%',
  },
});
