import { AnyAction, createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit';
import { MaraeStats, User, UserActivity, UserMarae, UserStats, WhanauMarae } from '../constants/Interfaces';
import Logger from '../constants/Logger';
import API from '../constants/API';
import { RejectedAction } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import { purge } from './commonActions';
import { setDefaultRegion } from './region.slice';
import { clearAuthState } from './authentication.slice';

interface UserState {
  user: User | null;
  loading: boolean;
  backgroundLoading: boolean;
  userDetailsSkipped: boolean;
  maraeSkipped: boolean;
  needsConfirmation: boolean;
  isConfirmed: boolean;
  error: SerializedError | null;
  whanau: null | User[];
  whanauUser: User | null;
  tipModal: string | null;
  maraeStats: MaraeStats | null;
  userStats: UserStats | null;
  activitiesCompleted: UserActivity[] | null;
}

const initialState: UserState = {
  user: null,
  loading: false,
  backgroundLoading: false,
  userDetailsSkipped: false,
  maraeSkipped: false,
  needsConfirmation: false,
  isConfirmed: false,
  error: null,
  whanau: null,
  whanauUser: null,
  tipModal: null,
  maraeStats: null,
  userStats: null,
  activitiesCompleted: null,
};

/**
 * Get the user data
 */
export const getUser = createAsyncThunk('user/getUser', async (param, thunkAPI) => {
  Logger.info(`Reducer -> user -> getUser: Getting user information`);
  const api: API = API.getInstance();
  try {
    const user: User = await api.get('/user');
    Logger.debug(`Reducer -> user -> getUser: User information received. ${user.userId}`);
    if (user.region && user.region.regionName) {
      thunkAPI.dispatch(setDefaultRegion(user.region.regionName));
    }
    return user;
  } catch (e: any) {
    Logger.warn(`Reducer -> user -> getUser: ${e.message}`);
    if (e.response && e.response.status === 404) {
      // if the get user returns 404, it definitely means the user is not in the db. (or was deleted)
      // in this case, post the user immediately.
      try {
        const newUser: User = await api.post('/user', {});
        return newUser;
      } catch (e: any) {
        // this could error with a 400 when the user is deleted
        Logger.warn(`Reducer -> user -> getUser: ${e.message}`);
        thunkAPI.dispatch(clearAuthState());
        throw e;
      }
    } else if (e.response && e.response.status === 400) {
      Logger.warn(`Reducer -> user -> getUser: no email on user`);
    }
    thunkAPI.dispatch(clearAuthState());
    throw e;
  }
});

export const patchUser = createAsyncThunk('user/patchUser', async (user: User) => {
  Logger.info(`Reducer -> user -> patchUser: Patching user information: ${JSON.stringify(user)}`);
  const api: API = API.getInstance();
  try {
    await api.patch('/user', user);
    Logger.debug(`Reducer -> user -> patchUser: User information has been successfully updated.`);
    return user;
  } catch (e: any) {
    Logger.error(`Reducer -> user -> patchUser: Error occurred in patching the user`, e);
    throw new Error(e.message);
  }
});

export const postUserMarae = createAsyncThunk('user/postUserMarae', async (whanauMarae: WhanauMarae) => {
  Logger.info(`Reducer -> user -> postUserMarae: Posting user marae information`);
  try {
    const api: API = API.getInstance();
    await api.post('/user/marae', whanauMarae);
    Logger.debug(`Reducer: user -> postUserMarae: User marae has been successfully uploaded.`);
    return whanauMarae;
  } catch (e) {
    Logger.error(`Reducer -> user -> postUserMarae: Error occurred in posting the user marae`, e);
    throw e;
  }
});
export const deleteUserMarae = createAsyncThunk('user/deleteUserMarae', async (marae: UserMarae) => {
  Logger.info(`Reducer -> user -> deleteMarae: Delete user marae `);
  try {
    const api: API = API.getInstance();
    await api.delete(`/user/marae/${marae.maraeId}?iwiId=${marae.iwi?.iwiId}`);
    Logger.debug(`Reducer: user -> deleteUserMarae: User Marae has been successfully deleted.`);
  } catch (e) {
    Logger.error(`Reducer -> user -> deleteUserMarae Error occurred in deleting the user marae`, e);
    throw e;
  }
});

export const getUserWhanau = createAsyncThunk('user/getUserWhanau', async (): Promise<User[]> => {
  Logger.info(`Reducer -> user -> getUserWhanau: Getting userWhanau information`);
  const api: API = API.getInstance();
  try {
    const whanau: any = await api.get('/user/whanau');
    Logger.debug(`Reducer -> user -> getUserWhanau: UserWhanau information received.`);
    return whanau;
  } catch (e: any) {
    Logger.warn(`Reducer -> user -> getUserWhanau: ${e.message}`);
    throw e;
  }
});

export const postUserWhanau = createAsyncThunk('user/postUserWhanau', async (user: User) => {
  Logger.info(`Reducer -> user -> postUserWhannu Posting user whanau information`);
  try {
    const api: API = API.getInstance();
    await api.post('/user/whanau', user);
    Logger.debug(`Reducer: user -> postUserWhannu User whanau has been successfully uploaded.`);
    const whanau: User[] = await api.get('/user/whanau');
    return whanau;
  } catch (e) {
    Logger.error(`Reducer -> user -> postUserWhannu Error occurred in posting the user whanau`, e);
    throw e;
  }
});
export const patchUserWhanau = createAsyncThunk('user/patchUserWhanau', async (user: User) => {
  Logger.info(`Reducer -> user -> patchUser: Patching userWhanau information: ${JSON.stringify(user)}`);
  const api: API = API.getInstance();
  try {
    await api.patch(`/user/whanau/${user.userId}`, user);
    Logger.debug(`Reducer -> user -> patchUserWhanau: User information has been successfully updated.`);
    return await api.get('/user/whanau');
  } catch (e: any) {
    Logger.error(`Reducer -> user -> patchUserWhanau: Error occurred in patching the user`, e);
    throw new Error(e.message);
  }
});

interface PostUserPhotoProps {
  userId: string;
  payload: {
    image: {
      mime: string;
      data: string;
      url: string;
    };
  };
}
export const postUserPhoto = createAsyncThunk('user/postUserPhoto', async (obj: PostUserPhotoProps) => {
  Logger.info(`Reducer -> user -> postUserPhotoPosting user whanau information`);
  try {
    const api: API = API.getInstance();
    await api.post(`/user/${obj.userId}/photo`, obj.payload);
    Logger.debug(`Reducer: user -> postUserPhoto User photo has been successfully uploaded.`);
    return obj;
  } catch (e) {
    Logger.error(`Reducer -> user -> postUserPhoto Error occurred in posting the user photo`, e);
    throw e;
  }
});
export const deleteUserPhoto = createAsyncThunk('user/deleteUserPhoto', async (userId: string) => {
  Logger.info(`Reducer -> user -> deleteUserPhoto user whanau information`);
  try {
    const api: API = API.getInstance();
    await api.delete(`/user/${userId}/photo`);
    Logger.debug(`Reducer: user -> deleteUserPhoto User photo has been successfully deleted.`);
    return userId;
  } catch (e: any) {
    Logger.error(`Reducer -> user -> deleteUserPhoto Error occurred in deleting the user photo`, e);
    throw e;
  }
});

export const getMaraeStats = createAsyncThunk('user/getMaraeStats', async (marae: UserMarae) => {
  Logger.info(`Reducer -> user -> getUserWhanau: Getting getMaraeStats information`);
  const api: API = API.getInstance();
  try {
    const stats: MaraeStats = await api.get(`/marae/${marae.maraeId}/stats`);
    Logger.debug(`Reducer -> user -> getUserWhanau: getMaraeStats information received.`);
    return stats;
  } catch (e: any) {
    Logger.warn(`Reducer -> user -> getMaraeStats Completed: ${e.message}`);
    throw e;
  }
});

export const getUserStats = createAsyncThunk('user/getUserStats', async () => {
  Logger.info(`Reducer -> user -> getUserStats: Getting userStats information`);
  const api: API = API.getInstance();
  try {
    const stats: UserStats = await api.get(`/user/stats`);
    Logger.debug(`Reducer -> user -> getUserStats: userStats information received.`);
    return stats;
  } catch (e: any) {
    Logger.warn(`Reducer -> user -> userStats error: ${e.message}`);
    throw e;
  }
});

export const getUserActivitiesCompleted = createAsyncThunk('getUserActivityCompleted', async () => {
  Logger.info(`Reducer -> user -> getUserActivityCompleted: Getting getUserActivityCompleted information`);
  const api: API = API.getInstance();
  try {
    const stats: UserActivity[] = await api.get(`user/activity/completed`);
    Logger.debug(`Reducer -> user -> getUserActivityCompleted: UserActivityCompleted information received.`);
    return stats;
  } catch (e: any) {
    Logger.warn(`Reducer -> user -> getUserActivityCompleted Completed: ${e.message}`);
    throw e;
  }
});

const isRejectedAction = (action: AnyAction): action is RejectedAction<any, any> => action.type.endsWith('/rejected');

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    skipUserDetails(state) {
      Logger.debug(`Reducer -> user -> skipUserDetails: User details wizard skipped.`);
      state.userDetailsSkipped = true;
    },
    skipMarae(state) {
      Logger.debug(`Reducer -> user -> skipMarae: Marae wizard skipped.`);
      state.maraeSkipped = true;
    },
    confirmUser(state) {
      Logger.debug(`Reducer -> user -> confirmUser: User confirmed`);
      state.isConfirmed = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUser.fulfilled, (state, action) => {
        const user = action.payload;
        if (user) {
          state.user = user;
        }
        if (!user.firstName) {
          // if the user doesn't have information, we need to display the confirmation screen
          state.needsConfirmation = true;
        }
        state.loading = false;
        state.error = null;
      })
      .addCase(getUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(patchUser.fulfilled, (state, action) => {
        const user = action.payload;
        if (user) {
          state.user = { ...state.user, ...user };
        }
        state.backgroundLoading = false;
        state.error = null;
      })
      .addCase(patchUser.pending, (state) => {
        state.backgroundLoading = true;
        state.error = null;
      })
      .addCase(postUserMarae.fulfilled, (state, action) => {
        const whanauMarae = action.payload;
        if (whanauMarae && state.user) {
          if (state.user.marae) {
            state.user.marae.push(whanauMarae);
          } else {
            state.user.marae = [whanauMarae];
          }
        }
        state.backgroundLoading = false;
        state.error = null;
      })
      .addCase(postUserMarae.pending, (state) => {
        state.backgroundLoading = true;
        state.error = null;
      })
      .addCase(getUserWhanau.fulfilled, (state, action) => {
        const whanau = action.payload;
        if (whanau) {
          state.whanau = whanau;
        }
        state.backgroundLoading = false;
        state.error = null;
      })
      .addCase(getUserWhanau.pending, (state) => {
        state.backgroundLoading = true;
        state.error = null;
      })
      .addCase(postUserWhanau.fulfilled, (state, action) => {
        state.whanau = action.payload;
        state.backgroundLoading = false;
        state.loading = false;
        state.error = null;
      })
      .addCase(postUserWhanau.pending, (state) => {
        state.loading = true;
        state.backgroundLoading = true;
        state.error = null;
      })
      .addCase(patchUserWhanau.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(patchUserWhanau.fulfilled, (state, action) => {
        state.whanau = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(postUserPhoto.fulfilled, (state, action) => {
        const { userId, payload } = action.payload;
        const { url } = payload.image;
        if (state.user?.userId === userId) {
          state.user.imageUrl = url;
        } else {
          const index = state.whanau?.findIndex((item) => item.userId === userId!);
          state.whanau![index!].imageUrl = url;
        }
        state.backgroundLoading = false;
        state.error = null;
      })
      .addCase(postUserPhoto.pending, (state) => {
        state.backgroundLoading = true;
        state.error = null;
      })
      .addCase(deleteUserPhoto.fulfilled, (state, action) => {
        const userId = action.payload;
        if (state.user?.userId === userId) {
          state.user.imageUrl = null;
        } else {
          const index = state.whanau?.findIndex((item) => item.userId === userId!);
          state.whanau![index!].imageUrl = null;
        }
        state.backgroundLoading = false;
        state.error = null;
      })
      .addCase(deleteUserPhoto.pending, (state) => {
        state.backgroundLoading = true;
        state.error = null;
      })
      .addCase(getMaraeStats.fulfilled, (state, action) => {
        state.maraeStats = action.payload;
      })
      .addCase(deleteUserMarae.pending, (state) => {
        state.backgroundLoading = true;
        state.error = null;
      })
      .addCase(deleteUserMarae.fulfilled, (state) => {
        state.backgroundLoading = false;
        state.error = null;
      })
      .addCase(getUserStats.fulfilled, (state, action) => {
        state.userStats = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getUserStats.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUserActivitiesCompleted.fulfilled, (state, action) => {
        state.activitiesCompleted = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getUserActivitiesCompleted.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(purge, (state) => {
        Logger.debug(`Reducer -> user: Purge request received.`);
        state.user = null;
        state.loading = false;
        state.backgroundLoading = false;
        state.userDetailsSkipped = false;
        state.maraeSkipped = false;
        state.needsConfirmation = false;
        state.isConfirmed = false;
        state.error = null;
        state.whanau = [];
        state.tipModal = null;
        state.maraeStats = null;
        state.userStats = null;
        state.activitiesCompleted = null;
      })
      .addMatcher(isRejectedAction, (state, action) => {
        state.loading = false;
        state.backgroundLoading = false;
        state.error = action.error;
      });
  },
});

export const { skipMarae, skipUserDetails, confirmUser } = userSlice.actions;
export default userSlice.reducer;
