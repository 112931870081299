import React from 'react';
import { View, Image, StyleSheet } from 'react-native';
import { Colors } from '../constants/Colors';
import { UIText } from './StyledText';
import i18n from '../translations';
import Fonts from '../constants/Fonts';

interface NoteInfoProps {
  message: 'addMarae' | 'points' | 'removeMarae' | 'tapPlusWhanau' | 'tapPlusMarae';
  bgcolor?: 'red' | 'white';
  maraeName?: string;
}

const NoteInfo = ({ message, bgcolor, maraeName }: NoteInfoProps) => (
  <View style={[styles.note, { backgroundColor: bgcolor === 'red' ? Colors.marron : Colors.white }]}>
    {message !== 'removeMarae' && message !== 'tapPlusWhanau' && message !== 'tapPlusMarae' && (
      <View style={[styles.noteIconContainer, { borderWidth: 2, borderColor: Colors.marron }]}>
        <Image source={require('../../assets/images/megaphoneIcon.png')} style={styles.noteIcon} />
      </View>
    )}
    <View style={styles.noteLabel}>
      <UIText style={[styles.noteText, { color: bgcolor === 'red' ? Colors.white : Colors.black }]}>
        {message === 'addMarae' && i18n.t('components.NoteInfo.addMarae')}
        {message === 'points' && `${maraeName} ${i18n.t('components.NoteInfo.points')}`}
        {message === 'removeMarae' &&
          `${i18n.t('components.NoteInfo.removeMarae1')} ${maraeName} ${i18n.t('components.NoteInfo.removeMarae2')}`}
        {message === 'tapPlusMarae' && `${i18n.t('components.NoteInfo.tapPlusMarae')}`}
        {message === 'tapPlusWhanau' && `${i18n.t('components.NoteInfo.tapPlusWhanau')}`}
      </UIText>
    </View>
  </View>
);

export default NoteInfo;

const styles = StyleSheet.create({
  note: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignSelf: 'center',
    borderRadius: 5,
    width: '100%',
    padding: 5,
  },
  noteIconContainer: {
    backgroundColor: Colors.white,
    borderRadius: 20,
    width: 30,
    height: 30,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 10,
  },
  noteIcon: {
    height: 20,
    width: 20,
  },
  noteLabel: {
    paddingHorizontal: 10,
    paddingVertical: 5,
    width: '90%',
  },
  noteText: {
    color: Colors.white,
    fontSize: Fonts.sizes.medium,
  },
});
