import React from 'react';
import { View } from '../../components/Themed';
import { FlatList, StyleSheet } from 'react-native';
import { Paragraph } from '../../components/StyledText';
import i18n from '../../translations';
import { User, UserActivity } from '../../constants/Interfaces';
import { Colors } from '../../constants/Colors';
import { useSelector } from 'react-redux';
import Fonts from '../../constants/Fonts';
import Logger from '../../constants/Logger';
import FavouriteActivityFragment from '../home/fragments/FavouriteActivityFragment';
import ScreenHolder from '../../components/ScreenHolder';
import { useNavigation } from '@react-navigation/native';
import Layout from '../../constants/Layout';
import useLogoutUser from '../../hooks/useLogoutUser';

export const FavesScreen = () => {
  const navigation = useNavigation();
  const user: User = useSelector((state: any) => state.user.user);

  const myActivities: UserActivity[] = useSelector((state: any) => state.activity.myActivities)
    .filter((item: UserActivity) => item.userId === user.userId)
    .sort((a, b) => a.startDate - b.startDate);

  const favActivities = myActivities.filter((item) => item.favourite);

  const handleOpenActivity = (activityId: string) => {
    Logger.debug(`Screen -> ActivitiesScreen -> handleOpenActivity: Navigating to activity ${activityId}`);
    navigation.navigate('FaveActivity', {
      activityId,
    });
  };

  useLogoutUser();

  return (
    <ScreenHolder navigation={navigation}>
      <View style={styles.root} transparent>
        <Paragraph style={styles.sectionTitle}>{i18n.t('components.UserDashboardFragment.mostFav')}</Paragraph>
        <FlatList
          data={favActivities}
          keyExtractor={(item) => item.activityId}
          renderItem={({ item }) => (
            <FavouriteActivityFragment activity={item} onPress={() => handleOpenActivity(item.activityId)} />
          )}
        />
      </View>
      <View style={{ height: Layout.isSmallDevice ? 200 : 150, backgroundColor: Colors.transparent }} />
    </ScreenHolder>
  );
};

const styles = StyleSheet.create({
  root: {
    marginHorizontal: 5,
    marginTop: 60,
    minHeight: Layout.isSmallDevice ? Layout.window.height - 200 : Layout.window.height - 100,
  },
  title: {
    color: Colors.white,
    fontSize: Fonts.sizes.bigHeading,
    fontFamily: Fonts.family.bold,
  },
  sectionTitle: {
    color: Colors.yellow,
    fontFamily: Fonts.family.bold,
    marginVertical: 10,
  },
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.darkBurgundy,
  },
  titleLogout: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  nextActivityContainer: {
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
  },
});
