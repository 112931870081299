import React from 'react';
import { StyleSheet, Pressable, Image, ImageSourcePropType } from 'react-native';
import { Colors } from '../../constants/Colors';
import Fonts from '../../constants/Fonts';
import { UIText } from '../StyledText';

interface SecondaryButtonProps {
  icon?: ImageSourcePropType;
  title: string;
  bgColor?: 'yellow' | 'white';
  onPress: () => void;
  styleButton?: {};
  styleText?: {};
  styleIcon?: {};
  iconRight?: any;
}

const SecondaryButton = ({
  icon,
  title,
  bgColor = 'yellow',
  onPress,
  styleButton,
  styleText,
  styleIcon,
  iconRight,
}: SecondaryButtonProps) => (
  <Pressable
    onPress={onPress}
    style={[styles.container, { backgroundColor: bgColor === 'yellow' ? Colors.yellow : Colors.white }, styleButton]}
  >
    {icon ? <Image style={[styles.icon, styleIcon]} source={icon} /> : null}
    <UIText style={[styles.label, styleText]}>{title}</UIText>
    {iconRight || null}
  </Pressable>
);

export default SecondaryButton;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '50%',
    height: 45,
    borderRadius: 50,
  },
  icon: {
    width: 15,
    height: 15,
    marginRight: 10,
  },
  label: {
    fontFamily: Fonts.family.bold,
    fontSize: Fonts.sizes.default,
  },
});
