import * as React from 'react';
import { BackHandler } from 'react-native';
import { createDrawerNavigator } from '@react-navigation/drawer';
import HamburgerMenu from '../components/HamburgerMenu';
import { DrawerParamList } from '../constants/Interfaces';
import ConfirmationScreen from '../screens/ConfirmationScreen';
import { ProfileNavigator } from './ProfileNavigator';
import { ActivitiesNavigator } from './ActivitiesNavigator';
import { HomeNavigator } from './HomeNavigator';
import Layout from '../constants/Layout';
import { FavesNavigation } from './FavesNavigation';
import { useNavigation } from '@react-navigation/native';
import { LeaderboardNavigation } from './LeaderboardNavigator';

const Drawer = createDrawerNavigator<DrawerParamList>();

export default function DrawerNavigation() {
  const navigation = useNavigation();
  React.useEffect(
    () =>
      navigation.addListener('beforeRemove', (e) => {
        e.preventDefault();
        BackHandler.exitApp();
      }),
    []
  );
  return (
    <Drawer.Navigator
      screenOptions={{
        drawerPosition: 'right',
        drawerType: 'front',
        headerShown: false,
        drawerStyle: {
          width: Layout.window.width * 0.88,
        },
      }}
      initialRouteName="DashboardStack"
      backBehavior="history"
      id="Drawer"
      drawerContent={(props) => <HamburgerMenu {...props} />}
    >
      <Drawer.Screen name="ProfileStack" component={ProfileNavigator} />
      <Drawer.Screen name="ActivitiesStack" component={ActivitiesNavigator} />
      <Drawer.Screen name="FavesStack" component={FavesNavigation} />
      <Drawer.Screen name="LeaderboardStack" component={LeaderboardNavigation} />
      <Drawer.Screen name="DashboardStack" component={HomeNavigator} />
      <Drawer.Screen name="ConfirmationScreen" component={ConfirmationScreen} />
    </Drawer.Navigator>
  );
}
