import { FavesScreen } from '../screens/faves/FavesScreen';
import * as React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { ActivityDetailsScreen } from '../screens/activities/ActivityDetailsScreen';
import { useNavigation } from '@react-navigation/native';

const ProfileStack = createStackNavigator();

export const FavesNavigation = () => {
  const navigation = useNavigation();

  React.useEffect(
    () =>
      navigation.addListener('beforeRemove', () => {
        navigation.navigate('DashboardStack');
      }),
    []
  );
  return (
    <ProfileStack.Navigator screenOptions={{ headerShown: false }}>
      <ProfileStack.Screen name="Faves" component={FavesScreen} />
      <ProfileStack.Screen name="FaveActivity" component={ActivityDetailsScreen} />
    </ProfileStack.Navigator>
  );
};
