import { createStackNavigator } from '@react-navigation/stack';
import { AuthParamList } from '../constants/Interfaces';
import * as React from 'react';
import LandingScreen from '../screens/authentication/LandingScreen';
import SocialMediaLogin from '../screens/authentication/SocialMediaLogin';
import Terms from '../screens/termsandconditions/Terms';
import Privacy from '../screens/termsandconditions/Privacy';

const AuthStack = createStackNavigator<AuthParamList>();

export default function AuthNavigator() {
  return (
    <AuthStack.Navigator initialRouteName="Landing" screenOptions={{ headerShown: false }}>
      <AuthStack.Screen name="Landing" component={LandingScreen} />
      <AuthStack.Screen name="SocialMediaLogin" component={SocialMediaLogin} />
      <AuthStack.Screen name="Privacy" component={Privacy} />
      <AuthStack.Screen name="Terms" component={Terms} />
    </AuthStack.Navigator>
  );
}
