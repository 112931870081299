import React from 'react';
import { View, StyleSheet, Pressable, Image } from 'react-native';
import { useSelector } from 'react-redux';
import { UIText } from '../../components/StyledText';
import { Colors } from '../../constants/Colors';
import Fonts from '../../constants/Fonts';
import { Activity, User, UserMarae } from '../../constants/Interfaces';
import i18n from '../../translations';
import { DateTime } from 'luxon';
import { shareContent } from '../../constants/Utils';

const ElevateContent = () => {
  const myMaraes: UserMarae[] = useSelector((state: any) => state.user.user.marae);
  const myWhanau: User[] = useSelector((state: any) => state.user.whanau);
  const user: User = useSelector((state: any) => state.user.user);
  const activities: Activity[] = useSelector((state: any) => state.activity.activities);
  const myRegions = myMaraes.map((item) => item.maraeLocation?.regionName);

  const comingActivities = () => {
    let count = 0;
    for (let i = 0; i < myRegions.length; i += 1) {
      for (let j = 0; j < activities.length; j += 1) {
        if (myRegions[i] === activities[j].location?.regionName) {
          count = +1;
        }
      }
    }
    return count;
  };
  const daysLeft = () => {
    const today = DateTime.now();
    const year = today.month > 3 ? today.year + 1 : today.year;
    const nextYear = DateTime.local(year, 4, 1);
    const diff = nextYear.diff(today, 'days').days.toFixed(0);
    return diff;
  };
  const getPoints = (age: number) => {
    if (age >= 5 && age <= 12) {
      return 15;
    }
    if (age >= 13 && age <= 20) {
      return 20;
    }
    if (age >= 21 && age <= 49) {
      return 30;
    }
    if (age > 50) {
      return 50;
    }
    return 0;
  };
  const getAge = (date) => DateTime.now().diff(DateTime.fromISO(date), 'years').years.toFixed(0);

  const getTotalPoints = () => {
    const ages = myWhanau.map((item) => +getAge(item?.doB));
    ages.push(+getAge(user.doB));
    const points: number[] = ages.map((item: number) => getPoints(item));
    const totalPoints = points.reduce((acc, current) => acc + current);
    return totalPoints;
  };

  return (
    <View>
      <UIText style={styles.title}>{i18n.t('components.ElevateContent.title')}</UIText>
      <View style={styles.subTitleContainer}>
        <UIText style={styles.subTitle}>{i18n.t('components.ElevateContent.getInvolved')}</UIText>
      </View>
      <UIText style={styles.paragraph}>
        {i18n.t('components.ElevateContent.description', {
          comingActivities: comingActivities(),
          days: daysLeft(),
          points: getTotalPoints() * comingActivities(),
        })}
      </UIText>
      <View style={styles.subTitleContainer}>
        <UIText style={styles.subTitle}>{i18n.t('components.ElevateContent.spread')}</UIText>
      </View>
      <UIText style={styles.paragraph}>{i18n.t('components.ElevateContent.fbMessage')}</UIText>
      <Pressable onPress={() => shareContent()}>
        <Image style={styles.fbIcon} source={require('../../../assets/images/shareFb.png')} />
      </Pressable>
    </View>
  );
};

export default ElevateContent;
const styles = StyleSheet.create({
  title: {
    fontFamily: Fonts.family.bold,
    fontSize: Fonts.sizes.smallHeading,
    marginBottom: 20,
  },
  subTitleContainer: {
    marginVertical: 10,
    paddingBottom: 5,
    borderBottomColor: Colors.yellow,
    borderBottomWidth: 2,
  },
  subTitle: {
    fontFamily: Fonts.family.bold,
    color: Colors.yellow,
  },
  paragraph: {
    fontSize: Fonts.sizes.medium,
  },
  fbIcon: {
    width: 100,
    height: 40,
    resizeMode: 'contain',
    marginTop: 30,
  },
});
