import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Heading, Paragraph, UIText } from '../../../components/StyledText';
import RatingStars from '../../../components/RatingStars';
import i18n from '../../../translations';
import { Review } from '../../../constants/Interfaces';
import { convertToNZ } from '../../../constants/Utils';

interface ReviewFragmentProps {
  content: Review;
}

const ReviewFragment = ({ content }: ReviewFragmentProps) => {
  const { firstName, lastName, score = 0, review, lastUpdated } = content;
  return (
    <View style={styles.root}>
      <Heading>{i18n.t('components.Review.review')}</Heading>
      <Paragraph style={styles.subTitle}>
        {firstName} {lastName}
      </Paragraph>
      <View style={styles.reviewContainer}>
        <RatingStars stars={score} />
        <UIText style={styles.review}>{review}</UIText>
        <UIText>_</UIText>
        <UIText>{convertToNZ({ date: lastUpdated!, format: 'MMMM dd, yyyy' })}</UIText>
      </View>
    </View>
  );
};

export default ReviewFragment;

const styles = StyleSheet.create({
  root: {
    height: '100%',
    marginHorizontal: '10%',
    alignItems: 'center',
  },
  imageStyle: {
    width: 100,
    height: 100,
  },
  subTitle: {
    marginTop: 20,
    marginBottom: 30,
  },
  reviewContainer: {
    alignSelf: 'flex-start',
  },
  review: {
    marginVertical: 15,
  },
});
