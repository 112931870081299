import React from 'react';
import { View, Image, StyleSheet, ScrollView, Pressable } from 'react-native';
import TermsConditions from '../../components/TermsConditions';
import { Colors } from '../../constants/Colors';
import Fonts from '../../constants/Fonts';
import Layout from '../../constants/Layout';
import { AntDesign } from '@expo/vector-icons';

interface TermsProps {
  navigation: any;
}

const Terms = ({ navigation }: TermsProps) => {
  function close() {
    navigation.goBack();
  }

  return (
    <>
      <ScrollView bounces={false} style={styles.scrollContainer}>
        <View style={[styles.root]}>
          <Image
            source={require('../../../assets/images/splash_top.png')}
            style={styles.topImage}
            resizeMethod="resize"
          />
          <Image
            source={require('../../../assets/images/mf_logo_dark_no_text.png')}
            style={styles.logo}
            resizeMethod="scale"
          />
          <View style={styles.body}>
            <TermsConditions />
          </View>
          <Image
            source={require('../../../assets/images/splash_bottom.png')}
            style={styles.bottomImage}
            resizeMethod="resize"
          />
        </View>
      </ScrollView>
      <View style={styles.closeContainer}>
        <Pressable style={styles.close} onPress={() => close()}>
          <AntDesign name="close" size={24} color={Colors.black} />
        </Pressable>
      </View>
    </>
  );
};
export default Terms;

const styles = StyleSheet.create({
  scrollContainer: {
    width: '100%',
    backgroundColor: Colors.backgroundYellow,
  },
  closeContainer: {
    top: 0,
    right: 0,
    position: 'absolute',
  },
  root: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.backgroundYellow,
    minHeight: Layout.window.height,
  },
  topImage: {
    position: 'absolute',
    top: 10,
    height: 200,
    resizeMode: 'contain',
    opacity: 0.5,
  },
  logo: {
    width: 100,
    height: 100,
    resizeMode: 'contain',
    opacity: 1,
    zIndex: 2,
    marginTop: 40,
    marginBottom: 60,
  },
  body: {
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: '5%',
    paddingHorizontal: 10,
    marginBottom: 80,
  },
  headerContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  paragraph: {
    marginTop: 27,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    marginBottom: 20,
  },
  headerDividers: {
    width: '100%',
    height: 10,
    marginBottom: 20,
  },
  buttonsContainer: {
    width: '80%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    flexDirection: 'row',
    height: 45,
    backgroundColor: 'white',
    borderRadius: 37,
    paddingVertical: 13,
    paddingHorizontal: 16,
    fontWeight: 'bold',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    marginVertical: 10,
  },
  buttonImg: {
    height: 25,
    width: 25,
    marginRight: 14.7,
    resizeMode: 'contain',
  },
  buttonText: {
    fontFamily: Fonts.family.bold,
  },
  bottomImage: {
    position: 'absolute',
    bottom: 0,
    width: Layout.window.width,
    height: 150,
    resizeMode: 'cover',
    zIndex: -1,
  },
  icons: {
    position: 'absolute',
    width: '100%',
    top: 12,
    left: 0,
    zIndex: 100,
  },
  close: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 30,
    right: 15,
    width: 50,
    height: 50,
    backgroundColor: Colors.whiteOpacitiy40,
    borderRadius: 45,
  },
});
