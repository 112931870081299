import * as React from 'react';
import { Activity, ActivityStats } from '../constants/Interfaces';
import { Pressable, StyleSheet, Image } from 'react-native';
import { Colors } from '../constants/Colors';
import { UIText } from './StyledText';
import Fonts from '../constants/Fonts';
import { View } from './Themed';
import i18n from '../translations';
import { useDispatch, useSelector } from 'react-redux';
import { getActivityStats } from '../reducers/activity.slice';
import { getCacheImage, validatePastActivity } from '../constants/Utils';
import Logger from '../constants/Logger';
import { useNavigation } from '@react-navigation/native';

interface ActivityTileProps {
  activity: Activity;
  onPress: (activityId: string) => void;
  background?: 'white' | 'red';
  whiteWidth?;
}

export const ActivityTile = ({ activity, onPress, background = 'red', whiteWidth }: ActivityTileProps) => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const activityStats: ActivityStats = useSelector((state: any) => state.activity.stats[activity.activityId]);
  const [activityImage, setActivityImage] = React.useState<string | undefined>(activity?.imageUrl);
  const handlePress = () => {
    onPress(activity.activityId);
  };
  const isFocused = navigation.isFocused();
  React.useEffect(() => {
    if (isFocused) {
      dispatch(getActivityStats(activity?.activityId));
    }
  }, [isFocused]);
  const goingCount = activityStats ? activityStats.engagementTypeGoing.personTotal : 0;
  const isPastActivity = validatePastActivity(activity.endDate);
  const cacheImage = async () => {
    try {
      const uri = await getCacheImage({ activityId: activity.activityId, uri: activity.imageUrl });
      setActivityImage(uri);
    } catch (e) {
      Logger.debug(e);
    }
  };
  const participantsLabel = () => {
    if (isPastActivity) {
      return `Completed - ${goingCount} Whānau attended`;
    }
    return i18n.t('components.ActivityTile.whanaugoing', { goingCount });
  };
  React.useEffect(() => {
    cacheImage();
  }, [activity]);

  return (
    <Pressable
      style={[
        styles.container,
        background === 'red'
          ? { backgroundColor: Colors.tileBurgundy, width: 230, height: 230, marginRight: 8 }
          : {
              backgroundColor: Colors.white,
              width: whiteWidth || '49%',
              marginBottom: 10,
            },
      ]}
      onPress={handlePress}
    >
      {activity && activityImage ? (
        <Image
          source={{ uri: activityImage }}
          style={[
            styles.coverImage,
            background === 'red' ? styles.imageDefaultSize : styles.imageAlternateSize,
            { resizeMode: 'cover' },
          ]}
        />
      ) : (
        <View transparent style={[background === 'red' ? styles.imageDefaultSize : styles.imageAlternateSize]} />
      )}
      <UIText
        numberOfLines={1}
        style={[
          styles.addressLabel,
          background === 'red'
            ? { color: Colors.white, fontSize: Fonts.sizes.medium }
            : { color: Colors.black, fontSize: Fonts.sizes.mini },
        ]}
      >
        {activity.location?.addressLine1}
      </UIText>
      <UIText
        style={[
          styles.activityName,
          background === 'red'
            ? { color: Colors.white, height: 50, fontSize: Fonts.sizes.smallHeading }
            : { color: Colors.black, fontSize: Fonts.sizes.medium, height: 40 },
        ]}
      >
        {activity.activityName}
      </UIText>
      <View transparent style={styles.goingContainer}>
        <View style={[styles.dot, { backgroundColor: isPastActivity ? Colors.darkBurgundy : Colors.yellow }]} />
        <UIText style={[styles.goingLabel, background === 'red' ? { color: Colors.white } : { color: Colors.black }]}>
          {participantsLabel()}
        </UIText>
      </View>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 5,
    padding: 8,
  },
  coverImage: {
    borderRadius: 5,
    alignSelf: 'center',
    resizeMode: 'cover',
  },
  imageDefaultSize: {
    width: 214,
    height: 102,
    marginBottom: 12,
  },
  imageAlternateSize: {
    width: '100%',
    height: 80,
    marginBottom: 4,
  },
  addressLabel: {
    color: Colors.white,
    marginBottom: 4,
  },
  activityName: {
    color: Colors.white,
    fontFamily: Fonts.family.bold,
    marginBottom: 8,
    flexWrap: 'wrap',
    overflow: 'hidden',
  },
  goingContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  goingLabel: {
    marginLeft: 6,
    fontSize: Fonts.sizes.mini,
    opacity: 0.5,
  },
  dot: {
    marginTop: 1,
    height: 4,
    width: 4,
    borderRadius: 4,
  },
});
