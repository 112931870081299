import * as React from 'react';
import { TakeWhanauFragment } from './TakeWhanauFragment';
import { ChooseMaraeFragment } from './ChooseMaraeFragment';
import Logger from '../../../constants/Logger';
import { Activity, User, UserActivity, UserMarae, WhanauType } from '../../../constants/Interfaces';
import _ from 'lodash';
import { ChooseWhanauTypeFragment } from './ChooseWhanauTypeFragment';

interface ActivityRegistrationFragmentProps {
  onDismiss: () => void;
  activity: Activity;
  myActivity: UserActivity | null;
}

export const ActivityRegistrationFragment = ({
  onDismiss,
  activity,
  myActivity,
}: ActivityRegistrationFragmentProps) => {
  const [index, setIndex] = React.useState<number>(0);
  const [selectedWhanaus, setSelectedWhanaus] = React.useState<User[]>([]);
  const [selectedMaraes, setSelectedMaraes] = React.useState<UserMarae[]>([]);
  const [selectedWhanauType, setSelectedWhanauType] = React.useState<WhanauType[]>([]);

  const handleToggleWhanau = (user: User) => {
    Logger.debug(`Toggling whanau for participation: ${user.userId}`);
    setSelectedWhanaus((whanaus) => {
      const itemIndex = _.findIndex(whanaus, ['userId', user.userId]);
      if (itemIndex > -1) {
        whanaus.splice(itemIndex, 1);
        return [...whanaus];
      }
      return [...whanaus, user];
    });
  };

  // Only one marae can be selected at a given time
  const handleToggleMarae = (userMarae: UserMarae) => {
    Logger.debug(`Toggling marae for participation: ${userMarae.maraeName}`);
    setSelectedMaraes((maraes) => {
      const itemIndex = _.findIndex(maraes, ['maraeId', userMarae.maraeId]);
      if (itemIndex > -1) {
        return [];
      }
      return [userMarae];
    });
  };

  // Only one marae can be selected at a given time
  const handleToggleWhanauType = (whanauType: WhanauType) => {
    Logger.debug(`Toggling whanau type for participation: ${whanauType}`);
    setSelectedWhanauType((whanauTypes) => {
      const itemIndex = _.findIndex(whanauTypes, (type) => type === whanauType);
      if (itemIndex > -1) {
        return [];
      }
      return [whanauType];
    });
  };

  switch (index) {
    case 0: {
      return (
        <TakeWhanauFragment
          onNext={() => setIndex(1)}
          onBack={onDismiss}
          selectedWhanaus={selectedWhanaus}
          toggleWhanau={handleToggleWhanau}
          activityId={activity.activityId}
        />
      );
    }
    case 1: {
      return (
        <ChooseMaraeFragment
          onBack={() => setIndex(0)}
          onNext={() => setIndex(2)}
          selectedWhanaus={selectedWhanaus}
          selectedMaraes={selectedMaraes}
          toggleMarae={handleToggleMarae}
          activity={activity}
          myActivity={myActivity}
        />
      );
    }
    case 2: {
      return (
        <ChooseWhanauTypeFragment
          onBack={() => setIndex(1)}
          onComplete={onDismiss}
          selectedWhanaus={selectedWhanaus}
          selectedMaraes={selectedMaraes}
          selectedWhanauType={selectedWhanauType}
          toggleWhanauType={handleToggleWhanauType}
          activity={activity}
          myActivity={myActivity}
        />
      );
    }
    default: {
      return null;
    }
  }
};
