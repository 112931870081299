import React, { useEffect, useRef } from 'react';
import { View, StyleSheet, Animated, ImageBackground, RefreshControl, Image, Platform } from 'react-native';
import { Colors } from '../constants/Colors';
import HeaderBar from './HeaderBar';
import BottomBanner from './BottomBanner';
import Layout from '../constants/Layout';

interface RefreshHolderProps {
  children;
  navigation;
  refresh;
  footerColor?: 'red';
  backgroundColorContent?: 'white';
  footerOff?: boolean;
}

const RefreshHolder = ({
  children,
  navigation,
  refresh,
  footerColor,
  backgroundColorContent,
  footerOff = false,
}: RefreshHolderProps) => {
  const { yellow, darkBurgundy } = Colors;
  const scrollPosition = useRef(new Animated.Value(0)).current;
  const [refreshing, setRefreshing] = React.useState(false);
  const isIOS = Platform.OS === 'ios';
  const animatedValues = () => (isIOS ? 5 * 18 : 5 * 16);
  const minHeaderHeight = 60;

  const headerHeight = scrollPosition.interpolate({
    inputRange: [-100, 0, 200],
    outputRange: [300, 300, 10],
    extrapolate: 'extend',
  });

  const headerBarHeight = scrollPosition.interpolate({
    inputRange: [-100, 0, 200, 2000],
    outputRange: [80, 80, minHeaderHeight, minHeaderHeight],
    extrapolate: 'extend',
  });
  const ellipseHeight = scrollPosition.interpolate({
    inputRange: [-2000, 0, 200, 2000],
    outputRange: [60, 60, 30, 30],
    extrapolateLeft: 'identity',
    extrapolateRight: 'extend',
  });
  const imagePosition = scrollPosition.interpolate({
    inputRange: [-2000, 0, 200, 2000],
    outputRange: [10, 10, 20, 20],
    extrapolateLeft: 'identity',
    extrapolateRight: 'extend',
  });
  const backgroundColorHeader = scrollPosition.interpolate({
    inputRange: [-2000, 0, 100, 2000],
    outputRange: [yellow, yellow, darkBurgundy, darkBurgundy],
    extrapolateLeft: 'identity',
    extrapolateRight: 'clamp',
  });

  const opacityImage = scrollPosition.interpolate({
    inputRange: [0, 100, 2000],
    outputRange: [1, 0, 0],
    extrapolateRight: 'clamp',
  });
  const opacityProfileFetch = scrollPosition.interpolate({
    inputRange: [-200, 0, animatedValues()],
    outputRange: [1, 1, 1],
    extrapolateRight: 'clamp',
  });

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      onRefresh();
    });
    return unsubscribe;
  }, [navigation]);

  const onRefresh = React.useCallback(async () => {
    setRefreshing(true);
    await refresh();
    setRefreshing(false);
  }, []);

  const showFooter = () => {
    if (footerOff) {
      return <></>;
    }
    if (footerColor) {
      return (
        <>
          <Image
            source={require('../../assets/images/screenHolder_footer.png')}
            style={styles.bottomImage}
            resizeMethod="resize"
          />
          <BottomBanner themeColor="red" />
        </>
      );
    }
    return <BottomBanner themeColor="white" />;
  };
  return (
    <>
      <View>
        <HeaderBar
          headerBarHeight={headerBarHeight}
          imagePosition={imagePosition}
          backgroundColorHeader={backgroundColorHeader}
          navigation={navigation}
          ellipseHeight={ellipseHeight}
          opacityProfile={opacityProfileFetch}
          isRefreshHolder
        />
        <Animated.View style={[styles.ViewContainer, { backgroundColor: backgroundColorHeader }]}>
          <Animated.ScrollView
            contentInsetAdjustmentBehavior="automatic"
            scrollEventThrottle={16}
            refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}
            onScroll={Animated.event(
              [
                {
                  nativeEvent: {
                    contentOffset: {
                      y: scrollPosition,
                    },
                  },
                },
              ],
              {
                useNativeDriver: false,
              }
            )}
          >
            <View style={{ backgroundColor: Colors.darkBurgundy }}>
              <View>
                <Animated.View
                  style={[
                    styles.heroImage,
                    {
                      backgroundColor: backgroundColorHeader,
                      height: headerHeight,
                    },
                  ]}
                >
                  <ImageBackground
                    source={require('../../assets/images/app_header.png')}
                    style={[styles.imageBackground]}
                  />
                </Animated.View>
                <View style={{ backgroundColor: darkBurgundy, height: 5 }} />
              </View>
            </View>
            <View style={{ backgroundColor: darkBurgundy }}>
              <Animated.View style={[styles.handleBar, { opacity: opacityImage }]} />
            </View>
            <View
              style={{
                backgroundColor: backgroundColorContent ?? Colors.darkBurgundy,
              }}
            >
              {children}
            </View>
          </Animated.ScrollView>
        </Animated.View>
      </View>
      {showFooter()}
    </>
  );
};

export default RefreshHolder;

const styles = StyleSheet.create({
  scrollView: {
    backgroundColor: Colors.darkBurgundy,
  },
  heroImage: {
    backgroundColor: Colors.darkBurgundy,
  },
  imageBackground: {
    flex: 1,
  },
  ViewContainer: {
    minHeight: '100%',
    backgroundColor: Colors.darkBurgundy,
  },
  scrollViewContainer: {
    backgroundColor: Colors.darkBurgundy,
    minHeight: Layout.window.height + 150,
  },
  handleBar: {
    backgroundColor: Colors.handleBar,
    height: 4,
    width: 80,
    borderRadius: 50,
    alignSelf: 'center',
    marginVertical: 10,
  },

  bottomImage: {
    position: 'absolute',
    bottom: 20,
    width: Layout.window.width,
    height: 105,
    resizeMode: 'contain',
    backgroundColor: Colors.white,
  },
});
