import { MaterialIcons } from '@expo/vector-icons';
import React from 'react';
import { Pressable, View, StyleSheet } from 'react-native';
import { Colors } from '../constants/Colors';
import { TicketActivity } from '../constants/Interfaces';
import { UIText } from './StyledText';

interface SelectionItemProps {
  item: TicketActivity;
  isSelected;
  setList;
}
export const SelectionItem = ({ item, isSelected, setList }: SelectionItemProps) => (
  <View style={styles.whanauList}>
    <Pressable
      onStartShouldSetResponderCapture={() => true}
      onMoveShouldSetResponderCapture={() => true}
      style={styles.whanauContainer}
      onPress={() => setList(item)}
    >
      <UIText style={styles.whanauName}>{item.name}</UIText>
      <View>
        {isSelected && (
          <View style={styles.checkIcon}>
            <MaterialIcons name="check" size={16} color={Colors.black} />
          </View>
        )}
      </View>
    </Pressable>
  </View>
);

const styles = StyleSheet.create({
  whanauList: {
    flexDirection: 'row',
    alignItems: 'center',

    height: 50,
    width: '95%',
    borderBottomWidth: 1,
    borderBottomColor: Colors.lightGrey,
  },
  whanauContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
    height: 50,
  },
  whanauName: {
    marginLeft: 15,
    color: Colors.black,
  },
  checkIcon: {
    backgroundColor: Colors.green,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 15,
    width: 20,
    height: 20,
  },
});
