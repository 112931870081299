import React, { useEffect } from 'react';
import { View, Image, StyleSheet, Pressable, Platform, Text } from 'react-native';
import { Colors } from '../constants/Colors';
import { Paragraph, UIText } from './StyledText';
import { AuthState, User } from '../constants/Interfaces';
import { AntDesign } from '@expo/vector-icons';
import Layout from '../constants/Layout';
import { useDispatch, useSelector } from 'react-redux';
import { getUserStats, getUserWhanau } from '../reducers/user.slice';
import i18n from '../translations';
import Fonts from '../constants/Fonts';
import { getActivities } from '../reducers/activity.slice';
import { getMaraeLeaderboard, getMaraes } from '../reducers/marae.slice';
import { getReviews } from '../reducers/review.slice';
import { getGenderPlaceholder, logOut } from '../constants/Utils';
import Constants from 'expo-constants';

const HamburgerMenu = ({ state, navigation }) => {
  const isLoggedIn = navigation.getParent('Drawer');
  const user: User = useSelector((state: any) => state.user.user);
  const authState: AuthState = useSelector((state: any) => state.authentication.authState);
  const dispatch = useDispatch();
  const appName = Constants.manifest?.name;
  const formattedName = appName?.replaceAll(' MaraeFit', '');

  useEffect(() => {
    if (user) {
      dispatch(getUserWhanau());
      dispatch(getUserStats());
      dispatch(getMaraes());
      dispatch(getMaraeLeaderboard());
      dispatch(getReviews());
    }
    dispatch(getActivities(authState));
  }, []);

  return (
    <View style={styles.hamburger}>
      <View style={styles.backgroundImage}>
        <Image source={require('../../assets/images/drawer_default_Image.png')} style={styles.defaultImage} />
        <Pressable style={styles.closeButton} onPress={() => navigation.closeDrawer()}>
          <AntDesign name="close" size={24} color={Colors.black} />
        </Pressable>
        <Paragraph style={styles.title}>
          {!isLoggedIn || !user || !user.firstName ? 'Nau mai' : `Nau mai ${user.firstName} ${user.lastName}`}
        </Paragraph>
        <View style={styles.imageEllipse}>
          <Image
            source={
              !isLoggedIn || !user || !user.imageUrl ? getGenderPlaceholder(user?.gender) : { uri: user.imageUrl }
            }
            style={styles.profilePic}
          />
        </View>
      </View>
      <View style={styles.menuContainer}>
        <Text style={styles.versionNumber}>{`${formattedName}${Constants.manifest?.version}`}</Text>
        {isLoggedIn && (
          <Pressable
            style={styles.menuOption}
            onPress={() => {
              navigation.navigate('ProfileStack');
            }}
          >
            <Image source={require('../../assets/images/profileIcon.png')} style={styles.optionImage} />
            <UIText style={styles.optionLabel}>{i18n.t('components.HamburgerMenu.profile')}</UIText>
          </Pressable>
        )}
        <Pressable
          style={styles.menuOption}
          onPress={() => {
            navigation.navigate('ActivitiesStack', { screen: 'Activities' });
          }}
        >
          <Image source={require('../../assets/images/activitiesIcon.png')} style={styles.optionImage} />
          <UIText style={styles.optionLabel}>{i18n.t('components.HamburgerMenu.activities')}</UIText>
        </Pressable>
        {isLoggedIn && (
          <Pressable
            style={styles.menuOption}
            onPress={() => {
              navigation.navigate('FavesStack', {
                screen: 'Faves',
              });
            }}
          >
            <Image source={require('../../assets/images/favesIcon.png')} style={styles.optionImage} />
            <UIText style={styles.optionLabel}>{i18n.t('components.HamburgerMenu.faves')}</UIText>
          </Pressable>
        )}

        {isLoggedIn && (
          <Pressable
            style={styles.menuOption}
            onPress={() => {
              navigation.navigate('LeaderboardStack');
            }}
          >
            <Image source={require('../../assets/images/leaderboardIcon.png')} style={styles.optionImage} />
            <UIText style={styles.optionLabel}>{i18n.t('components.HamburgerMenu.leaderboard')}</UIText>
          </Pressable>
        )}

        {isLoggedIn && (
          <Pressable
            style={styles.menuOption}
            onPress={() => {
              navigation.navigate('DashboardStack', { screen: 'Home' });
            }}
          >
            <Image source={require('../../assets/images/dashboardIcon.png')} style={styles.optionImage} />
            <UIText style={styles.optionLabel}>{i18n.t('components.HamburgerMenu.dashboard')}</UIText>
          </Pressable>
        )}

        {isLoggedIn ? (
          <Pressable style={styles.menuOption} onPress={logOut}>
            <Image source={require('../../assets/images/logOffIcon.png')} style={styles.optionImage} />
            <UIText style={styles.optionLabel}>{i18n.t('components.HamburgerMenu.logOff')}</UIText>
          </Pressable>
        ) : (
          <Pressable style={styles.menuOption} onPress={() => navigation.navigate('SocialMediaLogin')}>
            <Image
              source={require('../../assets/images/mf_logo_dark_no_text.png')}
              style={[styles.optionImage, { borderRadius: 0 }]}
            />
            <UIText style={styles.optionLabel}>{i18n.t('components.HamburgerMenu.register')}</UIText>
          </Pressable>
        )}
      </View>
    </View>
  );
};
export default HamburgerMenu;

const styles = StyleSheet.create({
  hamburger: {
    flex: 1,
  },
  closeButton: {
    alignSelf: 'flex-end',
    justifyContent: 'flex-start',
    top: -60,
    marginRight: Layout.backgroundMarginNormal.right,
  },
  backgroundImage: {
    backgroundColor: Colors.defaultGrey,
    alignItems: 'center',
    height: Layout.window.height > 667 ? 200 : 175,
  },
  defaultImage: {
    width: 180,
    height: 100,
  },
  title: {
    color: Colors.white,
    fontFamily: Fonts.family.bold,
  },
  imageEllipse: {
    width: 70,
    height: 70,
    borderColor: Colors.yellow,
    borderWidth: 2,
    borderRadius: 70,
    bottom: Layout.window.height > 667 ? -20 : -5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  profilePic: {
    width: 60,
    height: 60,
    borderRadius: 60,
  },
  menuContainer: {
    flex: 1,
    alignItems: 'center',
    paddingTop:
      Layout.window.height > 740
        ? Layout.backgroundMarginNormal.bottom + 40
        : Layout.backgroundMarginNormal.bottom + 20,
    backgroundColor: Colors.white,
    zIndex: -1,
    elevation: -1,
  },

  menuOption: {
    justifyContent: 'center',
    alignItems: 'center',
    // eslint-disable-next-line no-nested-ternary
    marginBottom: Layout.isLongDevice ? (Platform.OS === 'ios' ? 20 : 10) : 5,
  },
  optionImage: {
    height: Layout.isLongDevice ? 40 : 30,
    width: Layout.isLongDevice ? 40 : 30,
    borderRadius: Layout.isLongDevice ? 40 : 30,
    margin: 8,
  },
  optionLabel: {
    fontSize: Fonts.sizes.medium,
  },
  versionNumber: {
    position: 'absolute',
    bottom: 5,
    right: 5,
    color: Colors.defaultGrey,
    fontSize: Fonts.sizes.small,
  },
});
