import React from 'react';
import { View, StyleSheet, Image } from 'react-native';
import { useSelector } from 'react-redux';
import { Colors } from '../constants/Colors';
import Fonts from '../constants/Fonts';
import { UserMarae } from '../constants/Interfaces';
import { UIText } from './StyledText';

interface ProgressBarProps {
  marae: {
    pointTotal: number;
    maraeId: number;
    region: string;
    maraeName: string;
    participationTotal: number;
  };
  position: number;
  top: number;
}
const ProgressBar = ({ marae, position, top }: ProgressBarProps) => {
  const myMarae: UserMarae[] = useSelector((state: any) => state.user.user.marae);
  const progress = ((marae.pointTotal * 85) / top).toFixed(0);
  const isMyMarae = myMarae.find((item: UserMarae) => item.maraeId === marae.maraeId);
  const imageUrl = require('../../assets/images/marae_placeholder.png');
  return (
    <View style={styles.progressContainer}>
      <View style={[styles.barContainer, styles.barPoints, { width: `${progress}%` }]} />
      <View style={[styles.barContainer, styles.scoreContainer]}>
        <UIText
          style={[styles.maraeBar, { color: isMyMarae ? Colors.pink : Colors.black, width: '85%' }]}
          numberOfLines={1}
          ellipsizeMode="tail"
        >
          {marae.maraeName.toUpperCase()}
        </UIText>
        <UIText style={[styles.maraeBar, { color: isMyMarae ? Colors.pink : Colors.black }]}>{marae.pointTotal}</UIText>
      </View>
      <View style={styles.MaraeIconContainer}>
        <Image source={imageUrl} style={styles.maraePic} />
      </View>
      <View style={[styles.position, { backgroundColor: isMyMarae ? Colors.pink : Colors.yellow }]}>
        <UIText style={styles.positionLabel}>{position}</UIText>
      </View>
    </View>
  );
};

export default ProgressBar;

const styles = StyleSheet.create({
  progressContainer: {
    height: 40,
    width: '100%',
    marginVertical: 10,
  },
  barContainer: {
    width: '85%',
    height: 35,
    backgroundColor: Colors.whiteOpacity,
    borderTopEndRadius: 20,
    borderBottomEndRadius: 20,
    paddingLeft: '5%',
    paddingRight: 10,
    marginLeft: '15%',
    marginVertical: 5,
  },
  barPoints: {
    width: '85%',
    backgroundColor: Colors.palePink,
  },
  scoreContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    top: -45,
  },
  maraeBar: {
    fontFamily: Fonts.family.bold,
  },
  MaraeIconContainer: {
    backgroundColor: Colors.darkBurgundy,
    height: 55,
    width: 55,
    borderRadius: 30,
    top: -95,
    padding: 3,
    borderWidth: 3,
    borderColor: Colors.yellow,
    marginLeft: '2%',
  },
  maraePic: {
    height: '100%',
    width: '100%',
    borderRadius: 25,
  },
  position: {
    height: 20,
    width: 20,
    borderRadius: 10,
    top: -115,
    left: 40,
    justifyContent: 'center',
    alignItems: 'center',
  },
  positionLabel: {
    fontFamily: Fonts.family.bold,
    fontSize: Fonts.sizes.small,
  },
});
