import React, { useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import Fonts from '../constants/Fonts';
import Constants from 'expo-constants';
import { RichTextResponseNative } from '../utils/RichTextResponse';
import axios from 'axios';
import _ from 'lodash';

const { baseURL, accessToken } = Constants.expoConfig?.extra?.contentful;

const PrivacyPolicy = () => {
  const URL = `${baseURL}access_token=${accessToken}&content_type=privacyPolicy&locale=en`;
  const [data, setData] = useState<any>();

  useEffect(() => {
    const getData = async () => {
      const result = await axios(URL);
      const termsData = _.find(result.data.items, ['fields.title', 'Privacy Policy']);
      setData(termsData);
    };
    getData();
  }, []);

  const privacyRichText = () => RichTextResponseNative({ richTextResponse: data?.fields.content });

  return (
    <View style={styles.termsContainer}>
      <>{privacyRichText()}</>
    </View>
  );
};

export default PrivacyPolicy;
const styles = StyleSheet.create({
  termsContainer: {
    width: '100%',
  },
  conditionsTitle: {
    marginBottom: 20,
  },
  conditionsText: {
    fontSize: Fonts.sizes.small,
    marginBottom: 10,
  },
});
