import * as React from 'react';
import { Colors } from '../../constants/Colors';
import { View } from '../Themed';
import { Pressable, StyleProp, StyleSheet, ViewStyle } from 'react-native';
import { UIText } from '../StyledText';
import Fonts from '../../constants/Fonts';

interface SplitButtonProps {
  iconLeft?: {
    left?: any;
    right?: any;
  };
  iconRight?: {
    left?: any;
    right?: any;
  };
  labelLeft: string;
  labelRight: string;
  onPressLeft: () => void;
  onPressRight: () => void;
  backgroundColorLeft?: string;
  backgroundColorRight?: string;
  labelColorLeft?: string;
  labelColorRight?: string;
  borderColor?: string;
  leftRightRatio?: number; // left width / right width
  containerStyles?: StyleProp<ViewStyle>;
  leftContainerStyles?: StyleProp<ViewStyle>;
  rigthContainerStyles?: StyleProp<ViewStyle>;
  mainButton?: 'right' | 'left';
}

export const SplitButton = ({
  iconLeft,
  iconRight,
  labelLeft,
  labelRight,
  onPressLeft,
  onPressRight,
  backgroundColorLeft = Colors.backgroundYellow,
  backgroundColorRight = Colors.darkBurgundy,
  labelColorLeft = Colors.darkBurgundy,
  labelColorRight = Colors.white,
  borderColor = Colors.darkBurgundy,
  leftRightRatio = 1.4,
  containerStyles,
  leftContainerStyles,
  rigthContainerStyles,
  mainButton = 'right',
}: SplitButtonProps) => (
  <View transparent style={[styles.container, containerStyles]}>
    <Pressable
      style={[
        styles.buttonContainer,
        styles.leftContainer,
        {
          borderColor,
          backgroundColor: backgroundColorLeft,
          justifyContent: mainButton === 'left' ? 'flex-end' : 'flex-start',
          paddingRight: mainButton === 'left' ? 10 : 0,
        },
        leftContainerStyles,
      ]}
      onPress={onPressLeft}
    >
      {iconLeft && iconLeft.left ? iconLeft.left : null}
      <UIText style={[styles.label, { color: labelColorLeft }]}>{labelLeft}</UIText>
      {iconLeft && iconLeft.right ? iconLeft.right : null}
    </Pressable>
    <Pressable
      style={[
        styles.buttonContainer,
        styles.rightContainer,
        {
          borderColor,
          backgroundColor: backgroundColorRight,
          width: `${((1 / (leftRightRatio + 1)) * 100).toFixed()}%`,
          left: mainButton === 'left' ? 0 : undefined,
        },
        rigthContainerStyles,
      ]}
      onPress={onPressRight}
    >
      {iconRight && iconRight.left ? iconRight.left : null}
      <UIText style={[styles.label, { color: labelColorRight }]}>{labelRight}</UIText>
      {iconRight && iconRight.right ? iconRight.right : null}
    </Pressable>
  </View>
);

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  buttonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 45.6,
    borderWidth: 2,
  },
  leftContainer: {
    paddingLeft: 24,
    borderRadius: 37,
    flex: 1,
  },
  rightContainer: {
    position: 'absolute',
    right: 0,
    top: 0,
    justifyContent: 'center',
    borderRadius: 37,
    zIndex: 5,
  },
  label: {
    fontFamily: Fonts.family.bold,
  },
});
