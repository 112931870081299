import * as React from 'react';
import { View } from '../../../components/Themed';
import { UIText } from '../../../components/StyledText';
import i18n from '../../../translations';
import { StyleSheet, Pressable } from 'react-native';
import Fonts from '../../../constants/Fonts';

interface RegionFilterFragmentProps {
  regions: string[];
  onSelect: (regionName: string) => void;
  resetOption?: Boolean;
}

export const RegionFilterFragment = ({ regions, onSelect, resetOption }: RegionFilterFragmentProps) => {
  const handlePress = (regionName: string) => () => {
    onSelect(regionName);
  };
  const handleResetFilter = () => {
    onSelect('');
  };
  return (
    <View transparent style={styles.container}>
      <UIText style={styles.title}>{i18n.t('components.RegionFilterFragment.filter')}</UIText>

      <View transparent style={styles.regionContainer}>
        {regions.map((region, index) => (
          <Pressable key={index.toString()} style={styles.regionItem} onPress={handlePress(region)}>
            <UIText style={styles.regionLabel}>{region}</UIText>
          </Pressable>
        ))}
        {resetOption && (
          <Pressable onPress={handleResetFilter}>
            <UIText style={styles.resetLabel}>{i18n.t('components.RegionFilterFragment.reset')}</UIText>
          </Pressable>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {},
  title: {
    fontFamily: Fonts.family.bold,
    marginBottom: 20,
  },
  regionContainer: {
    flexWrap: 'wrap',
    maxHeight: 300,
  },
  regionItem: {
    width: '50%',
    marginBottom: 12,
  },
  regionLabel: {},
  resetLabel: { fontFamily: Fonts.family.bold },
});
