/* eslint-disable no-nested-ternary */
import * as React from 'react';
import { View } from '../../../components/Themed';
import { Heading, Paragraph, UIText } from '../../../components/StyledText';
import i18n from '../../../translations';
import { Colors } from '../../../constants/Colors';
import { MaterialIcons } from '@expo/vector-icons';
import { SplitButton } from '../../../components/buttons/SplitButton';
import { StyleSheet } from 'react-native';
import Layout from '../../../constants/Layout';
import Fonts from '../../../constants/Fonts';
import { Activity, User, UserMarae } from '../../../constants/Interfaces';
import ModalUI from '../../../components/ModalUI';
import MyMaraeListFragment from '../../profile/fragments/MyMarae/MyMaraeListFragment';

interface ChooseMaraeFragmentProps {
  onNext: () => void;
  onBack: () => void;
  toggleMarae: (userMarae: UserMarae) => void;
  selectedWhanaus: User[];
  selectedMaraes: UserMarae[];
  activity: Activity;
}

export const ChooseMaraeFragment = ({
  onNext,
  onBack,
  selectedWhanaus,
  selectedMaraes,
  toggleMarae,
  activity,
}: ChooseMaraeFragmentProps) => {
  const [modalVisible, setModalVisible] = React.useState<boolean>(false);
  const language = i18n.locale;

  const handleSelected = (userMarae: UserMarae) => {
    toggleMarae(userMarae);
  };

  const handleNext = () => {
    if (selectedMaraes.length !== 1) {
      setModalVisible(true);
      return;
    }
    onNext();
  };

  const getWhanauNames = (whanaus: User[]) => {
    const whanauList = whanaus.slice(0, 6);
    if (whanaus.length === 1) {
      return language === 'en' ? whanauList[0].firstName : `kōrua ko ${whanauList[0].firstName} `;
    }
    if (whanaus.length === 2) {
      return i18n.t('components.ChooseMaraeFragment.twoWhanau', {
        first: whanaus[0].firstName,
        second: whanaus[1].firstName,
      });
    }
    let result = '';

    for (let i = 0; i < whanauList.length; i += 1) {
      if (language === 'mi' && i === 0) {
        result += `koutou ko `;
      }
      if (i < whanauList.length - 2) {
        result +=
          language === 'en'
            ? `${whanauList[i].firstName}, `
            : i === 0
            ? `${whanauList[i].firstName}, `
            : `ko ${whanauList[i].firstName}, `;
      }
      if (i === whanauList.length - 2 && whanauList.length < 6) {
        result +=
          language === 'en'
            ? `${whanauList[i].firstName} ${i18n.t('components.ChooseMaraeFragment.threeWhanau')} `
            : ` ko ${whanauList[i].firstName}, `;
      }
      if (i === whanauList.length - 1 && whanauList.length < 6) {
        result += language === 'en' ? whanauList[i].firstName : `ko ${whanauList[i].firstName}`;
      }
      if (i === whanauList.length - 1 && whanauList.length > 5) {
        result += ` ko ${whanauList[i].firstName} ${i18n.t('components.ChooseMaraeFragment.otherWhanau', {
          whanaus: whanaus.length - whanauList.length + 1,
        })}`;
      }
    }
    return result;
  };

  return (
    <View transparent style={styles.container}>
      <UIText style={styles.step}>
        {i18n.t('components.ChooseMaraeFragment.step', {
          currentStep: 2,
          totalSteps: 3,
        })}
      </UIText>
      <Heading style={styles.title}>{i18n.t('components.ChooseMaraeFragment.title')}</Heading>
      <Paragraph style={styles.instruction}>
        {selectedWhanaus.length > 0
          ? i18n.t('components.ChooseMaraeFragment.someoneettending', {
              whanauNames: getWhanauNames(selectedWhanaus),
              isAre: selectedWhanaus.length === 1 ? 'is' : 'are',
              activityName: activity.activityName,
            })
          : ''}
        {i18n.t('components.ChooseMaraeFragment.instruction')}
      </Paragraph>

      <MyMaraeListFragment openDetails={() => {}} onSelect={handleSelected} selectedMarae={selectedMaraes} selection />

      <SplitButton
        containerStyles={styles.actionButtonContainer}
        labelLeft={i18n.t('components.ChooseMaraeFragment.goback')}
        labelRight={i18n.t('components.ChooseMaraeFragment.next')}
        onPressLeft={onBack}
        onPressRight={handleNext}
        backgroundColorLeft={Colors.white}
        leftRightRatio={1}
        iconLeft={{
          left: (
            <MaterialIcons
              name="arrow-back-ios"
              size={16}
              color={Colors.darkBurgundy}
              style={[styles.buttonIcon, styles.buttonIconLeft]}
            />
          ),
        }}
        iconRight={{
          right: (
            <MaterialIcons
              name="arrow-forward-ios"
              size={16}
              color={Colors.white}
              style={[styles.buttonIcon, styles.buttonIconRight]}
            />
          ),
        }}
      />
      <ModalUI closeModal={() => setModalVisible(false)} toggle={modalVisible}>
        <View transparent>
          <UIText>You must select a marae</UIText>
        </View>
      </ModalUI>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    paddingHorizontal: Layout.backgroundMarginNormal.left,
  },
  actionButtonContainer: {
    marginHorizontal: 20,
    marginTop: 40,
  },
  step: {
    fontSize: Fonts.sizes.small,
  },
  title: {
    paddingVertical: 14,
    fontSize: Fonts.sizes.smallHeading,
  },
  instruction: {
    fontSize: Fonts.sizes.medium,
    textAlign: 'center',
    paddingBottom: 32,
  },
  buttonIcon: {
    paddingTop: 2,
  },
  buttonIconLeft: {
    paddingRight: 8,
  },
  buttonIconRight: {
    paddingLeft: 8,
  },
});
