import * as React from 'react';
import { ActivityStats } from '../../../constants/Interfaces';
import { View } from '../../../components/Themed';
import { StyleSheet } from 'react-native';
import { Heading } from '../../../components/StyledText';
import i18n from '../../../translations';
import { AgeStats } from '../../../components/AgeStats';

interface ActivityStatsFragmentProps {
  activityStats: ActivityStats;
}

export const ActivityStatsFragment = ({ activityStats }: ActivityStatsFragmentProps) => (
  <View transparent style={styles.container}>
    <Heading style={styles.heading}>{i18n.t('components.ActivityStatsFragment.whosgoing')}</Heading>
    <View transparent style={styles.ageContainer}>
      <AgeStats
        stats={activityStats.engagementTypeGoing.ageOfParticipantsKaumatua}
        label={i18n.t('components.ActivityStatsFragment.kaumatua')}
        source={require('../../../../assets/images/age-60-plus.png')}
        containerStyle={styles.ageStatsItem}
      />
      <AgeStats
        stats={activityStats.engagementTypeGoing.ageOfParticipantsMatua20_39}
        label={`${i18n.t('components.ActivityStatsFragment.matua')} 20-39`}
        source={require('../../../../assets/images/age-20-39.png')}
        containerStyle={styles.ageStatsItem}
      />
      <AgeStats
        stats={activityStats.engagementTypeGoing.ageOfParticipantsRangatahi13_19}
        label={`${i18n.t('components.ActivityStatsFragment.rangatahi')} 13-19`}
        source={require('../../../../assets/images/age-16-19.png')}
        containerStyle={styles.ageStatsItem}
      />
      <AgeStats
        stats={activityStats.engagementTypeGoing.ageOfParticipantsPakeke40_59}
        label={`${i18n.t('components.ActivityStatsFragment.pakeke')} 40-59`}
        source={require('../../../../assets/images/age-40-59.png')}
        containerStyle={styles.ageStatsItem}
      />
      <AgeStats
        stats={activityStats.engagementTypeGoing.ageOfParticipantsMokopuna}
        label={i18n.t('components.ActivityStatsFragment.mokopuna')}
        source={require('../../../../assets/images/age-with-child.png')}
        containerStyle={styles.ageStatsItem}
      />
      <AgeStats
        stats={activityStats.engagementTypeGoing.ageOfParticipantsTaiohi}
        label={`${i18n.t('components.ActivityStatsFragment.tamariki')} 5-12`}
        source={require('../../../../assets/images/age-5-12.png')}
        containerStyle={styles.ageStatsItem}
      />
    </View>
  </View>
);

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    paddingTop: 20,
  },
  heading: {
    marginBottom: 40,
  },
  ageContainer: {
    marginLeft: 10,
    width: 250,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
  ageStatsItem: {
    width: 120,
    marginBottom: 20,
  },
});
