interface ResponsiveParams {
  deviceSize: 'extrasmall' | 'small' | 'medium' | 'large' | 'extralarge';
  // fontType?: 'PARAGRAPH' | 'HEADING_1' | 'HEADING_2' | 'HEADING_3' | 'HEADING_4' | 'HEADING_5' | 'HEADING_6';
  inheritedStyle: object[];
  ownStyles?: { XS?: object[]; S?: object[]; M?: object[]; L?: object[]; XL?: object[] };
}

export const responsiveFontGenerator = ({ deviceSize, inheritedStyle, ownStyles }: ResponsiveParams) => {
  let finalStyles: any[];

  switch (deviceSize) {
    case 'extrasmall':
      finalStyles = [
        ...inheritedStyle,
        ...(ownStyles?.XS || ownStyles?.S || ownStyles?.M || ownStyles?.L || ownStyles?.XL || []),
      ];
      break;
    case 'small':
      finalStyles = [...inheritedStyle, ...(ownStyles?.S || ownStyles?.M || ownStyles?.L || ownStyles?.XL || [])];
      break;
    case 'medium':
      finalStyles = [...inheritedStyle, ...(ownStyles?.M || ownStyles?.L || ownStyles?.XL || [])];
      break;
    case 'large':
      finalStyles = [...inheritedStyle, ...(ownStyles?.L || ownStyles?.XL || [])];
      break;
    default:
      finalStyles = [...inheritedStyle, ...(ownStyles?.XL || [])];
  }

  return finalStyles;
};
