import React, { useState, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';
import PrimaryButton from '../../../../components/buttons/PrimaryButton';
import { Colors } from '../../../../constants/Colors';
import { Marae, UserMarae } from '../../../../constants/Interfaces';
import Layout from '../../../../constants/Layout';
import i18n from '../../../../translations';
import MaraeDetailsFragment from './MaraeDetailsFragment';
import MyMaraeListFragment from './MyMaraeListFragment';

const MyMaraeFragment = ({ navigation, setTip }: { navigation: any; setTip: Function }) => {
  const [maraeDetails, setMaraeDetails] = useState(false);
  const [maraeSelected, setMaraeSelected] = useState<Marae>({});
  const maraes: Marae[] = useSelector((state: any) => state.user?.user?.marae || []);

  const handleMaraeSelected = (userMarae: UserMarae) => {
    setMaraeSelected(userMarae);
  };

  useEffect(() => {
    setTip('addMarae');
  }, []);

  return (
    <View style={styles.root}>
      {!maraeDetails && (
        <>
          {maraes.length < 4 && (
            <PrimaryButton
              themeColor="yellow"
              title={i18n.t('components.AddNewMarae.addMarae')}
              onPress={() => navigation.navigate('SelectRegion')}
              textStyle={{ color: Colors.white }}
              buttonStyle={styles.button}
            />
          )}
          <MyMaraeListFragment openDetails={setMaraeDetails} onSelect={handleMaraeSelected} />
        </>
      )}
      {maraeDetails && <MaraeDetailsFragment openDetails={setMaraeDetails} marae={maraeSelected} setTip={setTip} />}
    </View>
  );
};
export default MyMaraeFragment;

const styles = StyleSheet.create({
  root: {
    alignItems: 'center',
    width: Layout.window.width,
    paddingHorizontal: '6%',
  },
  button: {
    height: 45,
    width: 200,
    borderRadius: 28,
  },
});
