import React, { useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import { Colors } from '../../../constants/Colors';
import { Heading, UIText } from '../../../components/StyledText';
import { getActivityStats } from '../../../reducers/activity.slice';
import { useDispatch, useSelector } from 'react-redux';
import { Activity, ActivityStats, Dictionary, Engagement } from '../../../constants/Interfaces';
import Fonts from '../../../constants/Fonts';

const Insights = ({ activity }: { activity: Activity }) => {
  const dispatch = useDispatch();
  const activityStats: Dictionary<ActivityStats> = useSelector((state: any) => state.activity.stats);

  useEffect(() => {
    dispatch(getActivityStats(activity.activityId));
  }, []);

  const { activityName } = activity;
  const engagementTypeGoing: Engagement = activityStats[activity.activityId]?.engagementTypeGoing;
  const ethnicityOfParticipantsEuropean = engagementTypeGoing?.ethnicityOfParticipantsEuropean;
  const ethnicityOfParticipantsPasifika = engagementTypeGoing?.ethnicityOfParticipantsPasifika;
  const ethnicityOfParticipantsAsian = engagementTypeGoing?.ethnicityOfParticipantsAsian;
  const ethnicityOfParticipantsMelaa = engagementTypeGoing?.ethnicityOfParticipantsMelaa;
  const ethnicityOfParticipantsOther = engagementTypeGoing?.ethnicityOfParticipantsOther;

  const nonMaori =
    ethnicityOfParticipantsEuropean +
    ethnicityOfParticipantsPasifika +
    ethnicityOfParticipantsAsian +
    ethnicityOfParticipantsMelaa +
    ethnicityOfParticipantsOther;

  return (
    <View style={styles.root}>
      <Heading>{activityName}</Heading>
      <View style={styles.list}>
        <ItemList text="Total Participants" data={engagementTypeGoing?.personTotal} />
        <ItemList text="Total Male" data={engagementTypeGoing?.genderOfParticipantsTane} />
        <ItemList text="Total Female" data={engagementTypeGoing?.genderOfParticipantsWahine} />
        <ItemList text="Total Māori" data={engagementTypeGoing?.ethnicityOfParticipantsMaori} />
        <ItemList text="Total Non-Māori" data={nonMaori} />
        <ItemList text="Mokopuna 0-4" data={engagementTypeGoing?.ageOfParticipantsMokopuna} />
        <ItemList text="Tamariki 5-12" data={engagementTypeGoing?.ageOfParticipantsTaiohi} />
        <ItemList text="Rangatahi 13-19" data={engagementTypeGoing?.ageOfParticipantsRangatahi13_19} />
        <ItemList text="Matua 20-39" data={engagementTypeGoing?.ageOfParticipantsMatua20_39} />
        <ItemList text="Pakeke 40-59" data={engagementTypeGoing?.ageOfParticipantsPakeke40_59} />
        <ItemList text="Kaumatua 60+" data={engagementTypeGoing?.ageOfParticipantsKaumatua} />
      </View>
    </View>
  );
};

const ItemList = ({ text, data }) => (
  <View style={styles.itemList}>
    <UIText>{text}</UIText>
    <UIText style={styles.values}>{data || 0}</UIText>
  </View>
);

export default Insights;

const styles = StyleSheet.create({
  root: {
    height: '100%',
    marginHorizontal: '10%',
    alignItems: 'center',
  },
  imageStyle: {
    width: 100,
    height: 100,
  },
  subTitle: {
    marginTop: 20,
    marginBottom: 30,
  },
  list: {
    width: '100%',
    marginTop: 35,
  },
  itemList: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottomWidth: 2,
    borderColor: Colors.defaultGrey,
    paddingVertical: 8,
  },
  values: {
    fontFamily: Fonts.family.bold,
  },
});
