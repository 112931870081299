import React from 'react';
import { Button } from 'react-native-elements';
import { StyleProp, StyleSheet, TextStyle, ViewStyle } from 'react-native';
import { Colors } from '../../constants/Colors';
import Fonts from '../../constants/Fonts';

interface PrimaryButtonProps {
  containerStyle?: StyleProp<ViewStyle>;
  buttonStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  themeColor?: 'red' | 'yellow';
  title: string;
  onPress: () => void;
  disabled?: boolean;
  loading?: boolean;
}

export default function PrimaryButton(props: PrimaryButtonProps) {
  const { containerStyle, title, onPress, disabled, loading, themeColor = 'red', buttonStyle, textStyle } = props;
  return (
    <Button
      containerStyle={[styles.container, containerStyle]}
      buttonStyle={[
        styles.button,
        { backgroundColor: themeColor === 'red' ? Colors.darkBurgundy : Colors.yellow },
        buttonStyle,
      ]}
      disabledStyle={[
        styles.button,
        { backgroundColor: themeColor === 'red' ? Colors.darkBurgundy : Colors.yellow },
        buttonStyle,
        { opacity: themeColor === 'red' ? 0.33 : 1 },
        { borderColor: themeColor === 'red' ? 'none' : Colors.yellow },
      ]}
      titleStyle={[styles.buttonText, { color: themeColor === 'red' ? Colors.white : Colors.black }, textStyle]}
      disabledTitleStyle={[
        styles.buttonText,
        { color: themeColor === 'red' ? Colors.white : Colors.blackOpacity },
        textStyle,
      ]}
      title={title}
      onPress={onPress}
      disabled={disabled}
      loading={loading}
      loadingProps={{ color: themeColor === 'red' ? Colors.white : Colors.black }}
    />
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    paddingVertical: 12,
    paddingHorizontal: 6,
  },
  button: {
    height: 48,
    width: 240,
    borderRadius: 28,
  },
  buttonText: {
    fontFamily: Fonts.family.bold,
    fontSize: Fonts.sizes.button,
  },
});
