import React, { useRef, useState, useEffect } from 'react';
import { View, TouchableWithoutFeedback, FlatList } from 'react-native';
import { UIText } from '../components/StyledText';
import { Colors } from '../constants/Colors';
import Fonts from '../constants/Fonts';
import i18n from '../translations';
import DownloadStoreContainer from './DownloadStoreContainer';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import { languageListener } from './Utils/languageListener';

interface CarouselAddsProp {
  data;
  title?: string;
  subtitle?: string;
  showIcons?: boolean;
  showStoreButtons?: boolean;
  isWide?: boolean;
  whiteText?: boolean;
  isFooter?: boolean;
  isBlackDot?: boolean;
  carouselTimeout?: number;
}

const CarouselAds = ({
  data,
  title,
  subtitle,
  showIcons = false,
  showStoreButtons = true,
  isWide = false,
  whiteText = false,
  isBlackDot = false,
  isFooter = false,
  carouselTimeout = 15000,
}: CarouselAddsProp) => {
  const language = languageListener();
  const ITEM_WIDTH = 390;
  const WIDE_ITEM_WIDTH = 1150;
  const styles = useStyles();
  const currentIndex = useRef(0);
  const [activeDot, setActiveDot] = useState(0);
  const carouselRef = useRef<FlatList>(null);
  const viewabilityConfig = useRef({
    itemVisiblePercentThreshold: 50,
  });

  const handleIndexSelect = (index: number) => () => {
    if (carouselRef.current && index !== currentIndex.current) {
      currentIndex.current = index;
      carouselRef.current!.scrollToIndex({ index: currentIndex.current });
      setActiveDot(currentIndex.current);
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      currentIndex.current = data && currentIndex.current === data.length - 1 ? 0 : currentIndex.current! + 1;
      carouselRef.current!.scrollToIndex({ index: currentIndex.current });
      setActiveDot(currentIndex.current!);
    }, carouselTimeout);
    return () => {
      clearTimeout(timer);
    };
  }, [currentIndex]);

  const renderItemStyleGenerator = (isWide, whiteText) => {
    if (isWide && whiteText) {
      return [styles.renderWideItem, { marginBottom: 60 }];
    }

    if (isWide) {
      return [styles.renderWideItem, { padding: '2.5%' }];
    }

    return [isFooter ? styles.renderItemFooter : styles.renderItem];
  };

  const renderItem = ({ item }) => (
    <View style={renderItemStyleGenerator(isWide, whiteText)}>
      <UIText
        style={[
          isWide ? styles.messageLarge : styles.message,
          whiteText ? [styles.whiteText, { textAlign: 'left' }] : {},
        ]}
      >
        {item}
      </UIText>
    </View>
  );

  const containerStyleGenerator = (isWide, whiteText) => {
    if (isWide && whiteText) {
      return [styles.wideContainer, styles.wideContainerWhite];
    }

    if (isWide) {
      return [styles.wideContainer];
    }

    return [isFooter ? styles.containerFooter : styles.container];
  };

  const storeTitle = i18n.t('web.CarouselAds.available', { locale: language });

  return (
    <View style={containerStyleGenerator(isWide, whiteText)}>
      {showIcons && <DownloadStoreContainer showIcons isFooter={isFooter} />}
      <UIText style={[styles.title, whiteText ? styles.whiteText : null]}>{title}</UIText>
      <UIText style={[styles.subtitle, whiteText ? styles.whiteText : null]}>{subtitle}</UIText>
      <View style={[isFooter ? styles.contentContainerRow : null]}>
        <View style={[isFooter ? styles.contentContainerBar : null]} />
        <FlatList
          bounces={false}
          ref={carouselRef}
          data={data}
          initialScrollIndex={0}
          renderItem={renderItem}
          scrollEnabled
          scrollEventThrottle={16}
          horizontal
          viewabilityConfig={viewabilityConfig.current}
          showsHorizontalScrollIndicator={false}
          overScrollMode="never"
          pagingEnabled
          getItemLayout={(data, index) => ({
            length: isWide ? WIDE_ITEM_WIDTH : ITEM_WIDTH,
            offset: isWide ? WIDE_ITEM_WIDTH * index : ITEM_WIDTH * index,
            index,
          })}
          keyExtractor={(item, index) => index.toString()}
          contentContainerStyle={[styles.contentContainer]}
        />
      </View>

      {showStoreButtons && <DownloadStoreContainer title={storeTitle} isFooter={isFooter} />}

      <View
        style={[
          !whiteText ? styles.navContainer : [styles.navContainer, { justifyContent: 'flex-start' }],
          isFooter ? styles.navContainerFooter : null,
        ]}
      >
        {data?.map((item, index) => (
          <TouchableWithoutFeedback key={index} onPress={handleIndexSelect(index)}>
            {index === activeDot ? (
              <View
                key={index}
                style={[
                  styles.activeDot,
                  showIcons ? styles.activeDotColorReverse : styles.activeDotColor,
                  whiteText ? { backgroundColor: Colors.white, width: 25, height: 25, borderWidth: 0 } : {},
                  isBlackDot
                    ? {
                        backgroundColor: Colors.transparent,
                        width: 25,
                        height: 25,
                        borderWidth: 4,
                        borderColor: 'rgba(0,0,0,.5)',
                      }
                    : {},
                ]}
              />
            ) : (
              <View
                key={index}
                style={[
                  styles.dot,
                  showIcons ? styles.dotColorReverse : styles.dotColor,
                  whiteText
                    ? {
                        width: 13,
                        height: 13,
                        backgroundColor: Colors.white,
                        opacity: 0.5,
                      }
                    : {},
                  isBlackDot ? { width: 13, height: 13, backgroundColor: 'rgba(0,0,0,.5)' } : {},
                ]}
              />
            )}
          </TouchableWithoutFeedback>
        ))}
      </View>
    </View>
  );
};
export default CarouselAds;

const useStyles = CreateResponsiveStyle(
  {
    container: {
      width: 390,
      marginTop: 10,
      justifyContent: 'center',
    },
    containerFooter: {
      width: 600,
      marginTop: 10,
      justifyContent: 'center',
    },
    wideContainer: {
      justifyContent: 'center',
      width: 1150,
    },
    wideContainerWhite: {
      alignSelf: 'flex-start',
      marginLeft: '14.5%',
    },
    contentContainerRow: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
    },
    contentContainerBar: {
      borderColor: Colors.blackOpacity,
      borderLeftWidth: 7,
      borderRadius: 3,
      height: '80%',
      alignSelf: 'center',
    },
    contentContainer: {
      minHeight: 150,
    },
    navContainer: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 15,
      marginBottom: 15,
    },
    navContainerFooter: {
      display: 'none',
    },
    dot: {
      width: 10,
      height: 10,
      borderRadius: 25,
      marginVertical: 5,
      marginHorizontal: 6,
    },
    dotColor: {
      backgroundColor: Colors.darkBurgundy,
    },
    dotColorReverse: {
      backgroundColor: Colors.yellow,
    },
    activeDot: {
      borderWidth: 2,
      borderRadius: 25,
      height: 15,
      width: 15,
      justifyContent: 'center',
      alignItems: 'center',
    },
    activeDotColor: {
      backgroundColor: Colors.yellow,
      borderColor: Colors.darkBurgundy,
    },
    activeDotColorReverse: {
      backgroundColor: Colors.darkBurgundy,
      borderColor: Colors.yellow,
    },
    title: {
      alignSelf: 'center',
      fontFamily: Fonts.family.bold,
      fontSize: 34,
      lineHeight: 57,
    },
    subtitle: {
      alignSelf: 'center',
      fontFamily: Fonts.family.regular,
      fontSize: 25,
      lineHeight: 40,
    },
    message: {
      width: '100%',
      textAlign: 'center',
      fontSize: 20,
      lineHeight: 28,
    },
    messageLarge: {
      width: '100%',
      textAlign: 'center',
      fontSize: 25,
      lineHeight: 30,
      maxWidth: 600,
    },
    renderItem: {
      alignItems: 'center',
      height: '100%',
      width: 390,
      maxHeight: 390,
      padding: '5%',
    },
    renderItemFooter: {
      alignItems: 'center',
      height: '100%',
      width: 600,
      minHeight: 250,
      padding: '5%',
    },
    renderWideItem: {
      alignItems: 'center',
      height: '100%',
      width: 1150,
      maxHeight: 390,
    },
    whiteText: {
      color: Colors.white,
      alignSelf: 'flex-start',
    },
  },
  {
    [DEVICE_SIZES.MD]: {
      containerFooter: {
        width: 390,
      },
      renderItemFooter: {
        width: 390,
        minHeight: 200,
      },
      title: {
        fontSize: 22,
        lineHeight: 37,
      },
      subtitle: {
        fontSize: 21,
        lineHeight: 30,
      },
      message: {
        fontSize: 16,
        lineHeight: 22,
      },
      messageLarge: {
        fontSize: 24,
        maxWidth: 550,
      },
    },
    [DEVICE_SIZES.SM]: {
      containerFooter: {
        width: 390,
      },
      renderItemFooter: {
        width: 390,
        minHeight: 200,
      },
      title: {
        fontSize: 22,
        lineHeight: 37,
      },
      subtitle: {
        fontSize: 21,
        lineHeight: 30,
      },
      message: {
        fontSize: 16,
        lineHeight: 22,
      },
      messageLarge: {
        fontSize: 20,
        maxWidth: 450,
      },
    },
    [DEVICE_SIZES.XS]: {
      container: {
        width: 375,
      },
      containerFooter: {
        width: 350,
      },
      renderItemFooter: {
        width: 350,
        minHeight: 200,
      },
      navContainerFooter: {
        display: 'none',
      },
      title: {
        fontSize: 20,
        lineHeight: 37,
      },
      subtitle: {
        fontSize: 19,
        lineHeight: 30,
      },
      message: {
        fontSize: 16,
        lineHeight: 22,
      },
      messageLarge: {
        fontSize: 18,
        maxWidth: 300,
      },
      wideContainerWhite: {
        marginLeft: '10%',
      },
    },
  }
);
