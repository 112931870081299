import React, { useState } from 'react';
import { View } from 'react-native';
import { Colors } from '../constants/Colors';
import HamburgerMenu from './HamburgerMenu';
import MenuBar from './MenuBar';
import { CreateResponsiveStyle } from 'rn-responsive-styles';

interface LayoutProps {
  menuBarTheme?: 'dark' | 'light';
  isLayoutAbsolute?: boolean;
  isHightLighted?: boolean;
}

const Header = ({ menuBarTheme = 'dark', isLayoutAbsolute = false, isHightLighted = false }: LayoutProps) => {
  const styles = useStyles();
  const [showMenu, setShowMenu] = useState(false);
  return (
    <View style={[styles.root, menuBarTheme === 'light' ? styles.bgLight : null]}>
      <MenuBar
        menuBarTheme={menuBarTheme}
        showMenu={() => setShowMenu(true)}
        isLayoutAbsolute={isLayoutAbsolute}
        isHighlighted={isHightLighted}
      />
      {showMenu && <HamburgerMenu handleClose={() => setShowMenu(false)} />}
    </View>
  );
};

export default Header;

const useStyles = CreateResponsiveStyle(
  {
    root: { backgroundColor: Colors.yellow, zIndex: 20 },
    bgLight: {
      backgroundColor: Colors.transparent,
    },
  },
  {}
);
