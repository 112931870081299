import * as React from 'react';
import { Image, Pressable, StyleSheet } from 'react-native';
import { View } from './Themed';
import Modal from 'react-native-modal';
import { AntDesign } from '@expo/vector-icons';
import { Colors } from '../constants/Colors';
import { Heading, Paragraph } from './StyledText';
import Layout from '../constants/Layout';
import i18n from '../translations';
import Fonts from '../constants/Fonts';

export const TipModal = ({ message }: { message: string }) => {
  const [active, setActive] = React.useState<boolean>(false);

  const handleOpenModal = () => {
    setActive(true);
  };

  const handleCloseModal = () => {
    setActive(false);
  };

  return (
    <>
      <Modal
        isVisible={active}
        style={styles.modalContainer}
        onBackdropPress={handleCloseModal}
        onSwipeComplete={handleCloseModal}
        swipeDirection="down"
      >
        <View style={styles.modal}>
          <View transparent style={styles.tipImageContainer}>
            <Image style={styles.tipImage} source={require('../../assets/images/tip-icon.png')} />
          </View>
          <Pressable style={styles.close} onPress={handleCloseModal}>
            <AntDesign name="closecircle" size={30} color={Colors.black} />
          </Pressable>
          {message === 'addMarae' && (
            <>
              <Heading style={styles.heading}>{i18n.t('components.AddNewMarae.tipTitle')}</Heading>
              <Paragraph>{i18n.t('components.AddNewMarae.tipBody')}</Paragraph>
            </>
          )}
          {message === 'myWhanau' && (
            <>
              <Heading style={styles.heading}>{i18n.t('components.MyWhanauFragment.tipTitle')}</Heading>
              <Paragraph style={styles.paragraphWithBottomMargin}>
                {i18n.t('components.MyWhanauFragment.tipBody')}
              </Paragraph>
              <Paragraph>{i18n.t('components.MyWhanauFragment.tipBodySub')}</Paragraph>
            </>
          )}
          {message === 'language' && (
            <>
              <Heading style={styles.heading}>{i18n.t('components.LanguageFragment.tipTitle')}</Heading>
              <Paragraph>{i18n.t('components.LanguageFragment.tipBody')}</Paragraph>
            </>
          )}
          {message === 'activities' && (
            <>
              <Heading style={styles.heading}>{i18n.t('components.MaraeParty.tipActivitiesTitle')}</Heading>
              <Paragraph>{i18n.t('components.MaraeParty.tipActivitiesBody')}</Paragraph>
            </>
          )}
          {message === 'koha' && (
            <>
              <Heading style={styles.heading}>{i18n.t('components.MaraeParty.tipKohaTitle')}</Heading>
              <Paragraph>{i18n.t('components.MaraeParty.tipKohaBody')}</Paragraph>
            </>
          )}
          {message === 'koha' ? (
            <>
              <Heading style={styles.smallTitle}>{i18n.t('components.TipModal.tipKohaSystemTitle')}</Heading>
              <Paragraph>{i18n.t('components.TipModal.tipKohaSystemBody')}</Paragraph>
              <Heading style={styles.smallTitle}>{i18n.t('components.TipModal.tipKohaPointsTitle')}</Heading>
              <Paragraph>{i18n.t('components.TipModal.tipKohaPointsBody')}</Paragraph>
            </>
          ) : null}
        </View>
      </Modal>
      {!active ? (
        <View transparent style={styles.tipOpenerContainer}>
          <Pressable onPress={handleOpenModal}>
            <Image style={styles.tipImage} source={require('../../assets/images/tip-icon.png')} />
          </Pressable>
        </View>
      ) : null}
    </>
  );
};

const styles = StyleSheet.create({
  modalContainer: {
    justifyContent: 'flex-end',
    margin: 0,
    zIndex: 1000,
  },
  modal: {
    width: '100%',
    minHeight: 300,
    backgroundColor: Colors.white,
    borderRadius: 20,
    paddingBottom: 70,
    paddingTop: 25,
    paddingHorizontal: 30,
  },
  close: {
    position: 'absolute',
    top: 20,
    right: 20,
  },
  heading: {
    marginTop: 80,
    marginBottom: 5,
  },
  tipOpenerContainer: {
    marginBottom: 60,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    bottom: -25,
    right: Layout.window.width / 2.5,
  },
  tipImageContainer: {
    margin: -50,
    alignItems: 'center',
  },
  tipImage: {
    width: 50,
    height: 50,
    resizeMode: 'contain',
  },
  smallTitle: {
    fontSize: Fonts.sizes.smallHeading,
    marginTop: 20,
    marginBottom: 5,
  },
  paragraphWithBottomMargin: {
    marginBottom: 10,
  },
});
