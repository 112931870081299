import * as React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import HomeScreen from '../screens/home/HomeScreen';
import { ActivityDetailsScreen } from '../screens/activities/ActivityDetailsScreen';
import { HomeParamList } from '../constants/Interfaces';
import { useNavigation } from '@react-navigation/native';
import { SelectRegionScreen } from '../screens/profile/SelectRegionScreen';
import { TipScreen } from '../screens/profile/TipScreen';

const HomeStack = createStackNavigator<HomeParamList>();

export const HomeNavigator = () => {
  const navigation = useNavigation();

  React.useEffect(
    () =>
      navigation.addListener('beforeRemove', () => {
        navigation.navigate('DashboardStack');
      }),
    []
  );
  return (
    <HomeStack.Navigator screenOptions={{ headerShown: false }}>
      <HomeStack.Screen name="Home" component={HomeScreen} />
      <HomeStack.Screen name="ActivityDetails" component={ActivityDetailsScreen} />
      <HomeStack.Screen name="SelectRegion" component={SelectRegionScreen} />
      <HomeStack.Screen name="TipScreen" component={TipScreen} />
    </HomeStack.Navigator>
  );
};
