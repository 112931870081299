import { MaterialIcons } from '@expo/vector-icons';
import _ from 'lodash';
import React, { Dispatch, SetStateAction } from 'react';
import { View, StyleSheet, Pressable, FlatList } from 'react-native';
import { Colors } from '../../../constants/Colors';
import { UIText } from '../../../components/StyledText';
import { WhanauType } from '../../../constants/Interfaces';

interface WhanauTypeListProps {
  openDetails: Dispatch<SetStateAction<boolean>>;
  onSelect: (whanauType: WhanauType) => void;
  selectedWhanauType?: WhanauType[];
  selection?: boolean;
}
const WhanauTypeListFragment = ({ openDetails, onSelect, selectedWhanauType = [], selection }: WhanauTypeListProps) => {
  const renderItem = ({ item }: { item: WhanauType }) => {
    const selected = _.findIndex(selectedWhanauType, (type) => type === item) > -1;
    return (
      <View style={styles.whanauList}>
        <Pressable
          onStartShouldSetResponderCapture={() => true}
          onMoveShouldSetResponderCapture={() => true}
          style={styles.whanauContainer}
          onPress={() => {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            item && onSelect ? onSelect!(item!) : null;
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            openDetails ? openDetails!(true) : null;
          }}
        >
          <UIText style={[styles.whanauName, { color: selection ? Colors.black : Colors.white }]}>{`${item}`}</UIText>
        </Pressable>
        {selected ? (
          <View style={styles.selectedContainer}>
            <MaterialIcons name="check" size={16} color={Colors.black} />
          </View>
        ) : null}
      </View>
    );
  };
  return (
    <FlatList
      data={Object.values(WhanauType)}
      renderItem={renderItem}
      keyExtractor={(item, index) => index.toString()}
    />
  );
};

export default WhanauTypeListFragment;
const styles = StyleSheet.create({
  whanauList: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    height: 60,
    borderBottomColor: Colors.blackOpacity,
    borderBottomWidth: 1,
  },
  whanauContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '90%',
    height: '100%',
  },
  whanauImage: {
    width: 30,
    height: 30,
    marginRight: 10,
  },
  whanauName: {
    marginLeft: 15,
  },
  selectedContainer: {
    backgroundColor: Colors.green,
    justifyContent: 'center',
    alignItems: 'center',
    width: 20,
    height: 20,
    borderRadius: 15,
  },
});
