import React, { Dispatch, SetStateAction, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { Colors } from '../../../../constants/Colors';
import Backbutton from '../../../../components/Backbutton';
import AddWhanauFragment from './AddWhanauFragment';
import { User } from '../../../../constants/Interfaces';
import Layout from '../../../../constants/Layout';
import SectionButton from '../../../../components/buttons/SectionButton';
import i18n from '../../../../translations';
import EditPhoto from '../../../../components/EditPhoto';
import UserActivities from '../../../../components/UserActivities';
import { Heading } from '../../../../components/StyledText';

interface WhanauDetailsProps {
  user?: User;
  openDetails: Dispatch<SetStateAction<boolean>>;
  activityId?: string;
  hideFooter: Function;
  isAddWhanau?: boolean;
}
const whanauDetailsFragment = ({
  openDetails,
  user,
  activityId = undefined,
  hideFooter,
  isAddWhanau = false,
}: WhanauDetailsProps) => {
  const [active, setActive] = useState(1);

  return (
    <View style={styles.root}>
      <View style={styles.headerContainer}>
        <View style={styles.header}>
          <Backbutton onPress={() => openDetails(false)} />
          {!user?.userId && (
            <Heading style={styles.headerTitle}>{i18n.t('components.ProfileFragment.addWhanau')}</Heading>
          )}
          {user?.userId && (
            <View style={styles.titleContainer}>
              <WhanauDetailsNav active={active} setActive={setActive} />
            </View>
          )}
        </View>
      </View>
      {active === 1 && (
        <AddWhanauFragment
          user={user}
          openDetails={openDetails}
          activityId={activityId}
          hideFooter={hideFooter}
          isAddWhanau={isAddWhanau}
        />
      )}
      {active === 2 && <EditPhoto user={user!} />}
      {active === 3 && <UserActivities user={user!} />}
    </View>
  );
};

const WhanauDetailsNav = ({ active, setActive }) => (
  <View style={styles.navbar}>
    <SectionButton
      title={i18n.t('components.ProfileFragment.details')}
      selected={active === 1}
      onPress={() => setActive(1)}
    />
    <SectionButton
      title={i18n.t('components.ProfileFragment.photo')}
      selected={active === 2}
      onPress={() => setActive(2)}
    />
    <SectionButton
      title={i18n.t('components.ProfileFragment.activities')}
      selected={active === 3}
      onPress={() => setActive(3)}
    />
  </View>
);

export default whanauDetailsFragment;

const styles = StyleSheet.create({
  root: {
    width: Layout.window.width,
    alignContent: 'center',
  },
  headerContainer: {
    paddingHorizontal: '6%',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    alignContent: 'center',
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    paddingVertical: 5,
    paddingHorizontal: 15,
  },
  titleContainer: {
    width: '100%',
    alignItems: 'center',
  },
  headerTitle: {
    textAlign: 'center',
    color: Colors.white,
    marginHorizontal: Layout.window.width / 5 - 15,
  },

  navbar: {
    width: '100%',
    alignSelf: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
