import * as React from 'react';
import { FlatList, StyleSheet, TouchableOpacity, ActivityIndicator } from 'react-native';
import { View } from '../../components/Themed';
import Layout from '../../constants/Layout';
import { useDispatch } from 'react-redux';
import { Region, Scope } from '../../constants/Interfaces';
import { Colors } from '../../constants/Colors';
import { Heading, Paragraph } from '../../components/StyledText';
import { AntDesign } from '@expo/vector-icons';
import API from '../../constants/API';
import { useState } from 'react';
import Fonts from '../../constants/Fonts';
import { selectRegion, selectRegionLeaderboard } from '../../reducers/region.slice';
import { TipButton } from '../../components/TipButton';

interface SelectRegionScreenProps {
  navigation: any;
  route: {
    params: {
      regionFilter?: string;
    };
  };
}

const allRegions: Region = { regionId: 0, regionName: 'Ngā rohe katoa', regionNameEnglish: 'All regions' };

export const SelectRegionScreen = ({ navigation, route }: SelectRegionScreenProps) => {
  const regionFilter = route?.params?.regionFilter;
  const [listOfRegions, setListOfRegions] = useState<Region[]>([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const styles = StyleSheet.create({
    root: {
      flex: 1,
      alignItems: 'center',
      backgroundColor: Colors.white,
      width: Layout.window.width,
      height: Layout.window.height,
    },
    header: {
      width: '100%',
      height: 100,
      backgroundColor: Colors.white,
      padding: Layout.backgroundMarginNormal.left,
      marginTop: Layout.backgroundMarginNormal.top,
      borderBottomColor: Colors.grey,
      borderBottomWidth: 1,
      flexDirection: 'row',
    },

    closeContainer: { height: '100%', marginLeft: 'auto' },
    close: {
      justifyContent: 'center',
      alignItems: 'center',
      width: 50,
      height: 50,
      backgroundColor: Colors.whiteOpacitiy40,
      borderRadius: 45,
      zIndex: 999,
    },

    bold: {
      fontFamily: Fonts.family.bold,
    },
    regular: {
      fontFamily: Fonts.family.regular,
    },

    regionName: {
      fontFamily: Fonts.family.regular,
      fontSize: 19,
    },
    regionNameEnglish: {
      fontFamily: Fonts.family.bold,
      fontSize: 15,
    },

    loadingContainer: {
      position: 'absolute',
      marginVertical: Layout.window.height / 2,
    },
  });

  function close() {
    navigation.goBack();
    filterRegion('');
  }

  function filterRegion(value) {
    if (regionFilter === 'activities') {
      dispatch(selectRegion(value));
    } else if (regionFilter === 'leaderboard') {
      dispatch(selectRegionLeaderboard(value));
    }
  }

  function selectRegionFromList(region: Region) {
    if (regionFilter) {
      const filterString = region.regionName! === allRegions.regionName! ? '' : region.regionName!;
      filterRegion(filterString);
      navigation.goBack();
      return;
    }
    navigation.navigate('SelectMarae', { region });
  }

  React.useEffect(() => {
    setLoading(true);
    const api: API = API.getInstance();
    api
      .get('/regions', undefined, undefined, Scope.ANONYMOUS)
      .then((regions: Region[]) => {
        if (regionFilter) {
          regions.push(allRegions);
        }
        setListOfRegions(regions);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const defaultRenderItem = ({ item }: { item: Region }) => (
    <View transparent>
      <TouchableOpacity onPress={() => selectRegionFromList(item)}>
        <Heading style={styles.regionName}>{item.regionName}</Heading>
        <Paragraph style={styles.regionNameEnglish}>{item.regionNameEnglish}</Paragraph>
      </TouchableOpacity>
    </View>
  );

  return (
    <View style={styles.root}>
      <View style={styles.header}>
        <View>
          <Heading>Rohe Pōtae</Heading>
          <Paragraph style={styles.bold}>Select your marae region</Paragraph>
        </View>
        <View style={styles.closeContainer}>
          <TouchableOpacity style={styles.close} onPress={() => close()}>
            <AntDesign name="close" size={32} color={Colors.black} />
          </TouchableOpacity>
        </View>
      </View>
      <FlatList
        data={listOfRegions}
        renderItem={defaultRenderItem}
        keyExtractor={(item) => `${item.regionId}`}
        ItemSeparatorComponent={() => (
          <View
            style={{
              width: '100%',
              borderBottomColor: Colors.grey,
              borderBottomWidth: 1,
              marginVertical: Layout.backgroundMarginNarrow.top,
            }}
          />
        )}
        style={{ width: '100%', padding: Layout.backgroundMarginNormal.left }}
        contentContainerStyle={{ paddingBottom: 40 }}
      />
      <TipButton navigation={navigation} message="region" style={{ right: 20, top: 100 }} />
      {loading && (
        <View style={[styles.loadingContainer]} transparent>
          <ActivityIndicator size="large" color={Colors.black} />
        </View>
      )}
    </View>
  );
};
