import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Image, View } from 'react-native';
import { CreateResponsiveStyle, DEVICE_SIZES, useDeviceSize } from 'rn-responsive-styles';
import CarouselAds from './CarouselAds';
import { contentfulUrlGenerator } from './Utils/contentfulUrlGenerator';
import { languageListener } from './Utils/languageListener';
import LayoutWeb from './LayoutWeb';

const LandingWeb = () => {
  const styles = useStyles();
  const deviceSize = useDeviceSize();
  const [data, setData] = useState<any>();
  const language = languageListener();

  const URL = contentfulUrlGenerator('landingPage', language);

  useEffect(() => {
    const getData = async () => {
      const result = await axios(URL);
      setData(result.data);
    };
    getData();
  }, [language]);

  const fields = data ? data?.items[0].fields : null;

  return (
    <LayoutWeb>
      <View style={styles.body}>
        <View style={styles.phoneContainer}>
          <Image source={require('../../assets/images/phone-event-organiser.png')} style={styles.phone} />
        </View>
        <View style={styles.carouselContainer}>
          {data && (
            <CarouselAds
              title={fields?.title}
              data={fields?.carouselText}
              showStoreButtons={!(deviceSize === 'extrasmall')}
              isWide={deviceSize === 'extralarge'}
            />
          )}
        </View>
      </View>
    </LayoutWeb>
  );
};

export default LandingWeb;

const useStyles = CreateResponsiveStyle(
  {
    body: {
      width: '65%',
      minHeight: '40%',
      alignSelf: 'center',
      marginTop: '10',
      flexDirection: 'row',
      zIndex: 2,
      alignItems: 'center',
    },
    phoneContainer: {
      width: '50%',
      height: '100%',
    },
    phone: {
      height: '100%',
      width: '100%',
      resizeMode: 'contain',
    },
    carouselContainer: {
      width: '50%',
      height: '100%',
      alignItems: 'center',
    },
  },
  {
    [DEVICE_SIZES.MD]: {
      body: { width: '70%' },
      phoneContainer: { width: '50%', height: '80%' },
      carouselContainer: { width: '50%', height: '80%' },
    },
    [DEVICE_SIZES.SM]: {
      body: {
        minHeight: '60%',
        width: '90%',
        flexDirection: 'column',
        overflow: 'visible',
        marginTop: 20,
      },
      phoneContainer: {
        width: 318,
        height: 449,
      },
      carouselContainer: {
        width: '100%',
        height: '60%',
        marginTop: 0,
        alignItems: 'center',
      },
    },
    [DEVICE_SIZES.XS]: {
      body: {
        width: '100%',
        flexDirection: 'column',
        overflow: 'visible',
        marginTop: '5%',
      },
      phoneContainer: {
        width: 275,
        height: 388,
      },
      carouselContainer: {
        width: '100%',
        height: '50%',
        marginTop: 0,
        alignItems: 'center',
      },
    },
  }
);
