import React, { useEffect, useRef, useState } from 'react';
import { Animated, Image, StyleSheet } from 'react-native';
import Layout from '../constants/Layout';
import { Colors } from '../constants/Colors';

export default function SplashScreen({ inTest }: { inTest?: boolean }) {
  const [visible, setVisible] = useState<boolean>(true);
  const opacity = useRef(new Animated.Value(1)).current;

  useEffect(() => {
    if (!inTest) {
      setTimeout(() => {
        setVisible(false);
      }, 3500);
      setTimeout(() => {
        Animated.timing(opacity, {
          toValue: 0,
          duration: 500,
          useNativeDriver: true,
        }).start();
      }, 3000);
    }
  }, []);

  return (
    <Animated.View
      style={[
        styles.container,
        {
          opacity,
          display: visible ? 'flex' : 'none',
          position: visible ? 'absolute' : 'relative',
        },
      ]}
    >
      <Image source={require('../../assets/images/splash_top.png')} style={styles.topImage} resizeMethod="resize" />
      <Image source={require('../../assets/images/mf_logo_dark.png')} style={styles.logo} resizeMethod="scale" />
      <Image
        source={require('../../assets/images/splash_bottom.png')}
        style={styles.bottomImage}
        resizeMethod="resize"
      />
    </Animated.View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 100,
    elevation: 100,
    backgroundColor: Colors.backgroundYellow,
    alignItems: 'center',
    justifyContent: 'center',
  },
  topImage: {
    position: 'absolute',
    top: 0,
    height: 200,
    resizeMode: 'contain',
  },
  logo: {
    marginTop: -40,
    width: 150,
    height: 150,
    resizeMode: 'contain',
    opacity: 1,
    zIndex: 2,
  },
  bottomImage: {
    position: 'absolute',
    bottom: 0,
    width: Layout.window.width,
    height: 150,
    resizeMode: 'cover',
  },
});
