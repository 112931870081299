import Constants from 'expo-constants';

const { baseURL, accessToken } = Constants?.expoConfig?.extra?.contentful;

export const contentfulUrlGenerator = (type: string, locale: string | null, token = accessToken): string => {
  // contentFul uses "en-NZ" for maori locale whereas screens use "mi" so convert here
  const contentLocale = locale === 'en' ? locale : 'en-NZ';
  const contentfulUrl = `${baseURL}access_token=${token}&content_type=${type}&locale=${contentLocale}`;
  return contentfulUrl;
};
