import React, { useEffect, useState } from 'react';
import { View, StyleSheet, Pressable, Image } from 'react-native';
import SecondaryButton from './buttons/SecondaryButton';
import ButtonSwitch from './buttons/ButtonSwitch';
import EllipseBorderImage from './EllipseBorderImage';
import Layout from '../constants/Layout';
import i18n from '../translations';
import Modal from 'react-native-modal';
import { Colors } from '../constants/Colors';
import { UIText } from './StyledText';
import Fonts from '../constants/Fonts';
import * as ImagePicker from 'expo-image-picker';
import { User } from '../constants/Interfaces';
import PhotoCamera from './PhotoCamera';
import { deleteUserPhoto, postUserPhoto } from '../reducers/user.slice';
import Logger from '../constants/Logger';
import { useDispatch, useSelector } from 'react-redux';
import { Camera } from 'expo-camera';
import PrimaryButton from './buttons/PrimaryButton';
import { openSettings } from 'expo-linking';
import { getGenderPlaceholder } from '../constants/Utils';

interface EditPhotoProps {
  user: User;
}

interface ImageResult {
  uri: string;
  base64: string | null | undefined;
}

const EditPhoto = ({ user }: EditPhotoProps) => {
  const [activeMenu, setActiveMenu] = useState(false);
  const [openCamera, setOpenCamera] = useState(false);
  const dispatch = useDispatch();
  const userRedux: User =
    useSelector((state: any) => state.user.whanau)?.find((item) => item.userId === user.userId) || user;

  const handleCloseMenu = () => setActiveMenu(false);
  const userId = userRedux?.userId;
  const imageUrl = userRedux?.imageUrl;
  const [image, setImage] = useState<ImageResult | null>({ uri: imageUrl });

  const [galleryPermission, setGalleryPermission] = useState<null | boolean>(null);
  const [cameraPermission, setCameraPermission] = useState<null | boolean>(null);

  const onHandleCamera = async () => {
    try {
      const { status } = await Camera.requestCameraPermissionsAsync();
      if (status !== 'granted') {
        setCameraPermission(false);
        setActiveMenu(false);
        return;
      }
      setCameraPermission(true);
      setOpenCamera(true);
      setActiveMenu(false);
    } catch (e) {
      Logger.debug(e);
    }
  };

  const handleDelete = async (userId: string) => {
    try {
      await dispatch(deleteUserPhoto(userId));
    } catch (e) {
      Logger.debug(`Error occurred: ${e}`);
    }
  };

  const handleGalleryPicture = async () => {
    try {
      const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
      if (status !== 'granted') {
        setGalleryPermission(false);
        setActiveMenu(false);
        return;
      }
      setGalleryPermission(true);

      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 0.7,
        base64: true,
      });
      if (!result.canceled) {
        const formattedResult = {
          uri: result.assets[0].uri,
          base64: result.assets[0].base64,
        };
        setImage(formattedResult);
      }
    } catch (e) {
      Logger.debug(e);
    }
    setActiveMenu(false);
  };

  const uploadPhoto = async (userId, image) => {
    try {
      const imageSuccess: ImageResult = image;
      const imageFormat = imageSuccess.uri.split('.').pop();
      const payload = {
        image: {
          mime: `image/${imageFormat}`,
          data: imageSuccess.base64,
          url: imageSuccess.uri,
        },
      };
      await dispatch(postUserPhoto({ userId, payload }));
    } catch (e) {
      Logger.debug(`Error occurred: ${e}`);
    }
  };
  const handleMenu = () => {
    setActiveMenu(true);
  };

  const getPermissions = async () => {
    let status;
    try {
      status = await (await ImagePicker.requestMediaLibraryPermissionsAsync()).status;
      setGalleryPermission(status === 'granted');
      status = await (await Camera.requestCameraPermissionsAsync()).status;
      setCameraPermission(status === 'granted');
    } catch (e) {
      Logger.debug(e);
    }
  };
  useEffect(() => {
    getPermissions();
  }, []);

  useEffect(() => {
    if (image) {
      uploadPhoto(userId, image);
    }
  }, [image]);

  return (
    <>
      {openCamera ? (
        <View style={styles.root}>
          <PhotoCamera setImage={setImage} openCamera={setOpenCamera} />
        </View>
      ) : (
        <View style={styles.root}>
          <EllipseBorderImage
            img={imageUrl ? { uri: imageUrl } : getGenderPlaceholder(user?.gender)}
            color="yellow"
            imageStyle={styles.imageStyle}
            borderStyle={{ padding: 5 }}
          />
          {imageUrl && <ButtonSwitch leftButton={() => handleDelete(user?.userId!)} rightButton={handleMenu} />}
          {!imageUrl && (
            <SecondaryButton
              onPress={handleMenu}
              icon={require('../../assets/images/ProfilePic-transparent.png')}
              title={i18n.t('components.EditPhoto.uploadPhoto')}
              bgColor="yellow"
            />
          )}
        </View>
      )}

      <Modal
        isVisible={activeMenu}
        style={styles.modalContainer}
        onBackdropPress={handleCloseMenu}
        onSwipeComplete={handleCloseMenu}
        swipeDirection="down"
      >
        <View style={[styles.modal, { minHeight: !cameraPermission || !galleryPermission ? 300 : 200 }]}>
          <View style={styles.handleBar} />
          <View style={styles.optionsContainer}>
            <Pressable style={styles.modalOption} onPress={handleGalleryPicture}>
              <Image style={styles.iconModal} source={require('../../assets/images/photoGaleriaIcon.png')} />
              <UIText style={styles.optionLabel}>Select from your gallery</UIText>
            </Pressable>
            <Pressable style={styles.modalOption} onPress={onHandleCamera}>
              <Image style={styles.iconModal} source={require('../../assets/images/addPhotoIcon.png')} />
              <UIText style={styles.optionLabel}>Take new photo</UIText>
            </Pressable>
          </View>
          {!cameraPermission && (
            <UIText style={styles.settingsLabel}>{i18n.t('components.EditPhoto.noCameraPermission')}</UIText>
          )}
          {!galleryPermission && (
            <UIText style={styles.settingsLabel}>{i18n.t('components.EditPhoto.noGaleryPermission')}</UIText>
          )}
          {!cameraPermission || !galleryPermission ? (
            <PrimaryButton title="Change settings" onPress={() => openSettings()} />
          ) : null}
        </View>
      </Modal>
    </>
  );
};

export default EditPhoto;

const styles = StyleSheet.create({
  root: {
    alignItems: 'center',
    justifyContent: 'space-evenly',
    width: '100%',
    height: Layout.window.width,
  },
  imageStyle: {
    height: 120,
    width: 120,
  },
  modalContainer: {
    justifyContent: 'flex-end',
    margin: 0,
  },
  modal: {
    width: '100%',
    backgroundColor: Colors.white,
    borderRadius: 13,
    paddingVertical: 15,
    paddingHorizontal: 30,
    alignItems: 'center',
  },
  optionsContainer: {
    flex: 1,
    alignItems: 'center',
    marginTop: 20,
  },

  modalOption: {
    width: 200,
    height: 50,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: 10,
  },
  handleBar: {
    alignSelf: 'center',
    width: 60,
    height: 5,
    borderRadius: 5,
    backgroundColor: Colors.lightGrey,
  },
  iconModal: {
    height: 17,
    width: 17,
    marginRight: 10,
  },
  optionLabel: {
    fontFamily: Fonts.family.bold,
  },
  settingsLabel: {
    alignSelf: 'center',
  },
});
