import { View, Image } from 'react-native';
import React from 'react';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';

const RectanglesPattern = () => {
  const styles = useStyles();

  return (
    <View style={styles.body}>
      <Image
        source={{ uri: require('../../../../assets/images/rectangle.png') }}
        style={{ width: '37px', height: '24px', opacity: 1 }}
      />
      <Image
        source={{ uri: require('../../../../assets/images/rectangle.png') }}
        style={{ width: '37px', height: '24px', opacity: 0.8 }}
      />
      <Image
        source={{ uri: require('../../../../assets/images/rectangle.png') }}
        style={{ width: '37px', height: '24px', opacity: 0.6 }}
      />
      <Image
        source={{ uri: require('../../../../assets/images/rectangle.png') }}
        style={{ width: '37px', height: '24px', opacity: 0.4 }}
      />
      <Image
        source={{ uri: require('../../../../assets/images/rectangle.png') }}
        style={{ width: '37px', height: '24px', opacity: 0.2 }}
      />
    </View>
  );
};

export default RectanglesPattern;

const useStyles = CreateResponsiveStyle(
  {
    body: {
      position: 'absolute',
      bottom: 70,
      right: 140,
      zIndex: -1,
    },
  },
  {
    [DEVICE_SIZES.SM]: {
      body: {
        bottom: 10,
        right: 'auto',
      },
    },
    [DEVICE_SIZES.XS]: {
      body: {
        bottom: -15,
        right: 'auto',
        transform: [{ scale: 0.52 }],
        zIndex: 1,
      },
    },
  }
);
