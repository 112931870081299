import React from 'react';
import { View, Image, StyleSheet, Pressable } from 'react-native';
import Svg, { Polygon, Image as ImageSvg, ClipPath } from 'react-native-svg';
import { useSelector } from 'react-redux';
import { Colors } from '../constants/Colors';
import Fonts from '../constants/Fonts';
import { UserMarae } from '../constants/Interfaces';
import Layout from '../constants/Layout';
import Logger from '../constants/Logger';
import i18n from '../translations';
import { Paragraph, UIText } from './StyledText';

interface HeroLeaderboardProps {
  navigation: any;
  data: UserMarae[];
}
const HeroLeaderboard = ({ navigation, data }: HeroLeaderboardProps) => {
  const filterRegion: string = useSelector((state: any) => state.region.selectedRegionLeaderboard);

  const handleRegionToggle = () => {
    Logger.debug(`Region toggle clicked. `);
    navigation.navigate('SelectRegion', {
      regionFilter: 'leaderboard',
    });
  };

  return (
    <View style={styles.root}>
      <View style={styles.filterContainer}>
        <UIText style={styles.filterLabel}>{filterRegion || i18n.t('components.HeroLeaderboard.noRegion')}</UIText>
        <Pressable style={styles.filterIconContainer} onPress={() => handleRegionToggle()}>
          <Image style={styles.filterIcon} source={require('../../assets/images/filterIcon.png')} />
        </Pressable>
      </View>
      {data.length === 0 ? (
        <UIText style={styles.noPointsText}>{i18n.t('components.HeroLeaderboard.noPoints', { filterRegion })}</UIText>
      ) : (
        <View style={styles.podium}>
          {data.length > 2 && <PlacePodium style={[styles.secondPlace]} place={2} marae={data[1]} />}
          {data.length > 0 && <PlacePodium style={[styles.firstPlace]} place={1} marae={data[0]} />}
          {data.length > 2 && <PlacePodium style={[styles.thirdPlace]} place={3} marae={data[2]} />}
        </View>
      )}
    </View>
  );
};

const PlacePodium = ({ place, marae, style }) => {
  const maraeName = marae?.maraeName;
  const pointTotal = marae?.pointTotal;
  const participationTotal = marae?.participationTotal;
  const imageUrl = require('../../assets/images/leaderboard_placeholder.png');

  const imageHeight = (place) => {
    if (place === 1) {
      return { height: 120 };
    }
    if (place === 2) {
      return { height: 80 };
    }
    return { height: 60 };
  };

  const trianglePoints = (place) => {
    if (place === 1) {
      return `0,0 ${Layout.window.width * 0.49},0 ${(Layout.window.width * 0.49) / 2},130`;
    }
    if (place === 2) {
      return `0,0 ${Layout.window.width * 0.32},0 ${(Layout.window.width * 0.32) / 2},85`;
    }
    return `0,0 ${Layout.window.width * 0.27},0 ${(Layout.window.width * 0.27) / 2},65`;
  };
  return (
    <View style={[style]}>
      <View style={styles.podiumBadge}>
        <Svg style={[{ width: '100%' }, imageHeight(place)]}>
          <ClipPath id="clip">
            <Polygon points={trianglePoints(place)} stroke="purple" />
          </ClipPath>
          <ImageSvg
            width="100%"
            height="100%"
            preserveAspectRatio="xMidYMid slice"
            clipPath="url(#clip)"
            href={imageUrl}
          />
        </Svg>
        <View style={[styles.place, { height: imageHeight(place).height / 4, width: imageHeight(place).height / 4 }]}>
          <UIText style={[styles.placeLabel, { fontSize: place === 1 ? 18 : 13 }]}>{place}</UIText>
        </View>
      </View>
      <View style={styles.labelContainer}>
        <UIText style={styles.maraeLabel} numberOfLines={1} ellipsizeMode="tail">
          {maraeName}
        </UIText>
        <Paragraph style={styles.pointsLabel}>{`${pointTotal} ${i18n.t(
          'components.HeroLeaderboard.points'
        )}`}</Paragraph>
        <View style={styles.faves}>
          <Image style={styles.favIcon} source={require('../../assets/images/fav_darkIcon.png')} />
          <UIText style={styles.whanaus}>{`${participationTotal} Whānau`}</UIText>
        </View>
      </View>
    </View>
  );
};
export default HeroLeaderboard;

const styles = StyleSheet.create({
  root: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.yellow,
    zIndex: 1,
    paddingHorizontal: '5%',
  },
  filterLabel: {
    fontFamily: Fonts.family.bold,
    fontSize: Fonts.sizes.smallHeading,
  },
  filterContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 20,
    zIndex: 1000,
  },
  filterIconContainer: {
    height: 30,
    width: 30,
    borderRadius: 30,
    backgroundColor: Colors.darkBurgundy,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 15,
  },
  filterIcon: {
    height: 10,
    width: 20,
    marginLeft: 2,
  },
  podium: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  maraeLabel: {
    width: '75%',
    alignSelf: 'center',
    textAlign: 'center',
    fontSize: Fonts.sizes.medium,
    fontFamily: Fonts.family.bold,
  },
  faves: {
    backgroundColor: Colors.whiteOpacity,
    borderRadius: 10,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: 2,
    width: 100,
  },
  favIcon: {
    height: 20,
    width: 20,
    marginRight: 5,
  },
  podiumBadge: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  place: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.darkBurgundy,
    borderColor: Colors.yellow,
    borderWidth: 3,
    borderRadius: 50,
    alignSelf: 'center',
    top: -15,
    minWidth: 25,
    minHeight: 25,
  },
  firstPlace: {
    width: '55%',
    alignSelf: 'center',
  },
  secondPlace: {
    width: '35%',
    left: 30,
  },
  thirdPlace: {
    width: '32%',
    right: 40,
  },
  placeLabel: {
    color: Colors.white,
    fontFamily: Fonts.family.bold,
  },
  labelContainer: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  pointsLabel: {
    fontSize: Fonts.sizes.small,
    marginVertical: 5,
  },
  whanaus: {
    fontSize: Fonts.sizes.mini,
    fontFamily: Fonts.family.bold,
  },
  modal: {
    maxHeight: 400,
  },
  noPointsText: {
    alignSelf: 'center',
    paddingTop: 15,
    textAlign: 'center',
  },
});
