/* eslint-disable no-nested-ternary */
import * as React from 'react';
import { View } from '../../../components/Themed';
import { Heading, Paragraph, UIText } from '../../../components/StyledText';
import i18n from '../../../translations';
import { Colors } from '../../../constants/Colors';
import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import { SplitButton } from '../../../components/buttons/SplitButton';
import { StyleSheet } from 'react-native';
import Layout from '../../../constants/Layout';
import Fonts from '../../../constants/Fonts';
import { Activity, User, UserActivity, UserMarae, WhanauType } from '../../../constants/Interfaces';
import { getUserActivities, postUserActivity } from '../../../reducers/activity.slice';
import Logger from '../../../constants/Logger';
import { useDispatch } from 'react-redux';
import ModalUI from '../../../components/ModalUI';
import _ from 'lodash';
import { useNavigation } from '@react-navigation/native';
import WhanauTypeListFragment from './WhanauTypeListFragment';

interface ChooseWhanauTypeFragmentProps {
  onComplete: () => void;
  onBack: () => void;
  toggleWhanauType: (whanauType: WhanauType) => void;
  selectedWhanaus: User[];
  selectedMaraes: UserMarae[];
  selectedWhanauType: WhanauType[];
  activity: Activity;
  myActivity: UserActivity | null;
}

export const ChooseWhanauTypeFragment = ({
  onComplete,
  onBack,
  selectedWhanaus,
  selectedMaraes,
  selectedWhanauType,
  toggleWhanauType,
  activity,
  myActivity,
}: ChooseWhanauTypeFragmentProps) => {
  const dispatch = useDispatch();
  const [modalVisible, setModalVisible] = React.useState<boolean>(false);
  const navigation = useNavigation<any>();
  const language = i18n.locale;

  const handleOnComplete = async () => {
    Logger.debug(
      `Fragments -> ChooseWhanauTypeFragment -> handleOnComplete: Selected whanaus: ${selectedWhanaus.length}`
    );
    Logger.debug(
      `Fragments -> ChooseWhanauTypeFragment -> handleOnComplete: Selected maraes: ${selectedMaraes.length}`
    );
    Logger.debug(
      `Fragments -> ChooseWhanauTypeFragment -> handleOnComplete: Selected whanau type: ${selectedWhanauType}`
    );

    if (selectedWhanauType.length !== 1) {
      setModalVisible(true);
    } else {
      const promises: any[] = [];
      // Post main user's activity
      promises.push(
        dispatch(
          postUserActivity({
            activity: {
              activityId: activity.activityId.trim(),
              favourite: myActivity ? myActivity.favourite : false,
              going: true,
              maraeId: selectedMaraes[0].maraeId,
              whanauType: selectedWhanauType[0],
            },
          })
        )
      );

      _.each(selectedWhanaus, (whanau) => {
        promises.push(
          dispatch(
            postUserActivity({
              userId: whanau.userId,
              activity: {
                activityId: activity.activityId.trim(),
                favourite: false,
                going: true,
                maraeId: selectedMaraes[0].maraeId,
              },
            })
          )
        );
      });

      await Promise.all(promises);
      dispatch(getUserActivities());
      onComplete();

      // navigate to the confirmation screen now
      navigation.navigate('ConfirmationScreen', {
        title: i18n.t('components.ChooseWhanauTypeFragment.confirmTitle'),
        message: i18n.t('components.ChooseWhanauTypeFragment.confirmMessage', {
          activityName: activity.activityName,
          participants: getWhanauNames(selectedWhanaus),
        }),
        buttonText: i18n.t('components.ChooseWhanauTypeFragment.confirmButtonText'),
        nextScreenName: 'ActivityDetails',
        screenParams: {
          activityId: activity.activityId,
        },
      });
    }
  };

  const handleSelected = (whanauType: WhanauType) => {
    toggleWhanauType(whanauType);
  };

  const getWhanauNames = (whanaus: User[]) => {
    const whanauList = whanaus.slice(0, 6);
    if (whanaus.length === 1) {
      return language === 'en' ? whanauList[0].firstName : `kōrua ko ${whanauList[0].firstName} `;
    }
    if (whanaus.length === 2) {
      return i18n.t('components.ChooseWhanauTypeFragment.twoWhanau', {
        first: whanaus[0].firstName,
        second: whanaus[1].firstName,
      });
    }
    let result = '';

    for (let i = 0; i < whanauList.length; i += 1) {
      if (language === 'mi' && i === 0) {
        result += `koutou ko `;
      }
      if (i < whanauList.length - 2) {
        result +=
          language === 'en'
            ? `${whanauList[i].firstName}, `
            : i === 0
            ? `${whanauList[i].firstName}, `
            : `ko ${whanauList[i].firstName}, `;
      }
      if (i === whanauList.length - 2 && whanauList.length < 6) {
        result +=
          language === 'en'
            ? `${whanauList[i].firstName} ${i18n.t('components.ChooseWhanauTypeFragment.threeWhanau')} `
            : ` ko ${whanauList[i].firstName}, `;
      }
      if (i === whanauList.length - 1 && whanauList.length < 6) {
        result += language === 'en' ? whanauList[i].firstName : `ko ${whanauList[i].firstName}`;
      }
      if (i === whanauList.length - 1 && whanauList.length > 5) {
        result += ` ko ${whanauList[i].firstName} ${i18n.t('components.ChooseWhanauTypeFragment.otherWhanau', {
          whanaus: whanaus.length - whanauList.length + 1,
        })}`;
      }
    }
    return result;
  };

  return (
    <View transparent style={styles.container}>
      <UIText style={styles.step}>
        {i18n.t('components.ChooseWhanauTypeFragment.step', {
          currentStep: 3,
          totalSteps: 3,
        })}
      </UIText>
      <Heading style={styles.title}>{i18n.t('components.ChooseWhanauTypeFragment.title')}</Heading>
      <Paragraph style={styles.instruction}>
        {selectedWhanaus.length > 0
          ? i18n.t('components.ChooseWhanauTypeFragment.someoneettending', {
              whanauNames: getWhanauNames(selectedWhanaus),
              isAre: selectedWhanaus.length === 1 ? 'is' : 'are',
              activityName: activity.activityName,
            })
          : ''}
        {i18n.t('components.ChooseWhanauTypeFragment.instruction')}
      </Paragraph>

      <SplitButton
        containerStyles={styles.actionButtonContainer}
        labelLeft={i18n.t('components.ChooseWhanauTypeFragment.goback')}
        labelRight={i18n.t('components.ChooseWhanauTypeFragment.done')}
        onPressLeft={onBack}
        onPressRight={handleOnComplete}
        backgroundColorLeft={Colors.white}
        leftRightRatio={1}
        iconLeft={{
          left: (
            <MaterialIcons
              name="arrow-back-ios"
              size={16}
              color={Colors.darkBurgundy}
              style={[styles.buttonIcon, styles.buttonIconLeft]}
            />
          ),
        }}
        iconRight={{
          left: (
            <MaterialCommunityIcons
              name="check-all"
              size={16}
              color={Colors.white}
              style={[styles.buttonIcon, styles.buttonIconLeft]}
            />
          ),
        }}
      />

      <View style={styles.listContainer}>
        <WhanauTypeListFragment
          openDetails={() => {}}
          onSelect={handleSelected}
          selectedWhanauType={selectedWhanauType}
          selection
        />
      </View>

      <ModalUI closeModal={() => setModalVisible(false)} toggle={modalVisible}>
        <View transparent>
          <UIText>You must select a whanau type</UIText>
        </View>
      </ModalUI>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    paddingHorizontal: Layout.backgroundMarginNormal.left,
  },
  actionButtonContainer: {
    marginHorizontal: 20,
    marginBottom: 15,
  },
  step: {
    fontSize: Fonts.sizes.small,
  },
  title: {
    paddingVertical: 14,
    fontSize: Fonts.sizes.smallHeading,
  },
  instruction: {
    fontSize: Fonts.sizes.medium,
    textAlign: 'center',
    paddingBottom: 32,
  },
  buttonIcon: {
    paddingTop: 2,
  },
  buttonIconLeft: {
    paddingRight: 8,
  },
  buttonIconRight: {
    paddingLeft: 8,
  },
  listContainer: { width: '90%', height: 400 },
});
