import { Linking, Pressable, StyleSheet, View, Image } from 'react-native';
import React from 'react';
import Constants from 'expo-constants';

const { youtube, facebook, instagram } = Constants?.expoConfig?.extra?.socialMedia;

const SocialMediaBar = () => {
  const iconButtonOnPressHandler = (iconButtonName: string) => Linking.openURL(iconButtonName);

  return (
    <View style={styles.rootContainer}>
      <Pressable onPress={() => iconButtonOnPressHandler(youtube)} style={styles.iconButtonPressable}>
        <Image source={require('../../assets/images/icons8-youtube-48.png')} style={styles.icon} />
      </Pressable>
      <Pressable onPress={() => iconButtonOnPressHandler(facebook)} style={styles.iconButtonPressable}>
        <Image source={require('../../assets/images/icons8-facebook-48.png')} style={styles.icon} />
      </Pressable>
      <Pressable onPress={() => iconButtonOnPressHandler(instagram)} style={styles.iconButtonPressable}>
        <Image source={require('../../assets/images/icons8-instagram-48.png')} style={styles.icon} />
      </Pressable>
    </View>
  );
};

export default SocialMediaBar;

const styles = StyleSheet.create({
  rootContainer: {
    flexDirection: 'row',
  },
  iconButtonPressable: {
    width: 24,
    aspectRatio: 1,
    marginHorizontal: 6,
  },
  icon: {
    width: '100%',
    height: '100%',
  },
});
