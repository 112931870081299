import React from 'react';
import { View, StyleSheet, Image } from 'react-native';
import { Colors } from '../../../../constants/Colors';
import { FlatList } from 'react-native-gesture-handler';
import NoteInfo from '../../../../components/NoteInfo';
import { Paragraph } from '../../../../components/StyledText';
import Fonts from '../../../../constants/Fonts';
import { UserMarae } from '../../../../constants/Interfaces';
import { useSelector } from 'react-redux';
import { DateTime } from 'luxon';

interface MaraePointsProps {
  marae: UserMarae;
}
const dateFortmat = (date: string) => DateTime.fromISO(date).toFormat('MMMM dd');

const MaraePointsFragment = ({ marae }: MaraePointsProps) => {
  const maraeStats: any = useSelector((state: any) => state.user.maraeStats);

  return (
    <View style={styles.root}>
      <FlatList
        style={styles.list}
        data={maraeStats?.topActivities}
        renderItem={({ item }) => (
          <MaraeEvents name={item.activityName} date={item.endDate} points={item.pointsTotal} />
        )}
        keyExtractor={(item) => `${item.activityName}-${item.endDate}`}
        ListHeaderComponent={NoteInfo({ message: 'points', bgcolor: 'white', maraeName: marae.maraeName })}
        ListFooterComponent={<View style={{ height: 200 }} />}
      />
    </View>
  );
};

const MaraeEvents = ({ name, date, points }) => (
  <View style={styles.eventsContainer}>
    <Image style={styles.badgeIcon} source={require('../../../../../assets/images/badgePoints.png')} />
    <View style={styles.descriptionContainer}>
      <Paragraph style={styles.activityLabel}>{name}</Paragraph>
      <Paragraph style={styles.dateLabel}>{`${dateFortmat(date)}, ${points} Points`}</Paragraph>
    </View>
  </View>
);

export default MaraePointsFragment;
const styles = StyleSheet.create({
  root: {
    width: '93%',
    alignSelf: 'center',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  list: {
    width: '100%',
    marginTop: 20,
    height: 500,
  },
  eventsContainer: {
    width: '100%',
    flexDirection: 'row',
    marginBottom: 5,
  },
  badgeIcon: {
    width: 70,
    height: 70,
  },
  descriptionContainer: {
    width: '80%',
    justifyContent: 'center',
    alignContent: 'center',
  },
  activityLabel: {
    fontFamily: Fonts.family.bold,
    color: Colors.white,
    fontSize: Fonts.sizes.button,
  },
  dateLabel: {
    color: Colors.whiteOpacity,
  },
});
