import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Activity } from '../constants/Interfaces';
import Logger from '../constants/Logger';
import { ActivityTile } from './ActivityTile';

interface GridActivitiesProps {
  activities: Activity[];
  navigation;
}

const GridActivities = ({ activities, navigation }: GridActivitiesProps) => {
  const sortedActivities = [...activities].sort(() => Math.random() - 0.5);

  const handleOpenActivity = (activityId: string) => {
    Logger.debug(`Screen -> ActivitiesScreen -> handleOpenActivity: Navigating to activity ${activityId}`);
    navigation.navigate('ActivityDetails', {
      activityId,
    });
  };

  return (
    <View style={styles.grid}>
      {sortedActivities.map((item) => (
        <ActivityTile key={item.activityId} activity={item} onPress={handleOpenActivity} background="white" />
      ))}
    </View>
  );
};

export default GridActivities;
const styles = StyleSheet.create({
  grid: {
    flexDirection: 'row',
    width: '100%',
    overflow: 'hidden',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
});
