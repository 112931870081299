import React, { Dispatch, SetStateAction } from 'react';
import { StyleSheet, Pressable, Image, ImageSourcePropType } from 'react-native';
import { Colors } from '../constants/Colors';
import { User, UserMarae } from '../constants/Interfaces';
import { View } from './Themed';
import { MaterialIcons } from '@expo/vector-icons';

interface EllipseBorderImageProps {
  item?: User | UserMarae;
  borderStyle?: {};
  imageStyle?: { width: number };
  img: ImageSourcePropType;
  openDetails?: Dispatch<SetStateAction<boolean>>;
  onSelect?: (item: any) => void;
  selected?: boolean;
  color: 'yellow' | 'red';
}

const EllipseBorderImage = ({
  item,
  borderStyle,
  imageStyle,
  openDetails,
  onSelect,
  selected = false,
  img,
  color,
}: EllipseBorderImageProps) => {
  const width = imageStyle?.width;
  return (
    <Pressable
      style={[
        styles.ellipseContainer,
        borderStyle,
        { borderColor: color === 'yellow' ? Colors.yellow : Colors.darkBurgundy },
      ]}
      onPress={() => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        item && onSelect ? onSelect!(item!) : null;
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        openDetails ? openDetails!(true) : null;
      }}
    >
      <Image style={[styles.image, { height: width, width }]} source={img} />
      {selected ? (
        <View style={styles.selectedContainer}>
          <MaterialIcons name="check" size={16} color={Colors.black} />
        </View>
      ) : null}
    </Pressable>
  );
};
export default EllipseBorderImage;

const styles = StyleSheet.create({
  ellipseContainer: {
    borderRadius: 100,
    borderWidth: 2,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 4,
  },
  image: {
    borderRadius: 100,
  },
  selectedContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    height: 20,
    width: 20,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 20,
    backgroundColor: Colors.green,
    zIndex: 5,
    elevation: 5,
  },
});
