import React from 'react';
import { View, StyleSheet, Pressable, ImageBackground } from 'react-native';
import { Heading, Paragraph } from '../../../components/StyledText';
import { Colors } from '../../../constants/Colors';
import Layout from '../../../constants/Layout';
import Fonts from '../../../constants/Fonts';
import { getCacheImage } from '../../../constants/Utils';
import Logger from '../../../constants/Logger';

interface EventCardFragmentProps {
  title: string;
  subTitle?: string;
  imageUrl?: string;
  splitButton: React.ReactNode; // the SplitButton component
  isInList?: boolean;
  onPress?: () => void;
  activityId: string;
}

const EventCardFragment = ({
  title,
  subTitle,
  imageUrl,
  splitButton,
  isInList = true,
  onPress,
  activityId,
}: EventCardFragmentProps) => {
  const [activityImage, setActivityImage] = React.useState<string | undefined>(imageUrl);

  const cacheImage = async () => {
    try {
      const uri = await getCacheImage({ activityId, uri: imageUrl });
      setActivityImage(uri);
    } catch (e) {
      Logger.debug(e);
    }
  };

  React.useEffect(() => {
    cacheImage();
  }, [activityId]);

  const cardBody = () => (
    <>
      <Paragraph style={styles.label}>{subTitle}</Paragraph>
      <Heading style={styles.label} numberOfLines={1}>
        {title}
      </Heading>
    </>
  );

  return (
    <Pressable style={[styles.root, { width: isInList ? Layout.window.width * 0.75 : undefined }]} onPress={onPress}>
      {!!imageUrl && activityId && (
        <ImageBackground source={{ uri: activityImage }} style={styles.cardImage}>
          <View style={styles.titleContainer}>{cardBody()}</View>
        </ImageBackground>
      )}
      {!imageUrl && activityId && <View style={[styles.cardImage, styles.titleContainer]}>{cardBody()}</View>}
      <View style={styles.buttonContainer}>{splitButton}</View>
    </Pressable>
  );
};

export default EventCardFragment;

const styles = StyleSheet.create({
  root: {
    height: 290,
    marginBottom: 15,
    alignItems: 'center',
    borderRadius: 5,
  },
  cardImage: {
    width: '100%',
    minHeight: '80%',
    maxHeight: '80%',
    resizeMode: 'cover',
    borderRadius: 5,
    justifyContent: 'flex-end',
  },
  titleContainer: {
    padding: 15,
    backgroundColor: Colors.blackOpacity,
  },
  label: {
    ...Fonts.shadow,
    color: Colors.white,
    width: '95%',
  },
  buttonContainer: {
    width: '100%',
    marginVertical: 10,
  },
});
