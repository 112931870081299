import * as React from 'react';
import { Activity, ActivityStats, Dictionary } from '../../../constants/Interfaces';
import { View } from '../../../components/Themed';
import { Pressable, StyleSheet, Image } from 'react-native';
import { Colors } from '../../../constants/Colors';
import { Heading, UIText } from '../../../components/StyledText';
import Fonts from '../../../constants/Fonts';
import i18n from '../../../translations';
import { DonutChart } from '../../../components/DonutChart';
import { convertToNZ, getCacheImage } from '../../../constants/Utils';
import { getActivityStats } from '../../../reducers/activity.slice';
import { useDispatch, useSelector } from 'react-redux';
import Logger from '../../../constants/Logger';

interface EventStatsFragmentProps {
  activity: Activity;
  onPress?: (activityId: string) => void;
}

export const EventStatsFragment = ({ activity, onPress }: EventStatsFragmentProps) => {
  const activityStats: Dictionary<ActivityStats> = useSelector((state: any) => state.activity.stats);

  const dispatch = useDispatch();
  const stats: ActivityStats = activityStats[activity.activityId];
  const [activityImage, setActivityImage] = React.useState<string | undefined>(activity.imageUrl);

  let attendanceRate: number = 0;
  if (stats && stats.engagementTypeGoing.personTotal !== 0) {
    attendanceRate = (stats.engagementTypeCheckedIn.personTotal / stats.engagementTypeGoing.personTotal) * 100;
  }

  React.useEffect(() => {
    dispatch(getActivityStats(activity?.activityId));
  }, []);

  const cacheImage = async () => {
    try {
      const uri = await getCacheImage({ activityId: activity.activityId, uri: activity.imageUrl });
      setActivityImage(uri);
    } catch (e) {
      Logger.debug(e);
    }
  };

  React.useEffect(() => {
    cacheImage();
  }, [activity]);

  const handlePress = () => {
    if (onPress) {
      onPress(activity.activityId);
    }
  };
  return (
    <Pressable onPress={handlePress} style={styles.container}>
      <View transparent style={styles.summaryContainer}>
        {activity && <Image source={{ uri: activityImage }} style={[styles.cover, styles.image]} />}
        <View transparent style={styles.contentContainer}>
          <UIText style={styles.date}>
            {convertToNZ({ date: activity.startDate!, format: 'MMMM dd, yyyy, h:mma' })}
          </UIText>
          <Heading style={styles.activityName} numberOfLines={1}>
            {activity.activityName}
          </Heading>
        </View>
      </View>
      <View transparent style={styles.attendanceContainer}>
        <UIText style={styles.attendanceLabel}>{i18n.t('components.EventStatsFragment.attendance')}</UIText>
        <DonutChart percentage={attendanceRate} size={90} />
      </View>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    backgroundColor: Colors.white,
    borderRadius: 5,
    width: 300,
    height: 135,
    padding: 10,
  },
  summaryContainer: {
    width: 170,
    height: 120,
    borderRadius: 5,
    position: 'relative',
  },
  cover: {
    position: 'absolute',
    top: 0,
    left: 0,
    borderRadius: 5,
    width: 170,
    height: 116,
  },
  image: {
    resizeMode: 'cover',
  },
  mask: {
    backgroundColor: Colors.black,
    opacity: 0.32,
  },
  contentContainer: {
    width: '100%',
    position: 'absolute',
    padding: 4,
    bottom: 4,
    backgroundColor: Colors.blackOpacity,
  },
  date: {
    ...Fonts.shadow,
    color: Colors.white,
    fontSize: Fonts.sizes.mini,
  },
  activityName: {
    ...Fonts.shadow,
    color: Colors.white,
    fontSize: Fonts.sizes.medium,
    width: '95%',
    marginTop: 4,
    flex: 1,
  },
  attendanceContainer: {
    flex: 1,
    alignItems: 'center',
  },
  attendanceLabel: {
    color: Colors.black,
    fontFamily: Fonts.family.bold,
    fontSize: Fonts.sizes.small,
  },
});
