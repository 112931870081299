import { useState, useEffect } from 'react';

export const languageListener = (): string | null => {
  const [language, setLanguage] = useState<null | string>(localStorage.getItem('MaraeFitLanguage') || 'en');
  const changeLanguage = () => {
    setLanguage(localStorage.getItem('MaraeFitLanguage'));
  };
  useEffect(() => {
    window.addEventListener('storage', changeLanguage);
    return () => {
      window.removeEventListener('storage', changeLanguage);
    };
  }, []);
  return language;
};
