import { AnyAction, createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit';
import { MaraeLeaderboard, Marae } from '../constants/Interfaces';
import Logger from '../constants/Logger';
import API from '../constants/API';
import { RejectedAction } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import { purge } from './commonActions';
import { setRegions } from './region.slice';
import _ from 'lodash';

interface MaraeState {
  maraes: Marae[];
  loading: boolean;
  backgroundLoading: boolean;
  error: SerializedError | null;
  leaderboard: MaraeLeaderboard[] | null;
}

const initialState: MaraeState = {
  maraes: [],
  loading: false,
  backgroundLoading: false,
  error: null,
  leaderboard: [],
};

export const getMaraes = createAsyncThunk('marae/getMaraes', async (param, thunkAPI) => {
  Logger.info(`Reducer -> marae -> getMaraes: Getting public maraes`);
  const api: API = API.getInstance();
  try {
    const maraes: Marae[] = await api.get('/marae');
    Logger.debug(`Reducer -> marae -> getMaraes: maraes information received`);
    if (maraes && maraes.length > 0) {
      const activitiesByRegion = _.groupBy(maraes, (maraes) => maraes.maraeLocation?.regionName);
      const regions: string[] = [];
      _.forOwn(activitiesByRegion, (value, key) => {
        regions.push(key);
      });
      thunkAPI.dispatch(setRegions(regions));
    }
    return maraes;
  } catch (e) {
    Logger.warn(`Reducer -> marae -> getMaraes: ${e.message}`);
    throw e;
  }
});

export const getMaraeLeaderboard = createAsyncThunk('marae/getMaraeLeaderboard', async () => {
  Logger.info(`Reducer -> marae -> getMaraeLeaderboard: Getting Leaderboard`);
  const api: API = API.getInstance();
  try {
    const leaderboard: MaraeLeaderboard[] = await api.get(`/marae/leaderboard`);
    return leaderboard;
  } catch (e) {
    Logger.warn(`Reducer -> marae -> getMaraeLeaderboard: ${e.message}`);
    throw e;
  }
});

const isRejectedAction = (action: AnyAction): action is RejectedAction<any, any> => action.type.endsWith('/rejected');

const maraeSlice = createSlice({
  name: 'marae',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMaraes.fulfilled, (state, action) => {
        const maraes: Marae[] = action.payload;
        state.maraes = maraes;
        state.backgroundLoading = false;
        state.error = null;
      })
      .addCase(getMaraes.pending, (state) => {
        state.backgroundLoading = true;
        state.error = null;
      })
      .addCase(getMaraeLeaderboard.fulfilled, (state, action) => {
        state.leaderboard = action.payload;
        state.backgroundLoading = true;
        state.error = null;
      })
      .addCase(getMaraeLeaderboard.pending, (state) => {
        state.backgroundLoading = true;
        state.error = null;
      })
      .addCase(purge, (state) => {
        Logger.debug(`Reducer -> marae: Purge request received.`);
        state.maraes = [];
        state.loading = false;
        state.backgroundLoading = false;
        state.error = null;
        state.leaderboard = [];
      })
      .addMatcher(isRejectedAction, (state, action) => {
        state.loading = false;
        state.backgroundLoading = false;
        state.error = action.error;
      });
  },
});

export default maraeSlice.reducer;
