import { View, StyleSheet } from 'react-native';
import React, { useState } from 'react';
import { UIText } from '../../../../components/StyledText';
import i18n from '../../../../translations';
import { Colors } from '../../../../constants/Colors';
import Fonts from '../../../../constants/Fonts';
import { SplitButton } from '../../../../components/buttons/SplitButton';
import ModalUI from '../../../../components/ModalUI';
import Logger from '../../../../constants/Logger';
import API from '../../../../constants/API';
import { useDispatch, useSelector } from 'react-redux';
import { purge } from '../../../../reducers/commonActions';
import { useNavigation } from '@react-navigation/native';
import PrimaryButton from '../../../../components/buttons/PrimaryButton';
import { logOut } from '../../../../constants/Utils';

const DeleteAccountFragment = () => {
  const navigation = useNavigation();
  const [active, setActive] = useState(false);
  const userId: any = useSelector((state: any) => state.user.user?.userId) ?? null;
  const dispatch = useDispatch();

  const deleteUser = async () => {
    Logger.info(`Reducer -> user -> deleteUser: deleting user init`);
    const api: API = API.getInstance();
    try {
      await api.delete(`/user`, userId);
      Logger.debug(`Reducer -> user -> deleteUser: userDeleted.`);
    } catch (e) {
      Logger.warn(`Reducer -> user -> deleteUser: couldn't delete user ${e.message}`);
      throw e;
    }
  };

  const handleLogout = () => {
    logOut();
  };

  const deleteConfirmed = () => {
    deleteUser();
    handleLogout();
  };

  return (
    <View style={styles.root}>
      <View style={styles.noteLabel}>
        <UIText style={[styles.noteText, { color: Colors.black }]}>
          {i18n.t('components.DeleteAccountFragment.deleteMessage')}
        </UIText>
      </View>
      <PrimaryButton
        title={i18n.t('components.DeleteAccountFragment.DeleteAccountButton')}
        onPress={() => setActive(true)}
        themeColor="yellow"
      />
      <ModalUI toggle={active} closeModal={() => setActive(false)}>
        <UIText style={styles.modalMessage}>{i18n.t('components.DeleteAccountFragment.confirmDelete')}</UIText>
        <SplitButton
          labelLeft="Cancel"
          labelRight="Confirm"
          backgroundColorLeft={Colors.white}
          backgroundColorRight={Colors.darkBurgundy}
          onPressLeft={() => setActive(false)}
          onPressRight={deleteConfirmed}
        />
      </ModalUI>
    </View>
  );
};

export default DeleteAccountFragment;

const styles = StyleSheet.create({
  root: {
    paddingHorizontal: '8%',
    paddingVertical: 15,
  },
  noteLabel: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.white,
    borderRadius: 5,
    width: '100%',
    paddingHorizontal: 5,
    paddingVertical: 15,
    marginTop: 20,
    marginBottom: 25,
  },
  noteText: {
    color: Colors.white,
    fontSize: Fonts.sizes.medium,
    paddingHorizontal: 12,
  },
  modalMessage: {
    marginBottom: 15,
    alignSelf: 'center',
  },
});
