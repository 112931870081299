import { useNavigation } from '@react-navigation/native';
import axios from 'axios';
import Constants from 'expo-constants';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { Image, ImageBackground, ScrollView, TextInput, View } from 'react-native';
import { PickerInput } from '../components/inputs/PickerInput';
import { UIText } from '../components/StyledText';
import { Colors } from '../constants/Colors';
import Fonts from '../constants/Fonts';
import { Activity, DropdownItem, Region } from '../constants/Interfaces';
import i18n from '../translations';
import { ActivityTile } from './ActivityTile';
import Footer from './Footer';
import Header from './Header';
import { LinearGradient } from 'expo-linear-gradient';
import { languageListener } from './Utils/languageListener';
import { CreateResponsiveStyle, DEVICE_SIZES, useDeviceSize } from 'rn-responsive-styles';

const ActivitiesListing = () => {
  const styles = useStyles();
  const deviceSize = useDeviceSize();
  const language = languageListener();
  const [activities, setActivities] = useState([]);
  const apiEndpoint = Constants.expoConfig?.extra?.azure.apiEndpoint;
  const navigation = useNavigation();
  const [filterRegion, setFilterRegion] = useState<string>();
  const [listOfRegions, setListOfRegions] = useState<DropdownItem[]>([]);
  const [search, setSearch] = useState<string>('');

  const refStub = {
    hide: () => {},
  };
  const regionRef = useRef(refStub);

  // const filteredActivities = filterRegion ? activities.filter((activity: Activity) => activity?.location?.regionName === filterRegion) : activities;
  // Use an includes instead of equals to filter more regions such as Waikato/Te Rohe Potae
  const filteredActivities = filterRegion
    ? activities.filter(
        (activity: Activity) =>
          filterRegion.includes(activity?.location?.regionName!) ||
          filterRegion.includes(activity?.location?.regionNameEnglish!)
      )
    : activities;

  const sortedActivities: Activity[] = [...filteredActivities]
    // eslint-disable-next-line no-nested-ternary
    .sort((a: any, b: any) => (a.created! < b.created! ? 1 : b.created! < a.created! ? -1 : 0));

  const searchResults =
    search !== ''
      ? sortedActivities.filter(
          (item) =>
            item.activityName?.toLowerCase().includes(search.toLowerCase()) ||
            item.location?.regionName?.toLowerCase().includes(search.toLowerCase()) ||
            item.location?.regionNameEnglish?.toLowerCase().includes(search.toLowerCase())
        )
      : sortedActivities;

  const extendedSearchResult = [
    ...searchResults,
    {
      activityId: Math.random().toString(36),
      activityName: undefined,
      location: undefined,
      organisation: undefined,
      organiser: undefined,
      description: undefined,
      imageUrl: undefined,
      startDate: undefined,
      endDate: undefined,
      kaiwhakahaereId: undefined,
      created: undefined,
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios(`${apiEndpoint}/regions`);
      const regions: Region[] = result.data;
      const regionNames = _.groupBy<Region>(regions, (region: Region) => region.regionName);
      const regionNamesEnglish = _.groupBy<Region>(regions, (region: Region) => region.regionNameEnglish);
      const regionItems: DropdownItem[] = [
        {
          label: i18n.t('pages.ActivitiesScreen.allRegions', { locale: language }),
          value: '',
        },
      ];
      if (language === 'en') {
        _.forOwn(regionNamesEnglish, (value, key) => {
          regionItems.push({
            label: key,
            value: key,
          });
        });
      } else {
        _.forOwn(regionNames, (value, key) => {
          regionItems.push({
            label: key,
            value: key,
          });
        });
      }
      setListOfRegions(regionItems);
    };
    fetchData();
  }, [language]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios(`${apiEndpoint}/activity`);
      setActivities(result.data);
    };
    fetchData();
  }, [language]);

  const handleBlur = () => {
    regionRef.current.hide();
  };

  const handleOpenActivity = (activityId: string) => {
    navigation.navigate('ActivityDetails', {
      id: activityId,
    });
  };

  return (
    <>
      <View style={styles.root}>
        <Header menuBarTheme="light" />
        <View style={styles.viewHeaderImage}>
          <ImageBackground source={require('../../assets/images/ActivitiesHeader.png')} style={styles.headerImage}>
            <View style={styles.summaryContainer}>
              <UIText style={styles.summaryTitle}>
                {i18n.t('pages.ActivitiesScreen.headerTitle', { locale: language })}
              </UIText>
              <UIText style={[styles.summarySubTitle, { display: 'none' }]}>
                {i18n.t('pages.ActivitiesScreen.headerSubTitle', { locale: language })}
              </UIText>
              <View style={styles.searchContainer}>
                <View style={styles.searchTitleContainer}>
                  <UIText style={styles.searchTitle}>
                    {i18n.t('pages.ActivitiesScreen.searchFor', { locale: language })}
                  </UIText>
                </View>
                <View style={styles.searchBarContainer}>
                  <TextInput
                    style={styles.searchTextInput}
                    placeholder={i18n.t('pages.ActivitiesScreen.searchBy', { locale: language })}
                    onChangeText={(text) => setSearch(text)}
                  />
                  <View style={styles.searchIconContainer}>
                    <Image source={require('../../assets/images/searchIcon.png')} style={styles.searchIcon} />
                  </View>
                </View>
              </View>
            </View>
            <LinearGradient
              colors={['transparent', 'rgba(28,16,16, 0.8)']}
              start={{ x: 1, y: 1 }}
              end={{ x: 0, y: 1 }}
              style={styles.LeftToRightLinerGradient}
              pointerEvents="none"
            />
          </ImageBackground>
        </View>
        <View style={styles.allDetails}>
          <View style={styles.regionContainer}>
            <UIText style={styles.region}>
              {filterRegion || i18n.t('pages.ActivitiesScreen.noRegion', { locale: language })}
            </UIText>
            <View style={styles.regionSelectContainer}>
              <PickerInput
                ref={regionRef}
                sortEnabled
                placeholder={i18n.t('pages.ActivitiesScreen.region', { locale: language })}
                data={listOfRegions}
                value={filterRegion}
                maxItems={50}
                onSelect={(regionName: string) => {
                  setFilterRegion(regionName);
                }}
                onActive={handleBlur}
                containerStyle={styles.regionSelect}
                inputContainerStyle={styles.regionSelectInputContainer}
                inputStyle={styles.regionSelectInput}
                dropdownIconStyle={styles.regionSelectDropdownIcon}
                dropdownStyle={styles.regionSelectDropdown}
                placeholderStyle={styles.placeholderStyle}
              />
            </View>
          </View>

          <ScrollView
            style={styles.scroll}
            showsVerticalScrollIndicator={false}
            showsHorizontalScrollIndicator={false}
            contentContainerStyle={styles.scrollViewContentContainer}
          >
            {extendedSearchResult.map((item: Activity) => (
              <ActivityTile key={item.activityId} activity={item} onPress={handleOpenActivity} />
            ))}
          </ScrollView>
        </View>
        <Footer showReasons={deviceSize === 'extrasmall'} />
      </View>
    </>
  );
};

export default ActivitiesListing;

const useStyles = CreateResponsiveStyle(
  {
    root: {
      backgroundColor: Colors.white,
      width: '100%',
      height: '100%',
    },
    LeftToRightLinerGradient: {
      width: '28.6%',
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
    },
    viewHeaderImage: {
      position: 'absolute',
      top: 0,
      zIndex: -1,
      width: '100%',
      height: 944,
    },
    headerImage: {
      height: '100%',
      resizeMode: 'cover',
    },
    scroll: {
      flexGrow: 0,
    },
    allDetails: {
      marginTop: 895,
      minHeight: '60%',
      width: '80%',
      backgroundColor: 'transparent',
      zIndex: 1,
      alignSelf: 'center',
    },
    scrollViewContentContainer: { flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' },
    regionContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      zIndex: 3,
      width: '100%',
    },
    region: {
      color: Colors.black,
      fontSize: Fonts.sizes.smallHeading,
    },
    regionSelectContainer: {
      width: 360,
      minWidth: 360,
    },
    regionSelect: {},
    regionSelectInputContainer: {
      height: 60,
      borderRadius: 0,
    },
    regionSelectInput: {
      backgroundColor: Colors.lightGrey,
    },
    regionSelectDropdownIcon: {
      backgroundColor: Colors.lightGrey,
      borderLeftWidth: 2,
      borderColor: 'rgba(0,0,0,.1)',
      width: 60,
    },
    regionSelectDropdown: {
      width: 320,
      minWidth: 320,
      right: 60,
      top: 0,
      borderWidth: 1,
      borderColor: Colors.lightGrey,
    },
    summaryContainer: {
      height: '100%',
      width: '80%',
      justifyContent: 'center',
      alignSelf: 'center',
      zIndex: 2,
    },
    summaryTitle: {
      backgroundColor: Colors.transparent,
      fontSize: 60,
      lineHeight: 74,
      color: Colors.white,
      fontFamily: Fonts.family.bold,
      marginBottom: 30,
      width: '35%',
    },
    summarySubTitle: {
      backgroundColor: Colors.transparent,
      fontSize: 28,
      lineHeight: 38,
      color: Colors.white,
      marginBottom: 40,
      width: '50%',
    },
    searchContainer: {
      flexDirection: 'row',
      width: '100%',
      height: 86,
      marginTop: 32,
    },
    searchTitleContainer: {
      backgroundColor: 'rgba(255,255,255,0.3)',
      justifyContent: 'center',
      alignItems: 'center',
      width: '12.5%',
      height: '100%',
    },
    searchTitle: {
      fontSize: Fonts.sizes.smallHeading,
      paddingRight: 10,
      paddingLeft: 10,
    },
    searchBarContainer: {
      backgroundColor: Colors.white,
      borderRadius: 0,
      height: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      width: '75%',
      paddingHorizontal: 20,
    },
    searchIconContainer: {
      justifyContent: 'center',
      alignItems: 'center',
      width: 40,
      height: 60,
    },
    searchIcon: {
      width: 36,
      height: 36,
      resizeMode: 'contain',
    },
    searchTextInput: {
      flex: 1,
      height: '100%',
      paddingLeft: 10,
      fontSize: Fonts.sizes.smallHeading,
    },
    placeholderStyle: {},
  },
  {
    [DEVICE_SIZES.XL]: {
      summaryTitle: {},
    },
    [DEVICE_SIZES.LG]: {
      summaryTitle: {
        fontSize: 40,
        lineHeight: 50,
        width: '45%',
      },
    },
    [DEVICE_SIZES.MD]: {
      summaryTitle: {
        fontSize: 35,
        lineHeight: 40,
        width: '50%',
      },
    },
    [DEVICE_SIZES.SM]: {
      summaryTitle: {
        fontSize: 40,
        lineHeight: 50,
        width: '55%',
        marginTop: '65%',
      },
      summarySubTitle: {
        fontSize: 22,
        lineHeight: 30,
        width: '50%',
      },
      searchContainer: {
        flexDirection: 'row',
        width: '100%',
        height: 60,
      },
      searchTitleContainer: {
        width: '25%',
      },
      region: {
        display: 'none',
      },
      regionSelectContainer: {
        width: '100%',
        minWidth: '100%',
      },
    },
    [DEVICE_SIZES.XS]: {
      summaryTitle: {
        fontSize: 26,
        lineHeight: 32,
        width: '60%',
        marginTop: '65%',
      },
      summarySubTitle: {
        fontSize: 16,
        lineHeight: 23,
        width: '80%',
      },
      viewHeaderImage: {
        height: 560,
      },
      searchContainer: {
        flexDirection: 'row',
        width: '100%',
        height: 50,
        position: 'absolute',
        bottom: -50,
      },
      allDetails: {
        marginTop: 570,
      },
      root: {
        backgroundColor: Colors.backgroundYellow,
      },
      searchTitleContainer: {
        display: 'none',
      },
      searchBarContainer: {
        width: '100%',
        transform: [{ scale: 1.25 }],
      },
      region: {
        display: 'none',
      },
      regionSelectContainer: {
        width: '100%',
        minWidth: '100%',
      },
      regionSelectDropdown: {
        width: '100%',
        right: 0,
        top: 0,
        fontSize: 10,
      },
      searchTextInput: {
        fontSize: 10,
      },
      regionSelectDropdownIcon: {
        backgroundColor: Colors.backgroundYellow,
        borderLeftWidth: 0,
      },
      searchIcon: {
        width: 16,
        aspectRatio: 1,
      },
      regionSelectInput: {
        backgroundColor: Colors.backgroundYellow,
      },
      placeholderStyle: {
        fontFamily: Fonts.family.bold,
        fontSize: Fonts.sizes.smallHeading,
      },
    },
  }
);
