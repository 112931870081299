/**
 * This file contains static methods that are useful across the app.
 */
import { TextDecoder } from '@sinonjs/text-encoding';
import base64 from 'base64-js';
import { AuthSessionResult, TokenResponse } from 'expo-auth-session';
import { DateTime } from 'luxon';
import { AuthState } from './Interfaces';
import { Linking, Platform, Share } from 'react-native';
import Constants from 'expo-constants';
import * as FileSystem from 'expo-file-system';
import Logger from './Logger';
import { getStore } from '../store/configureStore';
import * as NavigationService from '../navigation/NavigationService';
import { purge } from '../reducers/commonActions';

const emailPattern: RegExp =
  /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/g;

export function logOut() {
  getStore().dispatch(purge());
  NavigationService.navigate('Landing');
}

/**
 * Capitilise a string
 * @param inputString the string to edit
 */
export function capitalizeFirstLetter(inputString: string) {
  return inputString.charAt(0).toUpperCase() + inputString.slice(1);
}

/**
 * Determine if a string is a valid JSON or not.
 * @param input
 */
export function isValidJSON(input: string): boolean {
  try {
    JSON.parse(input);
  } catch (e) {
    return false;
  }
  return true;
}

/**
 * Sanitize sensitive user information. Used for preprocess before sending
 * the logs to external destinations.
 * @param input string any string.
 * @returns sanitized string.
 */
export function sanitizeUserInfo(input: string): string {
  // Return non-string values as-is
  if (!input) {
    return input;
  }

  // Clear email addresses
  return input.replace(emailPattern, '***');
}

/**
 * simple sleep promise
 */
export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

/**
 * Determine whether the user session should be terminated. This is based on the
 * standard OAuth response error invalid_grant, which results in error code 2002
 * in Android and error code -10 in iOS.
 * @param error
 */
export function shouldTerminateSession(error: any): boolean {
  return error && error.code && (error.code === '-10' || error.code === '2002');
}

export function cleanAuthCode(code: string) {
  // Split invalid base64 hash char from end of authCode if it exists (as returned by B2C from social logins etc)
  const codeParts = code.split('#', 1);
  return codeParts[0];
}

export function parseJwt(token: string) {
  const base64Url = token.split('.')[1];
  let base64String = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const remainder = base64String.length % 4;
  if (remainder > 0) {
    base64String = base64String.padEnd(base64String.length + (4 - remainder), '=');
  }
  const decodedBytes: Uint8Array = base64.toByteArray(base64String);
  const decodedString = new TextDecoder().decode(decodedBytes);
  const jsonPayload = decodeURIComponent(
    decodedString
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );
  return JSON.parse(jsonPayload);
}

/**
 * Translate TokenResponse to serializable AuthState
 * @param response
 */
export function getAuthState(response: TokenResponse): AuthState {
  return {
    accessToken: response.accessToken,
    tokenType: response.tokenType,
    expiresIn: response.expiresIn,
    refreshToken: response.refreshToken,
    scope: response.scope,
    state: response.state,
    idToken: response.idToken,
    issuedAt: response.issuedAt,
  };
}

/**
 * Match the input string against the source string, ignoring case and ascent.
 * @param source the original value
 * @param input the search query
 */
export function matchWild(source: string, input: string): boolean {
  return source
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .startsWith(input.toLowerCase());
}

/**
 * Normalize search term for storing as history
 * @param searchTerm the search term
 */
export function normalizeSearch(searchTerm: string): string {
  return searchTerm
    .trim()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toUpperCase();
}

export const convertToNZ = ({ date, format }) =>
  DateTime.fromISO(date).toFormat(format).replace(':00', '').replace('PM', 'pm').replace('AM', 'am');

export async function shareContent(activityId?: string) {
  const website = Constants.expoConfig?.extra?.azure.website;
  const URL = activityId ? `${website}/activity/${activityId}` : website;

  try {
    if (Platform.OS === 'web') {
      const facebookURL = `https://www.facebook.com/sharer/sharer.php?u=${URL}`;
      Linking.openURL(facebookURL);
      return;
    }
    await Share.share({
      message: URL,
    });
  } catch (e) {
    console.warn(e);
  }
}

function hashCode(s: string): string | number {
  let h;
  // eslint-disable-next-line no-bitwise
  for (let i = 0; i < s.length; i += 1) h = (Math.imul(31, h) + s.charCodeAt(i)) | 0;
  return h;
}

export async function saveCacheImage({ activityId, uri }) {
  const path = `${FileSystem.cacheDirectory}${activityId}${hashCode(uri)}`;
  // get cached Image
  try {
    const image = await FileSystem.getInfoAsync(path);
    if (image.exists) {
      return;
    }
    // store new Image in cache
    await FileSystem.downloadAsync(uri, path);
  } catch (e) {
    Logger.debug(`Utils -> saveCacheImage -> ${e}`);
  }
}
// eslint-disable-next-line consistent-return
export async function getCacheImage({ activityId, uri }) {
  const path = `${FileSystem.cacheDirectory}${activityId}${hashCode(uri)}`;
  // get cached Image
  try {
    const image = await FileSystem.getInfoAsync(path);
    // get stored Image
    if (image.exists) {
      return image.uri;
    }
    // store new Image in cache
    const newImage = await FileSystem.downloadAsync(uri, path);
    return newImage.uri;
  } catch (e) {
    Logger.debug(`Utils -> getCacheImage -> ${e}`);
  }
}

export function getAuthResultFromUrl(url: string): AuthSessionResult {
  Logger.info('Authorization params passed in from another web page');
  Logger.debug(`Return url is: ${url}`);
  const urlParts = url.split('?');
  const queryString = urlParts[1];
  const params = {};
  queryString.split('&').forEach((part) => {
    const item = part.split('=');
    params[item[0]] = decodeURIComponent(item[1]);
  });
  return {
    url,
    params,
    type: 'success',
    errorCode: null,
    authentication: null,
  } as AuthSessionResult;
}

export function getGenderPlaceholder(gender: string | null | undefined) {
  switch (gender) {
    case 'Male':
      return require('../../assets/images/male_placeholder.png');
    case 'Female':
      return require('../../assets/images/female_placeholder.png');
    default:
      return require('../../assets/images/other_gender_placeholder.png');
  }
}
export function validatePastActivity(activityDate: string | null | undefined) {
  const endTime = activityDate?.substring(0, 11).concat('23:59:59');
  const isPastActivity = DateTime.fromISO(endTime!).toMillis() < DateTime.now().toMillis();
  return isPastActivity;
}
