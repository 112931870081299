import React, { useRef, useState } from 'react';
import { View } from '../../../components/Themed';
import { FlatList, StyleSheet } from 'react-native';
import { Paragraph, UIText } from '../../../components/StyledText';
import i18n from '../../../translations';
import { Activity, User, UserActivity } from '../../../constants/Interfaces';
import { Colors } from '../../../constants/Colors';
import { useSelector } from 'react-redux';
import EventCardFragment from './EventCardFragment';
import { SplitButton } from '../../../components/buttons/SplitButton';
import { ActionModal } from '../../../components/ActionModal';
import { getUserActivities } from '../../../reducers/activity.slice';
import Fonts from '../../../constants/Fonts';
import { MaterialIcons } from '@expo/vector-icons';
import Logger from '../../../constants/Logger';
import MapsFragment from './MapsFragment';
import GridActivities from '../../../components/GridActivities';
import { convertToNZ } from '../../../constants/Utils';
import Layout from '../../../constants/Layout';
import { ActivityScannerFragment } from './ActivityScannerFragment';
import { useAppDispatch } from '../../../store/configureStore';

export const UserDashboardFragment = ({ navigation }) => {
  const user: User = useSelector((state: any) => state.user.user);
  const [activeScanner, setActiveScanner] = React.useState<boolean>(false);
  const [directionsModal, setDirectionsModal] = useState(false);

  const activities: Activity[] = useSelector((state: any) => state.activity.activities);
  const myActivities: UserActivity[] = useSelector((state: any) => state.activity.myActivities)
    .filter((item: UserActivity) => item.userId === user.userId)
    // eslint-disable-next-line no-nested-ternary
    .sort((a, b) => (a.startDate > b.lstartDate ? 1 : b.startDate > a.startDate ? -1 : 0));
  const goingActivities = myActivities.filter((item) => item.going && !item.checkedIn);
  const [nextActivity, setNextActivity] = useState(goingActivities[0]);
  const dispatch = useAppDispatch();

  const handleCloseModal = () => {
    setActiveScanner(false);
    setDirectionsModal(false);
  };

  const handleQrScanner = (item) => {
    setNextActivity(item);
    setActiveScanner(true);
  };
  const handleDirections = (item) => {
    setNextActivity(item);
    setDirectionsModal(true);
  };
  const handleScanDone = () => {
    setActiveScanner(false);
    dispatch(getUserActivities());
    carouselRef.current?.scrollToIndex({ index: 0 });
  };
  const handleOpenActivity = (activityId: string) => {
    Logger.debug(`Screen -> ActivitiesScreen -> handleOpenActivity: Navigating to activity ${activityId}`);
    navigation.navigate('DashboardStack', {
      screen: 'ActivityDetails',
      params: { activityId },
    });
  };

  const carouselRef = useRef<FlatList>(null);

  const viewabilityConfig = useRef({
    itemVisiblePercentThreshold: 10,
  });

  return (
    <View style={styles.root} transparent>
      <UIText style={styles.title}>{i18n.t('components.UserDashboardFragment.dashboard')}</UIText>
      <View transparent style={styles.nextActivityContainer}>
        <Paragraph style={styles.sectionTitle}>{i18n.t('components.UserDashboardFragment.nextActivity')}</Paragraph>
        <MaterialIcons name="arrow-forward" size={30} color={Colors.yellow} />
      </View>
      <FlatList
        decelerationRate="fast"
        ref={carouselRef}
        data={goingActivities}
        keyExtractor={(item) => item.activityId}
        ItemSeparatorComponent={() => <View style={{ width: 10 }} transparent />}
        viewabilityConfig={viewabilityConfig.current}
        renderItem={({ item }) => (
          <EventCardFragment
            title={item.activityName!}
            subTitle={convertToNZ({ date: item?.startDate!, format: 'cccc dd MMMM, h:mma' })}
            imageUrl={item?.imageUrl}
            onPress={() => handleOpenActivity(item.activityId)}
            activityId={item.activityId}
            isInList
            splitButton={
              <SplitButton
                labelLeft={i18n.t('components.UserDashboardFragment.getDirections')}
                labelRight={i18n.t('components.UserDashboardFragment.qrScanner')}
                onPressLeft={() => handleDirections(item)}
                onPressRight={() => handleQrScanner(item)}
                backgroundColorLeft={Colors.darkBurgundy}
                backgroundColorRight={Colors.yellow}
                labelColorLeft={Colors.yellow}
                labelColorRight={Colors.black}
                borderColor={Colors.yellow}
              />
            }
          />
        )}
        horizontal
        showsHorizontalScrollIndicator={false}
      />

      <Paragraph style={styles.sectionTitle}>{i18n.t('components.UserDashboardFragment.interest')}</Paragraph>
      {activities && <GridActivities activities={activities} navigation={navigation} />}
      {nextActivity && (
        <>
          <ActionModal visible={directionsModal} onModalClose={handleCloseModal} height={250} closeIcon={false}>
            <MapsFragment location={nextActivity.location} />
          </ActionModal>
          <ActionModal
            visible={activeScanner}
            onModalClose={() => setActiveScanner(false)}
            height={Layout.window.height - 100}
          >
            <ActivityScannerFragment
              activityId={nextActivity?.activityId}
              title={i18n.t('components.CodeScannerFragment.headerWhanau')}
              onScanComplete={handleScanDone}
            />
          </ActionModal>
        </>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    minHeight: Layout.window.height - 60,
    paddingHorizontal: Layout.backgroundMarginNarrow.left,
    paddingBottom: 150,
  },
  title: {
    color: Colors.white,
    fontSize: Fonts.sizes.bigHeading,
    fontFamily: Fonts.family.bold,
  },
  sectionTitle: {
    color: Colors.yellow,
    fontFamily: Fonts.family.bold,
    marginVertical: 10,
  },
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.darkBurgundy,
  },
  nextActivityContainer: {
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
  },
  slide: {
    width: '100%',
  },
});
