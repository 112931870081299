import axios from 'axios';
import Constants from 'expo-constants';
import React, { useEffect, useState } from 'react';
import { Image, Pressable, ScrollView, View } from 'react-native';
import { UIText } from '../components/StyledText';
import { Colors } from '../constants/Colors';
import Fonts from '../constants/Fonts';
import { Activity } from '../constants/Interfaces';
import Layout from '../constants/Layout';
import { convertToNZ, shareContent } from '../constants/Utils';
import MapsFragment from '../screens/home/fragments/MapsFragment';
import i18n from '../translations';
import Footer from './Footer';
import Header from './Header';
import { languageListener } from './Utils/languageListener';
import { CreateResponsiveStyle, DEVICE_SIZES, useDeviceSize } from 'rn-responsive-styles';
import RectanglesPattern from './About/Fragments/RectanglesPattern';
import { LinearGradient } from 'expo-linear-gradient';
import Clouds from './Clouds';
import { DateTime } from 'luxon';

interface ActivitiesProps {
  route: { params: NavParams };
}

interface NavParams {
  id: string;
}

function createDateString(startDate?: string, endDate?: string | null) {
  if (startDate && endDate) {
    const start = DateTime.fromISO(startDate);
    const end = DateTime.fromISO(endDate);
    if (start.hasSame(end, 'day')) {
      return `${convertToNZ({ date: startDate, format: 'MMMM dd yyyy, h:mma' })}`;
    }
    return `${start.toFormat('dd MMMM yyyy')} - ${end.toFormat('dd MMMM yyyy')}`;
  }
  if (startDate && !endDate) {
    return `${convertToNZ({ date: startDate, format: 'MMMM dd yyyy, h:mma' })}`;
  }
  return '';
}

const ActivityDetailsWeb = ({ route }: ActivitiesProps) => {
  const styles = useStyles();
  const deviceSize = useDeviceSize();
  const { id } = route.params;
  const language = languageListener();
  const [activities, setActivities] = useState([]);
  const myActivity: Activity = activities.find((item: Activity) => item.activityId === id)!;
  const activityName = myActivity?.activityName;
  const image = myActivity?.imageUrl;
  const date = createDateString(myActivity?.startDate, myActivity?.endDate);
  const details = myActivity?.description;
  const address = myActivity?.location?.addressLine1;
  const organiserName = `${myActivity?.organiser?.firstName} ${myActivity?.organiser?.lastName}`;
  const location = myActivity?.location;
  const apiEndpoint = Constants.expoConfig?.extra?.azure.apiEndpoint;

  useEffect(() => {
    // api endpoint needs to be updated to the real host
    const fetchData = async () => {
      const result = await axios(`${apiEndpoint}/activity`);
      setActivities(result.data);
    };
    fetchData();
  }, []);

  if (deviceSize === 'small' || deviceSize === 'extrasmall') {
    return (
      <View style={styles.root}>
        <View style={{ position: 'absolute', top: 0, width: '100%' }}>
          <Header menuBarTheme="light" />
        </View>
        <View style={styles.viewHeaderImage}>
          <Image source={{ uri: image }} style={styles.headerImage} />

          <View style={styles.titleTop}>
            <View style={styles.infoContainer}>
              <UIText style={[styles.description, { color: Colors.white, fontWeight: '400' }]}>{date}</UIText>
            </View>
            <UIText style={[styles.ActivityLabel, { marginBottom: 35 }]}>{activityName}</UIText>
            <Pressable onPress={() => shareContent(id)}>
              <Image source={require('../../assets/images/shareFb.png')} style={styles.shareFb} />
            </Pressable>
          </View>
          <View
            style={
              deviceSize === 'small'
                ? { position: 'absolute', zIndex: 1, bottom: 0, right: 60, width: 30, height: 200 }
                : { display: 'none' }
            }
          >
            <RectanglesPattern />
          </View>
          <LinearGradient
            colors={['transparent', 'rgba(28,16,16, 0.7)']}
            start={{ x: 0, y: 0 }}
            end={{ x: 0, y: 1 }}
            style={styles.bottomToTopLinerGradient}
            pointerEvents="none"
          />
        </View>
        <View style={styles.allDetails}>
          <ScrollView style={styles.scroll}>
            <View style={styles.detailsRow}>
              <View style={styles.rightDetails}>
                <View style={styles.infoContainer}>
                  <UIText style={styles.label}>
                    {i18n.t('pages.ActivityDetailsScreen.details', { locale: language })}
                  </UIText>
                  <UIText style={styles.description}>{details}</UIText>
                </View>
              </View>
              <View style={styles.leftDetails}>
                <View style={styles.infoContainer}>
                  <UIText style={styles.label}>
                    {i18n.t('pages.ActivityDetailsScreen.address', { locale: language })}
                  </UIText>
                  <UIText style={styles.description}>{address}</UIText>
                  <MapsFragment location={location} TextLabelWeb={styles.description} web />
                </View>
                <View style={styles.infoContainer}>
                  <UIText style={styles.label}>
                    {i18n.t('pages.ActivityDetailsScreen.organiser', { locale: language })}
                  </UIText>
                  <UIText style={styles.description}>{organiserName}</UIText>
                </View>
              </View>
            </View>
          </ScrollView>
        </View>
        <Clouds />
        <Footer showReasons />
      </View>
    );
  }
  return (
    <View style={styles.root}>
      <Header menuBarTheme="light" />
      <View style={styles.viewHeaderImage}>
        <Image source={{ uri: image }} style={styles.headerImage} />
      </View>
      <View style={styles.allDetails}>
        <View style={styles.dateContainer}>
          <UIText style={styles.dateDescription}>{date}</UIText>
        </View>
        <ScrollView style={styles.scroll}>
          <View style={styles.titleTop}>
            <UIText style={styles.ActivityLabel}>{activityName}</UIText>
          </View>
          <View style={styles.detailsRow}>
            <View style={styles.leftDetails}>
              <View style={styles.infoContainer}>
                <UIText style={styles.label}>
                  {i18n.t('pages.ActivityDetailsScreen.address', { locale: language })}
                </UIText>
                <UIText style={styles.description}>{address}</UIText>
                <MapsFragment location={location} TextLabelWeb={styles.description} web />
              </View>
              <View style={styles.infoContainer}>
                <UIText style={styles.label}>
                  {i18n.t('pages.ActivityDetailsScreen.organiser', { locale: language })}
                </UIText>
                <UIText style={styles.description}>{organiserName}</UIText>
              </View>
              <Pressable onPress={() => shareContent(id)}>
                <Image source={require('../../assets/images/shareFb.png')} style={styles.shareFb} />
              </Pressable>
            </View>
            <View style={styles.rightDetails}>
              <View style={styles.infoContainer}>
                <UIText style={styles.label}>
                  {i18n.t('pages.ActivityDetailsScreen.details', { locale: language })}
                </UIText>
                <UIText style={styles.description}>{details}</UIText>
              </View>
            </View>
          </View>
        </ScrollView>
      </View>
      <Clouds />
      <Footer showReasons />
    </View>
  );
};

export default ActivityDetailsWeb;

const useStyles = CreateResponsiveStyle(
  {
    root: {
      backgroundColor: Colors.white,
      width: '100%',
      height: '100%',
      minHeight: Layout.window.height,
      position: 'relative',
      overflowX: 'clip',
    },
    viewHeaderImage: {
      position: 'absolute',
      top: 0,
      zIndex: -10,
      width: '100%',
      height: 766,
    },
    headerImage: {
      height: '100%',
      resizeMode: 'cover',
    },
    scroll: {
      flexGrow: 0,
    },
    allDetails: {
      marginTop: 492,
      minHeight: '40%',
      width: '60%',
      backgroundColor: Colors.white,
      zIndex: -5,
      alignSelf: 'center',
      position: 'relative',
    },
    dateContainer: {
      backgroundColor: Colors.black,
      position: 'absolute',
      paddingHorizontal: 40,
      paddingVertical: 9,
      top: -38,
      left: 0,
      zIndex: 2,
    },
    dateDescription: {
      color: Colors.white,
    },
    titleTop: {
      height: 170,
      backgroundColor: 'white',
      justifyContent: 'center',
      paddingHorizontal: 100,
      textAlign: 'center',
      marginBottom: 25,
      position: 'relative',
      zIndex: 1,
    },
    detailsRow: {
      flex: 3,
      flexDirection: 'row',
      paddingHorizontal: '12%',
    },
    leftDetails: {
      flex: 1,
      maxWidth: 250,
    },
    rightDetails: {
      flex: 3,
      marginLeft: '7%',
    },
    ActivityLabel: {
      color: Colors.black,
      fontFamily: Fonts.family.bold,
      fontSize: 50,
      lineHeight: 60,
      alignSelf: 'center',
    },
    label: {
      fontFamily: Fonts.family.bold,
      fontSize: 22,
      lineHeight: 34,
    },
    description: {
      fontSize: 22,
      lineHeight: 34,
    },
    infoContainer: {
      paddingVertical: 15,
    },
    shareFb: {
      height: 39,
      width: 123,
      resizeMode: 'contain',
      position: 'relative',
      zIndex: 99,
    },
    bottomToTopLinerGradient: {
      width: '100%',
      height: 464,
      position: 'absolute',
      bottom: 0,
    },
  },
  {
    [DEVICE_SIZES.LG]: {
      allDetails: {
        width: '70%',
      },
    },
    [DEVICE_SIZES.MD]: {
      allDetails: {
        width: '80%',
      },
      ActivityLabel: {
        fontSize: 40,
        lineHeight: 50,
      },
      label: {
        fontSize: 18,
        lineHeight: 28,
      },
      description: {
        fontSize: 18,
        lineHeight: 28,
      },
    },
    [DEVICE_SIZES.SM]: {
      viewHeaderImage: {
        position: 'relative',
        top: 0,
        height: 1181,
      },
      allDetails: { width: '100%', marginTop: 0 },
      titleTop: {
        textAlign: 'left',
        paddingHorizontal: 60,
        position: 'absolute',
        bottom: 250,
        backgroundColor: Colors.transparent,
      },
      ActivityLabel: {
        color: Colors.white,
        fontSize: 60,
        lineHeight: 80,
      },
      detailsRow: {
        flexDirection: 'column',
        padding: '7%',
      },
      leftDetails: {
        flex: -1,
      },
      rightDetails: {
        flex: -1,
        marginBottom: 55,
        marginLeft: 0,
      },
    },
    [DEVICE_SIZES.XS]: {
      viewHeaderImage: {
        position: 'relative',
        top: 0,
        height: 660,
      },
      allDetails: { width: '100%', marginTop: 0 },
      titleTop: {
        textAlign: 'left',
        paddingHorizontal: 36,
        position: 'absolute',
        bottom: 80,
        backgroundColor: Colors.transparent,
      },
      ActivityLabel: {
        color: Colors.white,
        fontSize: 35,
        lineHeight: 40,
      },
      description: {
        fontSize: 16,
        lineHeight: 26,
      },
      detailsRow: {
        flexDirection: 'column',
        padding: '7%',
      },
      leftDetails: {
        flex: -1,
      },
      rightDetails: {
        flex: -1,
        marginLeft: 0,
      },
    },
  }
);
