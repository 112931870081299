import React from 'react';
import { View, StyleSheet, Pressable, Linking, Platform } from 'react-native';
import EllipseBorderImage from '../../../components/EllipseBorderImage';
import { Heading, Paragraph, UIText } from '../../../components/StyledText';
import { Colors } from '../../../constants/Colors';
import { MaterialIcons } from '@expo/vector-icons';
import i18n from '../../../translations';
import { Organiser } from '../../../constants/Interfaces';

interface CallOrganiserProps {
  organiser: Organiser;
}

const CallOrganiser = ({ organiser }: CallOrganiserProps) => {
  const imageUrl = organiser?.imageUrl;
  const phone = organiser?.phone;
  const organiserName = `${organiser?.firstName} ${organiser?.lastName}`;

  const handleCall = (phone) => {
    const url = Platform.OS === 'android' ? `tel:$${phone}` : `telprompt:$${phone}`;
    Linking.openURL(url);
  };

  return (
    <View style={styles.root}>
      <Heading>{i18n.t('components.CallOrganiser.eventOrganiser')}</Heading>
      <Paragraph style={styles.subTitle}>{organiserName}</Paragraph>
      <EllipseBorderImage
        color="yellow"
        img={imageUrl ? { uri: imageUrl } : require('../../../../assets/images/profilePic.png')}
        imageStyle={styles.imageStyle}
      />
      {!!phone && (
        <Pressable style={styles.ringButton} onPress={() => handleCall(phone)}>
          <View style={styles.iconButton}>
            <MaterialIcons name="phone" size={20} color={Colors.black} />
          </View>
          <UIText>{i18n.t('components.CallOrganiser.ring')}</UIText>
        </Pressable>
      )}
    </View>
  );
};

export default CallOrganiser;

const styles = StyleSheet.create({
  root: {
    height: '100%',
    alignItems: 'center',
  },
  imageStyle: {
    width: 100,
    height: 100,
  },
  subTitle: {
    marginTop: 20,
    marginBottom: 30,
  },
  ringButton: {
    alignItems: 'center',
    flexDirection: 'row',
    height: 40,
    borderRadius: 50,
    backgroundColor: Colors.defaultGrey,
    width: '25%',
    padding: 5,
    marginTop: 30,
  },
  iconButton: {
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 50,
    backgroundColor: Colors.yellow,
    height: 30,
    width: 30,
    marginRight: 5,
  },
});
