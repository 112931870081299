import React from 'react';
import { View, StyleSheet, Image } from 'react-native';

interface RatingStarsProps {
  stars: number;
  starsColor?: 'red' | 'yellow';
}
const RatingStars = ({ stars, starsColor = 'yellow' }: RatingStarsProps) => (
  <View style={styles.reviewRating}>
    {[...new Array(stars)].map((item, index) => (
      <Image
        key={index}
        source={
          starsColor === 'red'
            ? require('../../assets/images/redStar.png')
            : require('../../assets/images/yellowStar.png')
        }
        style={styles.stars}
      />
    ))}
    {[...new Array(9 - stars)].map((item, index) => (
      <Image
        key={index}
        source={
          starsColor === 'red'
            ? require('../../assets/images/redStar.png')
            : require('../../assets/images/yellowStar.png')
        }
        style={[styles.stars, { opacity: 0.3 }]}
      />
    ))}
  </View>
);

export default RatingStars;

const styles = StyleSheet.create({
  reviewRating: { flexDirection: 'row' },
  stars: {
    height: 12,
    width: 12,
    marginRight: 4,
  },
});
