import { MaterialIcons } from '@expo/vector-icons';
import _ from 'lodash';
import React, { Dispatch, SetStateAction } from 'react';
import { View, StyleSheet, Pressable } from 'react-native';
import { Colors } from '../../../../constants/Colors';
import { useSelector } from 'react-redux';
import EllipseBorderImage from '../../../../components/EllipseBorderImage';
import { UIText } from '../../../../components/StyledText';
import { Marae, User } from '../../../../constants/Interfaces';

interface MyMaraeListProps {
  openDetails: Dispatch<SetStateAction<boolean>>;
  onSelect: (marae: Marae) => void;
  selectedMarae?: User[];
  selection?: boolean;
}
const MyMaraeuListFragment = ({ openDetails, onSelect, selectedMarae = [], selection }: MyMaraeListProps) => {
  const maraes: Marae[] = useSelector((state: any) => state?.user?.user?.marae);

  const renderItem = ({ item }: { item: Marae }) => {
    const selected = _.findIndex(selectedMarae, ['maraeId', item.maraeId]) > -1;
    return (
      <View key={item.maraeId} style={styles.maraeList}>
        <Pressable
          onStartShouldSetResponderCapture={() => true}
          onMoveShouldSetResponderCapture={() => true}
          style={styles.maraeContainer}
          onPress={() => {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            item && onSelect ? onSelect!(item!) : null;
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            openDetails ? openDetails!(true) : null;
          }}
        >
          <View
            style={{
              paddingVertical: 10,
              borderBottomWidth: 1,
              borderColor: Colors.blackOpacity,
              width: '90%',
              height: 65,
            }}
          >
            <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <EllipseBorderImage
                  item={item}
                  imageStyle={styles.maraeImage}
                  openDetails={openDetails}
                  onSelect={onSelect}
                  color="yellow"
                  img={require('../../../../../assets/images/marae_placeholder.png')}
                />
                <UIText style={[styles.maraeName, { color: selection ? Colors.black : Colors.white }]}>
                  {item.maraeName}
                </UIText>
              </View>
              {selected ? (
                <View style={styles.selectedContainer}>
                  <MaterialIcons name="check" size={16} color={Colors.black} />
                </View>
              ) : null}
            </View>
          </View>
        </Pressable>
      </View>
    );
  };
  return <View style={styles.root}>{maraes?.map((item) => renderItem({ item }))}</View>;
};

export default MyMaraeuListFragment;
const styles = StyleSheet.create({
  root: {
    width: '100%',
  },
  maraeList: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
  },
  maraeContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
  },
  maraeImage: {
    width: 30,
    height: 30,
    marginRight: 10,
  },
  maraeName: {
    marginLeft: 15,
  },
  selectedContainer: {
    backgroundColor: Colors.green,
    justifyContent: 'center',
    alignItems: 'center',
    width: 20,
    height: 20,
    borderRadius: 15,
  },
});
