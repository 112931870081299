import * as React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { useNavigation } from '@react-navigation/native';
import { LeaderboardScreen } from '../screens/leaderboard/LeaderboardScreen';
import { SelectRegionScreen } from '../screens/profile/SelectRegionScreen';
import { TipScreen } from '../screens/profile/TipScreen';

const LeaderboardNavigator = createStackNavigator();

export const LeaderboardNavigation = () => {
  const navigation = useNavigation();

  React.useEffect(
    () =>
      navigation.addListener('beforeRemove', () => {
        navigation.navigate('DashboardStack');
      }),
    []
  );
  return (
    <LeaderboardNavigator.Navigator screenOptions={{ headerShown: false }}>
      <LeaderboardNavigator.Screen name="LeaderboardScreen" component={LeaderboardScreen} />
      <LeaderboardNavigator.Screen name="SelectRegion" component={SelectRegionScreen} />
      <LeaderboardNavigator.Screen name="TipScreen" component={TipScreen} />
    </LeaderboardNavigator.Navigator>
  );
};
