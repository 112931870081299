import axios from 'axios';
import Constants from 'expo-constants';
import React, { useEffect, useState } from 'react';
import { View, Image, TextInput, StyleSheet, FlatList, Pressable, ActivityIndicator } from 'react-native';
import { Heading, UIText } from '../components/StyledText';
import { Colors } from '../constants/Colors';
import Fonts from '../constants/Fonts';
import { Activity, AuthState, Role, User } from '../constants/Interfaces';
import { convertToNZ } from '../constants/Utils';
import i18n from '../translations';
import * as DocumentPicker from 'expo-document-picker';
import PrimaryButton from '../components/buttons/PrimaryButton';
import { Button } from 'react-native-elements/dist/buttons/Button';
import LayoutWeb from './LayoutWeb';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import { purge } from '../reducers/commonActions';
import Logger from '../constants/Logger';
import API from '../constants/API';

const apiEndpoint = Constants.expoConfig?.extra?.azure.apiEndpoint;

const AddWhanau = () => {
  const [activities, setActivities] = useState<Activity[]>([]);
  const [search, setSearch] = useState<string>('');
  const [activity, setActivity] = useState<Activity>();
  const [file, setFile] = useState<any>();
  const user: User = useSelector((state: any) => state.user.user);
  const authState: AuthState = useSelector((state: any) => state.authentication.authState);
  const [fileUploaded, setFileUploaded] = useState<any>(false);
  const filterResults =
    search !== '' ? activities.filter((item) => item.activityName?.toLowerCase().includes(search.toLowerCase())) : [];
  const [error, setError] = useState<boolean>(false);
  const handleActivity = (activity: Activity) => {
    setActivity(activity);
    setSearch('');
  };
  const [loading, setLoading] = useState(false);
  const renderItem = (item: Activity) => (
    <Pressable style={styles.results} onPress={() => handleActivity(item)}>
      <UIText style={styles.titleLabel}>{item.activityName}</UIText>
      <UIText>{`${convertToNZ({ date: item.startDate!, format: 'MMMM dd' })}, ${item.location?.regionName}`}</UIText>
    </Pressable>
  );

  const handleFile = async () => {
    const result = await DocumentPicker.getDocumentAsync({ type: 'text/csv' });
    setFile(result);
  };
  const redirect = () => {
    navigation.navigate('LandingWeb');
  };
  useEffect(() => {
    if (!authState) {
      redirect();
    }
    const fetchData = async () => {
      const result = await axios(`${apiEndpoint}/activity`);
      setActivities(result.data);
    };
    fetchData();
  }, []);

  const handleSubmit = async () => {
    Logger.info(`AddWhanau File -> postParticipationUpload Posting user whanau information`);
    try {
      setLoading(true);
      const api: API = API.getInstance();
      const response = await api.post(`/activity/${activity?.activityId}/participationUpload`, { file: file.uri });
      setFileUploaded(response);
    } catch (e: any) {
      setError(true);
      setFileUploaded(e.response.data.error);
      Logger.error(`AddWhanau File -> postParticipationUpload Error occurred in uploading file`, e);
    }
    setLoading(false);
  };
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const handleLogout = () => {
    dispatch(purge());
    navigation.navigate('LandingWeb');
  };

  const handleResetFile = () => {
    setFile(null);
    setFileUploaded(false);
    setError(false);
  };
  return (
    <LayoutWeb>
      <View style={styles.root}>
        <Pressable onPress={handleLogout}>
          <UIText style={styles.logout}>Log off</UIText>
        </Pressable>
        {user?.role === Role.USER ? (
          <UIText>Please log in in the app</UIText>
        ) : (
          <>
            <Heading style={styles.title}> Search for your kaupapa</Heading>
            <View style={styles.barContainer}>
              <Image source={require('../../assets/images/searchIcon.png')} style={styles.icon} />
              <TextInput
                style={styles.textInput}
                placeholder="Search ..."
                onChangeText={(text) => setSearch(text)}
                defaultValue={activity?.activityName}
              />
            </View>
            {search !== '' && (
              <View style={styles.resultsContainer}>
                {filterResults.length > 0 ? (
                  <FlatList
                    data={filterResults}
                    keyExtractor={(item) => item.activityId}
                    renderItem={({ item }) => renderItem(item)}
                  />
                ) : (
                  <UIText> {i18n.t('components.SearchBar.nofound', { search })} </UIText>
                )}
              </View>
            )}
            {activity && (
              <View style={styles.fileContainer}>
                <UIText style={styles.titleLabel}>Kaupapa</UIText>
                <View style={styles.formContainer}>
                  <View style={styles.activityContainer}>
                    <View style={styles.activityLabelContainer}>
                      <UIText>{activity.activityName}</UIText>
                    </View>
                  </View>
                  <View style={styles.uploadContainer}>
                    {file && <UIText style={styles.fileName}>{file.name}</UIText>}
                    <Button style={styles.button} onPress={handleFile} title="Attach file" />
                    {!file && <UIText style={styles.fileName}>Select a csv file</UIText>}
                  </View>
                </View>
              </View>
            )}
            {file && !fileUploaded && !error && (
              <>
                {loading && <ActivityIndicator size="small" />}
                <PrimaryButton onPress={handleSubmit} title="Upload file" />
              </>
            )}
            {!error && fileUploaded && fileUploaded?.message ? (
              <>
                <UIText style={styles.fileResult}>{fileUploaded?.message}</UIText>
                {!error && <Button style={styles.button} onPress={handleResetFile} title="Upload new file" />}
              </>
            ) : null}
            {!error && fileUploaded && fileUploaded[0] && (
              <>
                {fileUploaded.map((item, index) => (
                  <UIText
                    style={styles.fileResult}
                    key={index}
                  >{`There is an error in ${item.location}, error: ${item.validationError}`}</UIText>
                ))}
                <Button style={styles.button} onPress={() => setFileUploaded(false)} title="Upload new file" />
              </>
            )}
            {error && (
              <>
                <UIText style={styles.fileResult}>{fileUploaded}</UIText>
                <Button style={styles.button} onPress={handleResetFile} title="Upload new file" />
              </>
            )}
          </>
        )}
      </View>
    </LayoutWeb>
  );
};

export default AddWhanau;

const styles = StyleSheet.create({
  root: {
    height: '70%',
    width: '100%',
    marginTop: 50,
    maxWidth: 800,
    alignSelf: 'center',
    paddingHorizontal: '5%',
  },
  title: {
    marginVertical: 20,
    alignSelf: 'center',
  },
  barContainer: {
    backgroundColor: Colors.white,
    borderRadius: 20,
    height: 30,
    paddingLeft: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    width: 20,
    height: 20,
    resizeMode: 'contain',
    marginRight: 5,
    alignSelf: 'center',
    justifyContent: 'center',
  },
  textInput: {
    flex: 1,
    height: '100%',
  },
  resultsContainer: {
    top: 25,
    backgroundColor: Colors.white,
    marginHorizontal: '5%',
    maxHeight: 200,
    minHeight: 100,
    borderRadius: 10,
    paddingHorizontal: 35,
    justifyContent: 'center',
    zIndex: 1,
  },
  results: {
    borderBottomColor: Colors.defaultGrey,
    borderBottomWidth: 1,
    paddingVertical: 10,
  },
  titleLabel: {
    fontFamily: Fonts.family.bold,
    marginVertical: 20,
    fontSize: Fonts.sizes.smallHeading,
  },
  formContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  activityContainer: {
    paddingVertical: 5,
  },
  activityLabelContainer: {
    backgroundColor: Colors.white,
    justifyContent: 'center',
    width: 300,
    height: 50,
    paddingHorizontal: 10,
    borderRadius: 5,
  },
  fileContainer: {
    alignItems: 'center',
    marginVertical: 25,
  },
  uploadContainer: {},
  button: {
    backgroundColor: Colors.darkBurgundy,
    color: Colors.black,
    alignSelf: 'center',
    borderRadius: 5,
    marginHorizontal: 20,
  },
  fileName: {
    height: 20,
    alignSelf: 'center',
    marginHorizontal: 10,
  },
  logout: {
    fontFamily: Fonts.family.bold,
    marginVertical: 0,
    borderColor: Colors.darkBurgundy,
    borderWidth: 2,
    width: 90,
    alignSelf: 'flex-end',
    textAlign: 'center',
    height: 35,
    padding: 5,
  },
  fileResult: {
    marginVertical: 20,
    alignSelf: 'center',
  },
});
