import React, { useState } from 'react';
import { View, Image, StyleSheet, Pressable, Platform, ActivityIndicator } from 'react-native';
import { useSelector } from 'react-redux';
import { Heading, Paragraph, UIText } from '../../components/StyledText';
import { Colors } from '../../constants/Colors';
import Fonts from '../../constants/Fonts';
import Layout from '../../constants/Layout';
import { getUser } from '../../reducers/user.slice';
import { isAccessTokenValid, loginUser } from '../../reducers/authentication.slice';
import { AuthState, DomainHintList } from '../../constants/Interfaces';
import i18n from '../../translations';
import ModalUI from '../../components/ModalUI';
import { SerializedError } from '@reduxjs/toolkit';
import Logger from '../../constants/Logger';
import { getUserActivities } from '../../reducers/activity.slice';
import { useAppDispatch } from '../../store/configureStore';

const SocialMediaLogin = ({ navigation }) => {
  const authState: AuthState = useSelector((state: any) => state.authentication.authState);
  const authLoading: boolean = useSelector((state: any) => state.authentication.loading);
  const userLoading: boolean = useSelector((state: any) => state.user.loading);
  const userError: SerializedError = useSelector((state: any) => state.user.error);
  const [termsConditions, setTermsConditions] = useState(false);
  const [openValidation, setOpenValidation] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();

  const isWeb = Platform.OS === 'web';

  const getGoingActivities = async () => {
    await dispatch(getUserActivities());
    navigation.navigate('Root');
  };

  React.useEffect(() => {
    if (authState) {
      // when the auth state is here, try to get the user
      // if that fails, try to create the user
      // if that fails, error

      setLoading(true);

      try {
        dispatch(getUser())
          .unwrap()
          .then((user) => {
            if (isAccessTokenValid(authState) && user) {
              if (
                user &&
                ((!user.firstName && !user.userDetailsSkipped) ||
                  ((!user.marae || user.marae.length === 0) && !user.maraeSkipped))
              ) {
                Logger.debug('Navigator -> AppNavigator: User details missing. Navigate to the SignUp stack');
                navigation.navigate('SignUpStack');
              } else {
                Logger.debug('Navigator -> AppNavigator: User details complete. Navigate to the Root stack');
                if (!isWeb) getGoingActivities();
              }
            }
          })
          .catch((_e) => {
            setLoading(false);
          });
      } catch (e: any) {
        setLoading(false);
      }
    }
  }, [authState]);

  const errorMessage = () => {
    if (userError.message === 'Request failed with status code 400') {
      return 'An error occured finding the email address of your social login, please try another type of login';
    }
    return '';
  };

  const handleLogin = (domain) => {
    setLoading(true);
    dispatch(loginUser(domain))
      .unwrap()
      .then((result) => {
        if (!result) {
          // user dismissed the login window
          setLoading(false);
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const handleLoginPress = (domain) => (termsConditions ? handleLogin(domain) : setOpenValidation(true));
  return (
    <View style={[styles.root]}>
      {authLoading || userLoading || loading ? (
        <View style={styles.authLoadingContainer}>
          <ActivityIndicator color={Colors.black} />
        </View>
      ) : null}
      <View style={styles.body}>
        <Image
          source={require('../../../assets/images/splash_top.png')}
          style={styles.topImage}
          resizeMethod="resize"
        />
        <Image
          source={require('../../../assets/images/mf_logo_dark_no_text.png')}
          style={styles.logo}
          resizeMethod="scale"
        />
        <View style={styles.headerContainer}>
          <Heading style={styles.titleHeader}>{i18n.t('pages.SocialMediaLogin.title')}</Heading>
          <Paragraph style={styles.paragraph}>{i18n.t('pages.SocialMediaLogin.intro')}</Paragraph>
        </View>
        <View style={styles.buttonsContainer}>
          <Pressable
            style={styles.button}
            onPress={() => {
              handleLoginPress(DomainHintList.google);
            }}
          >
            <Image style={styles.buttonImg} source={require('../../../assets/images/googleIcon.png')} />
            <UIText style={styles.buttonText}>Continue with Google </UIText>
          </Pressable>
          <Pressable
            style={styles.button}
            onPress={() => {
              handleLoginPress(DomainHintList.facebook);
            }}
          >
            <Image style={styles.buttonImg} source={require('../../../assets/images/facebookIcon.png')} />
            <UIText style={styles.buttonText}>Continue with Facebook </UIText>
          </Pressable>
          {Platform.OS === 'ios' && (
            <Pressable
              style={styles.button}
              onPress={() => {
                handleLoginPress(DomainHintList.apple);
              }}
            >
              <Image style={styles.buttonImg} source={require('../../../assets/images/appleIcon.png')} />
              <UIText style={styles.buttonText}>Continue with Apple </UIText>
            </Pressable>
          )}
          <Pressable
            style={styles.button}
            onPress={() => {
              handleLoginPress(null);
            }}
          >
            <Image style={styles.buttonImg} source={require('../../../assets/images/emailIcon.png')} />
            <UIText style={styles.buttonText}>Continue with Email </UIText>
          </Pressable>
        </View>
        <View style={styles.checkBoxContainer}>
          <Pressable style={styles.checkBox} onPress={() => setTermsConditions(!termsConditions)}>
            {termsConditions && (
              <Pressable style={styles.checkBoxTick} onPress={() => setTermsConditions(!termsConditions)} />
            )}
          </Pressable>
          <Paragraph style={styles.termsLabel}>{i18n.t('pages.SocialMediaLogin.terms')}</Paragraph>
          <Pressable onPress={() => navigation.navigate('Terms')}>
            <Paragraph style={styles.link}>{i18n.t('pages.SocialMediaLogin.termsLink')}</Paragraph>
          </Pressable>
        </View>
        <View style={[styles.checkBoxContainer, { marginTop: 12 }]}>
          <Pressable onPress={() => navigation.navigate('Privacy')}>
            <Paragraph style={styles.link}>{i18n.t('pages.SocialMediaLogin.privacyLink')}</Paragraph>
          </Pressable>
        </View>
        {userError && (
          <View style={{ marginTop: 12 }}>
            <UIText style={styles.buttonText}>{errorMessage()}</UIText>
          </View>
        )}
      </View>
      <Image
        source={require('../../../assets/images/splash_bottom.png')}
        style={styles.bottomImage}
        resizeMethod="resize"
      />
      {openValidation && (
        <ModalUI toggle={openValidation} closeModal={() => setOpenValidation(false)}>
          <Paragraph style={[styles.conditionsText]}>{i18n.t('pages.SocialMediaLogin.conditionsMessage')}</Paragraph>
        </ModalUI>
      )}
    </View>
  );
};
export default SocialMediaLogin;

const styles = StyleSheet.create({
  root: {
    flex: 1,
    backgroundColor: Colors.backgroundYellow,
    width: Layout.window.width,
    height: Layout.window.height,
  },
  topImage: {
    position: 'absolute',
    top: 0,
    height: 200,
    resizeMode: 'contain',
    opacity: 0.5,
  },
  logo: {
    position: 'absolute',
    top: 30,
    height: 50,
    resizeMode: 'contain',
    opacity: 1,
    zIndex: 2,
  },
  authLoadingContainer: {
    width: '100%',
    height: Layout.window.height,
    zIndex: 10,
    elevation: 10,
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: Colors.white,
    opacity: 0.7,
    alignItems: 'center',
    justifyContent: 'center',
  },
  body: {
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: '5%',
    paddingHorizontal: 10,
    paddingTop: Layout.isSmallDevice ? 100 : 150,
  },
  headerContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  titleHeader: {
    fontFamily: Fonts.family.bold,
    textAlign: 'center',
  },
  paragraph: {
    marginVertical: 20,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: Layout.isSmallDevice ? Fonts.sizes.medium : Fonts.sizes.paragraph,
    height: 50,
  },
  headerDividers: {
    width: '100%',
    height: 10,
    marginBottom: 20,
  },
  buttonsContainer: {
    width: Layout.window.width < 370 ? '85%' : '80%',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 15,
  },
  button: {
    flexDirection: 'row',
    height: 45,
    backgroundColor: 'white',
    borderRadius: 37,
    paddingVertical: 13,
    paddingHorizontal: 16,
    fontWeight: 'bold',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    marginVertical: 5,
  },
  buttonImg: {
    height: 25,
    width: 25,
    marginRight: 14.7,
    resizeMode: 'contain',
  },
  buttonText: {
    fontFamily: Fonts.family.bold,
    fontSize: Layout.isSmallDevice ? 15 : Fonts.sizes.paragraph,
  },
  bottomImage: {
    position: 'absolute',
    bottom: 0,
    width: Layout.window.width,
    height: 150,
    resizeMode: 'cover',
    zIndex: -1,
  },
  checkBoxContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    marginVertical: 0,
    marginHorizontal: '18%',
  },
  termsLabel: {
    textAlign: 'left',
    fontSize: Fonts.sizes.medium,
  },
  checkBox: {
    marginTop: 4,
    width: 20,
    height: 20,
    backgroundColor: Colors.white,
    padding: 2,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    marginRight: 5,
    zIndex: 10,
  },
  checkBoxTick: {
    backgroundColor: Colors.darkBurgundy,
    width: 12,
    height: 12,
    borderRadius: 6,
  },
  link: {
    fontFamily: Fonts.family.bold,
    textDecorationLine: 'underline',
    fontSize: Fonts.sizes.medium,
    marginLeft: 3,
  },
  conditionsText: {
    fontSize: Fonts.sizes.small,
    marginBottom: 10,
    alignSelf: 'center',
  },
});
