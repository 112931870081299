import React from 'react';
import { View, StyleSheet, Image, Pressable } from 'react-native';
import { Colors } from '../../constants/Colors';
import Fonts from '../../constants/Fonts';
import { UIText } from '../StyledText';
import i18n from '../../translations';

interface ButtonSwitchProps {
  leftButton: () => void;
  rightButton: () => void;
}

const ButtonSwitch = ({ leftButton, rightButton }: ButtonSwitchProps) => (
  <View style={styles.root}>
    <Pressable style={styles.leftButton} onPress={leftButton}>
      <UIText style={styles.leftLabel}>{i18n.t('components.ButtonSwitch.delete')}</UIText>
    </Pressable>
    <Pressable style={styles.rigthButton} onPress={rightButton}>
      <Image style={styles.rightIcon} source={require('../../../assets/images/ProfilePic-transparent.png')} />
      <UIText style={styles.rigthLabel}>{i18n.t('components.ButtonSwitch.changePhoto')}</UIText>
    </Pressable>
  </View>
);

export default ButtonSwitch;
const styles = StyleSheet.create({
  root: {
    height: 45,
    width: 240,
    flexDirection: 'row',
    borderWidth: 2,
    borderColor: Colors.yellow,
    borderRadius: 25,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  leftLabel: {
    color: Colors.yellow,
    fontFamily: Fonts.family.bold,
    fontSize: Fonts.sizes.default,
  },
  leftButton: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '40%',
  },
  rigthButton: {
    flexDirection: 'row',
    backgroundColor: Colors.yellow,
    height: '100%',
    borderRadius: 25,
    width: '60%',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  rightIcon: {
    height: 18,
    width: 18,
  },
  rigthLabel: {
    fontFamily: Fonts.family.bold,
    fontSize: Fonts.sizes.default,
  },
});
