import { DateTime } from 'luxon';
import React, { useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { Paragraph } from '../../../../components/StyledText';
import { Colors } from '../../../../constants/Colors';
import { MaraeStats, UserMarae, UserStats } from '../../../../constants/Interfaces';
import { getMaraeStats } from '../../../../reducers/user.slice';
import i18n from '../../../../translations';

interface MaraePartyProps {
  marae: UserMarae;
  section: 'activities' | 'koha';
}

const MaraePartyFragment = ({ marae, section }: MaraePartyProps) => {
  const maraeStats: MaraeStats = useSelector((state: any) => state.user.maraeStats);
  const today = DateTime.now();
  const year = today.month > 3 ? today.year : today.year - 1;
  const userStats: UserStats = useSelector((state: any) => state.user.userStats);
  const { maraeId } = marae;

  const pointBreakDown = userStats ? userStats?.pointBreakdown : null;
  const pointsMarae = pointBreakDown
    ? pointBreakDown.filter((item) => item.maraeId === maraeId).map((item) => item.points)
    : null;
  const koha = pointsMarae && pointsMarae.length > 0 ? pointsMarae.reduce((acc, current) => acc + current) : 0;
  const connections = maraeStats?.connections || 0;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMaraeStats(marae));
  }, [marae]);

  return (
    <View style={styles.root}>
      <View style={styles.ellipse}>
        <Paragraph style={styles.valueLabel}>
          {section === 'activities' && maraeStats?.connections}
          {section === 'koha' && koha}
        </Paragraph>
      </View>
      <Paragraph style={styles.message}>
        {section === 'activities' &&
          `${i18n.t('components.MaraeParty.activities', {
            conections: connections,
            marae: marae.maraeName,
            year,
          })} `}
        {section === 'koha' &&
          `${i18n.t('components.MaraeParty.koha', {
            koha,
            marae: marae.maraeName,
            totalPoints: maraeStats?.pointTotal,
          })} `}
      </Paragraph>
    </View>
  );
};

export default MaraePartyFragment;

const styles = StyleSheet.create({
  root: {
    width: 300,
    height: 300,
    alignSelf: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  ellipse: {
    borderColor: Colors.yellow,
    width: '50%',
    height: '50%',
    borderWidth: 10,
    borderRadius: 150,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 30,
    marginBottom: 25,
  },
  valueLabel: {
    color: Colors.white,
    fontSize: 60,
  },
  message: {
    color: Colors.white,
    marginTop: 0,
    textAlign: 'center',
  },
});
