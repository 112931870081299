import React, { useState } from 'react';
import { View, Image, Pressable } from 'react-native';
import { UIText } from '../../../components/StyledText';
import { Colors } from '../../../constants/Colors';
import Fonts from '../../../constants/Fonts';
import i18n from '../../../translations';
import VideoPlayer from './VideoPlayer';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';

const VideoContainer = ({ imageUrl, width, height, videoUrl }) => {
  const styles = useStyles();
  const [showVideo, setShowVideo] = useState(false);
  return (
    <View style={styles.root}>
      <Pressable style={styles.playVideoContainer} onPress={() => setShowVideo(true)}>
        <Image
          source={{ uri: imageUrl }}
          style={[
            {
              width,
              height,
            },
            styles.heroImage,
          ]}
        />
        <UIText style={styles.videoLabel}>{i18n.t('web.VideoContainer.watchVideo')}</UIText>
      </Pressable>
      {showVideo && <VideoPlayer videoUrl={videoUrl} close={() => setShowVideo(false)} />}
    </View>
  );
};

export default VideoContainer;
const useStyles = CreateResponsiveStyle(
  {
    root: {
      backgroundColor: Colors.transparent,
      width: '100%',
      height: '100%',
      position: 'absolute',
      zIndex: -1,
    },
    heroImage: {
      transform: [{ translateX: -8 }, { translateY: 0 }, { scale: 0.5 }],
    },
    bottonToTopGradientFilter: {
      position: 'absolute',
      width: '100%',
      height: '40%',
      bottom: 0,
      zIndex: -1,
    },
    playVideoContainer: {
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',
      position: 'absolute',
      bottom: 0,
      marginBottom: 150,
    },
    videoLabel: {
      color: Colors.white,
      fontFamily: Fonts.family.bold,
      fontSize: 14,
      transform: [{ translateY: -100 }],
    },
    videoContainer: {
      backgroundColor: Colors.transparent,
      width: 800,
      height: 485,
      alignSelf: 'center',
    },
  },
  {
    [DEVICE_SIZES.LG]: {
      heroImage: {
        transform: [{ translateX: -8 }, { translateY: 0 }, { scale: 0.5 }],
      },
      videoLabel: {
        transform: [{ translateY: -100 }],
      },
    },
    [DEVICE_SIZES.MD]: {
      heroImage: {
        transform: [{ translateX: -8 }, { translateY: 150 }, { scale: 0.4 }],
      },
      videoLabel: {
        transform: [{ translateY: 35 }],
      },
    },
    [DEVICE_SIZES.SM]: {
      heroImage: {
        transform: [{ translateX: -8 }, { translateY: -70 }, { scale: 0.5 }],
      },
      videoLabel: {
        transform: [{ translateY: -165 }],
      },
    },
    [DEVICE_SIZES.XS]: {
      heroImage: {
        transform: [{ translateX: -6 }, { translateY: 120 }, { scale: 0.3 }],
      },
      videoLabel: {
        transform: [{ translateY: -10 }],
      },
    },
  }
);
