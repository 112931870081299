import * as React from 'react';
import { View } from '../../../../components/Themed';
import Logger from '../../../../constants/Logger';
import { User } from '../../../../constants/Interfaces';
import { DateTime } from 'luxon';
import { getUserWhanau, patchUserWhanau, postUserWhanau } from '../../../../reducers/user.slice';
import { useDispatch, useSelector } from 'react-redux';
import TextInput from '../../../../components/inputs/TextInput';
import i18n from '../../../../translations';
import { PickerInput } from '../../../../components/inputs/PickerInput';
import { listOfEthnicities, listOfGenders } from '../../../../constants/References';
import CalendarInput from '../../../../components/inputs/CalendarInput';
import { ScrollView, StyleSheet } from 'react-native';
import PrimaryButton from '../../../../components/buttons/PrimaryButton';
import { Colors } from '../../../../constants/Colors';
import ModalUI from '../../../../components/ModalUI';
import { UIText } from '../../../../components/StyledText';
import { useNavigation } from '@react-navigation/core';
import BottomBanner from '../../../../components/BottomBanner';
import { useFocusEffect } from '@react-navigation/native';

interface AddWhanauProps {
  user?: User;
  openDetails: (arg: boolean) => void;
  activityId?: string;
  hideFooter: Function;
  isAddWhanau?: boolean;
}

const AddWhanauFragment = ({ user, openDetails, activityId, hideFooter, isAddWhanau = false }: AddWhanauProps) => {
  const dispatch = useDispatch();
  const userLoading: boolean = useSelector((state: any) => state.user.loading);
  const userBackgroundLoading: boolean = useSelector((state: any) => state.user.backgroundLoading);
  const [firstName, setFirstName] = React.useState<string>(user?.firstName ? user?.firstName : '');
  const [lastName, setLastName] = React.useState<string>(user?.lastName ? user?.lastName : '');
  const [gender, setGender] = React.useState<string>(user?.gender ? user?.gender : '');
  const [ethnicity, setEthnicity] = React.useState<string>(user?.ethnicity ? user?.ethnicity : '');
  const [doB, setDoB] = React.useState<string>(user?.doB ? user?.doB : '');
  const [phone, setPhone] = React.useState<string | null>(user?.phone ? user?.phone : null);
  const [email, setEmail] = React.useState<string | null>(user?.email ? user?.email : null);
  const [badEmail, setBadEmail] = React.useState<boolean>(false);

  const styles = StyleSheet.create({
    scrollContainer: {
      width: '100%',
      backgroundColor: Colors.darkBurgundy,
    },
    container: {
      marginTop: 24,
      height: isAddWhanau ? 975 : 965,
    },
    formContainer: {
      paddingHorizontal: 47,
    },
    submitButton: {
      alignItems: 'center',
      marginTop: 4,
      zIndex: 3,
    },
    footerContainer: {
      marginTop: 15,
    },
  });

  const navigation = useNavigation();
  const refStub = {
    hide: () => {},
  };
  const userId = user?.userId;

  useFocusEffect(
    React.useCallback(() => {
      hideFooter(true);
      return () => {
        hideFooter(false);
      };
    }, [])
  );
  const genderRef = React.useRef(refStub);
  const ethnicityRef = React.useRef(refStub);

  const getDropdownY = (position: number) => 4 + 60 * position + 10 * (position - 1);
  /**
   * Handle the global clicking events and hide all dropdowns
   */
  const handleBlur = () => {
    genderRef.current.hide();
    ethnicityRef.current.hide();
  };

  const isFormValid = () => firstName && lastName && gender && ethnicity && doB;

  const emailValidation = (email: string | null) => {
    // eslint-disable-next-line no-useless-escape
    const reg =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email && !reg.test(email!)) {
      Logger.debug('Email is Not Correct');
      setBadEmail(true);
    } else {
      submitForm();
    }
  };

  const handleOpenActivity = (activityId: string) => {
    Logger.debug(`Screen -> ActivitiesScreen -> handleOpenActivity: Navigating to activity ${activityId}`);
    setFirstName('');
    setLastName('');
    setGender('');
    setEthnicity('');
    setDoB('');
    setPhone(null);
    setEmail(null);
    navigation.navigate('ActivitiesStack', {
      screen: 'ActivityDetails',
      params: { activityId },
    });
  };
  const submitForm = async () => {
    Logger.debug('Submitting the form now');
    const user: User = userId
      ? {
          userId,
          firstName,
          lastName,
          phone,
          gender,
          ethnicity,
          doB: doB ? DateTime.fromISO(doB).toFormat('yyyy-MM-dd') : undefined,
        }
      : {
          firstName,
          lastName,
          phone,
          gender,
          ethnicity,
          doB: doB ? DateTime.fromISO(doB).toFormat('yyyy-MM-dd') : undefined,
        };

    if (email && email.length > 0) {
      user.email = email;
    }
    try {
      userId ? await dispatch(patchUserWhanau(user)) : await dispatch(postUserWhanau(user));
      dispatch(getUserWhanau());
      activityId ? handleOpenActivity(activityId) : openDetails(false);
    } catch (e) {
      Logger.debug(`Error occurred: ${e.message}`);
    }
  };

  return (
    <ScrollView bounces={false} style={styles.scrollContainer}>
      <View transparent style={styles.container}>
        <View transparent style={styles.formContainer}>
          <TextInput
            value={firstName}
            onFocus={handleBlur}
            onChangeText={(firstName) => setFirstName(firstName)}
            label={i18n.t('components.DetailsFragment.firstName')}
          />
          <TextInput
            value={lastName}
            onFocus={handleBlur}
            onChangeText={(lastName) => setLastName(lastName)}
            label={i18n.t('components.DetailsFragment.surname')}
          />
          <PickerInput
            ref={genderRef}
            maxItems={6}
            placeholder={i18n.t('components.DetailsFragment.gender')}
            data={listOfGenders}
            value={gender}
            onSelect={(gender: string) => {
              setGender(gender);
            }}
            onActive={handleBlur}
            dropdownStyle={{
              left: 32,
              top: getDropdownY(3),
            }}
          />
          <PickerInput
            ref={ethnicityRef}
            placeholder={i18n.t('components.DetailsFragment.ethnicity')}
            maxItems={6}
            data={listOfEthnicities}
            value={ethnicity}
            onSelect={(ethnicity: string) => {
              setEthnicity(ethnicity);
            }}
            onActive={handleBlur}
            dropdownStyle={{
              left: 32,
              top: getDropdownY(4),
            }}
          />
          <CalendarInput
            onChange={(date) => setDoB(date)}
            value={doB}
            placeholder={i18n.t('components.DetailsFragment.dob')}
            onActive={handleBlur}
          />
          <TextInput
            value={email}
            disabled={!!user?.email}
            onFocus={handleBlur}
            onChangeText={(email) => setEmail(email)}
            label={`${i18n.t('components.DetailsFragment.email')} (${i18n.t('components.DetailsFragment.optional')})`}
          />
          <TextInput
            value={phone}
            onChangeText={(phone) => setPhone(phone)}
            label={`${i18n.t('components.DetailsFragment.mobile')} (${i18n.t('components.DetailsFragment.optional')})`}
            keyboardType="numeric"
          />
          <PrimaryButton
            themeColor="yellow"
            loading={userLoading || userBackgroundLoading}
            disabled={!isFormValid() || userLoading}
            title={i18n.t('components.DetailsFragment.save')}
            onPress={() => emailValidation(email)}
            containerStyle={styles.submitButton}
          />
          <View style={{ height: 100, backgroundColor: Colors.darkBurgundy }} />
        </View>
        <View style={styles.footerContainer}>
          <BottomBanner themeColor="white" />
        </View>
      </View>
      <ModalUI toggle={badEmail} closeModal={() => setBadEmail(false)}>
        <UIText>Email not valid</UIText>
      </ModalUI>
    </ScrollView>
  );
};
export default AddWhanauFragment;
