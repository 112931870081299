import { Action } from 'redux';
import { TokenType } from 'expo-auth-session/src/TokenRequest.types';

// Below are enums (A-Z)
export enum ActionType {
  PURGE,
}

export enum Ethnicity {
  MAORI = 'Māori',
  EUROPEAN = 'European',
  PACIFIC = 'Pacific peoples',
  ASIAN = 'Asian',
  MELAA = 'MELAA (Middle Eastern / Latin American / African)',
  OTHER = 'Other',
}

export enum Gender {
  MALE = 'Male',
  FEMALE = 'Female',
  NON_BINARY = 'Non-binary',
  TRANSGENDER = 'Transgender',
  INTERSEX = 'Intersex',
  NOT_TO_SAY = 'Not-to-say',
}

export enum Role {
  USER = 'Whānau',
  USER_ADMIN = 'Kaiwhakahaere',
  ORGANISER = 'Organiser',
  ADMIN = 'Admin (SportNZ)',
}

export enum Scope {
  API = 'api',
  ANONYMOUS = 'anonymous',
}

export enum WhanauType {
  MARAE = 'Marae',
  HAPU = 'Hapu',
  IWI = 'Iwi',
  WHANAU = 'Whānau',
  KOHANGA_PUNA_REO = 'Kohanga / Puna Reo',
  KURA_KAUPAPA = 'Kura Kaupapa',
  WHARE_KURA = 'Whare Kura',
  WHARE_WANANGA = 'Whare Wananga',
  KAUPAPA_HAKINAKINA = 'Kaupapa Hākinakina',
  OTHER = 'Other',
}

// Below are interfaces (A-Z)
export interface AccessToken {
  token: string;
  expiry: string; // ISO-8201 date format
}

export interface Activity {
  activityId: string;
  activityName?: string;
  location?: Location;
  organisation?: Organisation;
  organiser?: Organiser;
  description?: string;
  imageUrl?: string;
  startDate?: string;
  endDate?: string | null;
  kaiwhakahaereId?: string;
  created?: string;
}

export interface ActivityParticipation {
  userId: string;
  activityId: string;
  password?: string;
}

export interface ActivityStats {
  engagementTypeCheckedIn: Engagement;
  engagementTypeGoing: Engagement;
}

export interface AppError {
  message: string;
  params?: [];
}

export interface AuthState {
  accessToken: string;
  tokenType: TokenType;
  expiresIn?: number;
  refreshToken?: string;
  scope?: string;
  state?: string;
  idToken?: string;
  issuedAt: number;
}

export interface CarouselCard {
  title?: string;
  paragraph: string;
}

export interface Dictionary<T> {
  [key: string]: T;
}

export interface DropdownItem {
  label: string;
  value: string | number;
}

export interface Engagement {
  pointsTotal: number;
  personTotal: number;
  ageOfParticipantsMokopuna: number;
  ageOfParticipantsRangatahi13_19: number;
  ageOfParticipantsMatua20_39: number;
  ageOfParticipantsPakeke40_59: number;
  ageOfParticipantsKaumatua: number;
  ageOfParticipantsTaiohi: number;
  ageOfParticipantsUnknown: number;
  genderOfParticipantsTane: number;
  genderOfParticipantsWahine: number;
  genderOfParticipantsNonBinary: number;
  genderOfParticipantsTransgender: number;
  genderOfParticipantsIntersex: number;
  genderOfParticipantsPreferNotToSay: number;
  ethnicityOfParticipantsMaori: number;
  ethnicityOfParticipantsEuropean: number;
  ethnicityOfParticipantsPasifika: number;
  ethnicityOfParticipantsAsian: number;
  ethnicityOfParticipantsMelaa: number;
  ethnicityOfParticipantsOther: number;
}

export interface Hapu {
  hapuId?: number;
  iwiId?: number;
  hapuName?: string;
}

export interface Iwi {
  iwiId?: number;
  regionId?: number;
  iwiName?: string;
  regionName?: string;
}

export interface IwiActivityPoints {
  iwiId?: number;
  activityId?: number;
  points?: number;
}

export interface IwiHapuMarae {
  maraeId?: number;
  iwiId?: number;
  hapuId?: number;
}

export interface IwiMarae extends Marae {
  hapu?: Hapu;
}

export interface Location {
  name?: string | null;
  regionName?: string;
  regionNameEnglish?: string;
  addressLine1?: string | null;
  addressLine2?: string | null;
  suburb?: string | null;
  city?: string;
  postcode?: string | null;
  geoLat?: number | null;
  geoLong?: number | null;
}

export interface Marae {
  maraeId?: number;
  maraeName?: string;
  wharenui?: string;
  imageUrl?: string;
  maraeLocation?: MaraeLocation;
}

export interface MaraeActivityPoints {
  maraeId?: number;
  activityId?: number;
  points?: number;
}

export interface MaraeLocation {
  city?: string;
  regionName?: string;
  regionNameEnglish?: string;
}

export interface Organisation {
  name?: string;
}

export interface Organiser {
  firstName: string;
  lastName: string;
  userId: string | null;
  imageUrl: string | null;
  phone: string | null;
  email: string | null;
}

export interface ReduxAction extends Action {
  payload?: any;
  error?: boolean;
}

export interface Region {
  regionId?: number;
  regionName?: string;
  regionNameEnglish?: string;
}

export interface Review {
  userId?: string;
  activityId?: string;
  activityName?: string; // required locally for ReviewCardFragment
  firstName?: string; // required locally for ReviewCardFragment
  lastName?: string; // required locally for ReviewCardFragment
  imageUrl?: string;
  score?: number;
  review?: string;
  lastUpdated?: string; // required locally for ReviewCardFragment
  gender?: string;
}
export interface SearchTerm {
  title: string; // case insensitive, always stored as upper case
  count: number;
}
export interface User {
  userId?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string | null;
  doB?: string;
  gender?: string | null;
  ethnicity?: string | null;
  role?: Role;
  region?: Region;
  marae?: UserMarae[];
  imageUrl?: string | null;
}

export interface UserActivity extends Activity {
  userId?: string;
  favourite: boolean;
  going: boolean;
  checkedIn?: boolean;
  maraeId?: number;
  whanauType?: string;
}

export interface UserMarae extends Marae {
  default?: boolean | null;
  iwi?: Iwi | null;
  hapu?: Hapu | null;
}

export interface WhanauMarae {
  userId?: string;
  maraeId?: number; // mandatory
  iwiId?: number; // mandatory
  hapuId?: number;
  default?: boolean;
}
export interface MaraeStats {
  topActivities: [
    {
      activityId: string;
      activityName: string;
      endDate: string;
      pointsTotal: number;
    }
  ];
  connections: number;
  pointTotal: number;
}
export interface UserStats {
  pointTotal: number;
  pointBreakdown: [
    {
      userId: string;
      maraeId: number;
      points: number;
    }
  ];
}
export interface VisitItem {
  id: string | number;
  type: 'Activity';
  timestamp?: string; // ISO datetime string
}
export interface WhanauParticipation {
  userId?: string;
  activityId?: number;
  maraeId?: number;
  iwiId?: number;
  favourite?: boolean | null;
  interested?: boolean | null;
  going?: boolean | null;
  checkedIn?: boolean | null;
  scoredPoints?: number | null;
  checkinDate?: string | null;
}

export interface TicketActivity {
  activityId: string;
  userId?: string;
  name: string;
}
export interface PaperQrCode {
  activityId: string;
  password: string;
}
export interface MaraeLeaderboard {
  maraeId: number;
  maraeName: string;
  region: string;
  pointTotal: number;
  participationTotal: number;
}
// Below are types (A-Z)
export type BottomTabParamList = {
  Home: undefined;
  TabTwo: undefined;
};
export type DrawerParamList = {
  ProfileStack: undefined;
  ActivitiesStack: undefined;
  FavesStack: undefined;
  LeaderboardStack: undefined;
  DashboardStack: undefined;
  ConfirmationScreen: undefined;
  openDrawer: Function;
};

export type AuthParamList = {
  Landing: undefined;
  SocialMediaLogin: undefined;
  Terms: undefined;
  Privacy: undefined;
};

export type SignUpParamList = {
  ParticipationConfirmation: undefined;
  UserDetails: undefined;
  AddMarae: undefined;
  SelectRegion: undefined;
  SelectMarae: undefined;
  TipScreen: undefined;
};

export type ActivitiesParamList = {
  Activities: undefined;
  ActivityDetails: undefined;
  SelectRegion: undefined;
  TipScreen: undefined;
};

export type ProfileParamList = {
  Profile: undefined;
};

export type HomeParamList = {
  Home: undefined;
  ActivityDetails: undefined;
  SelectRegion: undefined;
  TipScreen: undefined;
};

export type RootStackParamList = {
  LandingWeb: undefined;
  ActivityDetails: { id: string };
  About: undefined;
  Privacy: undefined;
  Terms: undefined;
  Contact: undefined;
  SignIn: undefined;
  AddWhanau: undefined;
  Root: DrawerParamList;
  AuthStack: AuthParamList;
  SignUpStack: SignUpParamList;
  ActivitiesListing: undefined;
  Delete: undefined;
};

export type TabTwoParamList = {
  TabTwoScreen: undefined;
};

export enum DomainHintList {
  google = 'google.com',
  facebook = 'facebook.com',
  apple = 'apple.com',
}

export enum ProfileSection {
  details,
  photo,
  activities,
  delete,
}
declare global {
  namespace ReactNavigation {
    interface RootParamList extends RootStackParamList {}
  }
}
