import * as React from 'react';
import { FlatList, Image, Pressable, StyleSheet } from 'react-native';
import { Activity, AuthState, User } from '../../constants/Interfaces';
import { useSelector } from 'react-redux';
import Logger from '../../constants/Logger';
import { Heading, UIText } from '../../components/StyledText';
import { View } from '../../components/Themed';
import i18n from '../../translations';
import { Colors } from '../../constants/Colors';
import Fonts from '../../constants/Fonts';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import Layout from '../../constants/Layout';
import { ActivityTile } from '../../components/ActivityTile';
import ModalUI from '../../components/ModalUI';
import { RegionFilterFragment } from './fragments/RegionFilterFragment';
import { selectRegion } from '../../reducers/region.slice';
import GridActivities from '../../components/GridActivities';
import { getActivities, getUserActivities } from '../../reducers/activity.slice';
import RefreshHolder from '../../components/RefreshHolder';
import useLogoutUser from '../../hooks/useLogoutUser';
import { validatePastActivity } from '../../constants/Utils';
import { useAppDispatch } from '../../store/configureStore';

export const ActivitiesScreen = ({ navigation }) => {
  const dispatch = useAppDispatch();
  const filterRegion: string = useSelector((state: any) => state.region.selectedRegion);
  const regions: string[] = useSelector((state: any) => state.region.regions);
  const activities: Activity[] = useSelector((state: any) => state.activity.activities);
  const user: User = useSelector((state: any) => state.user.user);
  const authState: AuthState = useSelector((state: any) => state.authentication.authState);
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const filteredActivities = filterRegion
    ? activities.filter((activity) => activity?.location?.regionName === filterRegion)
    : activities;

  const sortedActivities: Activity[] = [...filteredActivities]
    // eslint-disable-next-line no-nested-ternary
    .sort((a, b) => (a.created! < b.created! ? 1 : b.created! < a.created! ? -1 : 0))
    .filter((activity) => !validatePastActivity(activity.endDate));

  const handleOpenActivity = (activityId: string) => {
    Logger.debug(`Screen -> ActivitiesScreen -> handleOpenActivity: Navigating to activity ${activityId}`);
    navigation.navigate('ActivityDetails', {
      activityId,
    });
  };

  const handleRegionToggle = () => {
    Logger.debug(`Region toggle clicked. `);
    navigation.navigate('SelectRegion', {
      regionFilter: 'activities',
    });
  };

  const handleRegionSelection = (regionName: string) => {
    dispatch(selectRegion(regionName));
    setShowModal(false);
  };

  useLogoutUser();

  const renderItem = ({ item }: { item: Activity }) => <ActivityTile activity={item} onPress={handleOpenActivity} />;

  const handleRefresh = async () => {
    await dispatch(getActivities(authState));
    if (user) {
      await dispatch(getUserActivities());
    }
  };
  return (
    <RefreshHolder navigation={navigation} refresh={handleRefresh}>
      <View style={styles.root}>
        <View transparent>
          <Heading style={styles.title}>{i18n.t('pages.ActivitiesScreen.title')}</Heading>
          <Pressable onPress={handleRegionToggle}>
            <View transparent style={styles.regionContainer}>
              <UIText style={styles.region}>{filterRegion || i18n.t('pages.ActivitiesScreen.noRegion')}</UIText>
              <View style={styles.filterIconContainer}>
                <Image source={require('../../../assets/images/filterIconWhite.png')} style={styles.filterIcon} />
              </View>
            </View>
          </Pressable>
        </View>
        <View transparent style={styles.recentActivitiesContainer}>
          <View transparent style={styles.recentTitleContainer}>
            <UIText style={styles.recentTitle}>{i18n.t('pages.ActivitiesScreen.recentlyListed')}</UIText>
            <MaterialCommunityIcons name="arrow-right" color={Colors.yellow} size={24} />
          </View>
          <FlatList
            horizontal
            scrollEnabled
            overScrollMode="never"
            data={sortedActivities}
            showsHorizontalScrollIndicator={false}
            renderItem={renderItem}
            getItemLayout={(data, index) => ({ length: 200, offset: 200 * index, index })}
            keyExtractor={(item) => item.activityId}
          />
        </View>
        <View transparent style={styles.bottomHalfContainer}>
          <UIText style={styles.recentTitle}>{i18n.t('pages.ActivitiesScreen.roheActivities')}</UIText>
          {activities && (
            <GridActivities
              activities={[...filteredActivities].sort(() => Math.random() - 0.5)}
              navigation={navigation}
            />
          )}
        </View>
      </View>

      <ModalUI closeModal={() => setShowModal(false)} toggle={showModal} contentContainerStyle={styles.modal}>
        <RegionFilterFragment regions={regions} onSelect={handleRegionSelection} resetOption />
      </ModalUI>
    </RefreshHolder>
  );
};

const styles = StyleSheet.create({
  root: {
    minHeight: Layout.window.height + 180,
    backgroundColor: Colors.transparent,
    paddingHorizontal: Layout.backgroundMarginNarrow.left,
    paddingBottom: 150,
  },
  title: {
    color: Colors.white,
    fontSize: Fonts.sizes.smallHeading,
  },
  regionContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottomWidth: 1,
    paddingBottom: 8,
    borderBottomColor: Colors.yellow,
  },
  region: {
    color: Colors.white,
    fontSize: Fonts.sizes.smallHeading,
  },
  filterIconContainer: {
    backgroundColor: Colors.yellow,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 100,
    width: 30,
    height: 30,
  },
  filterIcon: {
    width: 20,
    height: 20,
    resizeMode: 'contain',
  },
  recentActivitiesContainer: {
    marginVertical: 12,
  },
  recentTitle: {
    color: Colors.yellow,
    marginBottom: 12,
  },
  subTitle: {
    color: Colors.white,
  },
  recentTitleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 12,
  },
  bottomHalfContainer: {},
  searchContainer: {
    width: '45%',
  },
  searchInnerContainer: {
    height: 200,
    marginRight: 12,
    marginTop: 12,
    backgroundColor: Colors.white,
    borderRadius: 5,
    paddingTop: 16,
    paddingHorizontal: Layout.backgroundMarginNarrow.left,
  },
  searchItemContainer: {
    flexDirection: 'row',
    marginBottom: 18,
  },
  countContainer: {
    backgroundColor: Colors.yellow,
    width: 18,
    height: 18,
    borderRadius: 18,
    alignItems: 'center',
    justifyContent: 'center',
  },
  count: {
    fontSize: Fonts.sizes.mini,
    fontFamily: Fonts.family.bold,
    color: Colors.darkBurgundy,
  },
  modal: {
    maxHeight: 400,
  },
});
