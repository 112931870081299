import React, { useState } from 'react';
import { TouchableOpacity, View, StyleSheet, Pressable } from 'react-native';
import { Camera, CameraCapturedPicture, CameraType } from 'expo-camera';
import { AntDesign, MaterialIcons } from '@expo/vector-icons';
import { Colors } from '../constants/Colors';
import Layout from '../constants/Layout';

const PhotoCamera = ({ setImage, openCamera }) => {
  const [type, setType] = useState(CameraType.back);

  let camera: Camera | null;

  const handleCloseModal = () => {
    openCamera(false);
  };

  const onSnap = async () => {
    if (camera) {
      const options = { quality: 0.7, base64: true };
      const data: CameraCapturedPicture = await camera.takePictureAsync(options);
      setImage(data);
    }
    openCamera(false);
  };
  const handleCamera = () => {
    setType(type === CameraType.back ? CameraType.front : CameraType.back);
  };
  return (
    <View style={styles.frame}>
      <Camera
        style={styles.cameraContainer}
        ref={(r) => {
          camera = r;
        }}
        type={type}
      />
      <Pressable style={styles.close} onPress={handleCloseModal}>
        <AntDesign name="close" size={24} color={Colors.white} />
      </Pressable>
      <Pressable style={styles.flipIcon} onPress={handleCamera}>
        <MaterialIcons name="flip-camera-ios" size={25} color="white" />
      </Pressable>
      <TouchableOpacity onPress={onSnap} style={styles.togglePicture} />
    </View>
  );
};

export default PhotoCamera;
const styles = StyleSheet.create({
  cameraContainer: {
    ...StyleSheet.absoluteFillObject,
    marginHorizontal: 15,
  },
  text: {
    color: 'white',
  },
  frame: {
    height: '100%',
    width: '100%',
  },
  togglePicture: {
    position: 'absolute',
    width: 70,
    height: 70,
    bottom: 20,
    left: Layout.window.width / 2 - 35,
    zIndex: 1000,
    borderRadius: 50,
    backgroundColor: '#fff',
  },
  close: {
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    width: 42,
    height: 42,
    right: 25,
    top: 10,
    zIndex: 1000,
    backgroundColor: Colors.yellow,
    borderRadius: 25,
  },
  flipIcon: {
    position: 'absolute',
    width: 27,
    height: 27,
    top: 18,
    zIndex: 1000,
    left: 30,
  },
});
